import { takeUntil } from 'rxjs/operators';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CategoryService } from 'src/app/services/be-notifications/category/category.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';

@Component({
  selector: 'app-notifications-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [
    CategoryService
  ]
})
export class CategoryComponent extends GenericListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private categoryService: CategoryService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'name', 'bundleKey', 'durationCancellation', 'durationRead', 'durationAbsolute', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      name: undefined,
      bundleKey: undefined,
      page: 0,
      size: 10,
      length: 0
    };
  }

  list(): void{
    this.categoryService.readAll(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
      this.parameters.name, this.parameters.bundleKey)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      this.parameters.dataSource = response.content;
      this.parameters.page = response.page;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
