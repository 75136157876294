import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { takeUntil } from 'rxjs/operators';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductService } from 'src/app/services/be-subscriptions/product/product.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends GenericListComponent implements OnInit, OnDestroy {

  translations: Map<string, string> = ProductDTO.translations;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private productService: ProductService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'name', 'translation', 'cost', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false
    };
  }

  list(): void{
    this.productService.readAll()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      console.log('response : ', response);
      this.parameters.dataSource = response;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
