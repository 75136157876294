<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Ticket management</h2>
      <p>Imposta i filtri di ricerca</p>


      <div fxLayout="column" class="responsive-mat-form">
          
          <!-- USER FIELD -->
          <mat-form-field>
            <input matInput [(ngModel)]="parameters.username" placeholder="Username utente bitdiver"/>
          </mat-form-field>

          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            <!-- dateFrom field -->
            <mat-form-field fxFlex="33%" fxFlex.sm="33%" fxFlex.xs="100%"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right':  2 + '%'}">
              <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dateTo"
                [(ngModel)]="parameters.dateFrom" [matDatepicker]="picker" placeholder="Scegli data da">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <button mat-button *ngIf="parameters.dateFrom" matSuffix mat-icon-button aria-label="Clear"
                (click)="parameters.dateFrom=null">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- dateTo field -->
            <mat-form-field fxFlex="33%" fxFlex.sm="33%" fxFlex.xs="100%"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right':  2 + '%'}">
              <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dateFrom"
                [(ngModel)]="parameters.dateTo" [matDatepicker]="picker2" placeholder="Scegli data a">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <button mat-button *ngIf="parameters.dateTo" matSuffix mat-icon-button aria-label="Clear"
                (click)="parameters.dateTo=null">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- ID FIELD -->
            <mat-form-field fxFlex="34%" fxFlex.sm="33%" fxFlex.xs="100%">
              <input type="number" matInput [(ngModel)]="parameters.idTicket" placeholder="Id ticket"/>
            </mat-form-field>
          </div>
          
          <!-- STATUS FIELD -->
          <mat-form-field >
            <mat-select name="status" [(ngModel)]="parameters.status" placeholder="Stato del ticket">
              <mat-option [value]="null">
                Tutti  
              </mat-option>
              <mat-option *ngFor="let stato of status" [value]="stato">
                {{ stato }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <!-- CATEGORY FIELD -->
          <mat-form-field>
            <mat-select name="category" [(ngModel)]="parameters.category" placeholder="Categoria ticket" [(ngModel)]="parameters.category">
              <mat-option [value]="null">
                Tutte  
              </mat-option>
              <mat-option *ngFor="let category of categories" [value]="category">
                {{ category}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>

      <mat-card-actions>
        <button (click)="list()" mat-raised-button id="search-button" name="Search" color="primary">Search</button>
        <button mat-raised-button (click)="back('/')" color="primary">Back</button>
      </mat-card-actions>

      <div *ngIf="parameters.showList" fxLayout="column">

        <h2 *ngIf="parameters.engSelect">Lingua/Preferenza user BitDiver: italiano</h2>
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" matSortDisableClear
          [dataSource]="parameters.dataSourceIta">

          <!-- Detail -->
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef>Detail</th>
            <td mat-cell *matCellDef="let ticket">
              <button mat-mini-fab color="primary" [routerLink]="['/ticket-management/detail/', ticket.id, ticket.insertUser]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.id}} </td>
          </ng-container>

           <!-- Data creation Column -->
          <ng-container matColumnDef="insertDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data creazione </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.insertDate |date : 'dd/MM/yyyy' }} </td>
          </ng-container>

           <!-- Data closure Column -->
          <ng-container matColumnDef="updateDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data chiusura </th>
            <td  mat-cell *matCellDef="let ticket">
              <span *ngIf="ticket.status==='CHIUSA'"> {{ticket.updateDate |date : 'dd/MM/yyyy' }} </span>
              <span *ngIf="ticket.status!=='CHIUSA'"></span>
            </td>
          </ng-container>

          <!-- User Column -->
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Utente bitdiver </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.insertUser}} </td>
          </ng-container>

           <!-- Oggetto Column -->
          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Oggetto </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.subject}} </td>
          </ng-container>

           <!-- stato Column -->
           <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Stato </th>
            <td mat-cell *matCellDef="let ticket" >
              <mat-icon style="padding-left: 6%;" title="CREATA" *ngIf="ticket.status==='CREATA'" class="icon-creata">fiber_new</mat-icon> 
              <mat-icon style="padding-left: 6%;" title="IN_ATTESA_DI_FEEDBACK" *ngIf="ticket.status==='IN_ATTESA_DI_FEEDBACK'" class="icon-inAttesaDiFeedback" >feedback</mat-icon> 
              <mat-icon style="padding-left: 6%;" title="IN_ATTESA_DI_RISPOSTA" *ngIf="ticket.status==='IN_ATTESA_DI_RISPOSTA'" class="icon-inAttesaDiRisposta" >pause_circle_filled</mat-icon> 
              <mat-icon style="padding-left: 6%;" title="CHIUSA" *ngIf="ticket.status==='CHIUSA'" class="icon-chiusa">close</mat-icon> 
            </td>
          </ng-container>

          <!-- Categoria Column -->
          <ng-container matColumnDef="category" >
            <th mat-header-cell *matHeaderCellDef > Categoria </th>
            <td mat-cell *matCellDef="let ticket"> 
              <mat-icon title="Segnala un'anomalia" *ngIf="ticket.category==='Segnala un\'anomalia' || ticket.category==='Report an anomaly'" >report_problem</mat-icon> 
              <mat-icon title="Richiesta informazioni" *ngIf="ticket.category==='Richiesta informazioni' || ticket.category==='Information request'" >info</mat-icon> 
              <mat-icon title="Problemi sui pagamenti" *ngIf="ticket.category==='Problemi sui pagamenti' || ticket.category==='Payment problems'" >payment</mat-icon> 
              <mat-icon title="Richiesta di una nuova funzionalità" *ngIf="ticket.category==='Richiesta di una nuova funzionalità' || ticket.category==='Request for a new feature'" >add_comment</mat-icon> 
            </td>
          </ng-container>

          <!-- Pagante Column -->
          <ng-container matColumnDef="paying">
            <th mat-header-cell *matHeaderCellDef> Pagante </th>
            <td mat-cell *matCellDef="let ticket">
              <mat-icon title="PAGANTE" *ngIf="isTicketPaying(ticket); else notPaying"
                class="icon-creata">check</mat-icon>
              <ng-template #notPaying>
                <mat-icon title="NON PAGANTE" class="icon-chiusa">close</mat-icon>
              </ng-template>
            </td>
          </ng-container>

          <!-- Ultima risposta Column -->
          <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Content </th>
            <td mat-cell *matCellDef="let ticket" [innerHTML]="(ticket.content.length>75)? (ticket.content | slice:0:75) +'...':(ticket.content)"></td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="parameters.size" [pageIndex]="parameters.page" [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>


      <div *ngIf="parameters.engSelect" fxLayout="column">
        <h2>Lingua/Preferenza user BitDiver: inglese</h2>

        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" matSortDisableClear
          [dataSource]="parameters.dataSourceEng">

          <!-- Detail -->
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef>Detail</th>
            <td mat-cell *matCellDef="let ticket">
              <button mat-mini-fab color="primary" [routerLink]="['/ticket-management/detail/', ticket.id, ticket.insertUser]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.id}} </td>
          </ng-container>

           <!-- Data creation Column -->
          <ng-container matColumnDef="insertDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data creazione </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.insertDate |date : 'dd/MM/yyyy' }} </td>
          </ng-container>

           <!-- Data closure Column -->
          <ng-container matColumnDef="updateDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data chiusura </th>
            <td  mat-cell *matCellDef="let ticket" >
              <span *ngIf="ticket.status==='CHIUSA'"> {{ticket.updateDate |date : 'dd/MM/yyyy' }} </span>
              <span *ngIf="ticket.status!=='CHIUSA'"></span>
            </td>
          </ng-container>

          <!-- User Column -->
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Utente bitdiver </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.insertUser}} </td>
          </ng-container>

           <!-- Oggetto Column -->
          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Oggetto </th>
            <td mat-cell *matCellDef="let ticket"> {{ticket.subject}} </td>
          </ng-container>

           <!-- stato Column -->
           <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
            <td mat-cell *matCellDef="let ticket" >
              <mat-icon title="CREATA" *ngIf="ticket.status==='CREATA'" class="icon-creata">fiber_new</mat-icon> 
              <mat-icon title="IN_ATTESA_DI_FEEDBACK" *ngIf="ticket.status==='IN_ATTESA_DI_FEEDBACK'" class="icon-inAttesaDiFeedback" >feedback</mat-icon> 
              <mat-icon title="CHIUSA" *ngIf="ticket.status==='CHIUSA'" class="icon-chiusa">close</mat-icon> 
            </td>
          </ng-container>

          <!-- Categoria Column -->
          <ng-container matColumnDef="category" >
            <th mat-header-cell *matHeaderCellDef > Categoria </th>
            <td mat-cell *matCellDef="let ticket"> 
              <mat-icon title="Segnala un'anomalia" *ngIf="ticket.category==='Report an anomaly'" >report_problem</mat-icon> 
              <mat-icon title="Richiesta informazioni" *ngIf="ticket.category==='Information request'" >info</mat-icon> 
              <mat-icon title="Problemi sui pagamenti" *ngIf="ticket.category==='Payment problems'" >payment</mat-icon> 
              <mat-icon title="Richiesta di una nuova funzionalità" *ngIf="ticket.category==='Request for a new feature'" >add_comment</mat-icon> 
            </td>
          </ng-container>

          <!-- Pagante Column -->
          <ng-container matColumnDef="paying">
            <th mat-header-cell *matHeaderCellDef> Pagante </th>
            <td mat-cell *matCellDef="let ticket">
              <mat-icon title="PAGANTE" *ngIf="isTicketPaying(ticket); else notPaying"
                class="icon-creata">check</mat-icon>
              <ng-template #notPaying>
                <mat-icon title="NON PAGANTE" class="icon-chiusa">close</mat-icon>
              </ng-template>
            </td>
          </ng-container>

          <!-- Ultima risposta Column -->
          <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Content </th>
            <td mat-cell *matCellDef="let ticket" [innerHTML]="(ticket.content.length>75)? (ticket.content | slice:0:75) +'...':(ticket.content)"></td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="parameters.sizeEng" [pageIndex]="parameters.pageEng" [length]="parameters.lengthEng" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
      
      <br/> 
  </mat-card-content>
</mat-card>