import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends GenericService<ProductDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/product';
    //this.url = "http://localhost:30300/product/admin/products"
  }

  public readAll(): Observable<ProductDTO[]> {
    return this.http.get<ProductDTO[]>(`${this.url}/admin/products`);
  }
}
