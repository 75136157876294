import {
  dataintegrationStatus,
  dataintegrationType
} from '../../shared/dto/dataintegration-catalog/DataIntegrationStatus';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { DataintegrationService } from 'src/app/services/dataintegration-catalog/dataintegration.service';

@Component({
  selector: 'app-dataintegration',
  templateUrl: './dataintegration.component.html',
  styleUrls: ['./dataintegration.component.scss'],
  providers: [
    DataintegrationService
  ]
  })
export class DataintegrationComponent extends GenericListComponent implements OnInit, OnDestroy {
[x: string]: any;

  readonly dataintegrationStatus = dataintegrationStatus;
  readonly dataintegrationType =dataintegrationType;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private dataintegrationService: DataintegrationService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['id', 'dataIntegrationName', 'dataIntegrationStatus', 'dataIntegrationBeta', 'dataIntegrationType', 'detail' ];
      this.parameters = {
        dataSource: [],
        showList: false,
        filter: undefined,
        sort: undefined,
        page: 0,
        size: 10,
        dataintegrationStatus: undefined,
        dataIntegrationName: undefined,
        dataIntegrationType: undefined,
        length: 0
      };
  };

list(): void {
    this.dataintegrationService.query({page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
         this.parameters.filter, this.parameters.dataintegrationStatus, this.parameters.dataIntegrationType).pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe((dataIntegrations) => {
      this.parameters.dataSource = dataIntegrations.content;
      this.parameters.page = dataIntegrations.page;
      this.parameters.length = dataIntegrations.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
