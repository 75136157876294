import { LogicalDeleteDTO } from '../../basic/logicalDeleteDTO';

export class SubscriptionExtensionDTO extends LogicalDeleteDTO {

    id: number;

    subscriptionId: number;

    promotionCode: string;

    affiliationPerk: boolean;

}
