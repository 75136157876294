import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';
import { AppDTO } from '../shared/dto/config/app';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  app: AppDTO;

  constructor(private navigatorService: NavigatorService,
    private router: Router) { }

  ngOnInit(): void {
    this.navigatorService.app$.subscribe(
      (res: AppDTO) => {
        this.app = res;
      }
    );
  }

  back(url: string): void {
    this.router.navigate([url]);
  }

}
