import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DisclaimerType } from 'src/app/shared/dto/disclaimer/DisclaimerType.types';
import { StatoDisclaimer } from 'src/app/shared/dto/disclaimer/StatoDisclaimer.types';
import { TranslationDto } from 'src/app/shared/dto/disclaimer/TranslationDto.types';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable()
export class TranslationService extends GenericService<TranslationDto, number> {

    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-be-disclaimer/translation';
    }

    translationsByFilter(language?: string, stato?: StatoDisclaimer, required?: boolean, disclaimerType?: DisclaimerType): Observable<TranslationDto[]> {
        const headers = {'language': language, 'stato': stato + '', 'required': required + '', 'disclaimerType': disclaimerType + ''};
        Object.keys(headers).forEach(key => headers[key] === 'undefined' && delete headers[key]);
        return this.http.get<TranslationDto[]>(`${this.url}/translationsByFilter`, {headers: headers});
    }

    getTranslationsNotRead(language: string): Observable<TranslationDto[]> {
        return this.http.get<TranslationDto[]>(`${this.url}/translationsNotRead`, {headers: {language}});
    }

    findByMessageId(messageId: number): Observable<TranslationDto[]> {
        return this.http.get<TranslationDto[]>(`${this.url}/findByMessageId/${messageId}`, {params: {messageId : messageId + ''}});
    }
}
