<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Social</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Social Id" readonly="true" />
                </mat-form-field>

                <!-- socialName field -->
                <mat-form-field>
                    <input matInput type="text" formControlName="socialName" placeholder="socialName">
                    <mat-error *ngIf="form.get('socialName').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('socialName').errors?.maxlength">
                        socialName max length reached (64 max)
                    </mat-error>
                </mat-form-field>

                <!-- socialType field -->
                <mat-form-field>
                    <mat-select formControlName="socialType" placeholder="SocialType">
                        <mat-option *ngFor="let aSocialType of socialTypeList" [value]="aSocialType">
                            {{aSocialType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- socialLink field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="socialLink" placeholder="SocialLink">
                    <mat-error *ngIf="form.get('socialLink').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('socialLink').errors?.maxlength">
                        socialLink max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- favorite field -->
                <div fxLayout="row" class="toggle-div">
                    <mat-slide-toggle color="primary" class="toggle-text" [checked]="social?.favorite" (change)="modificaPreferito()">Favorite</mat-slide-toggle>
                    
                </div>



                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('gestione-affiliation/detail/' + influencerId)" color="primary">Back</button>
            <button mat-raised-button (click)="socialSave()" [disabled]="(form.invalid || (!favoriteChanged && !form.dirty)) || (form.untouched && !favoriteChanged)">
                Save
            </button>
            <button mat-raised-button (click)="socialDelete()" [disabled]="social?.id === null">
                Delete
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>