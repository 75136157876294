import { TraceModificationDTO } from './../../basic/traceModificationDTO';

export class PromotionDTO extends TraceModificationDTO {

    id: number;

    code: string;

    days: number;

    months: number;

    description: string;

    active: boolean;

    idProduct: number;


}
