import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
  } from '@angular/common/http';
  import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FakeAuthenticationHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.production) {
      return next.handle(req);
    } else {
      const clonedRequest = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + environment.fakeBearer) });
      return next.handle(clonedRequest);
     }
  }
}
