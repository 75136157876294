import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PositionsService } from 'src/app/services/core-wallets/positions.service';
import { SymbolMappingDto } from 'src/app/shared/dto/core-wallets/symbolMappingDto';

@Component({
  selector: 'app-symbol-mapping-detail',
  templateUrl: './symbol-mapping-detail.component.html',
  styleUrls: ['./symbol-mapping-detail.component.scss'],
  providers: [
    PositionsService
  ]
})
export class SymbolMappingDetailComponent extends GenericDetailComponent implements OnInit {

  constructor(
    private positionsService: PositionsService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    this.form = this.loadForm(new SymbolMappingDto());
  }

  save(): void {
    this.form.markAsPristine();
    this.saveSymbolMapping(this.toSymbolMappingDto());
  }

  private saveSymbolMapping(symbol: SymbolMappingDto): void {
    this.positionsService.updateSymbolBySymbol(symbol.oldSymbol, symbol.newSymbol)
      .pipe(catchError(()=> of(null)))
      .subscribe(() => this.router.navigate(['/']));
  }

  private loadForm(symbolMapping: SymbolMappingDto): FormGroup {
    return this._formBuilder.group({...symbolMapping, ...this.formValidators(symbolMapping)});
  }

  private toSymbolMappingDto(): SymbolMappingDto {
    return this.form.getRawValue() as SymbolMappingDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(symbolMapping: SymbolMappingDto) {
    return {
      oldSymbol: [symbolMapping.oldSymbol, [Validators.required, Validators.maxLength(32)]],
      newSymbol: [symbolMapping.newSymbol, [Validators.required, Validators.maxLength(32)]],
    };
  }
}
