import { takeUntil } from 'rxjs/operators';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SubscriptionExtensionService } from 'src/app/services/be-subscriptions/extension/subscription-extension.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';

@Component({
  selector: 'app-subscription-extension-table',
  templateUrl: './subscription-extension-table.component.html',
  styleUrls: ['./subscription-extension-table.component.scss']
})
export class SubscriptionExtensionTableComponent extends GenericListComponent implements OnInit, OnDestroy {

  @Input() subscriptionId: number;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private extensionService: SubscriptionExtensionService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'subscription.id', 'promotionCode', 'affiliationPerk', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sort: undefined,
      page: 0,
      size: 50,
      length: 0
    };
  }

  list(): void{
    this.extensionService.readAllBySubscription(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable, this.subscriptionId)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      console.log('response : ', response);
      this.parameters.dataSource = response.content;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
