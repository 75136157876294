import { TraceModificationDTO } from '../basic/traceModificationDTO';

export class ManagedSymbolDto extends TraceModificationDTO {

	/**
	 * Managed Symbol symbol
	 */
    symbol: string;

    constructor() {
        super();
        this.symbol = '';
    }
}
