export const environment = {
    production: true,
    keycloak: true,
    urlPrefix: window.document.location.protocol + '//' + window.document.location.hostname + ((window.document.location.port != null && window.document.location.port != '') ? (':' + window.document.location.port) : '') + '/gateway',
    sitePrefix: window.document.location.protocol + '//' + window.document.location.hostname + ((window.document.location.port != null && window.document.location.port != '') ? (':' + window.document.location.port) : '')+'/',
    fakeBearer: '',
    fakeRoles: [],
    //parametri tenant
    clientId: "bitdiver_bo",
    issuer: "https://arzakena-auth.jenia.dev/auth/realms/arzakena",
    jwkProviderUrl: "https://arzakena-auth.jenia.dev/auth/realms/arzakena/protocol/openid-connect/certs",
    environmentName: 'ci' 
  };
  