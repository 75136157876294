import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { NotificationQueueService } from 'src/app/services/be-notifications/notification/notification-queue.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notifications-queue',
  templateUrl: './notification-queue.component.html',
  styleUrls: ['./notification-queue.component.scss'],
  providers: [
    NotificationQueueService
  ]
})
export class NotificationQueueComponent extends GenericListComponent implements OnInit, OnDestroy {

  readonly queueTypes: string[] = ['NOTIFICATION', 'MAIL'];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  private _notificationStatusSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _mailStatusSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private notificationQueueService: NotificationQueueService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['name', 'status', 'start', 'stop'];
    this.parameters = {
      dataSource: [],
      showList: false
    };
  }

  get notificationIsActive$(): Observable<boolean> {
    return this._notificationStatusSubject.asObservable();
  }

  get mailIsActive$(): Observable<boolean> {
    return this._mailStatusSubject.asObservable();
  }

  list(): void{
    this.notificationQueueService.loadNotificationActive()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(ret => this._notificationStatusSubject.next(ret));
    this.notificationQueueService.loadMailActive()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(ret => this._mailStatusSubject.next(ret));
    this.parameters.dataSource = this.queueTypes;
    this.parameters.showList = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getStatus(queueType: string): Observable<boolean> {
    if(queueType === 'NOTIFICATION'){
      return this.notificationIsActive$;
    }
    else if(queueType === 'MAIL'){
      return this.mailIsActive$;
    }
    return of(false);
  }

  startQueue(queueType: string): void {
    if(queueType === 'NOTIFICATION'){
      this.notificationQueueService.startNotification()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.list();
      });
    }
    else if(queueType === 'MAIL'){
      this.notificationQueueService.startMail()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.list();
      });
    }
  }

  stopQueue(queueType: string): void {
    if(queueType === 'NOTIFICATION'){
      this.notificationQueueService.stopNotification()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.list();
      });
    }
    else if(queueType === 'MAIL'){
      this.notificationQueueService.stopMail()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.list();
      });
    }
  }

}
