<div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
  <buttons-component [btnTxt]="'Disclaimer Messages'" [btnIcon]="'format_align_center'" [btnLink]="['/disclaimers/message']">
  </buttons-component>
  <buttons-component [btnTxt]="'Change Logs'" [btnIcon]="'collections_bookmark'" [btnLink]="['/changeLogs']">
  </buttons-component>
  <buttons-component [btnTxt]="'Check Squaring'" [btnIcon]="'graphic_eq'" [btnLink]="['/checkSquaring']">
  </buttons-component>
  <buttons-component [btnTxt]="'About'" [btnIcon]="'copyright'" [btnLink]="['/about']">
  </buttons-component>
  <buttons-component [btnTxt]="'Advertisements'" [btnIcon]="'label'" [btnLink]="['/campaigns']">
  </buttons-component>
  <buttons-component [btnTxt]="'Logout'" [btnIcon]="'exit_to_app'" (click)="logout()">
  </buttons-component>
</div>
