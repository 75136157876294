
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodeDTO } from 'src/app/shared/dto/code/CodeDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CodeService extends GenericService<CodeDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    // this.url = 'http://localhost:30900/code/admin';
    this.url = environment.urlPrefix + '/arzakena-be-affiliation/code/admin';

  }

  readByInfluencerId(influencerId: number): Observable<CodeDTO[]>  {
    return this.http.get<CodeDTO[]>(`${this.url}/readByInfluencerId/${influencerId}`);
  }

  readAllByInfluencerId(influencerId: number): Observable<CodeDTO[]>  {
    return this.http.get<CodeDTO[]>(`${this.url}/readAllByInfluencerId/${influencerId}`);
  }

  createByInfluencerId(code: CodeDTO, influencervalidator: number): Observable<CodeDTO> {
    return this.http.post<CodeDTO>( `${this.url}/${influencervalidator}`, code);
  }

  readById(id: number): Observable<CodeDTO>{
    return this.http.get<CodeDTO>(`${this.url}/readByInfluencerId/${id}`);
  }
}
