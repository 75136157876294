export const environment = {
  production: true,
  keycloak: true,
  urlPrefix: "https://api.bitdiver.com/gateway",
  sitePrefix: window.document.location.protocol + '//' + window.document.location.hostname + ((window.document.location.port != null && window.document.location.port != '') ? (':' + window.document.location.port) : '')+'/',
  fakeBearer: '',
  fakeRoles: [],
  //parametri tenant
  clientId: "bitdiver_bo",
  issuer: "https://auth.bitdiver.com/auth/realms/BitDiver",
  jwkProviderUrl: "https://auth.bitdiver.com/auth/realms/BitDiver/protocol/openid-connect/certs",
  environmentName: 'prod'
};

