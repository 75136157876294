import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeseriesDownloadConfigurationDTO } from 'src/app/shared/dto/criptocompare-timeseries/timeseriesDownloadConfiguretionDto';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeseriesDownloadConfigurationService extends GenericService<TimeseriesDownloadConfigurationDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-di-cryptocompare-timeseries/cryptocompareTimeseriesDownloadConfiguration';
  }

  public timeseriesDownloadConfiguration(page: number, size: number, sort: string, filter: string): Observable<PageDTO<TimeseriesDownloadConfigurationDTO>> {
    const params = {};
    if (page != null) {params['page'] = page;}
    if (size != null) {params['size'] = size;}
    if (sort != null) {params['sort'] = sort;}
    if (filter != null) {params['filter'] = filter;}
    return this.http.get<PageDTO<TimeseriesDownloadConfigurationDTO>>(`${this.url}`, { params: params });
  }

  public download(symbol: string, toSymbol: string, endDate: Date, startDate: Date): Observable<void> {
    let url = `${this.url}/download/${symbol}/${toSymbol}/${endDate.toISOString()}`;
    if(startDate){
      url += `?startTimestamp=${startDate.toISOString()}`;
    }
    return this.http.put<void>(url, {});
  }
}
