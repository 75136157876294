<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <div *ngIf="parameters.showList" fxLayout="column">
            <h2>Dettaglio dei tipi di abbonamenti</h2>
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                    <td mat-cell *matCellDef="let subscription"> {{subscription.id}} </td>
                </ng-container>

                <!-- StartDate Column -->
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> start date </th>
                    <td mat-cell *matCellDef="let subscription"> {{subscription.startDate| date :'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <!-- EndDate Column -->
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> end date </th>
                    <td mat-cell *matCellDef="let subscription"> {{subscription.endDate| date :'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <!-- active Column -->
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> active </th>
                    <td mat-cell *matCellDef="let subscription">
                        <mat-icon *ngIf="subscription.active" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!subscription.active" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <!-- paid Column -->
                <ng-container matColumnDef="paid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> paid </th>
                    <td mat-cell *matCellDef="let subscription">
                        <mat-icon *ngIf="subscription.paid" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!subscription.paid" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="subscriptionType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> type </th>
                    <td mat-cell *matCellDef="let subscription"> {{subscription.subscriptionType}} </td>
                </ng-container>

                <!-- Product Column -->
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> product </th>
                    <td mat-cell *matCellDef="let subscription"> {{translations.get(subscription.product.name)}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let subscription">
                        <button mat-mini-fab color="primary" [routerLink]="['/subscription/detail/', subscription.subscriptionType, subscription.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
        <mat-card-actions>
            <button mat-raised-button [routerLink]="['/user-subscriptions']" color="primary">Back</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>