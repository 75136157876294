<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Timeseries Download Configuration</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Timeseries Download Configuration Id"
                        readonly="true" />
                </mat-form-field>

                <!-- Symbol field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="symbol" placeholder="Symbol">
                    <mat-error *ngIf="form.get('symbol').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- hourDownloadTimeframe field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="hourDownloadTimeframe"
                        placeholder="hourDownloadTimeframe" min="0">
                    <mat-error *ngIf="form.get('hourDownloadTimeframe').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- lastTimeframedDownload field -->
                <mat-form-field>
                    <input matInput type="datetime-local" formControlName="lastTimeframedDownload" placeholder="Last Timeframed Download">
                    <mat-error *ngIf="form.get('lastTimeframedDownload').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- nextTimeframedDownload field -->
                <mat-form-field>
                    <input matInput type="datetime-local" formControlName="nextTimeframedDownload" placeholder="Next Timeframed Download">
                    <mat-error *ngIf="form.get('nextTimeframedDownload').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- fromSymbol field -->
                <mat-form-field>
                    <input matInput type="string" formControlName="fromSymbol"
                        placeholder="fromSymbol" min="0">
                    <mat-error *ngIf="form.get('fromSymbol').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- toSymbol field -->
                <mat-form-field>
                    <input matInput type="string" formControlName="toSymbol"
                        placeholder="toSymbol" min="0">
                    <mat-error *ngIf="form.get('toSymbol').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- exchange field -->
                <mat-form-field>
                    <input matInput type="string" formControlName="exchange"
                        placeholder="exchange" min="0">
                    <mat-error *ngIf="form.get('exchange').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-select formControlName="configurationStatus" placeholder="configuration status">
                        <mat-option *ngFor="let status of statuses" [value]="status">
                            {{status}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- responsive -->
                <!-- Priviledged field -->
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <mat-checkbox formControlName="priviledged" (change)="checkedControl()" labelPosition="before"
                        [checked]="check">Priviledged</mat-checkbox>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <mat-checkbox formControlName="useCadli" (change)="checkedControlUseCadli()" labelPosition="before"
                        [checked]="checkUseCadli">Use new api</mat-checkbox>
                </div>
                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <!-- Campi per scarico timeseries -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Scarico timeseries
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                        <!-- Campi data -->
                        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                            <!-- startDownloadDate field -->
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput [max]="form.get('endDownloadDate').value" [matDatepicker]="startDownloadDatePicker" formControlName="startDownloadDate"
                                    placeholder="Start Date" (click)="startDownloadDatePicker.open()" readonly="true">
                                <mat-datepicker-toggle matSuffix [for]="startDownloadDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDownloadDatePicker></mat-datepicker>
                                <button mat-button *ngIf="form.get('startDownloadDate').value" matSuffix mat-icon-button
                                    aria-label="Clear" (click)="form.get('startDownloadDate').setValue(null)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>

                            <!-- endDownloadDate field -->
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                                <input matInput [min]="form.get('startDownloadDate').value" [matDatepicker]="endDownloadDatePicker" formControlName="endDownloadDate"
                                    placeholder="End Date" (click)="endDownloadDatePicker.open()" readonly="true">
                                <mat-datepicker-toggle matSuffix [for]="endDownloadDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDownloadDatePicker></mat-datepicker>
                                <button mat-button *ngIf="form.get('endDownloadDate').value" matSuffix mat-icon-button
                                    aria-label="Clear" (click)="form.get('endDownloadDate').setValue(null)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <!-- toDownloadSymbol field -->
                        <mat-form-field>
                            <input matInput type="string" formControlName="toDownloadSymbol"
                                placeholder="toDownloadSymbol" min="0">
                        </mat-form-field>
                    </div>

                    <button mat-raised-button (click)="timeseriesDownloadConfigurationDownload()"
                        [disabled]="!form.get('endDownloadDate').value">
                        Download Timeseries
                    </button>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/timeseriesDownloadConfiguration')"
                color="primary">Back</button>
            <button mat-raised-button (click)="timeseriesDownloadConfigurationSave()"
                [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <delete-button (customClick)="timeseriesDownloadConfigurationDelete()"
                           message='Sei sicuro di voler cancellare questa configurazione?'
                           [disabled]="cryptocompareTimeseries?.id==null">
            </delete-button>
            <button mat-raised-button (click)="timeseriesDownloadConfigurationReset()"
                [disabled]="cryptocompareTimeseries?.id==null || !form.pristine">
                Reset
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
