<mat-card fxLayout="column" *ngIf="(translations$ | async) as translations">
  <mat-card-content fxFlex fxLayout="column">
    <div fxLayout="column"  *ngIf="translations.length; else noContent">
      <h2>Lista Traduzioni</h2>
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [dataSource]="translations">

        <!-- id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Id </th>
          <td mat-cell *matCellDef="let translation"> {{translation.id}} </td>
        </ng-container>

        <!-- type Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Title </th>
          <td mat-cell *matCellDef="let translation"> {{translation.title}} </td>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef> Language </th>
          <td mat-cell *matCellDef="let translation"> {{translation.language}} </td>
        </ng-container>

        <!-- expirationDate Column -->
        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef> Text </th>
          <td mat-cell *matCellDef="let translation"> {{translation.text | substring: 100 }} </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let translation">
            <button mat-mini-fab color="primary" [routerLink]="['/changeLogs/detail/'+changeLogId+ '/translation/detail/', translation.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <ng-template #noContent>
      <h2>Nessuna traduzione trovata</h2>
    </ng-template>
  </mat-card-content>
</mat-card>