import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { TriggerNotificationDTO } from 'src/app/shared/dto/be-notifications/notification/TriggerNotificationDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TriggerNotificationService extends GenericService<TriggerNotificationDTO, number> {

  noAdminurl: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-notifications/notification/generate';
    //this.url = 'http://localhost:31300/notification/generate';
  }

  public generate(dto: TriggerNotificationDTO): Observable<void> {
    return this.http.post<void>(`${this.url}/admin`, dto);
  }
}
