import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentCacheService } from '../services/component-cache.service';
import { CryptocompareAssetCacheService } from '../services/cryptocompare-catalog/asset-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { AssetDefinitionDto } from '../shared/dto/core-catalog/AssetDefinitionDto.types';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-di-cryprocompare-catalog',
  templateUrl: './cryptocompareAssetCache.component.html',
  styleUrls: ['./cryptocompareAssetCache.component.scss']
})
export class CryptocompareAssetCacheComponent extends GenericListComponent implements OnInit, OnDestroy {

  constructor(
    private cryptocompareAssetCacheService: CryptocompareAssetCacheService,
    protected dialog: MatDialog,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['symbol', 'fullName', 'decimalPoints', 'delete'];
    this.parameters = {
      dataSource : [],
      symbol: null,
      showList: false
    };
  }

  list(): void {
    this.cryptocompareAssetCacheService.assetCacheListBySymbol(
      this.parameters.symbol
    ).subscribe((response: AssetDefinitionDto) => {
      this.parameters.dataSource = [];
      console.log('response : ' , response);
      this.parameters.dataSource.push(response);
      this.parameters.showList = true;
    }
    );
  }

  confirm(message: string): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: message
    });
    return dialogRef.afterClosed();
  }

  delete(symbol: string): void {
    console.log('delete dell\' asset cache del symbol: ' + symbol);
    this.confirm('Sei sicuro di voler cancellare l\'asset cache di '+symbol+'?').subscribe((result) => {
      if (result) {
        this.cryptocompareAssetCacheService.assetCacheDeleteBySymbol(symbol).subscribe(
          () => {
            this.snackBar.open('Cancellazione di '+ symbol + ' con successo!', null, { duration: 3000 });
          }
        );
      }

    }
    );
  }

  error(): void{
    this.snackBar.open('Valorizzare il filtro prima di effettuare la ricerca!', null, { duration: 3000 });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
