<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Category Configurations</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">
    
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.idCategory" placeholder="ID Category" />
            </mat-form-field>

            <mat-form-field>
              <mat-select [(ngModel)]="parameters.channel" placeholder="Channel" #channelSelect>
                <mat-option *ngIf="!channelSelect.empty && channelSelect.panelOpen"></mat-option>
                <mat-option *ngFor="let channel of channels" [value]="channel">
                    {{channel}}
                </mat-option>
              </mat-select>
            </mat-form-field>    
        </div>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let category"> {{category.id}} </td>
                </ng-container>

                <!-- category Column -->
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                    <td mat-cell *matCellDef="let configuration"> {{configuration.category.name}} </td>
                </ng-container>

                <!-- channel Column -->
                <ng-container matColumnDef="channel">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Channel </th>
                    <td mat-cell *matCellDef="let configuration"> {{configuration.channel}} </td>
                </ng-container>

                <!-- defaultValue Column -->
                <ng-container matColumnDef="defaultValue">
                    <th mat-header-cell *matHeaderCellDef> Default Value </th>
                    <td mat-cell *matCellDef="let configuration"> {{configuration.defaultValue}} </td>
                </ng-container>

                <!-- editable Column -->
                <ng-container matColumnDef="editable">
                    <th mat-header-cell *matHeaderCellDef> Editable </th>
                    <td mat-cell *matCellDef="let configuration"> {{configuration.editable}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let configuration">
                        <button mat-mini-fab color="primary" [routerLink]="['/notifications/configuration/detail/', configuration.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
