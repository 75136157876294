<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Notification</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id"
                        readonly="true" />
                </mat-form-field>

                <!-- category field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="category" placeholder="Category">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{category.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('category').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- status field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="status" placeholder="Status">
                        <mat-option *ngFor="let status of statuses" [value]="status">
                            {{status}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('status').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- channel field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="channel" placeholder="Channel">
                        <mat-option *ngFor="let channel of channels" [value]="channel">
                            {{channel}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('channel').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- logicalKey field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="logicalKey" placeholder="Logical Key">
                    <mat-error *ngIf="form.get('logicalKey').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('logicalKey').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- username field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="username" placeholder="Username">
                    <mat-error *ngIf="form.get('username').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('username').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- email field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="email" placeholder="Email">
                    <mat-error *ngIf="form.get('email').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('email').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- header field -->
                <mat-form-field>
                  <textarea matInput style="height: 100px;" formControlName="header"
                  placeholder="Header"> </textarea>
                  <mat-error *ngIf="form.get('header').errors?.required">
                    Campo obbligatorio
                  </mat-error>
                </mat-form-field>
      
                <!-- body field -->
                <mat-form-field>
                  <textarea matInput style="height: 100px;" formControlName="body"
                  placeholder="Body"> </textarea>
                  <mat-error *ngIf="form.get('body').errors?.required">
                    Campo obbligatorio
                  </mat-error>
                </mat-form-field>

                <!-- attachments field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="attachments" placeholder="Attachments">
                </mat-form-field>

                <!-- readAt field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput [matDatepicker]="readAtPicker" formControlName="readAt"
                        placeholder="Read at" (click)="readAtPicker.open()" readonly="true">
                    <mat-datepicker-toggle matSuffix [for]="readAtPicker"></mat-datepicker-toggle>
                    <mat-datepicker #readAtPicker></mat-datepicker>
                    <button mat-button *ngIf="form.get('readAt').value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="form.get('readAt').setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('readAt').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- url field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="url" placeholder="Url">
                    <mat-error *ngIf="form.get('url').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                      <!-- DeleteDate field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput formControlName="deleteDate" placeholder="Delete Date" readonly="true" />
                      </mat-form-field>
        
                      <!-- DeleteUser field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput formControlName="deleteUser" placeholder="Delete User" readonly="true" />
                      </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/notifications/notifications')" color="primary">Back</button>
            <button mat-raised-button (click)="notificationSave()" [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <button mat-raised-button (click)="notificationDelete()" [disabled]="notification?.id===null">
                Delete
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>