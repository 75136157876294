import { TraceModificationDTO } from '../basic/traceModificationDTO';

export class SmartContractAddressDto extends TraceModificationDTO {

	smartContractAddress: string;

	builtOn: string;

    constructor() {
        super();
        this.smartContractAddress = '';
        this.builtOn = '';
    }
}
