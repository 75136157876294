import { NotificationDetailComponent } from './be-notifications/notification/notification-detail/notification-detail.component';
import { ReceiptDetailComponent } from './be-subscriptions/receipts/receipt-detail/receipt-detail.component';
import { ReceiptComponent } from './be-subscriptions/receipts/receipt.component';
import { ChangeLogTranslationDetailComponent } from './change-logs/translation-detail/change-log-translation-detail.component';
import { ChangeLogDetailComponent } from './change-logs/change-log-detail/change-log-detail.component';
import { ChangeLogComponent } from './change-logs/change-log/change-log.component';
import { AffiliationReportDetailComponent } from './gestione-affiliation/reportDetail/affiliation-report-detail.component';
import { SubscriptionDetailComponent } from './be-subscriptions/subscriptions/subscription-detail/subscription-detail.component';
import { SubscriptionsComponent } from './be-subscriptions/subscriptions/subscriptions.component';
import { PriceExchangeDetailComponent } from './be-subscriptions/price-exchange/price-exchange-detail/price-exchange-detail.component';
import { PriceExchangeComponent } from './be-subscriptions/price-exchange/price-exchange.component';
import { ProductDetailComponent } from './be-subscriptions/product/product-detail/product-detail.component';
import { ProductComponent } from './be-subscriptions/product/product.component';
import { FaqDetailComponent } from './faq-management/faq-detail.component';
import { FaqComponent } from './faq-management/faq.component';
import { TicketManagementComponent } from './ticket-management/ticket/ticket-management.component';
import { TicketManagementDetailComponent } from './ticket-management/ticket/ticket-management-detail.component';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt);

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppMaterialModule } from './app.material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPrintModule } from 'ngx-print';
import { AvatarModule } from 'ngx-avatar';

import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

import { HomeComponent } from './home/home.component';

import { AboutComponent } from './about/about.component';
import { AssetMappingConfigurationComponent } from './di-coinmarketcap-catalog/di-coinmarketcap.component';
import { CoinmarketcapAssetMappingConfigurationDetailComponent } from './di-coinmarketcap-catalog/di-coinmarketcap-detail.component';
import { WeisscryptoCatalogComponent } from './di-weisscrypto-catalog/weisscrypto-catalog.component';
import { WeisscryptoCatalogDetailComponent } from './di-weisscrypto-catalog/weisscrypto-catalog-detail.component';
import { CryptocompareTimeseriesComponent } from './di-cryptocompare-timeseries/di-cryptocompare-timeseries.component';
import { CryptocompareTimeseriesDetailComponent } from './di-cryptocompare-timeseries/di-cryptocompare-timeseries-detail.component';
import { AsyncProcessWarningEventComponent } from './core-errors-handling/async-process-warning-event/async-process-warning-event.component';
import { AsyncProcessWarningEventDetailComponent } from './core-errors-handling/async-process-warning-event/async-process-warning-event-detail.component';
import { AsyncProcessErrorEventComponent } from './core-errors-handling/async-process-error-event/async-process-error-event.component';
import { AsyncProcessErrorEventDetailComponent } from './core-errors-handling/async-process-error-event/async-process-error-event-detail.component';
import { CoinmarketcapAssetCacheComponent } from './di-coinmarketcap-catalog/asset-cache/asset-cache.component';
import { CryptocompareAssetCacheComponent } from './di-cryprocompare-catalog/cryptocompareAssetCache.component';
import { WeisscryptoAssetCacheComponent } from './di-weisscrypto-catalog/asset-cache/weisscrypto-asset-cache/weisscrypto-asset-cache.component';
import { TimeseriesDataVisualizationComponent } from './timeseries-data-visualization/timeseries-data-visualization.component';
import { AssetDefinitionComponent } from './core-catalog/asset-definition/asset-definition.component';
import { AssetDefinitionDetailComponent } from './core-catalog/asset-definition/asset-definition-detail.component';
import { AssetDefinitionHistoryComponent } from './core-catalog/asset-definition-history/asset-definition-history.component';
import { AssetDefinitionHistoryDetailComponent } from './core-catalog/asset-definition-history/asset-definition-history-detail.component';
import { GestioneAffiliationComponent } from './gestione-affiliation/gestione-affiliation.component';
import { GestioneAffiliationDetailComponent } from './gestione-affiliation/gestione-affiliation-detail.component';
import { SocialDetailComponent } from './gestione-affiliation/social/social-detail.component';
import { UsersDetailComponent } from './gestione-affiliation/users/users-detail.component';
import { CodeDetailComponent } from './gestione-affiliation/code/code-detail.component';
import { PromotionComponent } from './be-subscriptions/promotion/promotion.component';
import { PromotionGenerationComponent } from './be-subscriptions/promotion/promotion-generation/promotion-generation.component';
import { PromotionDetailComponent } from './be-subscriptions/promotion/promotion-detail/promotion-detail.component';
import { UserSubscriptionsComponent } from './be-subscriptions/user-subscriptions/user-subscriptions.component';
import { UserSubscriptionsDetailComponent } from './be-subscriptions/user-subscriptions/user-subscriptions-detail/user-subscriptions-detail.component';
import { DisclaimerMessageComponent } from './disclaimers/message/disclaimer-message.component';
import { DisclaimerMessageDetailComponent } from './disclaimers/message-detail/disclaimer-message-detail.component';
import { DisclaimerMessageTranslationDetailComponent } from './disclaimers/translation-detail/disclaimer-message-translation-detail.component';
import { UserSubscriptionSubsComponent } from './be-subscriptions/user-subscriptions/user-subscription-subs/user-subscription-subs.component';
import { SmartContractAddressMappingComponent } from './core-catalog/smart-contract-address-mapping/smart-contract-address-mapping/smart-contract-address-mapping.component';
import { SmartContractAddressMappingDetailComponent } from './core-catalog/smart-contract-address-mapping/smart-contract-address-mapping-detail/smart-contract-address-mapping-detail.component';
import { ManagedSymbolDetailComponent } from './di-cryptocompare-timeseries/managed-symbol/managed-symbol-detail/managed-symbol-detail.component';
import { UserSubscriptionReceiptsComponent } from './be-subscriptions/user-subscriptions/user-subscription-receipts/user-subscription-receipts.component';
import { SymbolMappingDetailComponent } from './core-wallets/symbol-mapping/symbol-mapping-detail.component';
import { SubscriptionExtensionComponent } from './be-subscriptions/extensions/subscription-extension.component';
import { SubscriptionExtensionDetailComponent } from './be-subscriptions/extensions/extension-detail/subscription-extension-detail.component';
import { AssetDefinitionAliasMappingComponent } from './core-catalog/asset-definition-alias-mapping/asset-definition-alias-mapping.component';

import { PositionsComponent } from './positions-container/positions-container.component';
import { CheckForManagedSymbolsComponent } from './core-wallets/check-for-managed-symbols/check-for-managed-symbols.component';
import { DataintegrationComponent } from './dataintegration-catalog/dataintegration/dataintegration.component';
import { DataintegrationDetailComponent } from './dataintegration-catalog/dataintegration-detail/dataintegration-detail.component';
import { StripeComponent } from './be-subscriptions/stripe/stripe.component';
import { StripeDetailComponent } from './be-subscriptions/stripe/stripeDetail/stripe-detail.component';
import { DIAlternativeMeFearAndGreedComponent } from './di-alternativeme-fearandgreed/di-alternativeme-fearandgreed.component';
import { AdvertisementsComponent } from './advertisements/advertisement.component';
import { AdvertisementDetailComponent } from './advertisements/advertisement-detail/advertisement-detail.component';
import { AdvertisementTranslationDetailComponent } from './advertisements/translation-detail/translation-detail.component';
import { CategoryComponent } from './be-notifications/category/category.component';
import { CategoryDetailComponent } from './be-notifications/category/category-detail/category-detail.component';
import { TemplateComponent } from './be-notifications/template/template.component';
import { TemplateDetailComponent } from './be-notifications/template/template-detail/template-detail.component';
import { CategoryConfigurationComponent } from './be-notifications/category-configuration/category-configuration.component';
import { CategoryConfigurationDetailComponent } from './be-notifications/category-configuration/category-configuration-detail/category-configuration-detail.component';
import { NotificationComponent } from './be-notifications/notification/notification.component';
import { NotificationGenerateComponent } from './be-notifications/notification-generate/notification-generate.component';
import { NotificationQueueComponent } from './be-notifications/notification-queue/notification-queue.component';
import { CheckSquaringComponent } from './check-squaring/check-squaring.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  // Coinmarketcap
  { path: 'assetMappingConfiguration', component: AssetMappingConfigurationComponent },
  { path: 'assetMappingConfiguration/detail/:id', component: CoinmarketcapAssetMappingConfigurationDetailComponent },
  { path: 'coinmarketcapAssetCache', component: CoinmarketcapAssetCacheComponent },
  // Weisscrypto
  { path: 'weisscryptoAssetMappingConfiguration', component: WeisscryptoCatalogComponent },
  { path: 'weisscryptoAssetMappingConfiguration/detail/:id', component: WeisscryptoCatalogDetailComponent },
  { path: 'weisscryptoAssetCache', component: WeisscryptoAssetCacheComponent },
  // Cryptocompare
  { path: 'timeseriesDownloadConfiguration', component: CryptocompareTimeseriesComponent },
  { path: 'timeseriesDownloadConfiguration/detail/:id', component: CryptocompareTimeseriesDetailComponent },
  { path: 'cryptocompareAssetCache', component: CryptocompareAssetCacheComponent },
  //AlterntiveMe
  { path: 'alternativeMeFearAndGreed', component: DIAlternativeMeFearAndGreedComponent},
  // Error-Handling
  { path: 'asyncProcessWarningEvent', component: AsyncProcessWarningEventComponent },
  { path: 'asyncProcessWarningEvent/detail/:id', component: AsyncProcessWarningEventDetailComponent },
  { path: 'asyncProcessErrorEvent', component: AsyncProcessErrorEventComponent },
  { path: 'asyncProcessErrorEvent/detail/:id', component: AsyncProcessErrorEventDetailComponent },
  // Grafico
  { path: 'timeseriesDataVisualizationComponent', component: TimeseriesDataVisualizationComponent },
  // Core-catalog
  { path: 'assetDefinition', component: AssetDefinitionComponent },
  { path: 'assetDefinition/detail/:symbol', component: AssetDefinitionDetailComponent },
  { path: 'assetDefinitionHistory', component: AssetDefinitionHistoryComponent },
  { path: 'assetDefinitionHistory/detail/:symbol', component: AssetDefinitionHistoryDetailComponent },
  { path: 'smartContractAddressMapping', component: SmartContractAddressMappingComponent },
  { path: 'smartContractAddressMapping/detail/:id', component: SmartContractAddressMappingDetailComponent },
  { path: 'assetDefinitionAliasMapping', component: AssetDefinitionAliasMappingComponent },
  //core wallets
  { path: 'managedSymbol/detail', component: ManagedSymbolDetailComponent },
  { path: 'symbolMapping/detail', component: SymbolMappingDetailComponent },
  { path: 'checkForManagedSymbols/detail', component: CheckForManagedSymbolsComponent },
  //positions-container
  { path: 'positions-container', component: PositionsComponent},
  { path: 'ticket-management', component: TicketManagementComponent },
  { path: 'ticket-management/detail/:idTicket/:username', component: TicketManagementDetailComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'faq/detail/:idFaq', component: FaqDetailComponent },
  //Gestione affiliation
  { path: 'gestione-affiliation', component: GestioneAffiliationComponent},
  { path: 'gestione-affiliation/detail/:id', component: GestioneAffiliationDetailComponent},
  { path: 'gestione-affiliation/detail/report/:id', component: AffiliationReportDetailComponent},
  { path: 'social/detail/:influencerId/:id', component: SocialDetailComponent},
  { path: 'code/detail/:influencerId/:id', component: CodeDetailComponent},
  { path: 'users/detail/:influencerId/:username', component: UsersDetailComponent},
  //Gestione subscriptions
  //--Promotions
  { path: 'promotion', component: PromotionComponent},
  { path: 'promotion/detail/:id', component: PromotionDetailComponent},
  { path: 'promotion-generation', component: PromotionGenerationComponent},
  //--Products
  { path: 'product', component: ProductComponent},
  { path: 'product/detail/:id', component: ProductDetailComponent},
  //--User
  { path: 'user-subscriptions', component: UserSubscriptionsComponent},
  { path: 'user-subscriptions/detail/:id', component: UserSubscriptionsDetailComponent},
  { path: 'user-subscriptions/subscriptions/:username', component: UserSubscriptionSubsComponent},
  { path: 'user-subscriptions/receipts/:username', component: UserSubscriptionReceiptsComponent},
  //--Price exchange
  { path: 'price-exchange', component: PriceExchangeComponent},
  { path: 'price-exchange/detail/:id', component: PriceExchangeDetailComponent},
  //--Subscriptions
  { path: 'subscription', component: SubscriptionsComponent},
  { path: 'subscription/detail/:type/:id', component: SubscriptionDetailComponent},
  //--Receipts
  { path: 'receipt', component: ReceiptComponent},
  { path: 'receipt/detail/:id', component: ReceiptDetailComponent},
  //--Extensions
  { path: 'subscription-extension', component: SubscriptionExtensionComponent},
  { path: 'subscription-extension/detail/:id', component: SubscriptionExtensionDetailComponent},
  //--Stripe Check
  { path: 'stripe', component: StripeComponent},
  { path: 'stripe/detail/:id', component: StripeDetailComponent},
  //--Disclaimers
  { path:'disclaimers/message', component: DisclaimerMessageComponent},
  { path:'disclaimers/message/detail/:id', component: DisclaimerMessageDetailComponent},
  { path:'disclaimers/message/detail/:messageId/translation/detail/:id', component: DisclaimerMessageTranslationDetailComponent},
  //--Advertisements
  { path: 'campaigns', component: AdvertisementsComponent},
  { path: 'campaigns/detail/:id', component: AdvertisementDetailComponent},
  { path: 'campaigns/detail/:advId/translation/detail/:id', component: AdvertisementTranslationDetailComponent},
  //--Change Logs
  { path:'changeLogs', component: ChangeLogComponent},
  { path:'changeLogs/detail/:id', component: ChangeLogDetailComponent},
  { path:'changeLogs/detail/:changeLogId/translation/detail/:id', component: ChangeLogTranslationDetailComponent},
  //--Data Integration Catalog
  {path: 'dataintegration', component: DataintegrationComponent},
  {path: 'dataintegration/detail/:id', component: DataintegrationDetailComponent},
  //Gestione Notifications
  //--Category
  { path:'notifications/category', component: CategoryComponent},
  { path:'notifications/category/detail/:id', component: CategoryDetailComponent},
  //--Category Configuration
  { path:'notifications/configuration', component: CategoryConfigurationComponent},
  { path:'notifications/configuration/detail/:id', component: CategoryConfigurationDetailComponent},
  //--Template
  { path:'notifications/template', component: TemplateComponent},
  { path:'notifications/template/detail/:id', component: TemplateDetailComponent},
  //--Notification
  { path:'notifications/notifications', component: NotificationComponent},
  { path:'notifications/notifications/detail/:id', component: NotificationDetailComponent},
  //--Notification generate
  { path:'notifications/generate', component: NotificationGenerateComponent},
  //--Notification queue
  { path:'notifications/queue', component: NotificationQueueComponent},
  //--Check Squaring
  {path: 'checkSquaring', component: CheckSquaringComponent},

];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    NgxPrintModule,
    AvatarModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  declarations: [
    ConfirmationDialogComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
