import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { ReceiptType } from './../../shared/dto/be-subscriptions/receipt/ReceiptDTO';
import { ReceiptService } from './../../services/be-subscriptions/receipt/receipt.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { ComponentCacheService } from '../../services/component-cache.service';
import { NavigatorService } from '../../services/navigator.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent extends GenericListComponent implements OnInit, OnDestroy {

  public types: ReceiptType[] = ['PROMOTION', 'CRYPTO', 'CARD', 'FREE', 'TRIAL'];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private receiptService: ReceiptService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'receiptType', 'startDate', 'endDate', 'idSub', 'idUser',
        'idInfluencerAffiliation', 'idCodeAffiliation', 'ammountPaid', 'currency', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      idFilter: undefined,
      receiptType: undefined,
      startDateFrom: undefined,
      startDateTo: undefined,
      endDateFrom: undefined,
      endDateTo: undefined,
      idSubscription: undefined,
      idUser: undefined,
      idInfluencerAffiliation: undefined,
      idCodeAffiliation: undefined,
      sort: undefined,
      page: 0,
      size: 50,
      length: 0
    };
  }

  list(): void{
    this.receiptService.readAll(
      {page: this.parameters.page, size: this.parameters.size, sort: this.fixSort(this.parameters.sort)} as Pageable,
      this.parameters.idFilter, this.parameters.receiptType, this.parameters.startDateFrom, this.parameters.startDateTo,
      this.parameters.endDateFrom, this.parameters.endDateTo, this.parameters.idSubscription, this.parameters.idUser,
      this.parameters.idInfluencerAffiliation, this.parameters.idCodeAffiliation)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      console.log('response : ', response);
      this.parameters.dataSource = response.content;
      this.parameters.page = response.page;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private fixSort(sort: any): any {
    sort = sort?.replace('idSub', 'subscription.id');
    sort = sort?.replace('idUser', 'user.id');
    return sort;
  }

}
