import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/services/be-subscriptions/subscription/subscription.service';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { SubscriptionDTO } from 'src/app/shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'user-subscription-subs',
  templateUrl: './user-subscription-subs.component.html',
  styleUrls: ['./user-subscription-subs.component.scss']
})
export class UserSubscriptionSubsComponent extends GenericListComponent implements OnInit {

  userSubscriptionsUsername: string;

  translations: Map<string, string> = ProductDTO.translations;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private subscriptionService: SubscriptionService,
    private activeRoute: ActivatedRoute,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'startDate', 'endDate', 'active', 'paid', 'product', 'subscriptionType', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sort: undefined,
      page: 0,
      size: 50,
      length: 0
    };
   }

  ngOnInit(): void {
    this.userSubscriptionsUsername = (this.activeRoute.snapshot.paramMap.get('username'));
    super.ngOnInit();
    this.list();
  }

  list(): void {
    this.subscriptionService.userSubscriptionsList(this.parameters.page, this.parameters.size, this.parameters.sort, this.userSubscriptionsUsername)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((res: PageDTO<SubscriptionDTO>)=>{
      this.parameters.dataSource = res.content;
      this.parameters.page = res.page;
      this.parameters.length = res.totalElements;
      this.parameters.showList = true;
    });
  }

}
