import { TraceModificationDTO } from './../../basic/traceModificationDTO';

export class ProductDTO extends TraceModificationDTO {

    static translations = new Map<string, string>([
        ['pages.payment.products.trialProduct.name', 'Trial Product'],
        ['pages.payment.products.freeProduct.name', 'Free Product'],
        ['pages.payment.products.monthlyProduct.name', 'Monthly Product'],
        ['pages.payment.products.monthlyAffiliationProduct.name', 'Monthly Affiliated Product'],
        ['pages.payment.products.yearlyProduct.name', 'Yearly Product'],
        ['pages.payment.products.yearlyAffiliationProduct.name', 'Yearly Affiliated Product']
    ]);

    id: number;

    name: string;

    cost: number;

    yearly: boolean;

    free: boolean;

    trialProduct: boolean;

    affiliation: boolean;

    description: string;

    metadata: string;


}
