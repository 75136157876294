import { NotificationChannel } from '../../../shared/dto/be-notifications/NotificationTypes';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { languages } from 'src/app/shared/dto/languages.types';
import { TemplateService } from 'src/app/services/be-notifications/template/template.service';
import { TemplateDTO } from 'src/app/shared/dto/be-notifications/template/TemplateDTO';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-notifications-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss'],
  providers: [
    TemplateService
  ],
})
export class TemplateDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  templateId: number;
  template: TemplateDTO;

  readonly languages = languages;
  readonly channels = ['MAIL', 'BELL', 'POPUP'];
  showPreview: string = null;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private templateService: TemplateService,
    private sanitizer: DomSanitizer,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        bundleKey: new FormControl('', [Validators.required, Validators.maxLength(256)]),
        templateHeader: new FormControl('', [Validators.required]),
        templateBody: new FormControl('', [Validators.required]),
        locale: new FormControl('', [Validators.required]),
        channel: new FormControl('', [Validators.required]),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
        deleteDate: new FormControl({ value: '', disabled: true }),
        deleteUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.templateId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.templateRead();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  templateSave(): void {
    this.templateUpdate();
  }

  //READ

  templateRead(): void {
    this.templateService.read(this.templateId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: TemplateDTO) => {
          console.log('response : ' , res);
          this.template = res;
          this.templateId = res.id;
          this.dtoToForm();
        }
      );
  }

  // UPDATE

  templateUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere il template?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.templateService.update(this.template)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.templateRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  templateDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare il template?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.templateService.delete(this.template.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/notifications/template']);
                }
              );
          }
        });
    }
  }

  doNotSanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private formToDto(): void {
    this.template.bundleKey = this.form.get('bundleKey').value;
    this.template.templateHeader = this.form.get('templateHeader').value;
    this.template.templateBody = this.form.get('templateBody').value;
    this.template.locale = this.form.get('locale').value;
    this.template.channel = this.form.get('channel').value;
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.template.id);
    this.form.get('bundleKey').setValue(this.template.bundleKey);
    this.form.get('templateHeader').setValue(this.template.templateHeader);
    this.form.get('templateBody').setValue(this.template.templateBody);
    this.form.get('locale').setValue(this.template.locale);
    this.form.get('channel').setValue(this.template.channel);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.template.insertDate));
    this.form.get('insertUser').setValue(this.template.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.template.updateDate));
    this.form.get('updateUser').setValue(this.template.updateUser);
    this.form.get('deleteDate').setValue(this.timestampFormatPipe.transform(this.template.deleteDate));
    this.form.get('deleteUser').setValue(this.template.deleteUser);
    this.form.get('optLock').setValue(this.template.optLock);
    this.form.markAsPristine();
  }
}
