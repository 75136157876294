import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { WeisscryptoAssetMappingConfigurationDTO } from 'src/app/shared/dto/weisscrypto-catalog/weisscryptoAssetMappingConfigurationDto';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WeisscryptoAssetMappingConfigurationService extends GenericService <WeisscryptoAssetMappingConfigurationDTO, number> {

  url: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-di-weisscrypto-catalog/weisscryptoAssetMappingConfiguration';
  }

  public assetMappingConfigurationList(page: number, size: number, sort: string, filter: string): Observable<PageDTO<WeisscryptoAssetMappingConfigurationDTO>> {
    const params = {};
    if (page != null) {params['page'] = page;}
    if (size != null) {params['size'] = size;}
    if (sort != null) {params['sort'] = sort;}
    if (filter != null) {params['filter'] = filter;}
    return this.http.get<PageDTO<WeisscryptoAssetMappingConfigurationDTO>>(`${this.url}`, { params: params });
}
}
