import { SettingsService } from '../services/generic-data/generic-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavigatorService } from '../services/navigator.service';


@Component({
  selector: 'generic-data',
  templateUrl: './generic-data.component.html',
  styleUrls: ['./generic-data.component.scss']
})
export class GenericDataComponent implements OnInit, OnDestroy {

  userCounted: number = 0;
  walletCounted: number = 0;
  oneWalletUsers: number = 0;
  totalTransactions: number = 0;
  bitcoin: number = 0;
  ether: number = 0;

  timer: any;


  constructor(public settingsService: SettingsService, public navigatorService: NavigatorService) {
  }

  ngOnInit(): void {
    this.loadData();
    this.automaticDataUpdate();
  }

  loadData(): void {
    this.settingsService.countRegisteredUsers()
      .subscribe(
        (res) => {
          this.userCounted = res;
        }
      );
    this.settingsService.countRegisteredWallet()

      .subscribe(
        (res) => {
          this.walletCounted = res;
        }
      );
    this.settingsService.oneWalletUsers()

      .subscribe(
        (res) => {
          this.oneWalletUsers = res;
        }
      );
    this.settingsService.symbolCount('BTC')

      .subscribe(
        (res) => {
          this.bitcoin = res;
        }
      );
    this.settingsService.symbolCount('ETH')

      .subscribe(
        (res) => {
          this.ether = res;
        }
      );
    this.settingsService.countTransaction()

      .subscribe(
        (res) => {
          this.totalTransactions = res;
        }
      );
  }

  automaticDataUpdate(): void {

    const t = this;
    this.timer = setTimeout(() => {
      t.loadData();
      t.automaticDataUpdate();
    }, 30000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  get environmentName(): string {
    return environment.environmentName;
  }

}


