import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'provider-component',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit, OnChanges, OnDestroy{
  index = 0;
  parameters = {
    dataSource: []
  };

  constructor(private componentCacheService: ComponentCacheService) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('selectedTab1')) {
      this.index = Number(sessionStorage.getItem('selectedTab1'));
    }
  }

  ngOnChanges(changes: any): void {
    this.index = changes['index'].currentValue;
    sessionStorage.setItem('selectedTab1', this.index.toString());
  }

  get environmentName(): string {
    return environment.environmentName;
  }

  ngOnDestroy(): void {
    this.componentCacheService.set(ProviderComponent.name, this.parameters);
    sessionStorage.setItem('selectedTab1', this.index.toString());
  }
}

