import { Component } from '@angular/core';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NavigatorService } from '../services/navigator.service';
import { PositionContainerType, positionContainerTypes } from '../shared/dto/core-wallets/positionContainerType.types';
import { GenericComponent } from '../shared/GenericComponent';
import { Subject, interval } from 'rxjs';
import { BlockchainType, blockchainTypes } from '../shared/dto/commons/BlockchainType.types';
import { ExchangeType, exchangeTypes } from '../shared/dto/commons/ExchangeType.types';
import { switchMap, take } from 'rxjs/operators';
import { ExcelExporterService } from '../services/excel-exporter/excel-exporter.service';
import { PositionsContainer } from '../shared/dto/core-wallets/positionsContainer';
import { PositionsContainerDTO } from '../shared/dto/positions-container/PositionsContainerDTO';
import { languages } from '../shared/dto/languages.types';
import { l10nConfig } from '../l10n-config';

@Component({
    selector: 'app-check-squaring',
    templateUrl: './check-squaring.component.html',
    styleUrls: ['./check-squaring.component.scss'],
    providers: [ExcelExporterService]
})
export class CheckSquaringComponent extends GenericComponent {
    readonly positionContainerTypes = positionContainerTypes;
    readonly exchangeTypes = exchangeTypes;
    readonly blockchainTypes = blockchainTypes;
    code: string = '';
    positionsContainerType: string = '';
    containerCodeType: string = '';
    locales: string[] = l10nConfig.schema.map(schema => schema.locale.language.slice(0, 2));
    currencies: string[] = l10nConfig.schema.map(schema => schema.locale.currency.slice(0, 3));
    selectedCurr: string;
    selectedLocale: string;
    private mismatch: boolean = true;
    private payload: PositionsContainerDTO;
    constructor(
        navigatorService: NavigatorService,
        dateAdapter: DateAdapter<Date>,
        router: Router,
        snackBar: MatSnackBar,
        timestampFormatPipe: TimestampFormatPipe,
        private excelExporterService: ExcelExporterService,
    ) {
        super(
            navigatorService,
            router,
            dateAdapter,
            snackBar,
            timestampFormatPipe);
    }

    isDownloadReady(): boolean {
        if (
            this.isEmpty(this.code) ||
            this.isEmpty(this.containerCodeType) ||
            this.isEmpty(this.positionsContainerType) ||
            this.isEmpty(this.selectedCurr) ||
            this.isEmpty(this.selectedLocale) ||
            this.mismatch
        ) {
            return false;
        }

        return true;
    }

    downloadReport(): void {
        this.snackBar.open('Download started', 'Close', {
            duration: 5000
        });
        this.payload = {
            positionContainerType: this.positionsContainerType,
        };
        switch (this.positionsContainerType) {
            case 'WALLET':
                this.payload = { ...this.payload, blockchainType: this.containerCodeType, address: this.code };
                break;
            case 'EXCHANGE_ACCOUNT':
                this.payload = { ...this.payload, exchangeType: this.containerCodeType, accountCode: this.code };
                break;
        }
        this.excelExporterService.downloadReport(this.payload, this.selectedCurr, this.selectedLocale)
            .pipe(
                take(1)
            )
            .subscribe(
                (res) => {
                    const url = window.URL.createObjectURL(res);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'squaring_report_new.xlsx';
                    a.click();
                    a.remove(); // remove the element
                },
                (err) => {
                    this.snackBar.open(err, 'Close', {
                        duration: 5000
                    });
                });
    }
    updateMismatch(): void {
        this.mismatch = true;
        switch (this.positionsContainerType) {
            case 'WALLET':
                this.mismatch = !blockchainTypes.includes(this.containerCodeType);
                return;
            case 'EXCHANGE_ACCOUNT':
                this.mismatch = !exchangeTypes.includes(this.containerCodeType);
                return;
        }
    }

    private isEmpty = (str: string): boolean => (!str?.length);

}
