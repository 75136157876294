import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPTY, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, filter, takeUntil } from 'rxjs/operators';
import { AssetDefinitionService } from 'src/app/services/core-catalog/asset-definition/asset-definition.service';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';

export class AssetDefinitionAliasWrapper {
  assetDefinition: AssetDefinitionDto;
  alias: string;
}
@Component({
  selector: 'asset-definition-input',
  templateUrl: './asset-definition-input.component.html',
  styleUrls: ['./asset-definition-input.component.scss'],
  providers: [
    AssetDefinitionService
  ]
})
export class AssetDefinitionInputComponent implements OnDestroy, OnChanges, OnInit {

  @Output() assetDefinitionChangedEvent: EventEmitter<AssetDefinitionAliasWrapper> = new EventEmitter<AssetDefinitionAliasWrapper>();
  @Input() assetDefinition: AssetDefinitionDto;
  @Input() initialSymbol: string;

  currentSymbol: string;
  form: FormGroup;

  private debouncer$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _debouncerUnsubscribe: Subject<void> = new Subject<void>();
  private _readUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private assetDefinitionService: AssetDefinitionService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if(!this.currentSymbol) {
      if(this.initialSymbol) {
        this.currentSymbol = this.initialSymbol;
      } else if(this.assetDefinition?.symbol) {
        this.currentSymbol = this.assetDefinition.symbol;
      }
    }
    this.form = this.loadForm();
    this.startDebouncer();
  }

  ngOnChanges(): void {
  }

  setAssetDefinition(assetDefinition: AssetDefinitionDto): void {
    this.assetDefinition = assetDefinition;
    const wrapper: AssetDefinitionAliasWrapper = new AssetDefinitionAliasWrapper();
    wrapper.assetDefinition = assetDefinition;
    if(assetDefinition) {
      if(assetDefinition.symbol !== this.currentSymbol ) {
        //il simbolo corrente è un'alias dell'assetDefinition
        wrapper.alias = this.currentSymbol;
      }
      this.form = this.loadForm();
    }
    this.assetDefinitionChangedEvent.emit(wrapper);
  }

  symbolChanged(): void {
    this.currentSymbol = this.form.get('inputSymbol').value;
    this.setAssetDefinition(undefined);
    this._readUnsubscribe.next();
    this.debouncer$.next(this.form.get('inputSymbol').value);
  }

  ngOnDestroy(): void {
    this._debouncerUnsubscribe.next();
    this._debouncerUnsubscribe.complete();
    this._readUnsubscribe.next();
    this._readUnsubscribe.complete();
  }

  private loadForm(): FormGroup {
    return this._formBuilder.group(this.formValidators());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators() {
    return {
      inputSymbol: [this.currentSymbol, [Validators.required, Validators.maxLength(32)]],
      symbol: this.assetDefinition?.symbol,
      fullName: this.assetDefinition?.fullName,
      coinName: this.assetDefinition?.coinName,
      type: this.assetDefinition?.type,
      description: this.assetDefinition?.description
    };
  }

  private startDebouncer(): void {
    this.debouncer$.pipe(
      takeUntil(this._debouncerUnsubscribe),
      debounceTime(1000),
      filter(symbol => !!symbol)
    ).subscribe((symbol) => {
      this.assetDefinitionService.readSymbol(symbol).pipe(
        takeUntil(this._readUnsubscribe),
        filter(assetDefinition => !!assetDefinition),
        catchError(() => EMPTY)
      ).subscribe(assetDefinition => this.setAssetDefinition(assetDefinition));
    });
  }
}
