import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';

export class FaqDTO extends LogicalDeleteDTO {
    id: number;
    userLocale: string;
    category: string;
    question: string;
    answer: string;
    orderFaq: number;
}
