import { TraceModificationDTO } from './../../basic/traceModificationDTO';

export class PriceExchangeDTO extends TraceModificationDTO {

    id: number;

    usd: number;

    eur: number;

}
