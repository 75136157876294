import { PriceExchangeDTO } from './../../../shared/dto/be-subscriptions/price-exchange/PriceExchangeDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceExchangeService extends GenericService<PriceExchangeDTO, number> {

  urlNotAdmin = environment.urlPrefix + '/arzakena-be-subscriptions/priceExchange';

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/priceExchange/admin';
    //this.url = "http://localhost:30300/price-exchange"
  }

  public readAll(): Observable<PriceExchangeDTO[]> {
    return this.http.get<PriceExchangeDTO[]>(`${this.url}/price-exchanges`);
  }

  public getByCost(cost: number): Observable<PriceExchangeDTO> {
    return this.http.get<PriceExchangeDTO>(`${this.urlNotAdmin}/${cost}`);
  }
}
