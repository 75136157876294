import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { SubscriptionService } from 'src/app/services/be-subscriptions/subscription/subscription.service';
import { UserSubscriptionsService } from 'src/app/services/be-subscriptions/user-subscriptions/user-subscriptions.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserSubscriptionsDTO } from 'src/app/shared/dto/be-subscriptions/user-subscriptions/UserSubscriptionsDTO';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';

@Component({
  selector: 'app-user-subscriptions-detail',
  templateUrl: './user-subscriptions-detail.component.html',
  styleUrls: ['./user-subscriptions-detail.component.scss']
})
export class UserSubscriptionsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  userSubscriptions: UserSubscriptionsDTO;
  userSubscriptionsId: number;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private userSubscriptionsService: UserSubscriptionsService,
    private subscriptionService: SubscriptionService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        username: new FormControl({ value: '', disabled: true }),
        active: new FormControl(''),
        renewable: new FormControl(''),
        promotionTries: new FormControl('', [Validators.required]),
        trialUsed: new FormControl(''),
        freeUsed: new FormControl(''),
        idInfluencerAffiliation: new FormControl({ value: '', disabled: true }),
        idCodeAffiliation: new FormControl({ value: '', disabled: true }),
        sevenDaysAgo: new FormControl({ value: '', disabled: true }),
        sixDaysAgo: new FormControl({ value: '', disabled: true }),
        fiveDaysAgo: new FormControl({ value: '', disabled: true }),
        fourDaysAgo: new FormControl({ value: '', disabled: true }),
        threeDaysAgo: new FormControl({ value: '', disabled: true }),
        twoDaysAgo: new FormControl({ value: '', disabled: true }),
        oneDayAgo: new FormControl({ value: '', disabled: true }),
        lastCapitalUpdate: new FormControl({ value: '', disabled: true }),
        nextCapitalUpdate: new FormControl('', [Validators.required]),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
      });
   }

  ngOnInit(): void {
    this.userSubscriptionsId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.userSubscriptionsId !== 0 && this.userSubscriptionsId != null) {
      this.userSubscriptionsRead();
    } else {
      this.userSubscriptions = new UserSubscriptionsDTO();
    }
  }

  userSubscriptionsSave(): void {
    if (this.userSubscriptions.id == null) {
      this.userSubscriptionsCreate();
    } else {
      this.userSubscriptionsUpdate();
    }
  }

  public userSubscriptionsRead(): void {
    this.userSubscriptionsService.read(this.userSubscriptionsId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: UserSubscriptionsDTO) => {
          this.userSubscriptions = res;
          this.userSubscriptionsId = res.id;
          this.dtoToForm();
        }
      );
  }

  public userSubscriptionsUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere i dati dell\'utente iscritto?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.userSubscriptionsService.update(this.userSubscriptions)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.userSubscriptionsRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  public userSubscriptionsCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.userSubscriptionsService.create(this.userSubscriptions)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: UserSubscriptionsDTO) => {
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.userSubscriptions = res;
            console.log(res);
            this.userSubscriptionsId = this.userSubscriptions.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/user-subscriptions/detail/' + res.id);
          }
        );
    }
  }


  public userSubscriptionsDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare l\'iscrizione di questo utente?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.userSubscriptionsService.delete(this.userSubscriptions.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' + res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/user-subscriptions']);
                }
              );
          }
        });
    }
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // public subscriptionsDeactive(){
  //   this.subscriptionService.deactive(this.userSubscriptions.username)
  //     .pipe(takeUntil(this._unsubscribeAll))
  //     .subscribe();
  // }

  private formToDto(): void {
    this.userSubscriptions.id = this.form.get('id').value;
    this.userSubscriptions.username = this.form.get('username').value;
    this.userSubscriptions.active = this.form.get('active').value;
    this.userSubscriptions.renewable = this.form.get('renewable').value;
    this.userSubscriptions.promotionTries = this.form.get('promotionTries').value;
    this.userSubscriptions.trialUsed = this.form.get('trialUsed').value;
    this.userSubscriptions.freeUsed = this.form.get('freeUsed').value;
    this.userSubscriptions.idInfluencerAffiliation = this.form.get('idInfluencerAffiliation').value;
    this.userSubscriptions.idCodeAffiliation = this.form.get('idCodeAffiliation').value;
    this.userSubscriptions.sevenDaysAgo = this.form.get('sevenDaysAgo').value;
    this.userSubscriptions.sixDaysAgo = this.form.get('sixDaysAgo').value;
    this.userSubscriptions.fiveDaysAgo = this.form.get('fiveDaysAgo').value;
    this.userSubscriptions.fourDaysAgo = this.form.get('fourDaysAgo').value;
    this.userSubscriptions.threeDaysAgo= this.form.get('threeDaysAgo').value;
    this.userSubscriptions.twoDaysAgo = this.form.get('twoDaysAgo').value;
    this.userSubscriptions.oneDayAgo = this.form.get('oneDayAgo').value;
    this.userSubscriptions.nextCapitalUpdate = new Date(this.form.get('nextCapitalUpdate').value);
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.userSubscriptions.id);
    this.form.get('username').setValue(this.userSubscriptions.username);
    this.form.get('active').setValue(this.userSubscriptions.active);
    this.form.get('renewable').setValue(this.userSubscriptions.renewable);
    this.form.get('promotionTries').setValue(this.userSubscriptions.promotionTries);
    this.form.get('trialUsed').setValue(this.userSubscriptions.trialUsed);
    this.form.get('freeUsed').setValue(this.userSubscriptions.freeUsed);
    this.form.get('idInfluencerAffiliation').setValue(this.userSubscriptions.idInfluencerAffiliation);
    this.form.get('idCodeAffiliation').setValue(this.userSubscriptions.idCodeAffiliation);
    this.form.get('sevenDaysAgo').setValue(this.userSubscriptions.sevenDaysAgo);
    this.form.get('sixDaysAgo').setValue(this.userSubscriptions.sixDaysAgo);
    this.form.get('fiveDaysAgo').setValue(this.userSubscriptions.fiveDaysAgo);
    this.form.get('fourDaysAgo').setValue(this.userSubscriptions.fourDaysAgo);
    this.form.get('threeDaysAgo').setValue(this.userSubscriptions.threeDaysAgo);
    this.form.get('twoDaysAgo').setValue(this.userSubscriptions.twoDaysAgo);
    this.form.get('oneDayAgo').setValue(this.userSubscriptions.oneDayAgo);
    this.form.get('lastCapitalUpdate').setValue(this.timestampFormatPipe.transform(this.userSubscriptions.lastCapitalUpdate));
    this.form.get('nextCapitalUpdate').setValue(this.timestampFormatPipe.transform(this.userSubscriptions.nextCapitalUpdate, 'yyyy-MM-ddTHH:mm:ss'));
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.userSubscriptions.insertDate));
    this.form.get('insertUser').setValue(this.userSubscriptions.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.userSubscriptions.updateDate));
    this.form.get('updateUser').setValue(this.userSubscriptions.updateUser);
    this.form.get('optLock').setValue(this.userSubscriptions.optLock);
    this.form.markAsPristine();
  }

}
