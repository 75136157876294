import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'dataintegration-detail-chiplist',
  templateUrl: './dataintegration-detail-chiplist.component.html',
  styleUrls: ['dataintegration-detail-chiplist.component.scss'],
})
export class DataintegrationChipListComponent implements OnInit {
  [x: string]: any;
  control: FormControl;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('control') set _control(value) {
    this.control = value as FormControl;
  }
  visible = true;
  selectable = true;
  removable = true;
  change = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur= true;
  keyCtrl = new FormControl();
  filteredKeys: Observable<string[]>;
  //keys: string[] = ['apiPassphrase'];
  allKeys: string[] = ['accountCode', 'apiSecret', 'apiPassphrase', 'address'];

  get keyList(): string[] {
    return this.control.value;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('keyInput') keyInput: ElementRef<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {}
  ngOnInit(): void {
    this.filteredKeys = this.keyCtrl.valueChanges.pipe(
      startWith(null),
      map((key: string | null) =>
        key ? this._filter(key) : this.allKeys.slice()
      ),
      map(key => this.allKeys.filter(keys => !this.keyList.includes(keys)))
    );
  }

  chipIsNotEmpty = function(): boolean {
    if(this.keyList.values() !== '') {
      return true;
    }
  };
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    // Add our key
    if ((value || '').trim()) {
      this.keyList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // Reset form control value
    if (this.keyCtrl) {
      this.keyCtrl.reset();
    }
  }

  remove(key: string): void {
    const index = this.keyList.indexOf(key);
    if (index >= 0) {
      this.keyList.splice(index, 1);
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.keyList.push(event.option.viewValue);
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.keyInput.nativeElement.value = '';
    // Reset form control value
    if (this.keyCtrl) {
      this.keyCtrl.reset();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allKeys.filter(
      key => key.toLowerCase().indexOf(filterValue) === 0
    );
  }

}
