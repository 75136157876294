<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Advertisements</h2>
      <p>Imposta il filtro di ricerca</p>
      <div fxLayout="column" class="responsive-mat-form">
  
        <mat-form-field>
          <input matInput [(ngModel)]="parameters.advertiser" placeholder="Advertiser" />
        </mat-form-field>

        <div fxLayout="row" class="responsive-mat-form">
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput readonly="true" (click)="picker.open()" [(max)]="today"
                    [(ngModel)]="parameters.visualizationDate" [matDatepicker]="picker" placeholder="Data in cui è attivo">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <button mat-button *ngIf="parameters.visualizationDate" matSuffix mat-icon-button aria-label="Clear"
                    (click)="parameters.visualizationDate=undefined">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

        </div>
  
      </div>
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/campaigns/detail/0']">New</button>
        <button mat-raised-button (click)="back('/')" color="primary">Back</button>
      </mat-card-actions>
      <br />
  
      <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
        <ng-container *ngIf="parameters.dataSource.length; else noContent">
          
          <h2>Lista advertisements</h2>
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
            matSortDisableClear [dataSource]="parameters.dataSource">
  
            <!-- id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
              <td mat-cell *matCellDef="let adv"> {{adv.id}} </td>
            </ng-container>
  
            <!-- type Column -->
            <ng-container matColumnDef="campaigner">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
              <td mat-cell *matCellDef="let adv"> {{adv.campaigner}} </td>
            </ng-container>
  
            <!-- title Column -->
            <ng-container matColumnDef="visualizationStart">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Inizio periodo visualizzazione </th>
              <td mat-cell *matCellDef="let adv"> {{adv.visualizationStart}} </td>
            </ng-container>
  
            <!-- expirationDate Column -->
            <ng-container matColumnDef="visualizationEnd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fine periodo visualizzazione </th>
              <td mat-cell *matCellDef="let adv"> {{adv.visualizationEnd}} </td>
            </ng-container>
  
            <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let adv">
                <button mat-mini-fab color="primary" [routerLink]="['/campaigns/detail/', adv.id]">
                  <mat-icon>search</mat-icon>
                </button>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
              (page)="setPaginator($event)"></mat-paginator>
          </div>
        </ng-container>
        <ng-template #noContent>
          <h2>Nessun advertisements trovato</h2>
        </ng-template>
      </div>
    </mat-card-content>
  </mat-card>