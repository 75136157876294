import { TimeseriesService } from './../services/core-timeseries/timeseries.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeseriesDownloadConfigurationService } from '../services/cryptocompare-timeseries/timeseries-download-configuration.service';
import { NavigatorService } from '../services/navigator.service';
import { TimeseriesDownloadConfigurationDTO } from '../shared/dto/criptocompare-timeseries/timeseriesDownloadConfiguretionDto';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';
import { timeseriesDownloadConfigurationStatuses } from '../shared/dto/criptocompare-timeseries/TimeseriesDownloadConfigurationStatus.types';

@Component({
  selector: 'app-di-cryptocompare-timeseries-detail',
  templateUrl: './di-cryptocompare-timeseries-detail.component.html',
  styleUrls: ['./di-cryptocompare-timeseries-detail.component.scss']
})

export class CryptocompareTimeseriesDetailComponent extends GenericDetailComponent implements OnInit {

  readonly statuses = timeseriesDownloadConfigurationStatuses;

  cryptocompareTimeseriesId: number;
  cryptocompareTimeseries: TimeseriesDownloadConfigurationDTO;
  check: boolean;
  checkUseCadli: boolean;

  constructor(
    private timeseriesDownloadConfigurationService: TimeseriesDownloadConfigurationService,
    private timeseriesService: TimeseriesService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        symbol: new FormControl('', Validators.required),
        hourDownloadTimeframe: new FormControl('', Validators.required),
        lastTimeframedDownload: new FormControl('', Validators.required),
        nextTimeframedDownload: new FormControl(''),
        fromSymbol: new FormControl(''),
        toSymbol: new FormControl(''),
        exchange: new FormControl(''),
        priviledged: new FormControl(''),
        useCadli: new FormControl(''),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
        startDownloadDate: new FormControl(''),
        endDownloadDate: new FormControl(''),
        toDownloadSymbol: new FormControl(''),
        configurationStatus: new FormControl(''),
      });
  }

  ngOnInit(): void {

    this.cryptocompareTimeseriesId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.cryptocompareTimeseriesId !== 0 && this.cryptocompareTimeseriesId != null) {
      this.timeseriesDownloadConfigurationRead();
    } else {
      this.cryptocompareTimeseries = new TimeseriesDownloadConfigurationDTO();
    }

  }

  timeseriesDownloadConfigurationSave(): void {
    if (this.cryptocompareTimeseries.id == null) {
      this.timeseriesDownloadConfigurationCreate();
    } else {
      this.timeseriesDownloadConfigurationUpdate();
    }
  }

  //READ

  timeseriesDownloadConfigurationRead(): void {
    this.timeseriesDownloadConfigurationService.read(this.cryptocompareTimeseriesId).subscribe(
      (res: TimeseriesDownloadConfigurationDTO) => {
        console.log('response : ' , res);
        this.check = res.priviledged;
        this.checkUseCadli = res.useCadli;
        this.cryptocompareTimeseries = res;
        this.cryptocompareTimeseriesId = res.id;
        this.dtoToForm();
      }
    );
  }

  //CREATE

  timeseriesDownloadConfigurationCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.timeseriesDownloadConfigurationService.create(this.cryptocompareTimeseries).subscribe(
        (res: TimeseriesDownloadConfigurationDTO) => {
          console.log('response : ' , res);
          this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
          this.cryptocompareTimeseries = res;
          console.log(res);
          this.cryptocompareTimeseriesId = this.cryptocompareTimeseries.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }
      );
    }
  }

  // UPDATE

  timeseriesDownloadConfigurationUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere l\'asset mapping configuration?').subscribe((result) => {
        if (result) {
          this.formToDto();
          this.timeseriesDownloadConfigurationService.update(this.cryptocompareTimeseries).subscribe(
            (res) => {
              console.log('response : ' , res);
              this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
              this.timeseriesDownloadConfigurationRead();
              this.form.markAsPristine();
            }
          );
        }
      });
    }
  }

  //DELETE

  timeseriesDownloadConfigurationDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare l\'asset mapping configuration?').subscribe((result) => {
        if (result) {
          this.formToDto();
          this.timeseriesDownloadConfigurationService.delete(this.cryptocompareTimeseries.id).subscribe(
            (res) => {
              console.log('response : ' , res);
              this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
              this.router.navigate(['/timeseriesDownloadConfiguration']);
            }
          );
        }
      });
    }
  }

  //RESET

  timeseriesDownloadConfigurationReset(): void {
    if (this.form.valid && this.form.pristine) {
      this.confirm('Sei sicuro di voler cancellare e riscaricare l\'asset mapping configuration?').subscribe((result) => {
        if (result) {
          this.formToDto();
          this.timeseriesService.reset(this.cryptocompareTimeseries.symbol).subscribe(
            (res) => {
              this.snackBar.open('Reset avvenuto con successo!', null, { duration: 3000 });
              this.router.navigate(['/timeseriesDownloadConfiguration']);
            }
          );
        }
      });
    }
  }

  //DOWNLOAD

  timeseriesDownloadConfigurationDownload(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler scaricare l\'asset mapping configuration?').subscribe((result) => {
        if (result) {
          this.formToDto();
          const toSymbol = (this.form.get('toDownloadSymbol').value)? this.form.get('toDownloadSymbol').value : this.form.get('toSymbol').value;
          this.timeseriesDownloadConfigurationService.download(
            this.cryptocompareTimeseries.symbol, toSymbol,
            this.form.get('endDownloadDate').value, this.form.get('startDownloadDate').value).subscribe(
            (res) => {
              this.snackBar.open('Download avvenuto con successo!', null, { duration: 3000 });
              this.router.navigate(['/timeseriesDownloadConfiguration']);
            }
          );
        }
      });
    }
  }

  checkedControl(): boolean {
    return this.form.get('priviledged') != null;

  }

  checkedControlUseCadli(): boolean {
    return this.form.get('useCadli') != null;

  }


  private formToDto(): void {
    this.cryptocompareTimeseries.symbol = this.form.get('symbol').value;
    this.cryptocompareTimeseries.hourDownloadTimeframe = this.form.get('hourDownloadTimeframe').value;
    this.cryptocompareTimeseries.lastTimeframedDownload = new Date(this.form.get('lastTimeframedDownload').value);
    this.cryptocompareTimeseries.nextTimeframedDownload = new Date(this.form.get('nextTimeframedDownload').value);
    this.cryptocompareTimeseries.fromSymbol = this.form.get('fromSymbol').value;
    this.cryptocompareTimeseries.toSymbol = this.form.get('toSymbol').value;
    this.cryptocompareTimeseries.exchange = this.form.get('exchange').value;
    this.cryptocompareTimeseries.priviledged = this.form.get('priviledged').value;
    this.cryptocompareTimeseries.useCadli = this.form.get('useCadli').value;
    this.cryptocompareTimeseries.configurationStatus = this.form.get('configurationStatus').value;

  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.cryptocompareTimeseries.id);
    this.form.get('symbol').setValue(this.cryptocompareTimeseries.symbol);
    this.form.get('hourDownloadTimeframe').setValue(this.cryptocompareTimeseries.hourDownloadTimeframe);
    this.form.get('lastTimeframedDownload').setValue(this.timestampFormatPipe.transform(this.cryptocompareTimeseries.lastTimeframedDownload, 'yyyy-MM-ddTHH:mm:ss'));
    this.form.get('nextTimeframedDownload').setValue(this.timestampFormatPipe.transform(this.cryptocompareTimeseries.nextTimeframedDownload, 'yyyy-MM-ddTHH:mm:ss'));
    this.form.get('fromSymbol').setValue(this.cryptocompareTimeseries.fromSymbol);
    this.form.get('toSymbol').setValue(this.cryptocompareTimeseries.toSymbol);
    this.form.get('exchange').setValue(this.cryptocompareTimeseries.exchange);
    this.form.get('priviledged').setValue(this.cryptocompareTimeseries.priviledged);
    this.form.get('useCadli').setValue(this.cryptocompareTimeseries.useCadli);
    this.form.get('configurationStatus').setValue(this.cryptocompareTimeseries.configurationStatus);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.cryptocompareTimeseries.insertDate));
    this.form.get('insertUser').setValue(this.cryptocompareTimeseries.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.cryptocompareTimeseries.updateDate));
    this.form.get('updateUser').setValue(this.cryptocompareTimeseries.updateUser);
    this.form.get('optLock').setValue(this.cryptocompareTimeseries.optLock);
    this.form.markAsPristine();
  }

}
