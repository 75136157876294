<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent"  *ngIf="this.form">
      <div fxLayout="column" fxFlex="40" class="form">
        <h2>Advertisement</h2>
        <div fxLayout="column">
          <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
            <!-- Id field -->
            <mat-form-field>
              <input matInput formControlName="id" placeholder="Id" readonly="true" />
            </mat-form-field>
  
            <mat-form-field>
                <input matInput formControlName="campaigner" placeholder="Advertiser" [readonly]="form.get('deleteDate').value"/>
            </mat-form-field>

            <mat-form-field>
                <input matInput formControlName="imageUrl" placeholder="Url Immagine" [readonly]="form.get('deleteDate').value"/>
            </mat-form-field>

            <mat-form-field>
                <input matInput formControlName="redirectUrl" placeholder="Url per reindirizzamento" [readonly]="form.get('deleteDate').value"/>
            </mat-form-field>
  
            <!-- visualizationStart field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput [matDatepicker]="visualizationStartPicker" formControlName="visualizationStart"
                  placeholder="Data inizio periodo di visualizzazione" (click)="visualizationStartPicker.open()" [readonly]="form.get('deleteDate').value" />
              <mat-datepicker-toggle matSuffix [for]="visualizationStartPicker" [disabled]="form.get('deleteDate').value"></mat-datepicker-toggle>
              <mat-datepicker #visualizationStartPicker [disabled]="form.get('deleteDate').value"></mat-datepicker>
            </mat-form-field>

            <!-- visualizationEnd field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput [matDatepicker]="visualizationEndPicker" formControlName="visualizationEnd"
                  placeholder="Data fine periodo di visualizzazione" (click)="visualizationEndPicker.open()" [readonly]="form.get('deleteDate').value" />
              <mat-datepicker-toggle matSuffix [for]="visualizationEndPicker" [disabled]="form.get('deleteDate').value"></mat-datepicker-toggle>
              <mat-datepicker #visualizationEndPicker [disabled]="form.get('deleteDate').value"></mat-datepicker>
            </mat-form-field>
  
            <!-- Campi di informazione operazione -->
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Dettagli operazione
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row">
                <!-- InsertDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                </mat-form-field>
  
                <!-- InsertUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                </mat-form-field>
              </div>
  
              <div fxLayout="row">
                <!-- UpdateDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                </mat-form-field>
  
                <!-- UpdateUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                </mat-form-field>
              </div>
  
              <div fxLayout="row">
                <!-- DeleteDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="deleteDate" placeholder="Delete Date" readonly="true" />
                </mat-form-field>
  
                <!-- DeleteUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="deleteUser" placeholder="Delete User" readonly="true" />
                </mat-form-field>
              </div>
              
            </mat-expansion-panel>
          </form>
        </div>
  
        <mat-card-actions>
          <button mat-raised-button (click)="backConfirm('/campaigns')" color="primary">Back</button>
          <button mat-raised-button (click)="save()" [disabled]="!form.dirty || form.get('deleteDate').value">
            Save
          </button>
          <button mat-raised-button (click)="deleteAdvertisement()" *ngIf="detailId()" [disabled]="!form.get('id').value || form.get('deleteDate').value">
            Delete
          </button>
          <button mat-raised-button [routerLink]="['/campaigns/detail/'+detailId()+ '/translation/detail/0']" *ngIf="detailId()" [disabled]="form.get('deleteDate').value">
            Aggiungi traduzione
          </button>
        </mat-card-actions>
        <div *ngIf="detailId()">
          <campaigns-translations-list [advId]="detailId()"></campaigns-translations-list>
        </div>
      </div>
    </mat-card-content>
  
  </mat-card>