/* eslint-disable @typescript-eslint/naming-convention */
export enum DataIntegrationStatus {
  ACTIVE  = 'ACTIVE',
  DISABLED = 'DISABLED',
  MAINTENANCE = 'MAINTENANCE'
}
export enum DataIntegrationType {
  WALLET  = 'WALLET',
  EXCHANGE_ACCOUNT = 'EXCHANGE_ACCOUNT'
}

export const dataintegrationStatus = Object.values(DataIntegrationStatus);
export const dataintegrationType = Object.values(DataIntegrationType);