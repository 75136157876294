import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WeisscryptoAssetCacheService extends GenericService<AssetDefinitionDto, number> {

    constructor(http: HttpClient) {
      super(http);
      this.url = environment.urlPrefix + '/arzakena-di-weisscrypto-catalog/assetCache';
    }

    public assetCacheListBySymbol(symbol: string): Observable<Array<AssetDefinitionDto>> {
      return this.http.get<Array<AssetDefinitionDto>>(`${this.url}/`+ symbol);
    }

    public assetCacheDeleteBySymbol(symbol: string): Observable<any> {
      return this.http.delete(`${this.url}/`+symbol);
    }


  }
