import { ChangeLogTranslationDto } from '../../shared/dto/change-log/ChangeLogTranslationDto.types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable()
export class ChangeLogTranslationService extends GenericService<ChangeLogTranslationDto, number> {

    noAdminurl: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-be-change-log/translation/admin';
        this.noAdminurl = environment.urlPrefix + '/arzakena-be-change-log/translation';
    }

    list(): Observable<ChangeLogTranslationDto[]> {
        return this.http.get<ChangeLogTranslationDto[]>(`${this.noAdminurl}/list`);
    }

    findByChangeLogId(idChangeLog: number): Observable<ChangeLogTranslationDto[]> {
        return this.http.get<ChangeLogTranslationDto[]>(`${this.noAdminurl}/list/${idChangeLog}`);
    }

    getTranslationsNotRead(idChangeLog: number, language: string): Observable<ChangeLogTranslationDto> {
        return this.http.get<ChangeLogTranslationDto>(`${this.noAdminurl}/${idChangeLog}/${language}`);
    }
}
