import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { UserSubscriptionsService } from 'src/app/services/be-subscriptions/user-subscriptions/user-subscriptions.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stripe-extension',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent extends GenericListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private userService: UserSubscriptionsService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
      super(navigatorService,
        componentCacheService,
        dateAdapter,
        router,
        snackBar,
        timestampFormatPipe);
      this.displayedColumns = ['user.id', 'user.username', 'user.renewable','detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        username: undefined,
        startDateFrom: undefined,
        startDateTo: undefined,
        endDateFrom: undefined,
        endDateTo: undefined,
        sort: undefined,
        page: 0,
        size: 50,
        length: 0
      };
  }

  list(): void {
    this.userService.getListWithActiveCardSubscriptions(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
      this.parameters.startDateFrom, this.parameters.startDateTo, this.parameters.endDateFrom, this.parameters.endDateTo,
      this.parameters.username)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((users) => {
        this.parameters.dataSource = users.content;
        this.parameters.page = users.page;
        this.parameters.length = users.totalElements;
        this.parameters.showList = true;
      });

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  isRenewable(check: boolean): string {
    if (check) {
      return 'Rinnovabile';
    } else {
      return 'Non rinnovabile';
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
