import { Component, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NavigatorService } from '../services/navigator.service';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';
import { PositionsContainerService } from '../services/positions-container/positions-container.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { ComponentCacheService } from '../services/component-cache.service';
import { Subject, of, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { PositionsInfoDTO } from '../shared/dto/positions-container/PositionsInfoDTO';
import { PageDTO } from '../shared/dto/PageDTO';
import { positionContainerTypes } from '../shared/dto/core-wallets/positionContainerType.types';
import { exchangeTypes } from '../shared/dto/commons/ExchangeType.types';
import { blockchainTypes } from '../shared/dto/commons/BlockchainType.types';
import { Pageable } from '../shared/dto/Pageable.types';

@Component({
  selector: 'position-container-component',
  templateUrl: './positions-container.component.html',
  styleUrls: ['./positions-container.component.scss'],
  providers: [
    PositionsContainerService
  ]
})
export class PositionsComponent extends GenericListComponent implements OnDestroy {
  readonly positionContainerTypes = positionContainerTypes;
  readonly exchangeTypes = exchangeTypes;
  readonly blockchainTypes = blockchainTypes;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(private positionsService: PositionsContainerService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['containerType', 'containerCodeType', 'totalKeys',
      'toManyTransactions', 'downloadProblems', 'verifying', 'verified','somePositionsSquared', 'invalidKey'];
      this.parameters = {
        dataSource: [],
        showList: false,
        filter1: null,
        filter2: null,
        sort: undefined,
        page: 0,
        size: 50,
        length: 0
      };
  }
  list(): void {
    if (this.parameters.filter1 === 'All') {
      this.parameters.filter1 = null;
    }
    this.positionsService.readInfos(this.parameters.filter2,
       this.parameters.filter1,
       {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable)
    .pipe(
      catchError((err) => {
        if (err.status === 404) {
          this.snackBar.open('Position not found!', null, { duration: 3000 });
              return of(null);
        } else {return throwError(err);}
      })
      ,takeUntil(this._unsubscribeAll))
    .subscribe((response: PageDTO<PositionsInfoDTO>) => {
      this.parameters.dataSource = response.content;
      this.parameters.page = response.page;
      this.parameters.size = response.size;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnDestroy(): void {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }
}
