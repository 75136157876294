import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { ManagedSymbolService } from 'src/app/services/core-wallets/managed-symbol.service';
import { ManagedSymbolDto } from 'src/app/shared/dto/core-wallets/managedSymbol.types';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-managed-symbol-detail',
  templateUrl: './managed-symbol-detail.component.html',
  styleUrls: ['./managed-symbol-detail.component.scss'],
  providers: [
    ManagedSymbolService
  ]
})
export class ManagedSymbolDetailComponent extends GenericDetailComponent implements OnInit {

  constructor(
    private managedSymbolService: ManagedSymbolService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    this.form = this.loadForm(new ManagedSymbolDto());
  }

  save(): void {
    this.form.markAsPristine();
    this.checkForManagedSymbol(this.toManagedSymbolDto().symbol);
  }

  private checkForManagedSymbol(symbol: string): void {
    this.managedSymbolService.manageNewSymbol(symbol).pipe(catchError(()=> of(null))).subscribe(() => this.router.navigate(['/timeseriesDownloadConfiguration']));
  }

  private loadForm(managedSymbol: ManagedSymbolDto): FormGroup {
    return this._formBuilder.group({...managedSymbol, ...this.formValidators(managedSymbol)});
  }

  private toManagedSymbolDto(): ManagedSymbolDto {
    return this.form.getRawValue() as ManagedSymbolDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(managedSymbol: ManagedSymbolDto) {
    return {
        symbol: [managedSymbol.symbol, [Validators.required, Validators.maxLength(32)]],
    };
  }
}
