import { FormGroup, FormControl } from '@angular/forms';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { CodeDTO } from 'src/app/shared/dto/code/CodeDTO';
import { CodeService } from './../../services/code/code.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NavigatorService } from '../../services/navigator.service';
import { GenericDetailComponent } from '../../shared/GenericDetailComponent';

@Component({
  selector: 'app-asset-mapping-configuration-detail',
  templateUrl: './affiliation-report-detail.component.html',
  styleUrls: ['./affiliation-report-detail.component.scss']
})
export class AffiliationReportDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  id: number;

  codes: CodeDTO[];

  form: FormGroup;

  selectedDate: Date = null;

  today: Date = new Date();

  private _unsubscribeAll: Subject<void> = new Subject<void>();;

  constructor(
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    private codeService: CodeService,
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
      this.form = new FormGroup({
        selectedDate: new FormControl({ value: '' })
      });
  }

  ngOnInit(): void {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));

    this.codeService.readAllByInfluencerId(this.id)
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(res => !!res.length)
      )
      .subscribe((res) => {this.codes = res;});
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  filter(): void{
    console.log(this.form.get('selectedDate').value);
    this.selectedDate = this.form.get('selectedDate').value;
  }
}
