<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Promotion</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id"
                        readonly="true" />
                </mat-form-field>

                <!-- code field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="code" placeholder="Code">
                    <mat-error *ngIf="form.get('code').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('code').errors?.maxlength">
                        Max length reached (8 max)
                    </mat-error>
                </mat-form-field>

                <!-- description field -->
                <mat-form-field>
                    <input matInput type="text" formControlName="description" placeholder="description" min="0">
                    <mat-error *ngIf="form.get('description').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('description').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- idProduct field -->
                <mat-form-field>
                    <mat-select formControlName="product" placeholder="product">
                        <mat-option *ngFor="let product of products" [value]="product.id">
                            {{productTranslations.get(product.name)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Days field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="days" placeholder="Days" min="0">
                    <mat-error *ngIf="form.get('days').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('days').errors?.max">
                        Max days reached (31 max)
                    </mat-error>
                    <mat-error *ngIf="form.get('days').errors?.min">
                        Days cannot be negative
                    </mat-error>
                </mat-form-field>

                <!-- Months field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="months" placeholder="Months" min="0">
                    <mat-error *ngIf="form.get('months').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('months').errors?.max">
                        Max months reached (1024 max)
                    </mat-error>
                    <mat-error *ngIf="form.get('months').errors?.min">
                        Months cannot be negative
                    </mat-error>
                </mat-form-field>


                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/promotion')" color="primary">Back</button>
            <button mat-raised-button (click)="promotionSave()" [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <button mat-raised-button (click)="promotionDelete()" [disabled]="promotion?.id==null">
                Delete
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>