<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Promotions generator</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Number field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="number" placeholder="Number" min="1">
                    <mat-error *ngIf="form.get('number').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('number').errors?.max">
                        Max number of promotions reached (100 max)
                    </mat-error>
                    <mat-error *ngIf="form.get('number').errors?.min">
                        Must be > 0
                    </mat-error>
                </mat-form-field>

                <!-- description field -->
                <mat-form-field>
                    <input matInput type="text" formControlName="description" placeholder="description">
                    <mat-error *ngIf="form.get('description').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('description').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- idProduct field -->
                <mat-form-field>
                    <mat-select formControlName="product" placeholder="product">
                        <mat-option *ngFor="let product of products" [value]="product.id">
                            {{productTranslations.get(product.name)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Days field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="days" placeholder="Days" min="0">
                    <mat-error *ngIf="form.get('days').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('days').errors?.max">
                        Max days reached (31 max)
                    </mat-error>
                    <mat-error *ngIf="form.get('days').errors?.min">
                        Days cannot be negative
                    </mat-error>
                </mat-form-field>

                <!-- Months field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="months" placeholder="Months" min="0">
                    <mat-error *ngIf="form.get('months').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('months').errors?.max">
                        Max months reached (1024 max)
                    </mat-error>
                    <mat-error *ngIf="form.get('months').errors?.min">
                        Months cannot be negative
                    </mat-error>
                </mat-form-field>

            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/promotion')" color="primary">Back</button>
            <button mat-raised-button (click)="generate()" [disabled]="form.invalid || !form.dirty ">
                Generate
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>