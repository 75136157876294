import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { WeisscryptoAssetMappingConfigurationService } from '../services/weisscrypto-catalog/asset-mapping-configuration.service';
import { PageDTO } from '../shared/dto/PageDTO';
import { WeisscryptoAssetMappingConfigurationDTO } from '../shared/dto/weisscrypto-catalog/weisscryptoAssetMappingConfigurationDto';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-weisscrypto-catalog',
  templateUrl: './weisscrypto-catalog.component.html',
  styleUrls: ['./weisscrypto-catalog.component.scss']
})
export class WeisscryptoCatalogComponent extends GenericListComponent  implements OnInit, OnDestroy {

  constructor(
      private assetMappingConfigurationService: WeisscryptoAssetMappingConfigurationService,
      componentCacheService: ComponentCacheService,
      navigatorService: NavigatorService,
      dateAdapter: DateAdapter<Date>,
      router: Router,
      snackBar: MatSnackBar,
      timestampFormatPipe: TimestampFormatPipe
  ) {

    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['cryptoId', 'weissSymbol', 'weissName', 'arzakenaSymbol', 'action',  'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        filter: null,
        sort: null,
        page: 0,
        size: 50,
        length: 0
  };
}

list(): void{
  this.assetMappingConfigurationService.assetMappingConfigurationList(
    this.parameters.page,
    this.parameters.size,
    this.parameters.sort,
    this.parameters.filter
  ).subscribe((response: PageDTO<WeisscryptoAssetMappingConfigurationDTO>) => {
    console.log('response : ' , response);
    this.parameters.dataSource = response.content;
    this.parameters.page = response.page;
    this.parameters.length = response.totalElements;
    this.parameters.showList = true;
    }
  );
}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
