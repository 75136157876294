import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent} from '@angular/material/chips';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { FormBuilder } from '@angular/forms';
import { ManagedSymbolService } from 'src/app/services/core-wallets/managed-symbol.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AssetDefinitionService } from 'src/app/services/core-catalog/asset-definition/asset-definition.service';

@Component({
  selector: 'app-check-for-managed-symbols',
  templateUrl: './check-for-managed-symbols.component.html',
  styleUrls: ['./check-for-managed-symbols.component.scss'],
  providers: [
    ManagedSymbolService
  ]
})
export class CheckForManagedSymbolsComponent extends GenericDetailComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  symbols: CustomSymbol[] = [];


  constructor(
    private managedSymbolService: ManagedSymbolService,
    private assetDefinitionService: AssetDefinitionService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.checkSymbolCatalog(value.trim()).subscribe((res) =>{
        this.symbols.push({name: value.trim(), color: res});
      });
    }
    if (input) {
      input.value = '';
    }
  }

  remove(symbol: CustomSymbol): void {
    const index = this.symbols.indexOf(symbol);
    if (index >= 0) {
      this.symbols.splice(index, 1);
    }
  }

  save(): void{
    this.insertSymbols();
  }

  checkInsertSimbols(): boolean{
    const s = this.symbols.filter(res => res.color === false);
    return s.length === 0;
  }

  back(): void {
    const M: string = 'Proseguendo, tutte le modifiche andranno perse. Sei sicuro di voler tornare alla pagina precedente?';
    if (this.symbols.length !== 0) {
        this.confirm(M)
            .pipe(take(1))
            .subscribe((result) => {
            if (result) {
              this.router.navigate(['/']);
            }
        });
    }
    else {
      this.router.navigate(['/']);
    }
  }

  checkSymbolCatalog(symbol: string): Observable<boolean>{
    return this.assetDefinitionService.readSymbol(symbol).pipe(
      switchMap(()=>of(true)),
      catchError(()=> of(false))
    );
  }

  private insertSymbols(): void{
    const listSymbols: string[] = this.symbols.map(symbol => symbol.name);
    this.managedSymbolService.checkForManagedSymbols(listSymbols)
    .pipe(catchError(()=> EMPTY))
    .subscribe(() => this.router.navigate(['/']));
  }

}

export interface CustomSymbol {
  name: string;
  color?: boolean;
}
