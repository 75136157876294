import { takeUntil } from 'rxjs/operators';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationService } from 'src/app/services/be-notifications/notification/notification.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { NotificationChannel, NotificationStatus } from 'src/app/shared/dto/be-notifications/NotificationTypes';

@Component({
  selector: 'app-notifications-notifications',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [
    NotificationService
  ]
})
export class NotificationComponent extends GenericListComponent implements OnInit, OnDestroy {

  readonly statuses: NotificationStatus[] = ['READ', 'UNREAD'];
  readonly channels: NotificationChannel[] = ['MAIL', 'BELL', 'POPUP'];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private notificationService: NotificationService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'category', 'status', 'channel', 'logicalKey', 'username', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      categoryBundleKey: undefined,
      status: undefined,
      channel: undefined,
      logicalKey: undefined,
      username: undefined,
      email: undefined,
      page: 0,
      size: 10,
      length: 0
    };
  }

  list(): void{
    this.notificationService.readAll(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
      this.parameters.categoryBundleKey, this.parameters.status, this.parameters.channel, this.parameters.logicalKey,
      this.parameters.username, this.parameters.email)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      this.parameters.dataSource = response.content;
      this.parameters.page = response.page;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
