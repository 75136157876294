import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class DIAlternativeMeFearAndGreedService extends GenericService<any, number>{

    fearAndGreedHistory$: Observable<any>;
    reloadUrl: string;

    constructor(http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-core-fearandgreed/fearandgreed/history/';
        this.reloadUrl = environment.urlPrefix + '/arzakena-di-alternativeme-fearandgreed/check/getIndexes/';
      }

    public loadfearAndgreedHistoryByDepth(timelapse: number): Observable<any>{
        const url = `${this.url}`+timelapse;
        return this.fearAndGreedHistory$ = this.http.get<any>(url);
    }

    public reloadFearAndGreed(limit: number): Observable<void> {
      const url = `${this.reloadUrl}`+limit;
      return this.http.put<void>(url, {});
    }
}
