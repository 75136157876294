<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent"  *ngIf="this.form">
    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Disclaimer Message</h2>
      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Id" readonly="true" />
          </mat-form-field>

          <!-- DisclaimerType field -->
          <mat-form-field>
              <mat-select formControlName="disclaimerType" placeholder="disclaimerType" [disabled]="form.get('deleteDate').value">
                  <mat-option *ngFor="let disclaimerType of disclaimerTypes" [value]="disclaimerType">
                      {{disclaimerType}}
                  </mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput formControlName="title" placeholder="Title" [readonly]="form.get('deleteDate').value"/>
          </mat-form-field>

          <mat-checkbox formControlName="required" labelPosition="before" style="margin-bottom: 10px;"  [disabled]="form.get('deleteDate').value">
            Required</mat-checkbox>

          <mat-checkbox formControlName="mustBeChecked" labelPosition="before" style="margin-bottom: 10px;"  [disabled]="form.get('deleteDate').value">
            Must Be Checked</mat-checkbox>

          <!-- endDateDisplay field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [matDatepicker]="expirationDatePicker" formControlName="expirationDate"
                placeholder="Expiration Date" (click)="expirationDatePicker.open()" [min]="tomorrow" [readonly]="form.get('deleteDate').value" />
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker" [disabled]="form.get('deleteDate').value"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker [disabled]="form.get('deleteDate').value"></mat-datepicker>
          </mat-form-field>

          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>

              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>

              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <!-- DeleteDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="deleteDate" placeholder="Delete Date" readonly="true" />
              </mat-form-field>

              <!-- DeleteUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="deleteUser" placeholder="Delete User" readonly="true" />
              </mat-form-field>
            </div>
            
          </mat-expansion-panel>
        </form>
      </div>

      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/disclaimers/message')" color="primary">Back</button>
        <button mat-raised-button (click)="save()" [disabled]="(form.invalid || !form.dirty) || form.get('deleteDate').value">
          Save
        </button>
        <button mat-raised-button  (click)="deleteMessage()" *ngIf="detailId()" [disabled]="!form.get('id').value || form.get('deleteDate').value">
          Delete
        </button>
        <button mat-raised-button  [routerLink]="['/disclaimers/message/detail/'+detailId()+ '/translation/detail/0']" *ngIf="detailId()" [disabled]="form.get('deleteDate').value">
          Aggiungi traduzione
        </button>
      </mat-card-actions>
      <div *ngIf="detailId()">
        <translations-list [messageId]="detailId()"></translations-list>
      </div>
    </div>
  </mat-card-content>

</mat-card>