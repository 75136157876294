

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
  <div fxLayout="column" fxFlex="40" class="form">
      <h2>Check Symbols</h2>
      <asset-definition-input (assetDefinitionChangedEvent)="assetDefinitionChanged($event)"></asset-definition-input>
      <div fxLayout="column" *ngIf='form' >
        <mat-form-field>
          <mat-label>Insert Aliases</mat-label>
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let alias of aliases?.value" [selectable]="true"
                     [removable]="true"
                     (removed)="remove(alias)">
              {{alias}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="New alias..."
                   #chipInput
                   [matChipInputFor]="chipList"
                   (matChipInputTokenEnd)="add(chipInput);">
          </mat-chip-list>
        </mat-form-field>
      </div>

      <mat-card-actions>
        <button mat-raised-button (click)="back('')" color="primary">Back</button>
        <button mat-raised-button (click)="save()" [disabled]="!aliases || !aliases.value?.length || !form.dirty">Save</button>
      </mat-card-actions>
    </div>
  </mat-card-content>

</mat-card>