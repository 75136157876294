<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Influencer</h2>
      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Influencer id" readonly="true" />
          </mat-form-field>

          <!-- displayName field -->
          <mat-form-field>
            <input matInput type="text" formControlName="displayName" placeholder="displayName" min="0">
            <mat-error *ngIf="form.get('displayName').errors?.required">
              DisplayName required
            </mat-error>
            <mat-error *ngIf="form.get('displayName').errors?.maxlength">
              DisplayName max lenght reached (max 64)
            </mat-error>
          </mat-form-field>

          <!-- logo field -->
          <mat-form-field fxFlex>
            <input matInput type="text" formControlName="logo" placeholder="Logo">
            <mat-error *ngIf="form.get('logo').errors?.pattern">
              Invalid Logo
            </mat-error>
            <mat-error *ngIf="form.get('logo').errors?.maxlength">
              Logo max length reached (max 256)
            </mat-error>
          </mat-form-field>

          <!-- logo field -->
          <mat-form-field fxFlex>
            <input matInput type="text" formControlName="logoDark" placeholder="Logo dark mode">
            <mat-error *ngIf="form.get('logoDark').errors?.pattern">
              Invalid Logo
            </mat-error>
            <mat-error *ngIf="form.get('logoDark').errors?.maxlength">
              Logo max length reached (max 256)
            </mat-error>
          </mat-form-field>


          <!-- logo field -->
          <mat-form-field fxFlex>
            <input matInput type="text" formControlName="logoNoFill" placeholder="Logo no fill">
            <mat-error *ngIf="form.get('logoNoFill').errors?.pattern">
              Invalid Logo
            </mat-error>
            <mat-error *ngIf="form.get('logoNoFill').errors?.maxlength">
              Logo max length reached (max 256)
            </mat-error>
          </mat-form-field>

          <!-- banner field -->
          <mat-form-field fxFlex>
            <input matInput type="text" formControlName="banner" placeholder="Banner">
            <mat-error *ngIf="form.get('banner').errors?.pattern">
              Invalid Banner
            </mat-error>
            <mat-error *ngIf="form.get('banner').errors?.maxlength">
              Banner max length reached (max 256)
            </mat-error>
          </mat-form-field>

          <!-- banner field -->
          <mat-form-field fxFlex>
            <input matInput type="text" formControlName="bannerDark" placeholder="Banner dark mode">
            <mat-error *ngIf="form.get('bannerDark').errors?.pattern">
              Invalid Banner
            </mat-error>
            <mat-error *ngIf="form.get('bannerDark').errors?.maxlength">
              Banner max length reached (max 256)
            </mat-error>
          </mat-form-field>

          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>

              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">

              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>

              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/gestione-affiliation')" color="primary">Back</button>
        <button mat-raised-button (click)="gestioneAffiliationSave();"
          [disabled]="(form.invalid || !form.dirty) || (!socialsTrueValidation(socialsForm) && socialsForm.dirty) || form.untouched">
          Save
        </button>
        <button mat-raised-button (click)="gestioneAffiliationDelete()" [disabled]="gestioneAffiliation?.id===null">
          Delete
        </button>
        <button mat-raised-button *ngIf="id" (click)="backConfirm('/gestione-affiliation/detail/report/'+id)" color="primary">Report</button>
      </mat-card-actions>


      <mat-tab-group *ngIf="gestioneAffiliation && gestioneAffiliation.id !== null" [(selectedIndex)]="selectedTabIndex"
        (selectedTabChange)="onTabChange($event);">

        <mat-tab label="Socials">
          <button mat-raised-button id="new-button"
            *ngIf="selectedTabIndex === 0 && gestioneAffiliation && gestioneAffiliation.id !== null"
            [routerLink]="['/social/detail/' + id +'/0']" color="accent">New Social</button>
          <app-social *ngIf="gestioneAffiliation.socials?.length !== 0" [influencerId]="id" [socials]="gestioneAffiliation.socials"></app-social>
        </mat-tab>

        <mat-tab label="Codici">
          <button mat-raised-button id="new-button"
            *ngIf="selectedTabIndex === 1 && gestioneAffiliation && gestioneAffiliation.id !== null"
            [routerLink]="['/code/detail/' + id + '/0']" color="accent">New Code</button>
          <app-code *ngIf="gestioneAffiliation.codes?.length !== 0" [influencerId]="gestioneAffiliation.id"></app-code>
        </mat-tab>

        <mat-tab label="Users">
          <button mat-raised-button id="new-button"
            *ngIf="selectedTabIndex === 2 && gestioneAffiliation && gestioneAffiliation.id !== null"
            [routerLink]="['/users/detail/' + id + '/new']" color="accent">New User</button>
          <app-users *ngIf="users?.length !== 0" [influencerId]="id" [users]="users"></app-users>
        </mat-tab>

      </mat-tab-group>

      <app-social-form-list *ngIf="gestioneAffiliation && gestioneAffiliation.id === null" [socialsForm]="socialsForm">
      </app-social-form-list>
      <div fxLayout="row">
        <button class="social-button" *ngIf="gestioneAffiliation && gestioneAffiliation.id === null" mat-raised-button
          (click)="addSocial()" color="primary">
          Add Social
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>