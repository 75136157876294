<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli Asset Mapping Configuration</h2>
    <div fxLayout="column">
      <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Asset Mapping Configuration Id" readonly="true" />
        </mat-form-field>

        <!-- cryptoId field -->
        <mat-form-field>
          <input matInput type="number" formControlName="cryptoId" placeholder="cryptoId" min="0">
          <mat-error *ngIf="form.get('cryptoId').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- weissSymbol field -->
        <mat-form-field fxFlex>
          <input matInput type="text" formControlName="weissSymbol" placeholder="weissSymbol">
          <mat-error *ngIf="form.get('weissSymbol').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- coinmarketcapName field -->
        <mat-form-field fxFlex>
          <input matInput type="text" formControlName="weissName" placeholder="weissName">
          <mat-error *ngIf="form.get('weissName').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- arzakenaSymbol field -->
        <mat-form-field fxFlex>
          <input matInput type="text" formControlName="arzakenaSymbol" placeholder="arzakenaSymbol">
        </mat-form-field>

        <!-- action field -->
        <mat-form-field fxFlex>
          <mat-select matInput formControlName="action" placeholder="action">
            <mat-option *ngFor="let action of actions" [value]="action.value">
              {{action.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">

            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/weisscryptoAssetMappingConfiguration')"
        color="primary">Back</button>
      <button mat-raised-button (click)="assetMappingConfigurationSave()" [disabled]="form.invalid || !form.dirty ">
        Save
      </button>
      <delete-button (customClick)="assetMappingConfigurationDelete()"
                     message='Sei sicuro di voler cancellare questo asset mapping?'
                     [disabled]="assetMappingConfiguration?.id==null">
      </delete-button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>