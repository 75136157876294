<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Influencer Report</h2>

      <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
        <!-- Date field -->
        <mat-form-field fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [matDatepicker]="selectedDatePicker" formControlName="selectedDate"
                placeholder="Selected Date" (click)="selectedDatePicker.open()" readonly="true" [max]="today">
            <mat-datepicker-toggle matSuffix  [for]="selectedDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #selectedDatePicker></mat-datepicker>
            <button mat-button *ngIf="form.get('selectedDate').value" matSuffix mat-icon-button
                aria-label="Clear" (click)="form.get('selectedDate').setValue(null)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
      </form>

      <mat-card-actions>
        <button mat-raised-button (click)="filter();"
          [disabled]="form.untouched">
          Search
        </button>
        <button mat-raised-button (click)="back('/gestione-affiliation/detail/'+id)" color="primary">Back</button>
      </mat-card-actions>

      <div fxLayout="column">
        <app-affiliation-report [influencerId]="id" [influencerOrCode]="true" [date]="selectedDate"
          fxFlex="fill" *ngIf="id !== null">
        </app-affiliation-report>
      </div>

      <mat-accordion>
        <mat-expansion-panel *ngFor="let code of codes">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ code.code }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-affiliation-report [codeId]="code.id" [influencerOrCode]="false"
            [date]="selectedDate" [applyMargin]="true" fxFlex="fill" style="width: 100%;">
          </app-affiliation-report>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </mat-card-content>
</mat-card>