import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentCacheService } from '../services/component-cache.service';
import { GestioneAffiliationService } from '../services/gestione-affiliation/gestione-affiliation.service';
import { NavigatorService } from '../services/navigator.service';
import { InfluencerDTO } from '../shared/dto/influencer/InfluencerDTO';
import { PageDTO } from '../shared/dto/PageDTO';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-gestione-affiliation',
  templateUrl: './gestione-affiliation.component.html',
  styleUrls: ['./gestione-affiliation.component.scss']
})
export class GestioneAffiliationComponent extends GenericListComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private gestioneAffiliationService: GestioneAffiliationService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['displayName', 'logo', 'banner', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
    };

  }

  list(): void {
    if (this.parameters.filter === '') {
      this.parameters.filter = null;
    }
    this.gestioneAffiliationService.influencerList(
      this.parameters.page,
      this.parameters.size,
      this.parameters.sort,
      this.parameters.filter
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: PageDTO<InfluencerDTO>) => {
        console.log('response : ' , response);
        this.parameters.dataSource = response.content;
        this.parameters.page = response.page;
        this.parameters.length = response.totalElements;
        this.parameters.showList = true;
      }
      );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }



}
