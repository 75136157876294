import { Component } from '@angular/core';
import { NavigatorService } from 'src/app/services/navigator.service';

@Component({
  selector: 'altro-component',
  templateUrl: './altro.component.html'
})
export class AltroComponent {

  constructor(private navigatorService: NavigatorService) {}

  logout(): void {
    this.navigatorService.logout();
  }

}
