import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CoinmarketcapAssetMappingConfigurationDTO } from 'src/app/shared/dto/coinmarketcap-catalog/assetMappingConfigurationDto';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CoinmarketcapAssetMappingConfigurationService extends GenericService <CoinmarketcapAssetMappingConfigurationDTO, number> {

  url: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-di-coinmarketcap-catalog/assetMappingConfiguration';
  }

  public assetMappingConfigurationList(page: number, size: number, sort: string, filter: string): Observable<PageDTO<CoinmarketcapAssetMappingConfigurationDTO>> {
    const params = {};
    if (page != null) {params['page'] = page;}
    if (size != null) {params['size'] = size;}
    if (sort != null) {params['sort'] = sort;}
    if (filter != null) {params['filter'] = filter;}
    return this.http.get<PageDTO<CoinmarketcapAssetMappingConfigurationDTO>>(`${this.url}`, { params: params });
}

}
