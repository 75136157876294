import { TraceModificationDTO } from '../basic/traceModificationDTO';
import { AssetDefinitionLiquidityDto } from './AssetDefinitionLiquidityDto.types';
import { AssetDefinitionSocialDto } from './AssetDefinitionSocialDto.types';
import { AssetDefinitionTaxonomyDto } from './AssetDefinitionTaxonomyDto.types';
import { AssetDefinitionTechDto } from './AssetDefinitionTechDto.types';
import { AssetDefinitionType } from './AssetDefinitionType.types';
import { AssetDefinitionUrlDto } from './AssetDefinitionUrlDto.types';
import { AssetDefinitionVolatilityDto } from './AssetDefinitionVolatilityDto.types';
import { AssetDefinitionWeissRatingDto } from './AssetDefinitionWeissRatingDto.types';

export class AssetDefinitionDto extends TraceModificationDTO {

	/**
	 * asset type (ex. coin)
	 */
	type: AssetDefinitionType;

	/**
	 * asset symbol (ex. BTC)
	 */
	symbol: string;

	/**
	 * Bitcratic Token (BCT)
	 */
	fullName: string;

	/**
	 * date of last modification of this asset catalog
	 */
	modificationDate: Date;

	/**
	 * date of first transaction
	 */
	firstTransactionDate: Date;

	/**
	 * name of the asset (ex.  BTC)
	 */
	name: string;

	/**
	 * name of the coin (ex. Bitcratic Token)
	 */
	coinName: string;

	/**
	 * description of the asset (ex. "Since the inception of the Blockchain Centralized Crypto Exchanges ...")
	 */
	description: string;

	/**
	 * asset logo
	 */
	logo: string;

	/**
	 * order of the asset, by importance
	 */
	sortOrder: number;

	/**
	 * is this asset a trading asset?
	 */
	trading: boolean;

	/**
	 * decimal places to use for quantity calculation
	 */
	decimalPoints: number;

	/**
	 * color to use for charts rendering
	 */
	color: string;

	/**
	 * taxonomy of the asset
	 */
	taxonomy: AssetDefinitionTaxonomyDto;

	/**
	 * weiss classification and characteristic
	 */
	weissRating: AssetDefinitionWeissRatingDto;

	/**
	 * decimal places to use for quantity calculation
	 */
	techAttributes: AssetDefinitionTechDto;

	/**
	 * list of tags of the asset definition
	 */
	tags: string[];

	/**
	 * list of tags of urls related to asset definition
	 */
	urls: AssetDefinitionUrlDto[];

	/**
	 * social attribute of the asset definition
	 */
	socialAttributes: AssetDefinitionSocialDto;

	/**
	 * volatility
	 */
	volatility: AssetDefinitionVolatilityDto;

	/**
	 * liquidity
	 */
	liquidity: AssetDefinitionLiquidityDto;

	/**
	 * list of tags of the asset definition
	 */
	alias: string[];

	constructor() {
		super();
		this.type = undefined;
		this.symbol = '';
		this.fullName = '';
		this.modificationDate = undefined;
		this.firstTransactionDate = undefined;
		this.name = '';
		this.coinName = '';
		this.description = '';
		this.logo = '';
		this.sortOrder = undefined;
		this.trading = false;
		this.decimalPoints = undefined;
		this.color = '';
		this.taxonomy = undefined;
		this.weissRating = undefined;
		this.techAttributes = undefined;
		this.tags = [];
		this.urls = [];
		this.socialAttributes = undefined;
		this.volatility = undefined;
		this.liquidity = undefined;
		this.alias = [];
	}
}
