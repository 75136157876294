import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';
import { DIAlternativeMeFearAndGreedService } from '../services/di-alternativeme-fearandgreed/di-alternativeme-fearandgreed.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-di-alternativeme-fearandgreed-data-visualization',
  templateUrl: './di-alternativeme-fearandgreed.component.html',
  styleUrls: ['./di-alternativeme-fearandgreed.component.scss']
})
export class DIAlternativeMeFearAndGreedComponent extends GenericListComponent implements OnInit, OnDestroy {

  //grafico
  numberOfDays: number = null;
  private inData = [];

  constructor(
    @Inject(PLATFORM_ID)
    private platformId,
    private zone: NgZone,
    //grafico^^
    private fearAndGreedService: DIAlternativeMeFearAndGreedService,
    protected dialog: MatDialog,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.parameters = {
      dataSource: [],
      samplingNumber: null,
      showList: false
    };
  }

  list(): void {
    if (this.parameters.samplingNumber >= 2) {
      this.fearAndGreedService.loadfearAndgreedHistoryByDepth(this.parameters.samplingNumber-2).subscribe((response: any) => {
        console.log('response : ' , response);
        this.parameters.dataSource = response;
        this.parameters.showList = true;
        this.inData = response.fearAndGreedDtos;
        this.drawChart();
      });
    } else {
      this.snackBar.open('Valore del filtro troppo basso! Aumentare il numero.', null, { duration: 3000 });
    }
  }

  reload(): void {
    this.fearAndGreedService.reloadFearAndGreed(this.numberOfDays)
    .pipe(catchError(()=> of(null)))
    .subscribe(() => this.router.navigate(['/alternativeMeFearAndGreed']));;
  }

  confirm(message: string): any {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: message
    });
    return dialogRef.afterClosed();
  }

  error(): void {
    this.snackBar.open('Valorizzare il filtro prima di effettuare la ricerca!', null, { duration: 3000 });
  }


  // Run the function only in the browser
  browserOnly(f: () => void): void {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }



  createSeries(field: string, nome: string, chart: am4charts.XYChart): am4charts.LineSeries {
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = 'date';
    series.name = nome;
    series.tooltipText = '{dateX}: [b]{valueY}[/]';
    series.strokeWidth = 2;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color('#fff');
    bullet.circle.strokeWidth = 2;

    return series;
  }


  drawChart(): void {
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    const chart = am4core.create('chartdiv', am4charts.XYChart);

    const array = this.inData?.map(r => ({ date: r.timestamp, value: r.index }));
    array?.forEach((element) => {
      const data = new Date(element?.date);
      element.date = data.toISOString();
      console.log('Data in stringa: '+ data.toISOString());
    });
    chart.data = array;
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.title.text = 'Date';
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 100;
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:ss.SSSZ';


    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Fear & Greed Value';
    // Create series

    this.createSeries('value', 'Fear & Greed Value in a Day', chart);

    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.adapter.add('xlsxWorkbook', (wb) => {
      // modify and add additional stuff to workbook object
      // ...
      wb.workbook.SheetNames.push('Data 2');
      wb.workbook.Sheets['Data 2'] = wb.xlsx.utils.aoa_to_sheet([
        ['Hello', 'world'],
      ]);
      return wb;
    });
  }
}
