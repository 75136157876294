import { SubscriptionDTO } from '../../../shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { StripeInvoice } from 'src/app/shared/dto/be-subscriptions/stripe/invoice.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardSubscriptionService extends GenericService<SubscriptionDTO, number> {

  private urlNoAdmin: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/subscription/card/admin';
    this.urlNoAdmin = environment.urlPrefix + '/arzakena-be-subscriptions/subscription/card';
    //this.url = "http://localhost:30300/product/admin/products"
  }

  public createSub(dto: SubscriptionDTO, username: string): Observable<SubscriptionDTO> {
    const params = {};
    if (username != null) {params['username'] = username;}
    return this.http.post<SubscriptionDTO>(`${this.url}`, dto, { params: params });
  }

  public readActiveSub(username: string): Observable<SubscriptionDTO>{
    return this.http.get<SubscriptionDTO>(`${this.url}/active/` + username);
  }

  public getInvoiceData(id: number): Observable<StripeInvoice>{
    return this.http.get<StripeInvoice>(`${this.urlNoAdmin}/getInvoice/` + id);
  }
}
