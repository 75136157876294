<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione containers</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">

            <mat-form-field>
                <mat-label>Container Type</mat-label>
                <mat-select [(ngModel)]="parameters.filter1" (selectionChange)="parameters.filter2 = null" name="p">
                    <mat-option [value]="null">
                        All
                    </mat-option>
                    <mat-option *ngFor="let position of positionContainerTypes" [value]="position">
                        {{ position }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

             <mat-form-field *ngIf="parameters.filter1 === 'EXCHANGE_ACCOUNT'">
                <mat-label>Container Code Type</mat-label>
                <mat-select [(ngModel)]="parameters.filter2" name="exchange">
                    <mat-option [value]="null">
                        All the exchange accounts
                    </mat-option>
                    <mat-option *ngFor="let exchange of exchangeTypes" [value]="exchange">
                        {{ exchange }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="parameters.filter1 === 'WALLET'">
                <mat-label>Container Code Type</mat-label>
                <mat-select [(ngModel)]="parameters.filter2" name="blockchain">
                    <mat-option [value]="null">
                        All the blockchains
                    </mat-option>
                    <mat-option *ngFor="let blockchain of blockchainTypes" [value]="blockchain">
                        {{ blockchain }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          
      
        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button (click)="back('')" color="primary">Back</button>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
            [hiddenColumns]="hiddenColumns()" [dataSource]="parameters.dataSource">

                <!-- positionType Column -->
                <ng-container matColumnDef="containerType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> containerType </th>
                    <td mat-cell *matCellDef="let info"> {{info.containerType}} </td>
                </ng-container>

                <!-- positionContainerType Column -->
                <ng-container matColumnDef="containerCodeType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> containerCodeType </th>
                    <td mat-cell *matCellDef="let info"> {{info.containerCodeType}} </td>
                </ng-container>

                <!-- totalKeys Column -->
                <ng-container matColumnDef="totalKeys">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> totalKeys </th>
                    <td mat-cell *matCellDef="let info"> {{info.totalKeys}} </td>
                </ng-container>

                <!-- toManyTransactions Column -->
                <ng-container matColumnDef="toManyTransactions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> tooManyTransactions </th>
                    <td mat-cell *matCellDef="let info"> {{info.toManyTransactions}} </td>
                </ng-container>

                <!-- downloadProblems Column -->
                <ng-container matColumnDef="downloadProblems">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> downloadProblems </th>
                    <td mat-cell *matCellDef="let info"> {{info.downloadProblems}} </td>
                </ng-container>
                
                <!-- verifying Column -->
                <ng-container matColumnDef="verifying">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> verifying </th>
                    <td mat-cell *matCellDef="let info"> {{info.verifying}} </td>
                </ng-container>

                <!-- verified Column -->
                <ng-container matColumnDef="verified">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> verified </th>
                    <td mat-cell *matCellDef="let info"> {{info.verified}} </td>
                </ng-container>

                 <!-- somePositionsSquared Column -->
                 <ng-container matColumnDef="somePositionsSquared">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> somePositionsSquared </th>
                    <td mat-cell *matCellDef="let info"> {{info.somePositionsSquared}} </td>
                </ng-container>

                 <!-- invalidKey Column -->
                 <ng-container matColumnDef="invalidKey">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> invalidKey </th>
                    <td mat-cell *matCellDef="let info"> {{info.invalidKey}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
