<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettaglio Utente con Card Subscription Attiva</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
                <!-- Id field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput formControlName="id" placeholder="Id" readonly="true" />
                </mat-form-field>

                <!-- username field -->
                <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput type="text" formControlName="username" placeholder="Username" readonly="true">
                </mat-form-field>
            </form>
            <div fxLayout="row">
                    <form *ngIf="invoiceData" [formGroup]="invoiceForm" fxLayout="column" class="responsive-mat-form">
                        <h2>Dettagli Invoice</h2>
                        <!-- Id field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="id" placeholder="Id Invoice" readonly="true">
                        </mat-form-field>
                        <!-- name field -->
                        <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="name" placeholder="Nome Account Invoice" readonly="true">
                        </mat-form-field>
                        <!-- data field -->
                        <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="data" placeholder="Informazioni Invoice" readonly="true">
                        </mat-form-field>
                        <div fxLayout="row" class="responsive-mat-form">
                            <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput type="text" formControlName="customerId" placeholder="Id customer" readonly="true">
                            </mat-form-field>

                            <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput type="text" formControlName="customerEmail" placeholder="Email customer" readonly="true">
                            </mat-form-field>

                        </div>
                        
                        <div fxLayout="row" class="responsive-mat-form">
                            <!-- finalizedAt field-->
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="finalizedAt" placeholder="Data di finalizzazione" readonly="true" />
                            </mat-form-field>
                            <!-- paidAt field-->
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="paidAt" placeholder="Data di pagamento" readonly="true" />
                            </mat-form-field>
                        </div>
                        
        
                        <div fxLayout="row" class="responsive-mat-form">
                            <!-- periodStart field-->
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="periodStart" placeholder="Data di inizio" readonly="true" />
                            </mat-form-field>

                            <!-- periodEnd field-->
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="periodEnd" placeholder="Data di fine" readonly="true" />
                            </mat-form-field>
                        </div>
                        <!-- status field-->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="status" placeholder="Status del pagamento" readonly="true" />
                        </mat-form-field>
        
                        <div fxLayout="row" class="responsive-mat-form">
                            <!-- amountDue field-->
                            <mat-form-field fxFlex="33" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="amountDue" placeholder="Importo dovuto" readonly="true" />
                            </mat-form-field>
    
                            <!-- amountPaid field-->
                            <mat-form-field fxFlex="33" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="amountPaid" placeholder="Importo pagato" readonly="true" />
                            </mat-form-field>

                            <!-- currency field-->
                            <mat-form-field fxFlex="33" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="currency" placeholder="Valuta" readonly="true" />
                            </mat-form-field>
                            
                        </div>
                        
        
                    </form>
                    
                    <form *ngIf="cardSubscription" [formGroup]="subForm" fxLayout="column" class="responsive-mat-form">
                        <h2>Dettagli Subscription</h2>
                        <!-- Id field -->
                        <mat-form-field
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}"> 
                            <input matInput type="number" formControlName="id" placeholder="Id Subscription" readonly="true">
                        </mat-form-field>
        
                        <div fxLayout="row" class="responsive-mat-form">
                            <!-- subType field -->
                            <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput type="text" formControlName="subType" placeholder="Tipo Subscription" readonly="true">
                            </mat-form-field>
        
                            <!-- amountPaid field -->
                            <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput type="text" formControlName="amountPaid" placeholder="Importo pagato" readonly="true">
                            </mat-form-field>
                        </div>
        
                        <div fxLayout="row" class="responsive-mat-form">
                            <!-- dateStart field-->
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="dateStart" placeholder="Data inizio Subscription" readonly="true" />
                            </mat-form-field>
        
                            <!-- dateEnd field-->
                            <mat-form-field  fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput formControlName="dateEnd" placeholder="Data fine Subscription" readonly="true" />
                            </mat-form-field>
                        </div> 
                        <div fxLayout="row" class="responsive-mat-form">
                            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput type="text" formControlName="customerId" placeholder="Id customer" readonly="true">
                            </mat-form-field>

                            <mat-form-field fxFlex fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                                <input matInput type="text" formControlName="customerEmail" placeholder="Email customer" readonly="true">
                            </mat-form-field>
                        </div>
                        
        
                    </form>

            </div>
            
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/stripe')" color="primary">Back</button>
        </mat-card-actions>
        <br />
        
    </mat-card-content>
</mat-card>