import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { SmartContractAddressMappingService } from 'src/app/services/core-catalog/smart-contract-address-mapping/smartContractAssetMapping.service';
import { SmartContractAddressMappingDto } from 'src/app/shared/dto/core-catalog/SmartContractAssetMappingDto.types';
import { blockchainTypes } from 'src/app/shared/dto/commons/BlockchainType.types';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';
import { AssetDefinitionAliasWrapper } from './asset-definition-input/asset-definition-input.component';

@Component({
  selector: 'app-smart-contract-address-mapping-detail',
  templateUrl: './smart-contract-address-mapping-detail.component.html',
  styleUrls: ['./smart-contract-address-mapping-detail.component.scss'],
  providers: [
    SmartContractAddressMappingService
  ]
})
export class SmartContractAddressMappingDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly blockchainTypes = blockchainTypes;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private smartContractAddressMappingService: SmartContractAddressMappingService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    if(this.detailId()) {
      this.read(this.detailId());
    } else {
      this.form = this.loadForm(new SmartContractAddressMappingDto());
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toSmartContractAddressMappingDto());
      return;
    }
    this.create(this.toSmartContractAddressMappingDto());
  }

  deleteSmartContractAddressMapping(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare la mappatura?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }

  detailId(): number {
    return +this.activeRoute.snapshot.paramMap.get('id');
  }

  assetDefinitionChanged($event: AssetDefinitionAliasWrapper): void {
    this.form.markAsDirty();
    this.form.controls['assetDefinition'].setValue($event.assetDefinition);
    this.form.controls['alias'].setValue($event.alias);
  }

  private create(smartContractAddressMapping: SmartContractAddressMappingDto): void {
    this.smartContractAddressMappingService.create(smartContractAddressMapping).subscribe((responseMapping) => {
      this.router.navigateByUrl(this.router.url.replace(this.detailId() + '', responseMapping.id + ''));
      this.form = this.loadForm({...new SmartContractAddressMappingDto(), ...responseMapping});
    });
  }

  private update(smartContractAddressMapping: SmartContractAddressMappingDto): void {
    this.smartContractAddressMappingService.update(smartContractAddressMapping).subscribe(() => this.read(this.detailId()));
  }

  private read(id: number): void {
    this.smartContractAddressMappingService.read(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(smartContractAddressMapping => this.form = this.loadForm({...new SmartContractAddressMappingDto(), ...smartContractAddressMapping}));
  }

  private delete(id: number): void {
    this.smartContractAddressMappingService.delete(id).subscribe(() => this.router.navigate(['/smartContractAddressMapping']));
  }
  private loadForm(smartContractAddressMapping: SmartContractAddressMappingDto): FormGroup {
    return this._formBuilder.group({...smartContractAddressMapping, ...this.formValidators(smartContractAddressMapping)});
  }

  private toSmartContractAddressMappingDto(): SmartContractAddressMappingDto {
    return this.form.getRawValue() as SmartContractAddressMappingDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(smartContractAddressMapping: SmartContractAddressMappingDto) {
    return {
        smartContractAddress: [smartContractAddressMapping.smartContractAddress, [Validators.required, Validators.maxLength(255)]],
        builtOn: [smartContractAddressMapping.builtOn, Validators.required],
        assetDefinition: [smartContractAddressMapping.assetDefinition, Validators.required],
    };
  }
}
