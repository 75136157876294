<form [formGroup]="socialForm" fxLayout="row" class="responsive-mat-form">

    <!-- social Name field -->
    <mat-form-field>
        <input matInput formControlName="socialName" placeholder="Social Name" />
        <mat-error *ngIf="socialForm.get('socialName').errors?.reuired">
            SocialName is required
        </mat-error>
        <mat-error *ngIf="socialForm.get('socialName').errors?.maxlength">
            socialName max length reached (max 64)
        </mat-error>
    </mat-form-field>

    <!-- socialType field -->
    <mat-form-field>
        <mat-select formControlName="socialType" placeholder="Social Type">
            <mat-option *ngFor="let aSocialType of socialTypeList" [value]="aSocialType">
                {{aSocialType}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <!-- socialLink field -->
    <mat-form-field>
        <input matInput formControlName="socialLink" placeholder="Social Link" />
        <mat-error *ngIf="socialForm.get('socialLink').errors?.pattern">
            Invalid Link
        </mat-error>
        <mat-error *ngIf="socialForm.get('socialLink').errors?.maxlength">
            socialName max length reached (max 256)
        </mat-error>

    </mat-form-field>



    <div fxLayout="row" fxLayoutAlign="end center" class="stella">
        <mat-icon *ngIf="favorite == false" (click)="modificaPreferito(true)" matPrefix="Favorite">
            star_border
        </mat-icon>
        <mat-icon *ngIf="favorite == true" color="primary" (click)="modificaPreferito(false)" matPrefix="Favorite">
            star
        </mat-icon>
    </div>
    <delete-button
            message='Sei sicuro di cancellare questo social?'
            (customClick)="deleteSocial()">
        <mat-icon>delete</mat-icon>
    </delete-button>
</form>