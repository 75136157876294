<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent"  *ngIf="this.form">
      <div fxLayout="column" fxFlex="40" class="form">
        <h2>Managed User</h2>
        <div fxLayout="column">
          <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
            <!-- user form to make sure that the user exists -->
            <user-definition-input [link]="user" [listOfUsers]="users" [userDefinition]="form.get('inputUsername')?.value" (userDefinitionChangedEvent)="form.controls['inputUsername'].setValue($event)" (booleanUsers1)="setBoolean1($event)" (booleanUsers2)="setBoolean2($event)"></user-definition-input>
          </form>
        </div>
  
        <mat-card-actions>
          <button mat-raised-button (click)="backConfirm('gestione-affiliation/detail/' + influencerId)" color="primary">Back</button>
          <button *ngIf="(user.length === 3 && user.includes('new')) && checkUsers1 && checkUsers2" mat-raised-button (click)="addUser()" [disabled]="form.invalid">
            Save
          </button>
          <button *ngIf="user.length !== 3 && !user.includes('new')" mat-raised-button (click)="deleteUser()" [disabled]="form.invalid">
            Delete
          </button>
        </mat-card-actions>
      </div>
    </mat-card-content>
  </mat-card>