<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">

        <h2>Utenti iscritti</h2>
        <p>Imposta il filtro di ricerca</p>

        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.idFilter" placeholder="ID" />
            </mat-form-field>

            <mat-form-field>
                <input matInput [(ngModel)]="parameters.username" placeholder="Username" />
            </mat-form-field>

            <mat-checkbox matCheckbox [indeterminate]="parameters.active === undefined"
                [checked]="parameters.active !== undefined && parameters.active"
                (change)="changeBool(parameters.active, 'active')">Active?</mat-checkbox>
            <mat-checkbox matCheckbox [indeterminate]="parameters.renewable === undefined"
                [checked]="parameters.renewable !== undefined && parameters.renewable"
                (change)="changeBool(parameters.renewable, 'renewable')">Renewable?</mat-checkbox>
            <mat-checkbox matCheckbox [indeterminate]="parameters.trialUsed === undefined"
                [checked]="parameters.trialUsed !== undefined && parameters.trialUsed"
                (change)="changeBool(parameters.trialUsed, 'trialUsed')">Trial Used?</mat-checkbox>
            <mat-checkbox matCheckbox [indeterminate]="parameters.freeUsed === undefined"
                [checked]="parameters.freeUsed !== undefined && parameters.freeUsed"
                (change)="changeBool(parameters.freeUsed, 'freeUsed')">Free Used?</mat-checkbox>
        </div>

        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button (click)="back('/')" color="primary">Back</button>
        </mat-card-actions>
        
        <ng-container *ngIf="invalidParameters;else nousername">
        </ng-container> 
        <ng-template #nousername>
            <div style="background-color: rgb(253 186 116); padding: 20px;">
                Non esiste l'utente cercato
            </div>
        </ng-template>
        <div *ngIf="parameters.showList" fxLayout="column">
            <h2>Lista Utenti iscritti</h2>
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                    <td mat-cell *matCellDef="let user"> {{user.id}} </td>
                </ng-container>

                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> username </th>
                    <td mat-cell *matCellDef="let user"> {{user.username}} </td>
                </ng-container>

                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> active </th>
                    <td mat-cell *matCellDef="let user">
                        <mat-icon *ngIf="user.active" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!user.active" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="renewable">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> renewable </th>
                    <td mat-cell *matCellDef="let user">
                        <mat-icon *ngIf="user.renewable" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!user.renewable" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="promotionTries">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> promotionTries </th>
                    <td mat-cell *matCellDef="let user"> {{user.promotionTries}} </td>
                </ng-container>

                <ng-container matColumnDef="trialUsed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> trialUsed </th>
                    <td mat-cell *matCellDef="let user">
                        <mat-icon *ngIf="user.trialUsed" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!user.trialUsed" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="freeUsed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> freeUsed </th>
                    <td mat-cell *matCellDef="let user">
                        <mat-icon *ngIf="user.freeUsed" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!user.freeUsed" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user">
                        <button mat-mini-fab color="primary" [routerLink]="['/user-subscriptions/detail/', user.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>