import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Clipboard} from '@angular/cdk/clipboard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CodeService } from '../../services/code/code.service';
import { ComponentCacheService } from '../../services/component-cache.service';
import { NavigatorService } from '../../services/navigator.service';
import { CodeDTO } from '../../shared/dto/code/CodeDTO';
import { GenericListComponent } from '../../shared/GenericListCoumponent';
import { TimestampFormatPipe } from '../../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent extends GenericListComponent implements OnInit, OnDestroy {


  @Input() influencerId: number;

  codes: CodeDTO[];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private codeService: CodeService,
    private clipboard: Clipboard,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,

  ) {

    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'code', 'codeUrl', 'influencerId', 'expirationDate', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: true,
      filter: null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
    };
  }

  list(): void {
    if (this.codes != null) {
      this.parameters.dataSource = this.codes;
      this.parameters.page = 1;
      this.parameters.length = 1;
      this.parameters.showList = true;
    } else {
      console.log('entrato');
      this.parameters.showList = false;
    }
  }


  ngOnInit(): void {
    this.codeService.readByInfluencerId(this.influencerId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {

        if (res[0] != null) {
          this.codes = res;
        }

      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  copyUrl(value: string): void {
    this.clipboard.copy('https://app.bitdiver.com?affiliationCode=' + value);
    this.snackBar.open('Url copiato!', null, { duration: 3000 });
  }
}
