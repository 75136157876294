<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Error</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Error Event Id" readonly="true" />
                </mat-form-field>

                <!-- source field -->
                <mat-form-field>
                    <input matInput type="text" formControlName="source" placeholder="source" readonly="true">
                </mat-form-field>

                <!-- timestamp field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="timestamp" placeholder="timestamp" readonly="true">
                </mat-form-field>

                <!-- failedClass field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="failedClass" placeholder="failedClass"
                        readonly="true">
                </mat-form-field>

                <!-- exceptionClass field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="exceptionClass" placeholder="exceptionClass"
                        readonly="true">
                </mat-form-field>

                <!-- message field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="message" placeholder="message"
                        readonly="true">
                </mat-form-field>

                <!-- stacktrace field -->

                <mat-card class="cdk-virtual-scroll-data-source-example stacktrace" style="margin-bottom: 10px;" >
                    <cdk-virtual-scroll-viewport  itemSize="100" class="example-viewport stacktrace-div">
                        <div *cdkVirtualFor='let error of form.get("stacktrace").value' class="example-item ">
                            <pre>
                                <code [innerHTML]='form.get("stacktrace").value' fxFlex ></code>
                            </pre>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </mat-card>


                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/asyncProcessErrorEvent')" color="primary">Back</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>