<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Asset Mapping Configuration</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">


          <mat-form-field>
            <input matInput [(ngModel)]="parameters.filter" placeholder="Filter"/>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/weisscryptoAssetMappingConfiguration/detail/0']">New</button>
          <button mat-raised-button (click)="back('/')" color="primary">Back</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- cryptoId Column -->
            <ng-container matColumnDef="cryptoId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CryptoId </th>
              <td mat-cell *matCellDef="let asset"> {{asset.cryptoId}} </td>
            </ng-container>
            
            <!-- weissSymbol Column -->
            <ng-container matColumnDef="weissSymbol">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > WeissSymbol </th>
              <td mat-cell *matCellDef="let asset"> {{asset.weissSymbol}} </td>
            </ng-container>

            <!-- weissName Column -->
            <ng-container matColumnDef="weissName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > WeissName </th>
              <td mat-cell *matCellDef="let asset"> {{asset.weissName}} </td>
            </ng-container>

            <!-- arzakenaSymbol Column -->
            <ng-container matColumnDef="arzakenaSymbol">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > ArzakenaSymbol </th>
                <td mat-cell *matCellDef="let asset"> {{asset.arzakenaSymbol}} </td>
              </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Action </th>
                <td mat-cell *matCellDef="let asset"> {{asset.action}} </td>
              </ng-container>
        
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let asset">
                  <button mat-mini-fab color="primary" [routerLink]="['/weisscryptoAssetMappingConfiguration/detail/', asset.id]"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
          </div>
      </div>
    </mat-card-content>
  </mat-card>