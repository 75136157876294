import { SubscriptionExtensionDTO } from '../../../shared/dto/be-subscriptions/extension/SubscriptionExtensionDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionExtensionService extends GenericService<SubscriptionExtensionDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/extension';
    //this.url = "http://localhost:30300/extension"
  }

  public readAll(pageable: Pageable): Observable<PageDTO<SubscriptionExtensionDTO>> {
    const params = {'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
    return this.http.get<PageDTO<SubscriptionExtensionDTO>>(`${this.url}/admin`, { params: params });
  }

  public readAllBySubscription(pageable: Pageable, idSub: number): Observable<PageDTO<SubscriptionExtensionDTO>> {
    if(!idSub){
      return this.readAll(pageable);
    }
    const params = {'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
    return this.http.get<PageDTO<SubscriptionExtensionDTO>>(`${this.url}/admin/${idSub}`, { params: params });
  }
}
