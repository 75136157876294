
<mat-card >

    <mat-card-content>
        <div fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisabled [dataSource]="socials">

                <!-- socialName Column -->
                <ng-container matColumnDef="socialName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> SocialName </th>
                    <td mat-cell *matCellDef="let social"> {{social.socialName}} </td>
                </ng-container>

                <!-- socialType Column -->
                <ng-container matColumnDef="socialType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> SocialType </th>
                    <td mat-cell *matCellDef="let influencer"> {{influencer.socialType}} </td>
                </ng-container>

                <!-- socialLink Column -->
                <ng-container matColumnDef="socialLink">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> SocialLink </th>
                    <td mat-cell *matCellDef="let social"> {{social.socialLink}} </td>
                </ng-container>

                <ng-container matColumnDef="favorite">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Favourite</th>
                    <td mat-cell *matCellDef="let social">
                        <mat-icon *ngIf="social.favorite === false" aria-readonly="true">star_border</mat-icon>
                        <mat-icon *ngIf="social.favorite === true" color="primary" aria-readonly="true">star</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let social">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/social/detail/'+ influencerId + '/' + social.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
    </mat-card-content>
</mat-card>