import { LogicalDeleteDTO } from '../../basic/logicalDeleteDTO';
import { NotificationChannel, NotificationStatus } from '../NotificationTypes';
import { CategoryDTO } from '../category/CategoryDTO';

export class NotificationMessageDTO extends LogicalDeleteDTO {

    id: number;

    category: CategoryDTO;

    status: NotificationStatus;

    channel: NotificationChannel;

    logicalKey: string;

    username: string;

    email: string;

    header: string;

    body: string;

    attachments: string;

    readAt: Date;

    url: string;

}
