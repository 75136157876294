import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubscriptionExtensionService } from 'src/app/services/be-subscriptions/extension/subscription-extension.service';
import { SubscriptionExtensionDTO } from 'src/app/shared/dto/be-subscriptions/extension/SubscriptionExtensionDTO';

@Component({
  selector: 'app-subscription-extension-detail',
  templateUrl: './subscription-extension-detail.component.html',
  styleUrls: ['./subscription-extension-detail.component.scss']
})
export class SubscriptionExtensionDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  extensionId: number;
  extension: SubscriptionExtensionDTO;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private extensionService: SubscriptionExtensionService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      subscriptionId: new FormControl('', [Validators.required]),
      promotionCode: new FormControl('', []),
      affiliationPerk: new FormControl('', [Validators.required]),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.extensionId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.extensionId !== 0 && this.extensionId != null) {
      this.extensionRead();
    } else {
      this.extension = new SubscriptionExtensionDTO();
    }
  }

  extensionSave(): void {
    if (this.extension.id == null) {
      this.extensionCreate();
    } else {
      this.extensionUpdate();
    }
  }

  //READ

  extensionRead(): void {
    this.extensionService.read(this.extensionId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: SubscriptionExtensionDTO) => {
          console.log('response : ' , res);
          this.extension = res;
          this.extensionId = res.id;
          this.dtoToForm();
        }
      );
  }

  //CREATE

  extensionCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.extensionService.create(this.extension)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: SubscriptionExtensionDTO) => {
            console.log('response : ' , res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.extension = res;
            console.log(res);
            this.extensionId = this.extension.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/subscription-extension/detail/' + res.id);
          }
        );
    }
  }

  // UPDATE

  extensionUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere il product?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.extensionService.update(this.extension)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.extensionRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  extensionDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare l\'estensione?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.extensionService.delete(this.extension.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/subscription-extension']);
                }
              );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  checkAffiliation(affiliation: boolean): void{
    if(affiliation === null){
      this.form.get('affiliation').setValue(true);
    } else if (affiliation) {
      this.form.get('affiliation').setValue(false);
    } else {
      this.form.get('affiliation').setValue(null);
    }
  }

  private formToDto(): void {
    this.extension.subscriptionId = this.form.get('subscriptionId').value;
    this.extension.promotionCode = this.form.get('promotionCode').value;
    this.extension.affiliationPerk = this.form.get('affiliationPerk').value;
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.extension.id);
    this.form.get('subscriptionId').setValue(this.extension.subscriptionId);
    this.form.get('promotionCode').setValue(this.extension.promotionCode);
    this.form.get('affiliationPerk').setValue(this.extension.affiliationPerk);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.extension.insertDate));
    this.form.get('insertUser').setValue(this.extension.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.extension.updateDate));
    this.form.get('updateUser').setValue(this.extension.updateUser);
    this.form.get('optLock').setValue(this.extension.optLock);
    this.form.markAsPristine();
    console.log(this.form.get('affiliation').value);
  }

}
