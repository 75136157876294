import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { NotificationChannel } from 'src/app/shared/dto/be-notifications/NotificationTypes';
import { TemplateDTO } from 'src/app/shared/dto/be-notifications/template/TemplateDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends GenericService<TemplateDTO, number> {

  noAdminurl: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-notifications/template/admin';
    this.noAdminurl = environment.urlPrefix + '/arzakena-be-notifications/template';
    //this.url = 'http://localhost:31300/template/admin';
    //this.noAdminurl = 'http://localhost:31300/template';
  }

  public readAll(pageable: Pageable, bundleKey: string, locale: string, channel: NotificationChannel): Observable<PageDTO<TemplateDTO>> {
    const params = {'bundleKey': bundleKey + '',
      'locale': locale + '',
      'channel': channel + '',
      'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => (params[key] === 'undefined' || params[key] === '') && delete params[key]);
    return this.http.get<PageDTO<TemplateDTO>>(`${this.noAdminurl}/list`, {params: params});
  }
}
