<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Products</h2>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="new-button" color="basic" [routerLink]="['/product/detail/0']">New
                    Product</button>
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [dataSource]="parameters.dataSource">
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> id </th>
                    <td mat-cell *matCellDef="let product"> {{product.id}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> name </th>
                    <td mat-cell *matCellDef="let product"> {{product.name}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="translation">
                    <th mat-header-cell *matHeaderCellDef> translation </th>
                    <td mat-cell *matCellDef="let product"> {{translations.get(product.name)}} </td>
                </ng-container>

                <!-- cost Column -->
                <ng-container matColumnDef="cost">
                    <th mat-header-cell *matHeaderCellDef> cost </th>
                    <td mat-cell *matCellDef="let product"> {{product.cost}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let product">
                        <button mat-mini-fab color="primary" [routerLink]="['/product/detail/', product.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>
