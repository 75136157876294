import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, filter, takeUntil } from 'rxjs/operators';
import { TicketService } from 'src/app/services/help-desk/ticket.service';
import { CorrectedUserDTO } from 'src/app/shared/dto/config/correctedUser';

@Component({
  selector: 'user-definition-input',
  templateUrl: './user-definition-input.component.html',
  providers: [
    TicketService
  ]
})
export class UserDefinitionInputComponent implements OnDestroy, OnInit {

  @Output() userDefinitionChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() booleanUsers1: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() booleanUsers2: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() userDefinition: string;
  @Input() listOfUsers: string[];
  @Input() link: string;

  form: FormGroup;
  currentUser: CorrectedUserDTO;

  private debouncer$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _debouncerUnsubscribe: Subject<void> = new Subject<void>();
  private _readUnsubscribe: Subject<void> = new Subject<void>();


  constructor(
    private userDefinitionService: TicketService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.loadForm();
    this.debouncer$.next(this.form.get('inputUsername').value);
    this.startDebouncer();
  }

  setUserDefinition(user: CorrectedUserDTO): void {
    this.currentUser = user;
    this.userDefinition = this.form.get('inputUsername').value;
    let check1: boolean = false;
    if (user) {
      this.form = this.loadForm();
      check1 = true;
    }
    let check2: boolean = true;
    for (const str of this.listOfUsers) {
      if (this.userDefinition === str) {
        check2 = false;
      }
    }
    this.userDefinitionChangedEvent.emit(this.userDefinition);
    this.booleanUsers1.emit(check1);
    this.booleanUsers2.emit(check2);
  }

  userChanged(): void {
    this.setUserDefinition(undefined);
    this._readUnsubscribe.next();
    this.debouncer$.next(this.form.get('inputUsername').value);
  }


  ngOnDestroy(): void {
    this._debouncerUnsubscribe.next();
    this._debouncerUnsubscribe.complete();
    this._readUnsubscribe.next();
    this._readUnsubscribe.complete();
  }

  private loadForm(): FormGroup {
    return this._formBuilder.group(this.formValidators());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators() {
    if (this.link !== 'new') {
      return {
        inputUsername: new FormControl({ value: this.userDefinition, disabled: true }),
        username: new FormControl({ value: this.userDefinition, disabled: true }),
        firstName: new FormControl({ value: this.currentUser?.firstName, disabled: true }),
        familyName: new FormControl({ value: this.currentUser?.lastName, disabled: true }),
        email: new FormControl({ value: this.currentUser?.email, disabled: true })
      };
    } else if (this.link === 'new' && this.userDefinition !== 'new') {
      return {
        inputUsername: [this.userDefinition, [Validators.required, Validators.maxLength(255)]],
        username: new FormControl({ value: this.userDefinition, disabled: true }),
        firstName: new FormControl({ value: this.currentUser?.firstName, disabled: true }),
        familyName: new FormControl({ value: this.currentUser?.lastName, disabled: true }),
        email: new FormControl({ value: this.currentUser?.email, disabled: true })
      };
    } else if (this.link === 'new' && this.userDefinition === 'new') {
      return {
        inputUsername: ['', [Validators.required, Validators.maxLength(255)]],
        username: new FormControl({ value: this.userDefinition, disabled: true }),
        firstName: new FormControl({ value: this.currentUser?.firstName, disabled: true }),
        familyName: new FormControl({ value: this.currentUser?.lastName, disabled: true }),
        email: new FormControl({ value: this.currentUser?.email, disabled: true })
      };
    }
  }

  private startDebouncer(): void {
    this.debouncer$.pipe(
      takeUntil(this._debouncerUnsubscribe),
      debounceTime(1000),
      filter(user => !!user)
    ).subscribe((username) => {
      this.userDefinitionService.getUsernameById(username).pipe(
        takeUntil(this._readUnsubscribe),
        filter(userDefinition => !!userDefinition),
        catchError(() => EMPTY)
      ).subscribe((userDefinition) => {
        this.setUserDefinition(userDefinition);
      });
    });
  }
}
