import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { MessageService } from 'src/app/services/disclaimer/message.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { disclaimerTypes } from 'src/app/shared/dto/disclaimer/DisclaimerType.types';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';

@Component({
  selector: 'app-disclaimer-message',
  templateUrl: './disclaimer-message.component.html',
  styleUrls: ['./disclaimer-message.component.scss'],
  providers: [
    MessageService
  ]
})
export class DisclaimerMessageComponent extends GenericListComponent implements OnInit, OnDestroy {

  readonly disclaimerTypes = disclaimerTypes;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private messageService: MessageService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'disclaimerType', 'title', 'expirationDate', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: undefined,
      sort: undefined,
      page: 0,
      size: 10,
      disclaimerType: undefined,
      deleted: false,
      expired: false,
      length: 0
    };
  }

  list(): void {
    this.messageService.query({page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
         this.parameters.filter, this.parameters.disclaimerType, this.parameters.deleted, this.parameters.expired).pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe((messages) => {
      this.parameters.dataSource = messages.content;
      this.parameters.page = messages.page;
      this.parameters.length = messages.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
