import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MessageDto } from 'src/app/shared/dto/disclaimer/MessageDto.types';
import { DisclaimerType } from 'src/app/shared/dto/disclaimer/DisclaimerType.types';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';

@Injectable()
export class MessageService extends GenericService<MessageDto, number> {

    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-be-disclaimer/message';
    }

    adminRead(id: number): Observable<MessageDto> {
        return this.http.get<MessageDto>(`${this.url}/admin/${id}`);
    }

    getLanguages(id: number): Observable<string[]> {
        return this.http.get<string[]>(`${this.url}/languages/${id}`);
    }

    query(pageable: Pageable, filter?: string, type?: DisclaimerType, deleted?: boolean, expired?: boolean): Observable<PageDTO<MessageDto>> {
        const params = {'filter': filter + '', 'type': type + '', 'deleted': deleted + '', 'expired': expired + '',
            'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.get<PageDTO<MessageDto>>(`${this.url}/query`, {params: params});
    }
}
