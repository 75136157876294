<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Codice</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- code field -->
                <mat-form-field>
                    <input matInput type="text" formControlName="code" placeholder="Code">
                    <mat-error *ngIf="form.get('code').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('code').errors?.maxlength">
                        Code max length reached (max 64)
                    </mat-error>
                </mat-form-field>

                <!-- expirationDate field -->
                <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput [matDatepicker]="picker" formControlName="expirationDate"
                        placeholder="Expiration Date" (click)="picker.open()" readonly="true">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="form.get('expirationDate').value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="form.get('expirationDate').setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('expirationDate').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>






                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>

            <mat-card-actions>
                <button mat-raised-button (click)=" backConfirm('gestione-affiliation/detail/' + influencerId)"
                    color="primary">Back</button>
                <button mat-raised-button (click)="codeSave()"
                    [disabled]=" (form.invalid || !form.dirty)">
                    Save
                </button>
                <button mat-raised-button (click)="codeDelete()" [disabled]="this.codeId == 0">
                    Delete
                </button>
            </mat-card-actions>
            <div fxLayout="row" *ngIf="codeId != 0">
                <app-affiliation-report [codeId]="codeId" [influencerOrCode]="false" fxFlex="fill">
                </app-affiliation-report>
            </div>

        </div>

    </mat-card-content>
</mat-card>