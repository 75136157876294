import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { AssetDefinitionService } from 'src/app/services/core-catalog/asset-definition/asset-definition.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';

@Component({
  selector: 'app-asset-definition',
  templateUrl: './asset-definition.component.html',
  styleUrls: ['./asset-definition.component.scss']
})
export class AssetDefinitionComponent  extends GenericListComponent  implements OnInit, OnDestroy {


  constructor(
    private assetDefinitinService: AssetDefinitionService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
) {

  super(navigatorService,
    componentCacheService,
    dateAdapter,
    router,
    snackBar,
    timestampFormatPipe);
    this.displayedColumns = ['symbol', 'decimalPoints', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      source: null,
      dataDa: null,
      dataA: null,
      failedClass: null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
    };
}

list(): void{
this.assetDefinitinService.assetDefinitionList(
  this.parameters.filter,
  this.parameters.mini,
  this.parameters.taxonomy,
  this.parameters.weissRating,
  this.parameters.techAttributes,
  this.parameters.socialAttributes,
  this.parameters.page,
  this.parameters.size,
  this.parameters.sort
).subscribe((response: PageDTO<AssetDefinitionDto>) => {
  console.log('response : ' , response);
  this.parameters.dataSource = response.content;
  this.parameters.page = response.page;
  this.parameters.length = response.totalElements;
  this.parameters.showList = true;
  });
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}

ngOnInit(): void {
  super.ngOnInit();
}


}
