import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { MessageDto } from './MessageDto.types';

export class TranslationDto extends LogicalDeleteDTO {

	/**
	 * Translations id
	 */
	id: number;

	/**
	 * Body of the translations
	 */
	text: string;

	/**
	 * Title of the translations
	 */
	title: string;

	/**
	 * Language for the translations
	 */
	language: string;

	/**
	 * Referenced message from the translation
	 */
	message: MessageDto;

    constructor(message?: MessageDto) {
        super();
        this.id = undefined;
		this.text = '';
        this.title = '';
		this.language = '';
		this.message = message;
    }
}
