<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettaglio Utente Iscritto</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id"
                        readonly="true" />
                </mat-form-field>

                <!-- username field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="username" placeholder="Username" readonly="true">
                </mat-form-field>

                <!-- promotionTries field -->
                <mat-form-field fxFlex>
                    <input matInput type="number" formControlName="promotionTries" placeholder="Promotion Tries">
                </mat-form-field>

                <div fxLayout="row">
                    <!-- active field -->
                    <mat-checkbox formControlName="active" labelPosition="before" style="margin-bottom: 10px; margin-right: 10px;">
                        active</mat-checkbox>

                    <!-- renewable field -->
                    <mat-checkbox formControlName="renewable" labelPosition="before" style="margin-bottom: 10px; margin-right: 10px;">
                        renewable</mat-checkbox>
                    
                    <!-- trialUsed field -->
                    <mat-checkbox formControlName="trialUsed" labelPosition="before" style="margin-bottom: 10px; margin-right: 10px;">
                        trialUsed</mat-checkbox>
                    
                    <!-- trialUsed field -->
                    <mat-checkbox formControlName="freeUsed" labelPosition="before" style="margin-bottom: 10px; margin-right: 10px;">
                        freeUsed</mat-checkbox>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- idInfluencerAffiliation field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}" 
                    [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right': 2 + '%'}">
                        <input matInput type="text" formControlName="idInfluencerAffiliation" placeholder="Id Influencer Affiliation" readonly="true">
                    </mat-form-field>

                    <!-- idCodeAffiliation field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                    [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput type="text" formControlName="idCodeAffiliation" placeholder="Id Code Affiliation" readonly="true">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- lastCapitalUpdate field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}" 
                    [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right': 2 + '%'}">
                        <input matInput type="text" formControlName="lastCapitalUpdate" placeholder="Last Capital Update" readonly="true">
                    </mat-form-field>

                    <!-- nextCapitalUpdate field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                    [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput type="datetime-local" formControlName="nextCapitalUpdate" placeholder="Next Capital Update">
                    </mat-form-field>
                </div>

                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettaglio Capitale
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="row">
                        <!-- oneDayAgo field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="oneDayAgo" placeholder="One Day Ago" readonly="true">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <!-- twoDaysAgo field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="twoDaysAgo" placeholder="Two Days Ago" readonly="true">
                        </mat-form-field>
                        <!-- threeDaysAgo field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="threeDaysAgo" placeholder="Three Days Ago" readonly="true">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <!-- fourDaysAgo field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="fourDaysAgo" placeholder="Four Days Ago" readonly="true">
                        </mat-form-field>
                        <!-- fiveDaysAgo field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="fiveDaysAgo" placeholder="Five Days Ago" readonly="true">
                        </mat-form-field>
                    </div>
                    
                    <div fxLayout="row">
                        <!-- sixDaysAgo field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="sixDaysAgo" placeholder="Six Days Ago" readonly="true">
                        </mat-form-field>
                        <!-- sevenDaysAgo field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
                        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput type="text" formControlName="sevenDaysAgo" placeholder="Seven Days Ago" readonly="true">
                        </mat-form-field>
                    </div>
                    
                </mat-expansion-panel>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/user-subscriptions')" color="primary">Back</button>
            <button mat-raised-button (click)="userSubscriptionsSave()" [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <button mat-raised-button (click)="userSubscriptionsDelete()" [disabled]="true">
                Delete
            </button>
            <!-- <button mat-raised-button (click)="subscriptionsDeactive()">
                Deactive
            </button> -->
            <button mat-raised-button [routerLink]="['/user-subscriptions/subscriptions/', userSubscriptions?.username]">
                Lista iscrizioni
             </button>
             <button mat-raised-button [routerLink]="['/user-subscriptions/receipts/', userSubscriptions?.username]">
                Lista ricevute
             </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>