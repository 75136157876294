import { OptLockDTO } from './optLockDTO';

export class TraceModificationDTO extends OptLockDTO {
    insertDate: Date;
    insertUser: string;
    updateDate: Date;
    updateUser: string;

    constructor() {
        super();
        this.insertDate = undefined;
        this.insertUser = undefined;
        this.updateDate = undefined;
        this.updateUser = undefined;
    }
}
