import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ManagedSymbolDto } from 'src/app/shared/dto/core-wallets/managedSymbol.types';
import { environment } from 'src/environments/environment';

@Injectable()
export class ManagedSymbolService {

    private url: string = environment.urlPrefix + '/arzakena-core-wallets/managedSymbol';

    constructor(private http: HttpClient) {}

    list(filter?: string): Observable<ManagedSymbolDto[]> {
        const params = {'filter': filter};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.get<ManagedSymbolDto[]>(`${this.url}/list`, {params: params});
    }

    manageNewSymbol(symbol: string): Observable<void> {
        return this.http.post<void>(`${this.url}/manageNewSymbol`, symbol);
    }

    checkForManagedSymbols(symbols: string[]): Observable<void>{
        return this.http.post<void>(`${this.url}/checkForManagedSymbols`, symbols);
    }
}
