import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { CodeService } from '../../services/code/code.service';
import { NavigatorService } from '../../services/navigator.service';
import { CodeDTO } from '../../shared/dto/code/CodeDTO';
import { GenericDetailComponent } from '../../shared/GenericDetailComponent';
import { TimestampFormatPipe } from '../../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-code-detail',
  templateUrl: './code-detail.component.html',
  styleUrls: ['./code-detail.component.scss']
})
export class CodeDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  code: CodeDTO;
  codeId: number = null;
  today: Date;
  influencerId: number;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();



  constructor(
    private codeService: CodeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    route: ActivatedRoute
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);


    this.today = new Date();

    route.params
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.codeId = Number(this.activeRoute.snapshot.paramMap.get('id'));
      });

    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      code: new FormControl('', [Validators.required, Validators.maxLength(64)]),
      expirationDate: new FormControl(''),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {

    this.influencerId = Number(this.activeRoute.snapshot.paramMap.get('influencerId'));
    this.codeId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.codeId !== 0 && this.codeId != null) {
      this.codeRead();
    } else {
      this.code = new CodeDTO();
    }
  }

  codeSave(): void {
    if (!this.code.id) {
      this.codeCreate();
    } else {
      this.codeUpdate();
    }
  }

  //READ

  codeRead(): void {
    this.codeService.read(this.codeId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: CodeDTO) => {
          console.log('response : ' , res);
          this.code = res;
          this.dtoToForm();
        }
      );

  }

  //CREATE

  codeCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.codeService.createByInfluencerId(this.code, this.influencerId)
        .pipe(takeUntil(this._unsubscribeAll),
          catchError((err) => {
            if (err.status === 409) {
              this.snackBar.open('Code already exists!', null, { duration: 3000 });
              return of(null);
            } else {return throwError(err);}
          }))
        .subscribe(
          (res: CodeDTO) => {
            console.log('response : ' , res);
            if (res != null) {
              this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            }
            this.code = res;
            console.log(res);
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/code/detail/' + this.influencerId + '/' + res.id);
            this.codeId = Number(this.activeRoute.snapshot.paramMap.get('id'));
          });
    }
  }

  // UPDATE

  codeUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere?')
        .pipe(
          takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.codeService.update(this.code)
              .pipe(takeUntil(this._unsubscribeAll),
                catchError((err) => {
                  if (err.status === 409) {
                    this.snackBar.open('Code already exists!', null, { duration: 3000 });
                    return of(null);
                  } else {return throwError(err);}
                }))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  if (res != null) {
                    this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  }
                  this.codeRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  codeDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare il codice?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.codeService.delete(this.codeId)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/code']);
                }
              );
            this.back('/gestione-affiliation/detail/' + this.influencerId);
          }
        });
    }
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {
    this.code.id = this.form.get('id').value;
    this.code.code = this.form.get('code').value.trim();
    if (String(this.code.expirationDate) !== '') {
      this.code.expirationDate = this.form.get('expirationDate').value;
    }
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.code.id);
    this.form.get('code').setValue(this.code.code);
    this.form.get('expirationDate').setValue(this.code.expirationDate);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.code.insertDate));
    this.form.get('insertUser').setValue(this.code.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.code.updateDate));
    this.form.get('updateUser').setValue(this.code.updateUser);
    this.form.get('optLock').setValue(this.code.optLock);
    this.form.markAsPristine();
  }

}
