import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NavigatorService } from '../services/navigator.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorInterceptor implements HttpInterceptor {

  private readonly versionInfoURL = environment.sitePrefix+'/META-INF/maven/it.jenia.japp/japp.client.webapp/pom.properties';

  constructor(
    private readonly _navigatorService: NavigatorService,
    private readonly _snackBar: MatSnackBar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log('httpinterceptor error : ' + err);
        if (err instanceof HttpErrorResponse) {
          this._snackBar.open(err.statusText, null, { duration: 3000 });
        }
        return throwError(err);
      })
    );
  }
}
