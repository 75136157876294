import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';
import { HistoryDTO } from 'src/app/shared/dto/core-catalog/HistoryDto.types';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AssetDefinitionHistoryService extends GenericService<AssetDefinitionDto, number>{
  [x: string]: any;

  url: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-core-catalog/assetDefinitionHistory';
  }

  public readSymbol(symbol: string, date: Date): Observable<AssetDefinitionDto>{
    return this.http.get<AssetDefinitionDto>(`${this.url}/${date}/${symbol}`);
  }

  public assetDefinitionHistoryList(symbol: string, mini?: boolean, taxonomy?: boolean, weissRating?: boolean,
     techAttributes?: boolean, socialAttributes?: boolean, page?: number, size?: number, sort?: string  ): Observable<PageDTO<HistoryDTO<AssetDefinitionDto>>> {
    const params = {};
    if (mini != null) {params['mini'] = mini;}
    if (taxonomy != null) {params['taxonomy'] = taxonomy;}
    if (weissRating != null) {params['weissRating'] = weissRating;}
    if (techAttributes != null) {params['techAttributes'] = techAttributes;}
    if (socialAttributes != null) {params['socialAttributes'] = socialAttributes;}
    if (page != null) {params['page'] = page;}
    if (size != null) {params['size'] = size;}
    if (sort != null) {params['sort'] = sort;}
    return this.http.get<PageDTO<HistoryDTO<AssetDefinitionDto>>>(`${this.url}/${symbol}`, { params: params });
  }
}
