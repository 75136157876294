
<mat-card >

    <mat-card-content>
        <div fxLayout="column">
            <table mat-table *ngIf="codes !== null" fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisabled [dataSource]="codes">

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                    <td mat-cell *matCellDef="let code"> {{code?.id}} </td>
                </ng-container>

                <!-- code Column -->
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> code </th>
                    <td mat-cell *matCellDef="let code"> 
                        {{code?.code}}
                    </td>
                </ng-container>

                <!-- codeUrl Column -->
                <ng-container matColumnDef="codeUrl">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Code url </th>
                    <td mat-cell *matCellDef="let code">
                        <button mat-icon-button (click)="copyUrl(code?.code)">
                            <mat-icon>link</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- influencer.id Column -->
                <ng-container matColumnDef="influencerId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> influencer.id </th>
                    <td mat-cell *matCellDef="let code"> {{code?.influencer?.id}} </td>
                </ng-container>

                <ng-container matColumnDef="expirationDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiration Date</th>
                    <td mat-cell *matCellDef="let code"> {{code?.expirationDate | date :'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let code">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/code/detail/' + influencerId + '/' + code?.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
    </mat-card-content>
</mat-card>