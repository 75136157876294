import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from 'src/app/shared/GenericService';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SettingsService extends GenericService<number, number>{

  btcValue: number = 0;
  ethValue: number = 0;

  constructor(http: HttpClient) {
    super(http);
  }

  public countRegisteredUsers(): Observable<number>{
    const url = environment.urlPrefix + '/arzakena-be-settings/settings/countUsers';
    return this.http.get<number>(url);
  }

  public countRegisteredWallet(): Observable<number>{
    const url = environment.urlPrefix + '/arzakena-be-portfolio/positionsContainer/registeredPositionContainer';
    return this.http.get<number>(url);
  }

  public oneWalletUsers(): Observable<number>{
    const url = environment.urlPrefix + '/arzakena-be-portfolio/positionsContainer/oneWalletUsersList';
    return this.http.get<number>(url);
  }

  public symbolCount(symbol: string): Observable<number>{
    const url = environment.urlPrefix + '/arzakena-core-wallets/genericData/totalBalance/' + symbol;
    return this.http.get<number>(url);
  }

  public countTransaction(): Observable<number>{
    const url = environment.urlPrefix + '/arzakena-core-wallets/genericData/transactions';
    return this.http.get<number>(url);
  }

}
