<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Ticket numero: {{ idTicket }}</h2>

    <div *ngIf="(ticket$ | async) as ticket">
      <div class="grid grid-cols xl-grid sm-grid">
        <div class="text-padding">
          <div class="grey-text">Utente</div>
          <div class="large-font">{{ ticketService.user.username }}</div>
        </div>
        <div class="text-padding">
          <div class="grey-text">Categoria</div>
          <div class="large-font">{{ticket.category}}</div>
        </div>
        <div class="text-padding">
          <div class="grey-text">Oggetto</div>
          <div class="large-font" >{{ticket.subject}}
          </div>
        </div>
        <div class="text-padding statusSelect">
          <div class="grey-text">Stato</div>
          <div *ngIf="ticket.status === 'CREATA' || ticket.status === 'CHIUSA'; else statusSelect" class="large-font">{{(ticket.status)}}</div>
          <ng-template #statusSelect>
            <mat-form-field>
              <mat-select name="status" [ngModel]="ticket.status" (selectionChange)="updateTicketStatus($event.value)" [disabled]="isUpdateInProgress">
                <mat-option *ngFor="let status of statuses" [value]="status">
                  {{status}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
        </div>
        <div class="text-padding">
          <div class="grey-text">Data di creazione</div>
          <div class="large-font">{{ticket.insertDate | date :'dd/MM/yyyy hh:mm a'}}</div>
        </div>
        <div class="text-padding">
          <div class="grey-text">Data ultimo messaggio</div>
          <div class="large-font" *ngIf="!ticket.updateDate">
            {{ticket.insertDate | date :'dd/MM/yyyy'}}</div>
          <div class="large-font" *ngIf="ticket.updateDate">
            {{ticket.updateDate | date :'dd/MM/yyyy hh:mm a'}}</div>
        </div>
      </div>
      <br>
    <conversation [data]="ticket$ | async"></conversation>
    <div class="flex">
      <mat-card-actions class="pr-2">
        <button (click)="back()" mat-raised-button color="primary">Back</button>
      </mat-card-actions>
      
      <ng-container *ngIf="ticket.status==='CHIUSA'; else open">
        <mat-card-actions>
          <button (click)="reopenTicket()" mat-raised-button color="primary" [disabled]="isUpdateInProgress">Riapri ticket</button>
        </mat-card-actions>
      </ng-container>
      
      <ng-template #open>
        <mat-card-actions>
          <button (click)="closeTicket()" mat-raised-button [disabled]="isUpdateInProgress">Chiudi ticket</button>
        </mat-card-actions>
      </ng-template>
      
    </div>
  </div>

  <br />
  </mat-card-content>
</mat-card>