/* eslint-disable @typescript-eslint/naming-convention */
export enum ExchangeType {
    BINANCE = 'Binance',
	BITFINEX = 'Bitfinex',
	BITGET = 'Bitget',
	BITPANDA = 'Bitpanda',
	BITSTAMP = 'Bitstamp',
	BYBIT = 'Bybit',
	COINBASE = 'Coinbase',
	CRYPTOCOM = 'Cryptocom',
	CRYPTOCOM_APP = 'Cryptocom app',
	FTX = 'Ftx',
	GATEIO = 'GateIo',
	GEMINI = 'Gemini',
	HUOBI = 'Huobi',
	KRAKEN = 'Kraken',
	KUCOIN = 'KuCoin',
	NEXO = 'Nexo',
	OKX = 'Okx',
	THE_ROCK_TRADING = 'The Rock Trading',
	YOUNGPLATFORM = 'Youngplatform',
}

export const exchangeTypes = Object.keys(ExchangeType).sort();
