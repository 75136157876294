import { TraceModificationDTO } from './traceModificationDTO';

export class LogicalDeleteDTO extends TraceModificationDTO {
    deleteDate: Date;
    deleteUser: string;

    constructor() {
        super();
        this.deleteDate = undefined;
        this.deleteUser = undefined;
    }
}
