import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlockchainType } from 'src/app/shared/dto/commons/BlockchainType.types';
import { PositionDto } from 'src/app/shared/dto/core-wallets/positionDto';
import { PositionIdDto } from 'src/app/shared/dto/core-wallets/positionIdDto.types';
import { environment } from 'src/environments/environment';

@Injectable()
export class PositionsService {

    private url: string = environment.urlPrefix + '/arzakena-core-wallets/position';

    constructor(private http: HttpClient) {}

    read(positionIdDto: PositionIdDto,  symbolTo: string, onlyBalances?: boolean, errorPositions?: boolean): Observable<PositionDto> {
        const params = {'onlyBalances': '' + onlyBalances, 'errorPositions': '' + errorPositions};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.post<PositionDto>(`${this.url}/${symbolTo}`, positionIdDto, {params: params});
    }

    multipleRead(positionIdDtoList: PositionIdDto[],  symbolTo: string, onlyBalances?: boolean, errorPositions?: boolean): Observable<PositionDto[]> {
        const params = {'onlyBalances': '' + onlyBalances, 'errorPositions': '' + errorPositions};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.post<PositionDto[]>(`${this.url}/${symbolTo}`, positionIdDtoList, {params: params});
    }

    readByTimestamp(positionId: PositionIdDto, timestamp: Date, symbolTo: string, onlyBalances?: boolean, errorPositions?: boolean): Observable<PositionDto> {
        const params = {'onlyBalances': '' + onlyBalances, 'errorPositions': '' + errorPositions};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.post<PositionDto>(`${this.url}/${timestamp}/${symbolTo}`, positionId, {params: params});
    }

    multipleReadByTimestamp(positionIdList: PositionIdDto[], timestamp: Date, symbolTo: string, onlyBalances?: boolean, errorPositions?: boolean): Observable<PositionDto[]> {
        const params = {'onlyBalances': '' + onlyBalances, 'errorPositions': '' + errorPositions};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.post<PositionDto[]>(`${this.url}/${timestamp}/${symbolTo}`, positionIdList, {params: params});
    }

    updateSymbolForSmartContract(symbol: string, blockchainType: BlockchainType, smartContractAddress: string): Observable<void> {
        return this.http.put<void>(`${this.url}/${symbol}/${blockchainType}/${smartContractAddress}`, undefined);
    }

    updateSymbolBySymbol(oldSymbol: string, newSymbol: string): Observable<void> {
        return this.http.put<void>(`${this.url}/updateSymbolBySymbol/${oldSymbol}/${newSymbol}`, undefined);
    }
}
