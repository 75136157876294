import { PriceExchangeService } from './../../../services/be-subscriptions/price-exchange/price-exchange.service';
import { ProductService } from 'src/app/services/be-subscriptions/product/product.service';
import { takeUntil } from 'rxjs/operators';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { Subject } from 'rxjs';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  productId: number;
  product: ProductDTO;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private productService: ProductService,
    private priceExchangeService: PriceExchangeService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      name: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      cost: new FormControl('', [Validators.required, Validators.min(0)]),
      yearly: new FormControl('', [Validators.required]),
      free: new FormControl('', [Validators.required]),
      trialProduct: new FormControl('', Validators.required),
      affiliation: new FormControl(''),
      description: new FormControl('', [Validators.required]),
      metadata: new FormControl(''),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.productId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.productId !== 0 && this.productId != null) {
      this.productRead();
    } else {
      this.product = new ProductDTO();
    }
  }

  productSave(): void {
    if (this.product.id == null) {
      this.productCreate();
    } else {
      this.productUpdate();
    }
  }

  //READ

  productRead(): void {
    this.productService.read(this.productId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: ProductDTO) => {
          console.log('response : ' , res);
          this.product = res;
          this.productId = res.id;
          this.dtoToForm();
        }
      );
  }

  //CREATE

  productCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.productService.create(this.product)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: ProductDTO) => {
            console.log('response : ' , res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.product = res;
            console.log(res);
            this.productId = this.product.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/product/detail/' + res.id);
          }
        );
    }
  }

  // UPDATE

  productUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere il product?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.productService.update(this.product)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.productRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  productDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare il product?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.productService.delete(this.product.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/product']);
                }
              );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  checkAffiliation(affiliation: boolean): void{
    if(affiliation === null){
      this.form.get('affiliation').setValue(true);
    } else if (affiliation) {
      this.form.get('affiliation').setValue(false);
    } else {
      this.form.get('affiliation').setValue(null);
    }
  }

  openPriceExchange(): void{
    this.priceExchangeService.getByCost(this.product.cost).subscribe(
      (res) => {
        if(!res){
          this.router.navigate(['/price-exchange/detail/0']);
        } else {
          this.router.navigate(['/price-exchange/detail/' + res.id]);
        }
      }
    );
  }

  private formToDto(): void {
    this.product.name = this.form.get('name').value;
    this.product.cost = this.form.get('cost').value;
    this.product.yearly = this.form.get('yearly').value;
    this.product.free = this.form.get('free').value;
    this.product.trialProduct = this.form.get('trialProduct').value;
    this.product.affiliation = this.form.get('affiliation').value;
    this.product.description = this.form.get('description').value;
    this.product.metadata = this.form.get('metadata').value;

  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.product.id);
    this.form.get('name').setValue(this.product.name);
    this.form.get('cost').setValue(this.product.cost);
    this.form.get('yearly').setValue(this.product.yearly);
    this.form.get('free').setValue(this.product.free);
    this.form.get('trialProduct').setValue(this.product.trialProduct);
    this.form.get('affiliation').setValue(
      (this.product.affiliation === undefined || this.product.affiliation === null)? null : this.product.affiliation);
    this.form.get('description').setValue(this.product.description);
    this.form.get('metadata').setValue(this.product.metadata);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.product.insertDate));
    this.form.get('insertUser').setValue(this.product.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.product.updateDate));
    this.form.get('updateUser').setValue(this.product.updateUser);
    this.form.get('optLock').setValue(this.product.optLock);
    this.form.markAsPristine();
    console.log(this.form.get('affiliation').value);
  }

}
