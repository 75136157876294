import { GenericService } from '../../shared/GenericService';
import { environment } from 'src/environments/environment';
import { ReplyDTO } from '../../shared/dto/help-desk/reply';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class ReplyService extends GenericService<ReplyDTO, number> implements OnDestroy {

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(http: HttpClient){
        super(http);
        this.url = environment.urlPrefix + '/arzakena-be-helpdesk/reply';
    }

    public createReply(replyDto: ReplyDTO): Observable<ReplyDTO> {

        return this.http.post<ReplyDTO>(`${this.url}/`, replyDto).pipe(
            takeUntil(this._unsubscribeAll),
        );
    }

    public replyList(idTicket: number, username: string): Observable<ReplyDTO[]> {

        const params = {};
        return this.http.get<ReplyDTO[]>(`${this.url}/list/`+idTicket+'/'+username, {
            params: params
          });
    }

    public updateReply(reply: ReplyDTO): Observable<ReplyDTO> {
        let params = {};
        if (reply != null) { params = reply; }

        return this.http.put<ReplyDTO>(`${this.url}/`, reply).pipe(
            takeUntil(this._unsubscribeAll)
        );
      }

    ngOnDestroy(): void{
    }
}
