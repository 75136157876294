import { TriggerNotificationSingleUserDto, TriggerNotificationUsersDto } from './../../shared/dto/be-notifications/notification/TriggerNotificationDTO';
import { NotificationUserType } from './../../shared/dto/be-notifications/NotificationTypes';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { CategoryService } from 'src/app/services/be-notifications/category/category.service';
import { CategoryDTO } from 'src/app/shared/dto/be-notifications/category/CategoryDTO';
import { NotificationService } from 'src/app/services/be-notifications/notification/notification.service';
import { TriggerNotificationDTO } from 'src/app/shared/dto/be-notifications/notification/TriggerNotificationDTO';
import { TriggerNotificationService } from 'src/app/services/be-notifications/notification/trigger-notification.service';
import { TemplateService } from 'src/app/services/be-notifications/template/template.service';
import { TemplateDTO } from 'src/app/shared/dto/be-notifications/template/TemplateDTO';
import { NotificationChannel, NotificationMethod, NotificationParameterType as NotificationParameterType } from 'src/app/shared/dto/be-notifications/NotificationTypes';
import { Pageable } from 'src/app/shared/dto/Pageable.types';

@Component({
  selector: 'app-notifications-notification-generate',
  templateUrl: './notification-generate.component.html',
  styleUrls: ['./notification-generate.component.scss'],
  providers: [
    NotificationService,
    CategoryService,
    TemplateService
  ]
})
export class NotificationGenerateComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  notification: TriggerNotificationDTO = new TriggerNotificationDTO();;

  categories: CategoryDTO[];
  readonly channels: NotificationChannel[] = ['MAIL', 'BELL', 'POPUP'];
  readonly methods: NotificationMethod[] = ['INSERT', 'DELETE_INSERT', 'DELETE', 'MARK_AS_READ'];
  templates: string[];
  readonly parameterTypes: NotificationParameterType[] = ['NUMBER', 'STRING', 'DATE', 'BOOLEAN', 'OBJECT'];
  readonly userTypes: NotificationUserType[] = ['SINGLE', 'MULTIPLE'];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private triggerNotificationService: TriggerNotificationService,
    private categoryService: CategoryService,
    private templateService: TemplateService,
    private fb: FormBuilder,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = this.fb.group({
      timestamp: this.fb.control({ value: ''}),
      category: this.fb.control('', [Validators.required]),
      channel: this.fb.control(''),
      method: this.fb.control('', [Validators.required]),
      logicalKey: this.fb.control('', [Validators.required]),
      bundleKey: this.fb.control('', [Validators.required]),
      parameters: this.fb.array([]),
      attachments: this.fb.array([]),
      userType: this.fb.control('', [Validators.required]),
      username: this.fb.control(''),
      email: this.fb.control(''),
      url: this.fb.control(''),
      usernames: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.categoryService.readAll({page: undefined, size: 1000, sort: undefined} as Pageable, undefined, undefined)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      this.categories = response.content;
      this.templateService.readAll({page: undefined, size: 1000, sort: undefined} as Pageable, undefined, undefined, undefined)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((responseTemplate) => {
        //Removes duplicates
        this.templates = [... new Set(responseTemplate.content.map(template => template.bundleKey))];
      });
    });
  }

  notificationGenerate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.triggerNotificationService.generate(this.notification)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
            this.snackBar.open('Generazione avvenuta con successo!', null, { duration: 3000 });
            this.form.markAsPristine();
          }
        );
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  formClear(): void {
    this.parametersArray.clear();
    this.attachmentsArray.clear();
    this.usernamesArray.clear();
    this.form.reset();
  }

  addParameter(): void {
    const parameter = this.fb.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      value: ['', Validators.required]
    });

    this.parametersArray.push(parameter);
  }

  removeParameter(index: number): void {
    this.parametersArray.removeAt(index);
  }

  addAttachment(): void {
    const attachment = this.fb.group({
      name: ['', Validators.required],
      url: ['', Validators.required]
    });

    this.attachmentsArray.push(attachment);
  }

  removeAttachment(index: number): void {
    this.attachmentsArray.removeAt(index);
  }

  addUsername(): void {
    const username = this.fb.group({
      name: ['', Validators.required]
    });

    this.usernamesArray.push(username);
  }

  removeUsername(index: number): void {
    this.usernamesArray.removeAt(index);
  }

  get parametersArray(): FormArray{
    return this.getFormArray('parameters');
  }

  get attachmentsArray(): FormArray{
    return this.getFormArray('attachments');
  }

  get usernamesArray(): FormArray{
    return this.getFormArray('usernames');
  }

  private getFormArray(name: string): FormArray {
    return this.form.controls[name] as FormArray;
  }

  private formToDto(): void {
    this.notificationToUser();

    this.notification.timestamp = this.form.get('timestamp').value;
    this.notification.categoryBundleKey = this.form.get('category').value;
    this.notification.method = this.form.get('method').value;
    this.notification.logicalKey = this.form.get('logicalKey').value;
    this.notification.templateBundleKey = this.form.get('bundleKey').value;
    this.notification.url = this.form.get('url').value;

    const channelValue = this.form.get('channel').value;
    if(channelValue && channelValue !== '') {
      this.notification.channel = channelValue;
    }

    //Setting the parameters
    const parametersMap: any = {};
    this.parametersArray.controls.forEach((parameter: AbstractControl) => {
      let value = null;
      //The type of the value depends on the type field
      switch(parameter.get('type').value as NotificationParameterType){
        case 'DATE': {
          value = parameter.get('value').value as Date;
          break;
        }
        case 'STRING': {
          value = parameter.get('value').value as string;
          break;
        }
        case 'NUMBER': {
          value = parameter.get('value').value as number;
          break;
        }
        case 'BOOLEAN': {
          value = parameter.get('value').value as boolean;
          break;
        }
        case 'OBJECT': {
          value = JSON.parse(parameter.get('value').value);
          break;
        }
      }
      parametersMap[parameter.get('name').value] = value;
    });
    this.notification.parameters = parametersMap;

    //Setting the attachments
    const attachmentsMap: any = {};
    this.attachmentsArray.controls.forEach((attachment: AbstractControl) => {
      attachmentsMap[attachment.get('name').value] = attachment.get('url').value;
    });
    this.notification.attachments = attachmentsMap;
  }

  private notificationToUser(): void{
    //The triggerNotification has 2 children classes
    //This method chooses which one it needs to be
    const userType: NotificationUserType = this.form.get('userType').value;
    if(userType === 'SINGLE'){
      const newNotification = new TriggerNotificationSingleUserDto();
      newNotification.user = this.form.get('username').value;
      newNotification.email = this.form.get('email').value;
      this.notification = newNotification;
    } else {
      const newNotification = new TriggerNotificationUsersDto();
      const users: string[] = [];
      this.usernamesArray.controls.forEach((username: AbstractControl) => {
        users.push(username.get('name').value);
      });
      newNotification.users = users;
      this.notification = newNotification;
    }
    this.notification.triggerNotificationType = userType;
  }

}
