import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { CategoryDTO } from 'src/app/shared/dto/be-notifications/category/CategoryDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends GenericService<CategoryDTO, number> {

  noAdminurl: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-notifications/category/admin';
    this.noAdminurl = environment.urlPrefix + '/arzakena-be-notifications/category';
    //this.url = 'http://localhost:31300/category/admin';
    //this.noAdminurl = 'http://localhost:31300/category';
  }

  public readAll(pageable: Pageable, name: string, bundleKey: string): Observable<PageDTO<CategoryDTO>> {
    const params = {'name': name + '',
      'bundleKey': bundleKey + '',
      'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => (params[key] === 'undefined' || params[key] === '') && delete params[key]);
    return this.http.get<PageDTO<CategoryDTO>>(`${this.noAdminurl}/list`, {params: params});
  }
}
