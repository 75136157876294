<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Price Exchanges</h2>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="new-button" color="basic" [routerLink]="['/price-exchange/detail/0']">New
                    Price Exchange</button>
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [dataSource]="parameters.dataSource">
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> id </th>
                    <td mat-cell *matCellDef="let priceExchange"> {{priceExchange.id}} </td>
                </ng-container>

                <!-- usd Column -->
                <ng-container matColumnDef="usd">
                    <th mat-header-cell *matHeaderCellDef> usd </th>
                    <td mat-cell *matCellDef="let product"> {{product.usd}} </td>
                </ng-container>

                <!-- eur Column -->
                <ng-container matColumnDef="eur">
                    <th mat-header-cell *matHeaderCellDef> eur </th>
                    <td mat-cell *matCellDef="let priceExchange"> {{priceExchange.eur}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let priceExchange">
                        <button mat-mini-fab color="primary" [routerLink]="['/price-exchange/detail/', priceExchange.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>