import { Pageable } from './../../shared/dto/Pageable.types';
import { ProductService } from './../../services/be-subscriptions/product/product.service';
import { SubscriptionType } from './../../shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { ProductDTO } from './../../shared/dto/be-subscriptions/product/ProductDTO';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { ComponentCacheService } from './../../services/component-cache.service';
import { NavigatorService } from './../../services/navigator.service';
import { SubscriptionService } from './../../services/be-subscriptions/subscription/subscription.service';
import { Subject } from 'rxjs';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent extends GenericListComponent implements OnInit, OnDestroy {

  types: SubscriptionType[] = ['PROMOTION', 'CRYPTO', 'CARD'];

  translations: Map<string, string> = ProductDTO.translations;
  products: ProductDTO[];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private subscriptionService: SubscriptionService,
    private productService: ProductService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['id', 'startDate', 'endDate', 'active', 'paid', 'product', 'subscriptionType', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        idFilter: undefined,
        startDateFrom: undefined,
        startDateTo: undefined,
        endDateFrom: undefined,
        endDateTo: undefined,
        active: undefined,
        paid: undefined,
        productId: undefined,
        type: undefined,
        sort: undefined,
        page: 0,
        size: 50,
        length: 0
      };
      this.productService.readAll().subscribe(res => this.products = res);
  }

  list(): void{
    this.subscriptionService.readAll(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
      this.parameters.idFilter, this.parameters.startDateFrom, this.parameters.startDateTo, this.parameters.endDateFrom, this.parameters.endDateTo,
      this.parameters.active, this.parameters.paid, this.parameters.productId, this.parameters.type
    )
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((subscriptions) => {
      console.log('response : ' , subscriptions);
      this.parameters.dataSource = subscriptions.content;
      this.parameters.page = subscriptions.page;
      this.parameters.length = subscriptions.totalElements;
      this.parameters.showList = true;
      }
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  changeBool(value: boolean, field: string): void{
    if(value === undefined){
      this.parameters[field] = true;
    } else if (value) {
      this.parameters[field] = false;
    } else {
      this.parameters[field] = undefined;
    }
  }

}
