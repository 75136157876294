import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { AdvertisementDto } from 'src/app/shared/dto/advertisements/AdvertisementDto.types';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { AdvertisementService } from 'src/app/services/advertisements/advertisement.service';

@Component({
  selector: 'app-campaigns-detail',
  templateUrl: './advertisement-detail.component.html',
  styleUrls: ['./advertisement-detail.component.scss'],
  providers: [
    AdvertisementService
  ]
})
export class AdvertisementDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private advService: AdvertisementService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    if(this.detailId()) {
      this.read(this.detailId());
    } else {
      this.form = this.loadForm(new AdvertisementDto());
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toAdvertisementDto());
      return;
    }
    this.create(this.toAdvertisementDto());
  }

  deleteAdvertisement(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare l\'advertisement?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }


  detailId(): number {
    return +this.activeRoute.snapshot.paramMap.get('id');
  }

  private create(adv: AdvertisementDto): void {
    this.advService.create(adv).subscribe((responseMessage) => {
      this.router.navigateByUrl(this.router.url.replace(this.detailId() + '', responseMessage.id + ''));
      this.form = this.loadForm({...new AdvertisementDto(), ...responseMessage});
    });
  }

  private update(adv: AdvertisementDto): void {
    this.advService.update(adv).subscribe(() => this.read(this.detailId()));
  }

  private read(id: number): void {
    this.advService.adminRead(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(adv => this.form = this.loadForm({...new AdvertisementDto(), ...adv}));
  }

  private delete(id: number): void {
    this.advService.delete(id).subscribe(() => this.router.navigate(['/campaigns']));
  }
  private loadForm(adv: AdvertisementDto): FormGroup {
    return this._formBuilder.group({...adv, ...this.formValidators(adv)});
  }

  private toAdvertisementDto(): AdvertisementDto {
    return this.form.getRawValue() as AdvertisementDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(adv: AdvertisementDto) {
    return {
        campaigner: [adv.campaigner, [Validators.required, Validators.maxLength(64)]],
        imageUrl: [adv.imageUrl, Validators.required, , Validators.maxLength(256)],
        redirectUrl: [adv.redirectUrl, Validators.required, , Validators.maxLength(256)],
        visualizationStart: [adv.visualizationStart,  Validators.required],
        visualizationEnd: [adv.visualizationEnd, Validators.required],
        translations: [adv.translations]
    };
  }
}
