<mat-card id="card" fxLayout="column" class="mainContentBody" [ngStyle]="{'min-width': '75%'}">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>FAQ detail</h2>
        <div fxLayout="column" class="responsive-mat-form" >
            <form [formGroup]="form" fxLayout="column">

                <!-- Id field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput formControlName="id" placeholder="FAQ id" readonly="true" />
                </mat-form-field>

                <!-- USERLOCALE FIELD -->
                <mat-form-field>
                    <mat-select name="userLocale" formControlName="userLocale" placeholder="Language" (selectionChange)="newCategories()">
                        <mat-option *ngFor="let language of languages" [value]="language">
                            {{ language }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Category check -->
                <section class="example-section">
                    <mat-checkbox color="primary" value="checked" (click)="changeValueChecked(checked)"
                        (change)="valueChangeCategory('')">Existing category : {{ form.get('userLocale').value }}
                    </mat-checkbox>
                </section>


                <div fxLayout="row" >
                    <!-- Category field -->
                    <mat-form-field fxFlex="85%" fxFlex.sm="100%" fxlayout="column" *ngIf="checked"
                    [ngStyle]="{'padding-right': 2 + '%'}" >
                        <input matInput formControlName="category" placeholder="Create a category"
                            (change)="valueChangeCategory($event.target.value)" #categoryContent maxlength="255"/>
                            <mat-hint >{{categoryContent.value.length}} / 255</mat-hint>
                    </mat-form-field>

                    <!-- Category select -->
                    <mat-form-field fxFlex="85%" fxFlex.sm="100%" fxlayout="column" *ngIf="!checked"
                    [ngStyle]="{'padding-right': 2 + '%'}" >
                        <mat-select matInput formControlName="category" placeholder="Choose a category"
                            (selectionChange)="valueChangeCategory($event)">
                            <mat-option *ngFor="let category of categories" [value]="category">
                                {{category}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- OrderFaq field -->
                    <mat-form-field fxFlex="15%" fxFlex.sm="100%" fxlayout="column">
                        <input (change)="valueChangeOrder($event.target.value)" type="number" matInput
                            formControlName="orderFaq" placeholder="FAQ order" />
                    </mat-form-field>
                </div>

                <!-- Question field -->
                <mat-form-field fxlayout="column">
                    <input matInput formControlName="question" placeholder="Question" #questionContent maxlength="255"/>
                    <mat-hint >{{questionContent.value.length}} / 255</mat-hint>
                    <mat-error *ngIf="form.get('question').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Answer field -->
                <mat-form-field fxlayout="column">
                    <textarea matInput formControlName="answer" placeholder="Answer" [rows]="12" #answerContent maxlength="4096"></textarea>
                    <mat-hint >{{answerContent.value.length}} / 4096</mat-hint>
                    <mat-error *ngIf="form.get('answer').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>


                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>

        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/faq')" color="primary">Back</button>
            <button mat-raised-button (click)="saveFaq()" [disabled]="form.invalid || !form.dirty"
                *ngIf="faq?.deleteDate==null">Save</button>
            <delete-button (customClick)="deleteFaq()" [disabled]="faq?.id==null"></delete-button>
        </mat-card-actions>

    </mat-card-content>
</mat-card>