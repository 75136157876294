<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Subscription Extensions</h2>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="new-button" color="basic" [routerLink]="['/subscription-extension/detail/0']">New
                    Subscription Extension</button>
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div fxLayout="column">
            <app-subscription-extension-table></app-subscription-extension-table>
        </div>
    </mat-card-content>
</mat-card>
