import { Component, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html'
  })
export class AffiliatedUsersComponent extends GenericListComponent{

  @Input() influencerId: number;
  @Input() users: string[];
  nameValue: string = '';

  constructor(
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,

  ) {

    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['usersName', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: true,
      filter: null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
    };
  }
  list(): void{
    if(this.users && this.users.length){
      this.parameters.dataSource = this.users;
      this.parameters.page = 1;
      this.parameters.length = 1;
      this.parameters.showList = true;
    }
}

}
