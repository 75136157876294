import { HttpErrorResponse } from '@angular/common/http';
import { FaqDTO } from './../shared/dto/help-desk/Faq';
import { FaqService } from './../services/help-desk/faq.service';

import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-faq',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.scss']
})

export class FaqDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  faq: FaqDTO;
  faqId: number;
  languages: string[] = ['it-IT', 'en-US'];
  categories: string[];
  checked: boolean = true;
  form: FormGroup;

  constructor(
    private faqService: FaqService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      orderFaq: new FormControl({ value: '', disabled: true }, Validators.required),
      userLocale: new FormControl(''),
      question: new FormControl({ value: '', disabled: true }, Validators.required),
      answer: new FormControl({ value: '', disabled: true }, Validators.required),
      category: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true })
    });
  }


  ngOnInit(): void {
    this.faqId = Number(this.activeRoute.snapshot.paramMap.get('idFaq'));

    if (this.faqId !== 0 && this.faqId != null) {
      this.readFaq(this.faqId);
      this.form.enable();
      this.form.controls['id'].disable();
    }
    else {
      this.faq = new FaqDTO();
      this.form.get('userLocale').setValue(this.languages[0]);
      this.newCategories();
    }

  }
  ngOnDestroy(): void {
  }

  newCategories(): void {
    this.faqService.categoryList(this.form.get('userLocale').value).subscribe(
      (res: string[]) => {
        this.categories = res;
      }
    );

    if (this.faqId === 0) {
      this.form.controls['answer'].disable();
      this.form.controls['question'].disable();
      this.form.controls['orderFaq'].disable();
      this.form.get('orderFaq').setValue(null);

    }

  }

  valueChangeCategory(valid): void {
    if (valid === '' || valid == null) {
      this.form.controls['orderFaq'].disable();
    }
    else {
      this.form.controls['orderFaq'].enable();
    }
    this.form.get('orderFaq').setValue(null);

  }

  valueChangeOrder(order: number): void {

    if ((order == null || order <= 0) && this.form.get('category') == null) {
      this.form.controls['answer'].disable();
      this.form.controls['question'].disable();
    }
    else {
      this.form.controls['answer'].enable();
      this.form.controls['question'].enable();
    }

    let currentOrder = -1;
    if(this.faqId !== 0){
      currentOrder = this.faq.orderFaq;
    }

    this.faqService.faqList(
      this.form.get('category').value,
      null,
      this.form.get('userLocale').value
    ).subscribe(
      (res: FaqDTO) => {
        const data = res;
        for (let i = 0; data[i] != null; i++) {
          if(order <= 0){
            data[i + 1] = null;
            this.snackBar.open('Impossibile inserire posizioni minori o uguali a 0', null, { duration: 5000 });
            this.form.controls['answer'].disable();
            this.form.controls['question'].disable();
          }
          else if (order === data[i].orderFaq && (order !== currentOrder || this.form.get('category').value !== this.faq.category)) {
            data[i + 1] = null;
            this.snackBar.open('E\' già presente una faq della categoria ' + data[i].category + ' nella posizione ' + order, null, { duration: 5000 });
            this.form.controls['orderFaq'].setErrors({'incorrect': true});
          }
        }
      }
    );
  }

  changeValueChecked(value): void {
    this.checked = !value;
    this.form.get('category').setValue(null);
    this.form.get('orderFaq').setValue(null);
  }

  saveFaq(): void {
    if (this.faqId === 0) {
      this.createFaq();
    }
    else {
      this.updateFaq();
    }
  }

  createFaq(): void {
    if (this.form.valid) {
      this.formToDto();
      this.faqService.create(this.faq)
        .subscribe(
          (res: FaqDTO) => {
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.faq = res;
            this.faqId = this.faq.id;
            this.dtoToForm();
            this.form.markAsPristine();
          }, (err: HttpErrorResponse) => {
            if(err.status === 401 || err.status === 403)
              {this.snackBar.open('Non sei autorizzato per questa operazione', null, { duration: 3000 });}
            else
              {this.snackBar.open('Ops, qualcosa è andato storto', null, { duration: 3000 });}
          }
        );
    }
  }

  updateFaq(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere la FAQ?').subscribe((result) => {
        if (result) {
          this.formToDto();
          this.faqService.update(this.faq).subscribe(
            (res) => {
              this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
              this.readFaq(this.faqId);
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              if(err.status === 401 || err.status === 403)
                {this.snackBar.open('Non sei autorizzato per questa operazione', null, { duration: 3000 });}
              else
                {this.snackBar.open('Ops, qualcosa è andato storto', null, { duration: 3000 });}
            }
          );
        }
      });
    }
  }

  deleteFaq(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare la FAQ?').subscribe((result) => {
        if (result) {
          this.formToDto();
          this.faqService.delete(this.faq.id).subscribe(
            (res) => {
              this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
              this.router.navigate(['/faq']);
            }
          );
        }
      });
    }
  }

  readFaq(id: number): void {
    this.faqService.read(id).subscribe(
      (res: FaqDTO) => {
        this.faq = res;
        this.dtoToForm();
        this.checked = true;
        this.newCategories();
      }
    );
  }

  private formToDto(): void {
    this.faq.orderFaq = this.form.get('orderFaq').value;
    this.faq.question = this.form.get('question').value;
    this.faq.answer = this.form.get('answer').value;
    this.faq.category = this.form.get('category').value;
    this.faq.userLocale = this.form.get('userLocale').value;
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.faq.id);
    this.form.get('orderFaq').setValue(this.faq.orderFaq);
    this.form.get('question').setValue(this.faq.question);
    this.form.get('answer').setValue(this.faq.answer);
    this.form.get('userLocale').setValue(this.faq.userLocale);
    this.form.get('category').setValue(this.faq.category);
    this.form.get('deleteDate').setValue(this.faq.deleteDate);
    this.form.get('deleteUser').setValue(this.faq.deleteUser);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.faq.insertDate));
    this.form.get('insertUser').setValue(this.faq.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.faq.updateDate));
    this.form.get('updateUser').setValue(this.faq.updateUser);
    this.form.get('optLock').setValue(this.faq.optLock);
    this.form.markAsPristine();
  }
}
