import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { AssetDefinitionService } from 'src/app/services/core-catalog/asset-definition/asset-definition.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';
import { AssetDefinitionTaxonomyDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionTaxonomyDto.types';
import { AssetDefinitionWeissRatingDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionWeissRatingDto.types';
import { AssetDefinitionTechDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionTechDto.types';
import { AssetDefinitionUrlDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionUrlDto.types';
import { AssetDefinitionSocialDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionSocialDto.types';

@Component({
  selector: 'app-asset-definition-history-detail',
  templateUrl: './asset-definition-history-detail.component.html',
  styleUrls: ['./asset-definition-history-detail.component.scss']
})
export class AssetDefinitionHistoryDetailComponent extends GenericDetailComponent implements OnInit {

  assetDefinitionSymbol: string;
  assetDefinition: AssetDefinitionDto;
  logo: string;
  taxonomy: AssetDefinitionTaxonomyDto;
  weissRating: AssetDefinitionWeissRatingDto;
  techAttributes: AssetDefinitionTechDto;
  tags: string[];
  urls: AssetDefinitionUrlDto[];
  socialAttributes: AssetDefinitionSocialDto;

  constructor(
    private assetDefinitionService: AssetDefinitionService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      symbol: new FormControl({ value: '' }),
      type: new FormControl({ value: '' }),
      fullName: new FormControl({ value: '' }),
      modificationDate: new FormControl({ value: '' }),
      name: new FormControl({ value: '' }),
      coinName: new FormControl({ value: '' }),
      description: new FormControl({ value: '' }),
      logo: new FormControl({ value: '' }),
      sortOrder: new FormControl({ value: '' }),
      trading: new FormControl({ value: '' }),
      decimalPoints: new FormControl({ value: '' }),
      color: new FormControl({ value: '' }),
      //taxonomy
      taxonomyAccess: new FormControl({ value: '' }),
      taxonomyFCA: new FormControl({ value: '' }),
      taxonomyFINMA: new FormControl({ value: '' }),
      taxonomyIndustry: new FormControl({ value: '' }),
      taxonomyCollateralizedAsset: new FormControl({ value: '' }),
      taxonomyCollateralizedAssetType: new FormControl({ value: '' }),
      taxonomyCollateralType: new FormControl({ value: '' }),
      taxonomyCollateralInfo: new FormControl({ value: '' }),
      //weissRating
      weissRatingCategory: new FormControl({ value: '' }),
      weissRatingRatingSource: new FormControl({ value: '' }),
      weissRatingRatingCode: new FormControl({ value: '' }),
      weissRatingRatingWeight: new FormControl({ value: '' }),
      weissRatingRatingChange: new FormControl({ value: '' }),
      weissRatingMarketPerformanceSource: new FormControl({ value: '' }),
      weissRatingMarketPerformanceCode: new FormControl({ value: '' }),
      weissRatingMarketPerformanceWeight: new FormControl({ value: '' }),
      weissRatingTechAdoptionSource: new FormControl({ value: '' }),
      weissRatingTechAdoptionCode: new FormControl({ value: '' }),
      weissRatingTechAdoptionWeight: new FormControl({ value: '' }),
      weissRatingMomentumSource: new FormControl({ value: '' }),
      weissRatingMomentumCode: new FormControl({ value: '' }),
      weissRatingMomentumWeight: new FormControl({ value: '' }),
      weissRatingRiskSource: new FormControl({ value: '' }),
      weissRatingRiskCode: new FormControl({ value: '' }),
      weissRatingRiskWeight: new FormControl({ value: '' }),
      weissRatingTechnologySource: new FormControl({ value: '' }),
      weissRatingTechnologyCode: new FormControl({ value: '' }),
      weissRatingTechnologyWeight: new FormControl({ value: '' }),
      weissRatingAdoptionSource: new FormControl({ value: '' }),
      weissRatingAdoptionCode: new FormControl({ value: '' }),
      weissRatingAdoptionWeight: new FormControl({ value: '' }),
      //techAttributes
      techAttributesBuiltOn: new FormControl({ value: '' }),
      techAttributesSmartContractAddress: new FormControl({ value: '' }),
      techAttributesAlgorithm: new FormControl({ value: '' }),
      techAttributesProofType: new FormControl({ value: '' }),
      techAttributesPreMinedValue: new FormControl({ value: '' }),
      tags: new FormControl({ value: '' }),
      urls: new FormControl({ value: '' }),
      socialAttributesSubreddit: new FormControl({ value: '' }),
      socialAttributesTwitterUsername: new FormControl({ value: '' }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.assetDefinitionSymbol = String(this.activeRoute.snapshot.paramMap.get('symbol'));
    if (this.assetDefinitionSymbol !== '' && this.assetDefinitionSymbol != null) {
      this.assetDefinitionRead();
    } else {
      this.assetDefinition = new AssetDefinitionDto();
    }

  }


  //READ

  assetDefinitionRead(): void {
    this.assetDefinitionService.readSymbol(this.assetDefinitionSymbol).subscribe(
      (res: AssetDefinitionDto) => {
        console.log('response : ' , res);
        this.assetDefinition = res;
        this.assetDefinitionSymbol = res.symbol;
        this.logo = res.logo;
        this.taxonomy = res.taxonomy;
        this.techAttributes = res.techAttributes;
        this.weissRating = res.weissRating;
        this.tags = res.tags;
        this.urls = res.urls;
        this.socialAttributes = res.socialAttributes;
        this.dtoToForm();
      }
    );
  }

  private dtoToForm(): void {
    this.form.get('symbol').setValue(this.assetDefinition.symbol);
    this.form.get('type').setValue(this.assetDefinition.type);
    this.form.get('fullName').setValue(this.assetDefinition.fullName);
    this.form.get('modificationDate').setValue(this.timestampFormatPipe.transform(new Date(this.assetDefinition.modificationDate)));
    this.form.get('name').setValue(this.assetDefinition.name);
    this.form.get('coinName').setValue(this.assetDefinition.coinName);
    this.form.get('description').setValue(this.assetDefinition.description);
    this.form.get('logo').setValue(this.assetDefinition.logo);
    this.form.get('sortOrder').setValue(this.assetDefinition.sortOrder);
    this.form.get('trading').setValue(this.assetDefinition.trading);
    this.form.get('decimalPoints').setValue(this.assetDefinition.decimalPoints);
    this.form.get('color').setValue(this.assetDefinition.color);
    //taxonomy
    if (this.taxonomy != null) {
      this.form.get('taxonomyAccess').setValue(this.assetDefinition.taxonomy.access);
      this.form.get('taxonomyFCA').setValue(this.assetDefinition.taxonomy.fca);
      this.form.get('taxonomyFINMA').setValue(this.assetDefinition.taxonomy.finma);
      this.form.get('taxonomyIndustry').setValue(this.assetDefinition.taxonomy.industry);
      this.form.get('taxonomyCollateralizedAsset').setValue(this.assetDefinition.taxonomy.collateralizedAsset);
      this.form.get('taxonomyCollateralizedAssetType').setValue(this.assetDefinition.taxonomy.collateralizedAssetType);
      this.form.get('taxonomyCollateralType').setValue(this.assetDefinition.taxonomy.collateralType);
      this.form.get('taxonomyCollateralInfo').setValue(this.assetDefinition.taxonomy.collateralInfo);
    }
    //Weiss Rating
    if (this.weissRating != null) {
      this.form.get('weissRatingCategory').setValue(this.assetDefinition.weissRating.category);
      this.form.get('weissRatingRatingSource').setValue(this.assetDefinition.weissRating.rating.source);
      this.form.get('weissRatingRatingCode').setValue(this.assetDefinition.weissRating.rating.code);
      this.form.get('weissRatingRatingWeight').setValue(this.assetDefinition.weissRating.rating.weight);
      this.form.get('weissRatingRatingChange').setValue(this.assetDefinition.weissRating.ratingChange);
      this.form.get('weissRatingMarketPerformanceSource').setValue(this.assetDefinition.weissRating.marketPerformance.source);
      this.form.get('weissRatingMarketPerformanceCode').setValue(this.assetDefinition.weissRating.marketPerformance.code);
      this.form.get('weissRatingMarketPerformanceWeight').setValue(this.assetDefinition.weissRating.marketPerformance.weight);
      this.form.get('weissRatingTechAdoptionSource').setValue(this.assetDefinition.weissRating.techAdoption.source);
      this.form.get('weissRatingTechAdoptionCode').setValue(this.assetDefinition.weissRating.techAdoption.code);
      this.form.get('weissRatingTechAdoptionWeight').setValue(this.assetDefinition.weissRating.techAdoption.weight);
      this.form.get('weissRatingMomentumSource').setValue(this.assetDefinition.weissRating.momentum.source);
      this.form.get('weissRatingMomentumCode').setValue(this.assetDefinition.weissRating.momentum.code);
      this.form.get('weissRatingMomentumWeight').setValue(this.assetDefinition.weissRating.momentum.weight);
      this.form.get('weissRatingRiskSource').setValue(this.assetDefinition.weissRating.risk.source);
      this.form.get('weissRatingRiskCode').setValue(this.assetDefinition.weissRating.risk.code);
      this.form.get('weissRatingRiskWeight').setValue(this.assetDefinition.weissRating.risk.weight);
      this.form.get('weissRatingTechnologySource').setValue(this.assetDefinition.weissRating.technology.source);
      this.form.get('weissRatingTechnologyCode').setValue(this.assetDefinition.weissRating.technology.code);
      this.form.get('weissRatingTechnologyWeight').setValue(this.assetDefinition.weissRating.technology.weight);
      this.form.get('weissRatingAdoptionSource').setValue(this.assetDefinition.weissRating.adoption.source);
      this.form.get('weissRatingAdoptionCode').setValue(this.assetDefinition.weissRating.adoption.code);
      this.form.get('weissRatingAdoptionWeight').setValue(this.assetDefinition.weissRating.adoption.weight);
    }
    //techAttributes
    if (this.techAttributes != null) {
      this.form.get('techAttributesBuiltOn').setValue(this.assetDefinition.techAttributes.builtOn);
      this.form.get('techAttributesSmartContractAddress').setValue(this.assetDefinition.techAttributes.smartContractAddress);
      this.form.get('techAttributesAlgorithm').setValue(this.assetDefinition.techAttributes.algorithm);
      this.form.get('techAttributesProofType').setValue(this.assetDefinition.techAttributes.proofType);
      this.form.get('techAttributesPreMinedValue').setValue(this.assetDefinition.techAttributes.preMinedValue);
    }
    this.form.get('tags').setValue(this.assetDefinition.tags);
    this.form.get('urls').setValue(this.assetDefinition.urls);
    if (this.socialAttributes != null) {
      this.form.get('socialAttributesSubreddit').setValue(this.assetDefinition.socialAttributes.subreddit);
      this.form.get('socialAttributesTwitterUsername').setValue(this.assetDefinition.socialAttributes.twitterUsername);
    }
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.assetDefinition.insertDate));
    this.form.get('insertUser').setValue(this.assetDefinition.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.assetDefinition.updateDate));
    this.form.get('updateUser').setValue(this.assetDefinition.updateUser);
    this.form.get('optLock').setValue(this.assetDefinition.optLock);
    this.form.markAsPristine();
  }

}
