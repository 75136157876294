import { switchMap, takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { Subject } from 'rxjs';
import { TicketDTO } from './../../shared/dto/help-desk/ticket';
import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { NavigatorService } from './../../services/navigator.service';
import { ReplyDTO } from './../../shared/dto/help-desk/reply';
import { TicketService } from './../../services/help-desk/ticket.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ReplyService } from './conversation.service';

@Component({
  selector: 'conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data: TicketDTO;

  username: string;
  ticketChiuso = false;
  form: FormGroup;

  private stopPolling = new Subject();
  private _replysSubject: ReplaySubject<ReplyDTO[]> = new ReplaySubject<ReplyDTO[]>(1);

  get replyList$(): Observable<ReplyDTO[]> {
    return this._replysSubject.asObservable();
  }

  constructor(public dialog: MatDialog,
    private navigatorService: NavigatorService,
    public replyService: ReplyService,
    public ticketService: TicketService,
  ) {
    this.form = new FormGroup({
      message: new FormControl('', Validators.maxLength(4096))
    });
  }


  ngOnInit(): void {
    this.navigatorService.collaboratore$.subscribe(
      (res: CollaboratoreDTO) => {
        this.username = res.nome;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.stopPolling.next();
    if (this.data.status !== 'CHIUSA') {
      timer(1, 3000).pipe(
        switchMap(() => this.replyService.replyList(this.data.id, this.data.insertUser)),
        takeUntil(this.stopPolling)
      ).subscribe(replies => this._replysSubject.next(replies));
    }
    else{
      this.replyService.replyList(this.data.id, this.data.insertUser).subscribe(replies => this._replysSubject.next(replies));
    }
  }

  ngOnDestroy(): void {
    this.stopPolling.next();
    this.stopPolling.complete();
  }

  clearForm(): void {
    this.form.get('message').setValue(null);
  }

  createMessage(): ReplyDTO {
    return {
      content: this.form.get('message').value,
      ticket: this.data
    } as ReplyDTO;
  }

  sendMessage(): void {
    const reply: ReplyDTO = this.createMessage();
    this.clearForm();

    this.replyService.createReply(reply).subscribe(() => {

      this.ticketService.readTicket(this.data.id, this.data.insertUser).subscribe((res) => {
        this.data.status = res.status;
      });

    });
  }

}
