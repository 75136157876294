<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Subscription</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id"
                        readonly="true" />
                </mat-form-field>

                <!-- SubscriptionType field -->
                <mat-form-field>
                    <mat-select formControlName="subscriptionType" placeholder="subscriptionType" (selectionChange)="changeType($event)">
                        <mat-option *ngFor="let subscriptionType of subscriptionTypes" [value]="subscriptionType">
                            {{subscriptionType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Username field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="username" placeholder="Username">
                    <mat-error *ngIf="form.get('username').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Campi data -->
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- startDate field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput [max]="form.get('endDate').value" [matDatepicker]="startDatePicker" formControlName="startDate"
                            placeholder="Start Date" (click)="startDatePicker.open()" readonly="true">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                        <button mat-button *ngIf="form.get('startDate').value" matSuffix mat-icon-button
                            aria-label="Clear" (click)="form.get('startDate').setValue(null)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('startDate').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <!-- endDate field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput [min]="form.get('startDate').value" [matDatepicker]="endDatePicker" formControlName="endDate"
                            placeholder="End Date" (click)="endDatePicker.open()" readonly="true">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                        <button mat-button *ngIf="form.get('endDate').value" matSuffix mat-icon-button
                            aria-label="Clear" (click)="form.get('endDate').setValue(null)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('endDate').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Upgrade field -->
                <mat-checkbox formControlName="upgrade" labelPosition="before" style="margin-bottom: 10px;">
                    Upgrade</mat-checkbox>

                <!-- Active field -->
                <mat-checkbox formControlName="active" labelPosition="before" style="margin-bottom: 10px;">
                    Active</mat-checkbox>

                <!-- Paid field -->
                <mat-checkbox formControlName="paid" labelPosition="before" style="margin-bottom: 10px;">
                    Paid</mat-checkbox>


                <!-- Campi prezzo -->
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- Price Paid field -->
                    <mat-form-field fxFlex="33" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput type="number" formControlName="pricePaid" placeholder="Price Paid" min="0">
                        <mat-error *ngIf="form.get('pricePaid').errors?.min">
                            Cost cannot be negative
                        </mat-error>
                    </mat-form-field>

                    <!-- Ammount Paid field -->
                    <mat-form-field fxFlex="33" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput type="number" formControlName="ammountPaid" placeholder="Ammount Paid" min="0">
                        <mat-error *ngIf="form.get('ammountPaid').errors?.min">
                            Cost cannot be negative
                        </mat-error>
                    </mat-form-field>

                    <!-- Currency field -->
                    <mat-form-field fxFlex="33" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput type="text" formControlName="currency" placeholder="currency">
                    </mat-form-field>
                </div>

                <!-- Product field -->
                <mat-form-field>
                    <mat-select formControlName="product" placeholder="product">
                        <mat-option *ngFor="let product of products" [value]="product.id">
                            {{productTranslations.get(product.name)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Campi Card sub -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="subscriptionType==='CARD'">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Card Subscription Fields
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" class="responsive-mat-form mat-elevation-z0" >
                        <!-- CustomerId field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="customerId" placeholder="CustomerId">
                        </mat-form-field>
                        <!-- PaymentId field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="paymentId" placeholder="PaymentId">
                        </mat-form-field>
                        <!-- SubscriptionId field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="subscriptionId" placeholder="SubscriptionId">
                        </mat-form-field>
                        <!-- PaymentReceipt field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="paymentReceipt" placeholder="PaymentReceipt">
                        </mat-form-field>
                        <!-- endDateDisplay field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput [max]="form.get('endDateDisplay').value" [matDatepicker]="endDateDisplayPicker" formControlName="endDateDisplay"
                                placeholder="End Date Display" (click)="endDateDisplayPicker.open()" readonly="true">
                            <mat-datepicker-toggle matSuffix [for]="endDateDisplayPicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDateDisplayPicker></mat-datepicker>
                            <button mat-button *ngIf="form.get('endDateDisplay').value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="form.get('endDateDisplay').setValue(null)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-error *ngIf="form.get('endDateDisplay').errors?.required">
                                Campo obbligatorio
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <!-- Campi Crypto sub -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="subscriptionType==='CRYPTO'">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Crypto Subscription Fields
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" class="responsive-mat-form mat-elevation-z0" >
                        <!-- InvoiceId field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="invoiceId" placeholder="InvoiceId">
                        </mat-form-field>
                        <!-- TransactionId field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="transactionId" placeholder="TransactionId">
                        </mat-form-field>
                        <!-- CryptoAmount field -->
                        <mat-form-field fxFlex>
                            <input matInput type="number" formControlName="cryptoAmount" placeholder="CryptoAmount">
                        </mat-form-field>
                        <!-- TransactionCurrency field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="transactionCurrency" placeholder="TransactionCurrency">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <!-- Payment details -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="subscriptionType==='CARD' || subscriptionType==='CRYPTO'">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Payment Details Fields
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" class="responsive-mat-form mat-elevation-z0" >
                        <!-- Email field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="email" placeholder="Email">
                        </mat-form-field>
                        <!-- Country field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="country" placeholder="Country">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <!-- Campi Promotion sub -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="subscriptionType==='PROMOTION'">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Promotion Subscription Fields
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" class="responsive-mat-form mat-elevation-z0" >
                        <!-- PromotionCode field -->
                        <mat-form-field fxFlex>
                            <input matInput type="text" formControlName="promotionCode" placeholder="PromotionCode">
                        </mat-form-field>
                        <!-- Trial field -->
                        <mat-checkbox formControlName="trial" labelPosition="before" style="margin-bottom: 10px;">
                            Trial</mat-checkbox>
                        <!-- Free field -->
                        <mat-checkbox formControlName="free" labelPosition="before" style="margin-bottom: 10px;">
                            Free</mat-checkbox>
                    </div>
                </mat-expansion-panel>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/subscription')" color="primary">Back</button>
            <button mat-raised-button (click)="subscriptionSave()" [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <button mat-raised-button (click)="subscriptionDelete()" [disabled]="subscription?.id===null">
                Delete
            </button>
            <button mat-raised-button (click)="showExtensions()" [disabled]="subscription?.id===null">
                {{((extensions)? 'Hide' : 'Show') + ' Extensions'}}
            </button>
        </mat-card-actions>

        <div fxLayout="column" *ngIf="extensions">
            <app-subscription-extension-table [subscriptionId]="subscriptionId"></app-subscription-extension-table>
        </div>

    </mat-card-content>
</mat-card>