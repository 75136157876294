import { Pageable } from './../../shared/dto/Pageable.types';
import { ProductService } from '../../services/be-subscriptions/product/product.service';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentCacheService } from '../../services/component-cache.service';
import { NavigatorService } from '../../services/navigator.service';
import { PromotionService } from '../../services/be-subscriptions/promotion/promotion.service';
import { GenericListComponent } from '../../shared/GenericListCoumponent';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent extends GenericListComponent  implements OnInit, OnDestroy {

  productsName: Map<number, string> = new Map();

  products: ProductDTO[];
  productTranslations: Map<string, string> = ProductDTO.translations;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private promotionService: PromotionService,
    private productService: ProductService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
) {
  super(navigatorService,
    componentCacheService,
    dateAdapter,
    router,
    snackBar,
    timestampFormatPipe);
  this.displayedColumns = ['id', 'code', 'description', 'product', 'days', 'months', 'active', 'detail'];
  this.parameters = {
    dataSource: [],
    showList: false,
    idFilter: undefined,
    codeFilter: undefined,
    descriptionFilter: undefined,
    products: [],
    productIds: [],
    active: undefined,
    sort: undefined,
    page: 0,
    size: 50,
    length: 0
  };
}

  list(): void {
    if (this.parameters.products?.length) {
      this.parameters.productIds = this.parameters.products.map(product => product.id);
    }
    combineLatest([this.productService.readAll(), this.promotionService.readAll(
      {page: this.parameters.page, size: this.parameters.size, sort: this.fixSort(this.parameters.sort)} as Pageable,
      this.parameters.idFilter, this.parameters.codeFilter, this.parameters.descriptionFilter,
      this.parameters.productIds, this.parameters.active
    )])
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(([products, promotions]) => {
      this.products = products;
      for(const product of products){
        this.productsName.set(product.id, ProductDTO.translations.get(product.name));
      }
      this.parameters.dataSource = promotions.content;
      this.parameters.length = promotions.totalElements;
      this.parameters.showList = true;
      this.parameters.page = promotions.page;
      }
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  changeBool(value: boolean, field: string): void{
    if(value === undefined){
      this.parameters[field] = true;
    } else if (value) {
      this.parameters[field] = false;
    } else {
      this.parameters[field] = undefined;
    }
  }

  private fixSort(sort: any): any {
    return sort?.replace('product', 'idProduct');
  }
}
