import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AffiliationReportDTO } from 'src/app/shared/dto/affiliation-report/AffiliationReportDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable()
export class AffiliationReportService extends GenericService<AffiliationReportDTO, number> {


  url: string;


  constructor(http: HttpClient) {
    super(http);
    // this.url = 'http://localhost:30900/report/admin';
    this.url = environment.urlPrefix + '/arzakena-be-affiliation/report/admin';
  }

  getTheReportByInfluencer(influencerId: number, date?: Date): Observable<AffiliationReportDTO> {
    const params = {};
    if(date){
      params['date'] = new Date(new Date(date).getTime()).toISOString();
    }
    return this.http.get<AffiliationReportDTO>(`${this.url}/reportByInfluencer/${influencerId}`, {params: params});
  }

  getTheReportByCode(codeId: number, date?: Date): Observable<AffiliationReportDTO> {
    const params = {};
    if(date){
      params['date'] = new Date(new Date(date).getTime()).toISOString();
    }
    return this.http.get<AffiliationReportDTO>(`${this.url}/reportByCode/${codeId}`, {params: params});
  }
}
