<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Fear & Greed Value Visualization</h2>
        <p>Imposta il filtri</p>
        <div fxLayout="column" class="responsive-mat-form">

            <mat-form-field>
                <input matInput type="number" [(ngModel)]="parameters.samplingNumber" placeholder="Giorni da analizzare" min="2"/>
            </mat-form-field>

        </div>
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <input matInput type="number" [(ngModel)]="numberOfDays" placeholder="Giorni di cui prendere Fear & Greed" min="2"/>
            </mat-form-field>
        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button"
                (click)="parameters.samplingNumber !== null ? list() : error() " name="Search"
                color="primary">Search</button>
            <button mat-raised-button (click)="reload()" color="primary">Reload Fear & Greed</button>
            <button mat-raised-button (click)="back('/')" color="primary">Back</button>
        </mat-card-actions>
        <div id="chartdiv"></div>
        <br/>
    </mat-card-content>
</mat-card>