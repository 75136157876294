import { FaqDTO } from './../../shared/dto/help-desk/Faq';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from 'src/app/shared/GenericService';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FaqService extends GenericService<FaqDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-helpdesk/faq';
  }

  public faqList(category: string, question: string, userLocale: string): Observable<FaqDTO> {
    const params = {};
    if (category != null) { params['category'] = category; }
    if (question != null) { params['question'] = question; }
    return this.http.get<FaqDTO>(`${this.url}/listByParameters/` + userLocale, {
      params: params
    });
  }

  public categoryList(userLocale: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/listCategory/` + userLocale, {
    });
  }

}
