import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GestioneAffiliationService } from 'src/app/services/gestione-affiliation/gestione-affiliation.service';

@Component({
  selector: 'app-social-form',
  templateUrl: './social-form.component.html',
  styleUrls: ['./social-form.component.scss']
})
export class SocialFormComponent implements OnInit, OnDestroy {

  @Input() socialForm: FormGroup;
  @Input() index: number;


  socialTypeList: string[];
  favorite = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private gestioneAffiliationService: GestioneAffiliationService) {
    this.socialTypeList = [
      'TELEGRAM',
      'INSTAGRAM',
      'DISCORD',
      'REDDIT',
      'LINKEDIN',
      'YOUTUBE',
      'TWITCH',
      'TWITTER',
      'FACEBOOK',
      'OTHER'
    ];
    gestioneAffiliationService.resetFavorites
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.favorite = false;
        this.socialForm.get('favorite').setValue(false);
      });
  }

  ngOnInit(): void {
  }

  deleteSocial(): void {
    this.gestioneAffiliationService.deleteSocial(this.index);
  }

  canShowError(controlName: string): boolean{
    const control = this.socialForm.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  hasError(controlName: string, errorProp: string): void {
    const control = this.socialForm.get(controlName);
    return control.errors[errorProp];
  }

  modificaPreferito(activate: boolean): void {
    if (activate) {
      this.gestioneAffiliationService.cleanAll();
      this.favorite = true;
      this.socialForm.get('favorite').setValue(true);

    } else {
      this.favorite = false;
      this.socialForm.get('favorite').setValue(false);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
