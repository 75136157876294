<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Timeseries Data Visualization</h2>
        <p>Imposta il filtri</p>
        <div fxLayout="column" class="responsive-mat-form">

            <mat-form-field>
                <input matInput [(ngModel)]="parameters.symbol" placeholder="symbol" />
            </mat-form-field>

            <!-- Responsive -->
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                <!-- DataDa field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.timestampTo"
                        [(ngModel)]="parameters.timestampFrom" [matDatepicker]="picker" placeholder="Scegli Timestamp From">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="parameters.timestampFrom" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.timestampFrom=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <!-- DataA field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.timestampFrom"
                        [(ngModel)]="parameters.timestampTo" [matDatepicker]="picker2" placeholder="Scegli TimestampTo">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <button mat-button *ngIf="parameters.timestampTo" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.timestampTo=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <mat-form-field>
                <input matInput type="number" [(ngModel)]="parameters.samplingNumber" placeholder="SamplingNumber" min="1"/>
            </mat-form-field>

            <mat-form-field>
                <mat-select matInput [(ngModel)]="parameters.samplingUnit" placeholder="Sampling Unit">
                    <mat-option *ngFor="let action of actions" [value]="action.value">
                        {{action.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button"
                (click)="parameters.symbol != null || parameters.timestampFrom != null || parameters.timestampTo != null  ? list() : error() " name="Search"
                color="primary">Search</button>
            <button mat-raised-button (click)="back('/')" color="primary">Back</button>
        </mat-card-actions>
        <div id="chartdiv"></div>
        <br/>
    </mat-card-content>
</mat-card>