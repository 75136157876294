import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { WarningEventService } from 'src/app/services/core-errors-handling/warning-event.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { AsyncProcessWarningEventDTO } from 'src/app/shared/dto/core-error-handling/warningDto/asyncProcessWarningsEventDto';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';

@Component({
  selector: 'app-async-process-warning-event',
  templateUrl: './async-process-warning-event.component.html',
  styleUrls: ['./async-process-warning-event.component.scss']
})
export class AsyncProcessWarningEventComponent extends GenericListComponent  implements OnInit, OnDestroy  {

  constructor(
    private warningService: WarningEventService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
) {

  super(navigatorService,
    componentCacheService,
    dateAdapter,
    router,
    snackBar,
    timestampFormatPipe);
    this.displayedColumns = ['source', 'timestamp', 'warningClass',  'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      source: null,
      dataDa: null,
      dataA:null,
      warningClass:null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
    };
}

list(): void{
this.warningService.warningEventList(
  this.parameters.page,
  this.parameters.size,
  this.parameters.sort,
  this.parameters.source,
  this.parameters.dataDa,
  this.parameters.dataA,
  this.parameters.warningClass
).subscribe((response: PageDTO<AsyncProcessWarningEventDTO>) => {
  console.log('response : ' , response);
  this.parameters.dataSource = response.content;
  this.parameters.page = response.page;
  this.parameters.length = response.totalElements;
  this.parameters.showList = true;
  });
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}

ngOnInit(): void {
  super.ngOnInit();
}
}
