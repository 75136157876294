import { ChangeLogDto } from './../../shared/dto/change-log/ChangeLog.types';
import { ChangeLogService } from './../../services/change-log/change-log.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { disclaimerTypes } from 'src/app/shared/dto/disclaimer/DisclaimerType.types';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';

@Component({
  selector: 'app-change-log-detail',
  templateUrl: './change-log-detail.component.html',
  styleUrls: ['./change-log-detail.component.scss'],
  providers: [
    ChangeLogService
  ]
})
export class ChangeLogDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly disclaimerTypes = disclaimerTypes;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private changeLogService: ChangeLogService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    if(this.detailId()) {
      this.read(this.detailId());
    } else {
      this.form = this.loadForm(new ChangeLogDto());
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toChangeLogDto());
      return;
    }
    this.create(this.toChangeLogDto());
  }

  deleteChangeLog(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare il change log?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }

  detailId(): number {
    return +this.activeRoute.snapshot.paramMap.get('id');
  }

  private create(changeLog: ChangeLogDto): void {
    this.changeLogService.create(changeLog).subscribe((responseChangeLog) => {
      this.router.navigateByUrl(this.router.url.replace(this.detailId() + '', responseChangeLog.id + ''));
      this.form = this.loadForm({...new ChangeLogDto(), ...responseChangeLog});
    });
  }

  private update(changeLog: ChangeLogDto): void {
    this.changeLogService.update(changeLog).subscribe(() => this.read(this.detailId()));
  }

  private read(id: number): void {
    this.changeLogService.read(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(changeLog => this.form = this.loadForm({...new ChangeLogDto(), ...changeLog}));
  }

  private delete(id: number): void {
    this.changeLogService.delete(id).subscribe(() => this.router.navigate(['/changeLogs']));
  }
  private loadForm(changeLog: ChangeLogDto): FormGroup {
    return this._formBuilder.group({...changeLog, ...this.formValidators(changeLog)});
  }

  private toChangeLogDto(): ChangeLogDto {
    return this.form.getRawValue() as ChangeLogDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(changeLog: ChangeLogDto) {
    return {
        version: [changeLog.version, [Validators.required, Validators.maxLength(100)]],
        date: [changeLog.date, Validators.required]
    };
  }
}
