import { takeUntil } from 'rxjs/operators';
import { PriceExchangeDTO } from './../../../shared/dto/be-subscriptions/price-exchange/PriceExchangeDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NavigatorService } from './../../../services/navigator.service';
import { PriceExchangeService } from './../../../services/be-subscriptions/price-exchange/price-exchange.service';
import { Subject } from 'rxjs';
import { GenericDetailComponent } from './../../../shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-price-exchange-detail',
  templateUrl: './price-exchange-detail.component.html',
  styleUrls: ['./price-exchange-detail.component.scss']
})
export class PriceExchangeDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  priceExchangeId: number;
  priceExchange: PriceExchangeDTO;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private priceExchangeService: PriceExchangeService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      usd: new FormControl('', [Validators.required, Validators.min(0)]),
      eur: new FormControl('', [Validators.required, Validators.min(0)]),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.priceExchangeId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.priceExchangeId !== 0 && this.priceExchangeId !== null) {
      this.priceExchangeRead();
    } else {
      this.priceExchange = new PriceExchangeDTO();
    }
  }

  priceExchangeSave(): void {
    if (this.priceExchange.id == null) {
      this.priceExchangeCreate();
    } else {
      this.priceExchangeUpdate();
    }
  }

  //READ

  priceExchangeRead(): void {
    this.priceExchangeService.read(this.priceExchangeId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: PriceExchangeDTO) => {
          console.log('response : ', res);
          this.priceExchange = res;
          this.priceExchangeId = res.id;
          this.dtoToForm();
        }
      );
  }

  //CREATE

  priceExchangeCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.priceExchangeService.create(this.priceExchange)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: PriceExchangeDTO) => {
            console.log('response : ', res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.priceExchange = res;
            console.log(res);
            this.priceExchangeId = this.priceExchange.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/price-exchange/detail/' + res.id);
          }
        );
    }
  }

  // UPDATE

  priceExchangeUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere il price exchange?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.priceExchangeService.update(this.priceExchange)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ', res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.priceExchangeRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  priceExchangeDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare il price exchange?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.priceExchangeService.delete(this.priceExchange.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ', res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/price-exchange']);
                }
              );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {
    this.priceExchange.usd = this.form.get('usd').value;
    this.priceExchange.eur = this.form.get('eur').value;

  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.priceExchange.id);
    this.form.get('usd').setValue(this.priceExchange.usd);
    this.form.get('eur').setValue(this.priceExchange.eur);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.priceExchange.insertDate));
    this.form.get('insertUser').setValue(this.priceExchange.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.priceExchange.updateDate));
    this.form.get('updateUser').setValue(this.priceExchange.updateUser);
    this.form.get('optLock').setValue(this.priceExchange.optLock);
    this.form.markAsPristine();
  }

}
