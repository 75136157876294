<div fxLayout="row" class="card-container">
    <mat-card class="card">
        <mat-card-title>Total Affiliates</mat-card-title>
        <mat-card-content [ngClass]="{'apply-margin': applyMargin}" class="content">
            {{ affiliationReport?.totalAffiliates }}
        </mat-card-content>
    </mat-card>

    <mat-card class="card">
        <mat-card-title>Affilates Paying</mat-card-title>
        <mat-card-content [ngClass]="{'apply-margin': applyMargin}" class="content">
            {{ affiliationReport?.totalAffiliatesPaying }}
        </mat-card-content>
    </mat-card>

    <mat-card class="card">
        <mat-card-title>Affilates on Trial</mat-card-title>
        <mat-card-content [ngClass]="{'apply-margin': applyMargin}" class="content">
            {{ affiliationReport?.totalAffiliatesOnTrial }}
        </mat-card-content>
        
    </mat-card>


    <mat-card class="card">
        <mat-card-title>Affilates on Free Sub</mat-card-title>
        <mat-card-content [ngClass]="{'apply-margin': applyMargin}" class="content">
            {{ affiliationReport?.totalAffiliatesWithFreeSubscription }}
        </mat-card-content>
    </mat-card>

    <mat-card class="card">
        <mat-card-title>Affilates with a Promo</mat-card-title>
        <mat-card-content [ngClass]="{'apply-margin': applyMargin}" class="content">
            {{ affiliationReport?.totalAffiliatesPromoCode }}
        </mat-card-content>
    </mat-card>

    <mat-card class="card">
        <mat-card-title>Affiliates without Sub</mat-card-title>
        <mat-card-content [ngClass]="{'apply-margin': applyMargin}" class="content">
            {{ affiliationReport?.affilatesNotPaying }}
        </mat-card-content>
    </mat-card>
</div>