<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <div fxLayout="column">
      <mat-card class="mat-elevation-z0" [ngClass]="'content-'+environmentName">
        <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
          <!-- Avatar + Nome/Cognome -->
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" style="margin-right: 15px;">
              <ngx-avatar size="65" bgColor="#fef2e4" fgColor="#000000"
                name="{{collaboratore?.nome}} {{collaboratore?.cognome}}"></ngx-avatar>
            </div>
            <div fxLayout="column" style="margin-bottom: -10px;">
              <mat-card-title style="font-size: 45px;">{{collaboratore?.nome}} {{collaboratore?.cognome}}
              </mat-card-title>
              <mat-card-subtitle style="font-size: 30px; margin-top: -15px;">{{ today | date :'dd/MM/yyyy' }}
              </mat-card-subtitle>
            </div>
          </div>
          <!-- Crtyto-widget -->
          <div fxLayout="row">
            <app-crypto-widget *ngIf="isActive('gt-sm')"></app-crypto-widget>
          </div>
        </mat-card-content>
      </mat-card>

      <div>
        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex" #tabs>
          <!-- TAB  HOME-->
          <mat-tab label="Home" aria-label="Home">
            <generic-data></generic-data>
          </mat-tab>
          <!-- TAB PROVIDER-->
          <mat-tab label="Provider" aria-label="Provider">
            <provider-component></provider-component>
          </mat-tab>
          <!-- TAB POSITIONS-->
          <mat-tab label="Wallets & Exchanges" aria-label="Wallets & Exchanges">
            <positions-component></positions-component>
          </mat-tab>
          <!-- TAB  ERRORS-->
          <mat-tab label="Errors" aria-label="Errors">
            <errors-component></errors-component>
          </mat-tab>
          <!-- TAB  Timeseries-->
          <mat-tab label="Timeseries" aria-label="Timeseries">
            <timeseries-component></timeseries-component>
          </mat-tab>
          <!-- TAB  Catalog-->
          <mat-tab label="Catalog" aria-label="Catalog">
            <catalog-component></catalog-component>
          </mat-tab>
          <!-- TAB  SEGNALAZIONI-->
          <mat-tab label="Segnalazioni" aria-label="Segnalazioni">
            <segnalazioni-component></segnalazioni-component>
          </mat-tab>
          <!-- TAB  AFFILIATION -->
          <mat-tab label="Affiliation" aria-label="Affiliation">
            <affiliation-component></affiliation-component>
          </mat-tab>
          <!-- TAB  SUBSCRIPTIONS -->
          <mat-tab label="Subscription" aria-label="Subscription">
            <subscriptions-component></subscriptions-component>
          </mat-tab>
          <!-- TAB  NOTIFICATIONS -->
          <mat-tab label="Notification" aria-label="Notification">
            <home-notifications-component></home-notifications-component>
          </mat-tab>
          <!-- TAB ALTRO -->
          <mat-tab label="Altro" aria-label="Altro">
            <altro-component></altro-component>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-card-content>
</mat-card>