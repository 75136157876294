<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione affiliaton</h2>
    <p>Imposta il filtro di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">

      <mat-form-field>
        <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
      </mat-form-field>
      
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/gestione-affiliation/detail/0']">New</button>
      <button mat-raised-button (click)="back('/')" color="primary">Back</button>
    </mat-card-actions>
    <br />

    <div *ngIf="parameters.showList" fxLayout="column">
      <h2>Lista Influencer</h2>
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
        matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- displayName Column -->
        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> displayName </th>
          <td mat-cell *matCellDef="let influencer"> {{influencer.displayName}} </td>
        </ng-container>

        <!-- logo Column -->
        <ng-container matColumnDef="logo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Logo </th>
          <td mat-cell *matCellDef="let influencer"> {{influencer.logo}} </td>
        </ng-container>

        <!-- banner Column -->
        <ng-container matColumnDef="banner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Banner </th>
          <td mat-cell *matCellDef="let influencer"> {{influencer.banner}} </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let asset">
            <button mat-mini-fab color="primary" [routerLink]="['/gestione-affiliation/detail/', asset.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
          (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>