import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigatorService } from '../services/navigator.service';
import { WeisscryptoAssetMappingConfigurationService } from '../services/weisscrypto-catalog/asset-mapping-configuration.service';
import { WeisscryptoAssetMappingConfigurationDTO } from '../shared/dto/weisscrypto-catalog/weisscryptoAssetMappingConfigurationDto';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-weisscrypto-catalog-detail',
  templateUrl: './weisscrypto-catalog-detail.component.html',
  styleUrls: ['./weisscrypto-catalog-detail.component.scss']
})
export class WeisscryptoCatalogDetailComponent extends GenericDetailComponent implements OnInit {
  assetMappingConfigurationId: number;
  assetMappingConfiguration: WeisscryptoAssetMappingConfigurationDTO;
   //select per l'enum
   actions  = [
    {value: 'SKIP', viewValue: 'Skip'},
    {value: 'PASS', viewValue: 'Pass'},
    {value: 'CONVERT', viewValue: 'Convert'}
  ];


  constructor(
    private assetMappingConfigurationService: WeisscryptoAssetMappingConfigurationService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        cryptoId: new FormControl('', Validators.required),
        weissSymbol: new FormControl('', Validators.required),
        weissName: new FormControl('', Validators.required),
        arzakenaSymbol: new FormControl(''),
        action: new FormControl('', Validators.required),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
      });
  }

  ngOnInit(): void {
    this.assetMappingConfigurationId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.assetMappingConfigurationId !== 0 && this.assetMappingConfigurationId != null) {
      this.assetMappingConfigurationRead();
    } else {
      this.assetMappingConfiguration = new WeisscryptoAssetMappingConfigurationDTO();
    }

  }

  assetMappingConfigurationSave(): void {
    if (this.assetMappingConfiguration.id == null) {
      this.assetMappingConfigurationCreate();
    } else {
      this.assetMappingConfigurationUpdate();
    }
  }

  //READ

  assetMappingConfigurationRead(): void {
    this.assetMappingConfigurationService.read(this.assetMappingConfigurationId).subscribe(
      (res: WeisscryptoAssetMappingConfigurationDTO) => {
        console.log('response : ' , res);
        this.assetMappingConfiguration = res;
        this.assetMappingConfigurationId = res.id;
        this.dtoToForm();
      }
    );
  }

  //CREATE

  assetMappingConfigurationCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.assetMappingConfigurationService.create(this.assetMappingConfiguration).subscribe(
        (res: WeisscryptoAssetMappingConfigurationDTO) => {
          console.log('response : ' , res);
          this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
          this.assetMappingConfiguration = res;
          console.log(res);
          this.assetMappingConfigurationId = this.assetMappingConfiguration.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }
      );
    }
  }

  // UPDATE

  assetMappingConfigurationUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere l\'asset mapping configuration?').subscribe((result) => {
        if (result) {
          this.formToDto();
          this.assetMappingConfigurationService.update(this.assetMappingConfiguration).subscribe(
            (res) => {
              console.log('response : ' , res);
              this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
              this.assetMappingConfigurationRead();
              this.form.markAsPristine();
            }
          );
        }
      });
    }
  }

  //DELETE

  assetMappingConfigurationDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare l\'asset mapping configuration?').subscribe((result) => {
        if (result) {
          this.formToDto();
          this.assetMappingConfigurationService.delete(this.assetMappingConfiguration.id).subscribe(
            (res) => {
              console.log('response : ' , res);
              this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
              this.router.navigate(['/weisscryptoAssetMappingConfiguration']);
            }
          );
        }
      });
    }
  }




  private formToDto(): void {
    this.assetMappingConfiguration.cryptoId = this.form.get('cryptoId').value;
    this.assetMappingConfiguration.weissSymbol = this.form.get('weissSymbol').value;
    this.assetMappingConfiguration.weissName = this.form.get('weissName').value;
    this.assetMappingConfiguration.arzakenaSymbol = this.form.get('arzakenaSymbol').value;
    this.assetMappingConfiguration.action = this.form.get('action').value;
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.assetMappingConfiguration.id);
    this.form.get('cryptoId').setValue(this.assetMappingConfiguration.cryptoId);
    this.form.get('weissSymbol').setValue(this.assetMappingConfiguration.weissSymbol);
    this.form.get('weissName').setValue(this.assetMappingConfiguration.weissName);
    this.form.get('arzakenaSymbol').setValue(this.assetMappingConfiguration.arzakenaSymbol);
    this.form.get('action').setValue(this.assetMappingConfiguration.action);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.assetMappingConfiguration.insertDate));
    this.form.get('insertUser').setValue(this.assetMappingConfiguration.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.assetMappingConfiguration.updateDate));
    this.form.get('updateUser').setValue(this.assetMappingConfiguration.updateUser);
    this.form.get('optLock').setValue(this.assetMappingConfiguration.optLock);
    this.form.markAsPristine();
  }

}


