<div fxLayout="column">
    <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [dataSource]="parameters.dataSource"
        matSort (matSortChange)="setSort($event)" matSortDisableClear>
        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
            <td mat-cell *matCellDef="let extension"> {{extension.id}} </td>
        </ng-container>

        <!-- idSub Column -->
        <ng-container matColumnDef="subscription.id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> subscription id </th>
            <td mat-cell *matCellDef="let extension"> {{extension.subscriptionId}} </td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="promotionCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> promotion code </th>
            <td mat-cell *matCellDef="let extension"> {{extension.promotionCode}} </td>
        </ng-container>

        <!-- affiliationPerk Column -->
        <ng-container matColumnDef="affiliationPerk">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> affiliation perk </th>
            <td mat-cell *matCellDef="let extension"> {{extension.affiliationPerk}} </td>
        </ng-container>

        <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let extension">
                <button mat-mini-fab color="primary" [routerLink]="['/subscription-extension/detail/', extension.id]">
                    <mat-icon>search</mat-icon>
                </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
            (page)="setPaginator($event)"></mat-paginator>
    </div>
</div>