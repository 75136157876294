<mat-card class="button-dimension">
    <mat-card-actions>
         <div>
            <button mat-button fxFlex [routerLink]="btnLink" class="customButtonPadding">
                <mat-card-title>
                    <span class="centered-text">{{ btnTxt }}</span>
                </mat-card-title>
                <mat-icon class="icon-font-size">{{btnIcon}}</mat-icon>
            </button>
        </div>
    </mat-card-actions>
</mat-card>