import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { AdvertisementDto } from './AdvertisementDto.types';

export class TranslationDto extends LogicalDeleteDTO {

	/**
	 * Translations id
	 */
	id: number;

	/**
	 * Title of the translations
	 */
	title: string;

	/**
	 * Button of the translations
	 */
	button: string;


	redirectUrl: string;


    /**
     *  Description of the translations
     */
	description: string;

	/**
	 * Language for the translations
	 */
	language: string;

	/**
	 * Referenced message from the translation
	 */
	advertisementId: number;

    constructor(adv?: AdvertisementDto) {
        super();
        this.id = undefined;
		this.title = '';
        this.button = '';
        this.description = '';
		this.language = '';
		this.advertisementId = adv?.id;
    }
}
