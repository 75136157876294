import { TraceModificationDTO } from './../../basic/traceModificationDTO';

export class UserSubscriptionsDTO extends TraceModificationDTO {

    id: number;

	username: string;

	active: boolean;

	renewable: boolean;

	promotionTries: number;

	trialUsed: boolean;

	freeUsed: boolean;

	idInfluencerAffiliation: number;

	idCodeAffiliation: number;

	sevenDaysAgo: number;

	sixDaysAgo: number;

	fiveDaysAgo: number;

	fourDaysAgo: number;

	threeDaysAgo: number;

    twoDaysAgo: number;

	oneDayAgo: number;

	lastCapitalUpdate: Date;

	nextCapitalUpdate: Date;

}
