import { List } from '@amcharts/amcharts4/core';
import { TraceModificationDTO } from '../basic/traceModificationDTO';
import { CodeDTO } from '../code/CodeDTO';
import { SocialDTO } from './SocialDTO';

export class InfluencerDTO extends TraceModificationDTO {

    id: number;

    displayName: string;

    logo: string;

	banner: string;

    logoDark: string;

    bannerDark: string;

	logoNoFill: string;

    socials: List<SocialDTO>;

    codes: List<CodeDTO>;
}
