import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { CorrectedUserDTO } from 'src/app/shared/dto/config/correctedUser';
import { GestioneAffiliationService } from 'src/app/services/gestione-affiliation/gestione-affiliation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html'
})
export class UsersDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  influencerId: number = null;
  nameValue: string;
  user: string;
  users: string[];
  checkUsers1: boolean;
  checkUsers2: boolean;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private gestioneAffiliationService: GestioneAffiliationService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    this.form = this.loadForm(new CorrectedUserDTO());
    this.influencerId = Number(this.activeRoute.snapshot.paramMap.get('influencerId'));
    this.getUsers();
    console.log(this.checkUsers1, this.checkUsers2);
    this.form.controls['inputUsername'].setValue(String(this.activeRoute.snapshot.paramMap.get('username')));
  }


  addUser(): void {
    this.form.markAsPristine();
    this.gestioneAffiliationService.addUser(this.influencerId, this.form.get('inputUsername')?.value).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      () => this.router.navigate(['/gestione-affiliation/detail/' + this.influencerId])
    );
  }
  deleteUser(): void {
    this.form.markAsPristine();
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare lo user?')
      .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.gestioneAffiliationService.deleteUser(this.influencerId, this.form.get('inputUsername')?.value)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
              (res) => {
                console.log('response : ' , res);
                this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
              }
            );
            console.log('entrato dopo viene il back');
            this.back('gestione-affiliation/detail/' + this.influencerId);
          }
        });
    }
  }

  getUsers(): void {
    this.user = String(this.activeRoute.snapshot.paramMap.get('username'));
    this.gestioneAffiliationService.readUser(this.influencerId)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((res) => {
      this.users = res;
    });
  }

  setBoolean1(event: boolean): void {
    this.checkUsers1 = event;
  }

  setBoolean2(event: boolean): void {
    this.checkUsers2 = event;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }


  private loadForm(user: CorrectedUserDTO): FormGroup {
    return this._formBuilder.group({...user, ...this.formValidators(user)});
  }


  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(user: CorrectedUserDTO) {
    return {
        inputUsername: [user.username, [Validators.required, Validators.maxLength(255)]]
    };
  }
}
