<div>

    <div>
        <div fxLayout="column" fxLayoutAlign=" start">
            <div class="content" [innerText]="data.content">
            </div>

            <div class="fixed_date_left">
                {{data.insertDate | date :'dd/MM/yyyy hh:mm a'}}
            </div>
        </div>
    </div>



    <div *ngIf="(replyList$ | async) as replys" fxlayout="row">

        <div *ngFor="let reply of replys">
            <div *ngIf="reply.insertUser === data.insertUser" fxLayout="column" fxLayoutAlign=" start">
                <div class="fixed_left" [innerText]="reply.content">
                </div>

                <div class="fixed_date_left">
                    {{reply.insertDate | date :'dd/MM/yyyy hh:mm a'}}
                </div>

            </div>

            <div *ngIf="reply.insertUser !== data.insertUser" fxLayout="column" fxLayoutAlign=" end">
                <div class="fixed_right" [innerText]="reply.content">
                </div>
                <div class="fixed_date_right">
                    {{reply.insertDate | date :'dd/MM/yyyy hh:mm a'}}
                </div>
            </div>
        </div>

    </div>
    <br>

    <!-- Message field -->
    <div class="div-form" style="display: inline-block" *ngIf="data.status!=='CHIUSA'">
        <form [formGroup]="form" fxLayout="row" fxLayoutAlign="space-evenly center">
            <mat-form-field class="mat-form-field-width" fxLayoutAlign=" center" appearance="fill">
                <textarea [rows]="6" matInput formControlName="message" maxlength="4096" #replyContent
                    placeholder="Scrivi un messaggio">
                </textarea>
                <mat-hint>{{replyContent.value.length}} / 4096</mat-hint>
            </mat-form-field>

            <div fxLayoutAlign=" center">
                <button mat-icon-button (click)="sendMessage()"
                    [disabled]="form.pristine || form.invalid || form.get('message').value === null">
                    <mat-icon>send</mat-icon>
                </button>

            </div>
        </form>
    </div>
    <div *ngIf="data.status==='CHIUSA'">
        TICKET CHIUSO, NON È POSSIBILE RISPONDERE

    </div>

</div>