import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GestioneAffiliationService } from '../services/gestione-affiliation/gestione-affiliation.service';
import { NavigatorService } from '../services/navigator.service';
import { InfluencerDTO } from '../shared/dto/influencer/InfluencerDTO';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';
import { UserDTO } from '../shared/dto/config/user';

@Component({
  selector: 'app-asset-mapping-configuration-detail',
  templateUrl: './gestione-affiliation-detail.component.html',
  styleUrls: ['./gestione-affiliation-detail.component.scss']
})
export class GestioneAffiliationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  id: number;
  gestioneAffiliation: InfluencerDTO;
  influencer: InfluencerDTO;
  socialsForm: FormArray;
  nameValue: string = '';
  addedUser: UserDTO;
  users: string[];
  usersLoad: boolean;
  selectedTabIndex: number = 0;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private gestioneAffiliationService: GestioneAffiliationService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);

    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      displayName: new FormControl('', [Validators.required, Validators.maxLength(64)]),
      logo: new FormControl('', [Validators.pattern(/^(https:)([/|.|\w|\s|-])*\.(?:svg)$/), Validators.maxLength(256)]),
      banner: new FormControl('', [Validators.pattern(/^(https:)([/|.|\w|\s|-])*\.(?:svg|png|jpg|jpeg)$/), Validators.maxLength(256)]),
      logoDark: new FormControl('', [Validators.pattern(/^(https:)([/|.|\w|\s|-])*\.(?:svg)$/), Validators.maxLength(256)]),
      bannerDark: new FormControl('',
        [Validators.pattern(/^(https:)([/|.|\w|\s|-])*\.(?:svg|png|jpg|jpeg)$/), Validators.maxLength(256)]
        ), insertDate: new FormControl({ value: '', disabled: true }),
      logoNoFill: new FormControl('', [Validators.pattern(/^(https:)([/|.|\w|\s|-])*\.(?:svg)$/), Validators.maxLength(256)]),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.socialsForm = this._formBuilder.array([
      this._formBuilder.group({
        socialName: ['', [Validators.required, Validators.maxLength(64)]],
        socialType: ['', Validators.required],
        socialLink: ['', [Validators.required, Validators.pattern(
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
          ), Validators.maxLength(256)]],
        favorite: false
      })
    ]);
    this.gestioneAffiliationService.onDeleteSocial
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.deleteSocial.bind(this));
  }

  ngOnInit(): void {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.id !== 0 && this.id != null) {
      this.gestioneAffiliationRead();
      this.getUsers();
    } else {
      this.gestioneAffiliation = new InfluencerDTO();
    }
  }

  gestioneAffiliationSave(): void {
    if (this.gestioneAffiliation.id == null) {
      this.gestioneAffiliationCreate();
    } else {
      this.gestioneAffiliationUpdate();
    }
  }

  getUsers(): void {
    this.gestioneAffiliationService.readUser(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: string[]) => {
          this.users = res;
        }
      );
  }

  //READ

  gestioneAffiliationRead(): void {
    this.gestioneAffiliationService.influencerById(this.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: InfluencerDTO) => {
          this.gestioneAffiliation = res;
          this.dtoToForm();
          res = this.influencer;
        }
      );


  }

  //CREATE

  gestioneAffiliationCreate(): void {
    if (this.form.valid) {
      this.formToDto(false);
      this.gestioneAffiliationService.create(this.gestioneAffiliation)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: InfluencerDTO) => {
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.gestioneAffiliation = res;
            this.dtoToForm();
            this.form.markAsPristine();
            this.id = res.id;
            this.back('/gestione-affiliation/detail/' + res.id);
          }
        );

    }
  }

  // UPDATE

  gestioneAffiliationUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere l\'influencer?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto(true);
            this.gestioneAffiliationService.update(this.gestioneAffiliation)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.gestioneAffiliationRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  gestioneAffiliationDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare l\'influencer?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto(false);
            this.gestioneAffiliationService.delete(this.gestioneAffiliation.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/gestione-affiliation']);
                }
              );
            this.backConfirm('/gestione-affiliation');
          }
        });
    }
  }




  deleteSocial(index: number): void {
    this.socialsForm.removeAt(index);
  }


  addSocial(): void {

    this.socialsForm.push(
      this._formBuilder.group({
        socialName: ['', Validators.required],
        socialType: ['', Validators.required],
        socialLink: ['', Validators.required],
        favorite: false
      })
    );
  }


  canShowError(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  hasError(controlName: string, errorProp: string): any {
    const control = this.form.get(controlName);
    return control.errors[errorProp];
  }

  onTabChange($event): void {

  }


  cleanFormArray(formArray: FormArray): FormArray {
    const a: FormArray = new FormArray([]);
    for (let i = 0; i < formArray.length; i++) {
      if (formArray.at(i).valid) {
        a.push(formArray.at(i));
      }
    }
    return a;
  }

  socialsTrueValidation(formArray: FormArray): boolean {
    for (let i = 0; i < formArray.length; i++) {
      if (formArray.at(i).valid) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }


  private formToDto(isAnUpdate: boolean): void {
    this.gestioneAffiliation.displayName = this.form.get('displayName').value.trim();
    this.gestioneAffiliation.logo = this.form.get('logo').value.trim();
    this.gestioneAffiliation.banner = this.form.get('banner').value.trim();
    this.gestioneAffiliation.logoDark = this.form.get('logoDark').value.trim();
    this.gestioneAffiliation.bannerDark = this.form.get('bannerDark').value.trim();
    this.gestioneAffiliation.logoNoFill = this.form.get('logoNoFill').value.trim();
    if (!isAnUpdate) {
      this.gestioneAffiliation.socials = this.cleanFormArray(this.socialsForm).value;
    }

  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.gestioneAffiliation.id);
    this.form.get('displayName').setValue(this.gestioneAffiliation.displayName);
    this.form.get('logo').setValue(this.gestioneAffiliation.logo);
    this.form.get('banner').setValue(this.gestioneAffiliation.banner);
    this.form.get('logoDark').setValue(this.gestioneAffiliation.logoDark);
    this.form.get('bannerDark').setValue(this.gestioneAffiliation.bannerDark);
    this.form.get('logoNoFill').setValue(this.gestioneAffiliation.logoNoFill);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.gestioneAffiliation.insertDate));
    this.form.get('insertUser').setValue(this.gestioneAffiliation.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.gestioneAffiliation.updateDate));
    this.form.get('updateUser').setValue(this.gestioneAffiliation.updateUser);
    this.form.get('optLock').setValue(this.gestioneAffiliation.optLock);
    this.form.markAsPristine();
  }

}
