import { TraceModificationDTO } from '../basic/traceModificationDTO';


export class CoinmarketcapAssetMappingConfigurationDTO extends TraceModificationDTO {

    id: number;

    cryptoId: string;

    coinmarketcapSymbol: string;

	coinmarketcapName: string;

	arzakenaSymbol: string;

	action: string;

}
