import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-social-form-list',
  templateUrl: './social-form-list.component.html',
  styleUrls: ['./social-form-list.component.scss']
})
export class SocialFormListComponent implements OnInit {

  @Input() socialsForm: FormArray;

  get socialControls(): FormGroup[] {
    return this.socialsForm.controls as FormGroup[];
  }

  constructor() { }

  ngOnInit(): void {
  }
}
