import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { WeisscryptoAssetCacheService } from 'src/app/services/weisscrypto-catalog/asset-cache.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-weisscrypto-asset-cache',
  templateUrl: './weisscrypto-asset-cache.component.html',
  styleUrls: ['./weisscrypto-asset-cache.component.scss']
})
export class WeisscryptoAssetCacheComponent extends GenericListComponent implements OnInit, OnDestroy {

  constructor(
    private assetCacheService: WeisscryptoAssetCacheService,
    protected dialog: MatDialog,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['weissRating.category', 'symbol', 'weissRating.marketPerformance.code', 'weissRating.rating.code', 'weissRating.risk.code', 'delete'];
    this.parameters = {
      symbol: null,
      showList: false
    };
  }

  list(): void {
    this.assetCacheService.assetCacheListBySymbol(
      this.parameters.symbol
    ).subscribe((response: Array<AssetDefinitionDto>) => {
      console.log('response : ' , response);
      this.parameters.dataSource = response;
      this.parameters.showList = true;
    }
    );
  }

  confirm(message: string): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: message
    });
    return dialogRef.afterClosed();
  }

  delete(symbol: string): void {
    console.log('delete dell\' asset cache del symbol: ' + symbol);
    this.confirm('Sei sicuro di voler cancellare l\'asset cache di '+symbol+'?').subscribe((result) => {
      if (result) {
        this.assetCacheService.assetCacheDeleteBySymbol(symbol).subscribe(
          () => {
            this.snackBar.open('Cancellazione di '+ symbol + ' con successo!', null, { duration: 3000 });
          }
        );
      }

    }
    );
  }

  error(): void{
    this.snackBar.open('Valorizzare il filtro prima di effettuare la ricerca!', null, { duration: 3000 });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }



}
