import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { TemplateService } from 'src/app/services/be-notifications/template/template.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { languages } from 'src/app/shared/dto/languages.types';

@Component({
  selector: 'app-notifications-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  providers: [
    TemplateService
  ]
})
export class TemplateComponent extends GenericListComponent implements OnInit, OnDestroy {

  readonly languages = languages;
  readonly channels = ['MAIL', 'BELL', 'POPUP'];

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private templateService: TemplateService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['id', 'bundleKey', 'locale', 'channel', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        bundleKey: undefined,
        locale: undefined,
        channel: undefined,
        page: 0,
        size: 10,
        length: 0
      };
  }

  list(): void{
    this.templateService.readAll(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
      this.parameters.bundleKey, this.parameters.locale, this.parameters.channel)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      this.parameters.dataSource = response.content;
      this.parameters.page = response.page;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
