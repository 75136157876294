import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { InfluencerDTO } from 'src/app/shared/dto/influencer/InfluencerDTO';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GestioneAffiliationService extends GenericService <InfluencerDTO, number> {

  url: string;

  onDeleteSocial: Subject<number>;
  resetFavorites: Subject<boolean>;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-affiliation/influencer/admin';
    this.onDeleteSocial = new Subject<number>();
    this.resetFavorites = new Subject<boolean>();
  }

  public influencerList(page: number, size: number, sort: string, filter: string): Observable<PageDTO<InfluencerDTO>> {
    const params = {};
    if (page != null) {params['page'] = page;}
    if (size != null) {params['size'] = size;}
    if (sort != null) {params['sort'] = sort;}
    if (filter != null) {
      params['filter'] = filter;
      return this.http.get<PageDTO<InfluencerDTO>>(`${this.url}/readById/${filter}`, { params: params });
    } else {
      return this.http.get<PageDTO<InfluencerDTO>>(`${this.url}/readAll`, { params: params });
    }
  }

  public influencerById(id: number): Observable<InfluencerDTO>{
    return this.http.get<InfluencerDTO>(`${this.url}/readById/${id}`);
  }

  public readUser(id: number): Observable<string[]>{
    return this.http.get<string[]>(`${this.url}/listUsers/${id}`);
  }

  public addUser(id: number, username: string): Observable<void>{
    return this.http.put<void>(`${this.url}/addUser/${id}`, username);
  }

  public deleteUser(id: number, username: string): Observable<void>{
    return this.http.put<void>(`${this.url}/deleteUser/${id}`, username);
  }

  deleteSocial(index: number): void {
    this.onDeleteSocial.next(index);
  }

  cleanAll(): void {
    this.resetFavorites.next(true);
    console.log('entrato cleanAll');
  }

}
