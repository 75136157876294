import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { ProductService } from 'src/app/services/be-subscriptions/product/product.service';
import { PromotionService } from 'src/app/services/be-subscriptions/promotion/promotion.service';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { PromotionDTO } from 'src/app/shared/dto/be-subscriptions/promotion/PromotionDTO';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';

@Component({
  selector: 'app-promotion-generation',
  templateUrl: './promotion-generation.component.html',
  styleUrls: ['./promotion-generation.component.scss']
})
export class PromotionGenerationComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  products: ProductDTO[];

  promotion: PromotionDTO;
  numberOfPromotions: number = 0;

  productTranslations: Map<string, string> = ProductDTO.translations;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private productService: ProductService,
    private promotionService: PromotionService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      number: new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      days: new FormControl('', [Validators.required, Validators.max(31), Validators.min(0)]),
      months: new FormControl('', [Validators.required, Validators.max(1024), Validators.min(0)]),
      product: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.promotion = new PromotionDTO();
    this.productService.readAll()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.products = res;
      });
  }

  generate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.promotionService.generatePromotions(this.promotion, this.numberOfPromotions)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            console.log('response : ' , res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            console.log(res);
            this.form.markAsPristine();
            this.back('/promotion');
          }
        );
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {
    this.numberOfPromotions = this.form.get('number').value;
    this.promotion.description = this.form.get('description').value;
    this.promotion.idProduct = this.form.get('product').value;
    this.promotion.days = this.form.get('days').value;
    this.promotion.months = this.form.get('months').value;
    this.promotion.active = true;
  }

}
