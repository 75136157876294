import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { TranslationService } from 'src/app/services/disclaimer/translation.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TranslationDto } from 'src/app/shared/dto/disclaimer/TranslationDto.types';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { languages } from 'src/app/shared/dto/languages.types';
import { MessageService } from 'src/app/services/disclaimer/message.service';

@Component({
  selector: 'app-disclaimer-message-translation-detail',
  templateUrl: './disclaimer-message-translation-detail.component.html',
  styleUrls: ['./disclaimer-message-translation-detail.component.scss'],
  providers: [
    TranslationService,
    MessageService
  ]
})
export class DisclaimerMessageTranslationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly languages = languages;

  showPreview: boolean = false;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private translationService: TranslationService,
    private messageService: MessageService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    if(this.detailId()) {
      this.read(this.detailId());
    } else {
      this.readMessage();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toTranslationDto());
      return;
    }
    this.create(this.toTranslationDto());
  }

  deleteTranslation(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare la traduzione?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }

  detailId(): number {
    return +this.activeRoute.snapshot.paramMap.get('id');
  }

  detailMessageId(): number {
    return +this.activeRoute.snapshot.paramMap.get('messageId');
  }

  private create(translation: TranslationDto): void {
    this.translationService.create(translation).subscribe((responseTranslation) => {
      this.router.navigateByUrl(this.router.url.replace('/' + this.detailId(), '/' + responseTranslation.id));
      this.form = this.loadForm({...new TranslationDto(), ...responseTranslation});
    });
  }

  private update(translation: TranslationDto): void {
    this.translationService.update(translation).subscribe(() => this.read(this.detailId()));
  }

  private read(id: number): void {
    this.translationService.read(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(translation => this.form = this.loadForm({...new TranslationDto(), ...translation}));
  }

  private delete(id: number): void {
    this.translationService.delete(id).subscribe(() => this.router.navigate(['/disclaimers/message/detail/' + this.detailMessageId()]));
  }

  private readMessage(): void {
    this.messageService.read(this.detailMessageId()).pipe(takeUntil(this._unsubscribeAll)).subscribe(message => this.form = this.loadForm(new TranslationDto(message)));
  }

  private loadForm(translation: TranslationDto): FormGroup {
    return this._formBuilder.group({...translation, ...this.formValidators(translation)});
  }

  private toTranslationDto(): TranslationDto {
    return this.form.getRawValue() as TranslationDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(translation: TranslationDto) {
    return {
        title: [translation.title, [Validators.required, Validators.maxLength(255)]],
        text: [translation.text, [Validators.required]],
        language: [translation.language, Validators.required]
    };
  }
}
