<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Asset Definition</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- type field -->
                <mat-form-field>
                    <input matInput formControlName="type" placeholder="Asset Definition type" readonly="true" />
                </mat-form-field>

                <!-- symbol field -->
                <mat-form-field>
                    <div fxLayout="row" class="responsive-mat-form">
                        <input matInput formControlName="symbol" placeholder="Asset Definition Symbol"
                            readonly="true" />
                        <img *ngIf="logo" style="height: 20px; width: 20px;" [src]="logo" />
                    </div>
                </mat-form-field>

                <!-- fullName field -->
                <mat-form-field>
                    <input matInput formControlName="fullName" placeholder="Asset Definition Full Name"
                        readonly="true" />
                </mat-form-field>

                <!-- modificationDate field -->
                <mat-form-field>
                    <input matInput formControlName="modificationDate" placeholder="Asset Definition Modification Date"
                        readonly="true" />
                </mat-form-field>

                <!-- name field -->
                <mat-form-field>
                    <input matInput formControlName="name" placeholder="Asset Definition Name" readonly="true" />
                </mat-form-field>

                <!-- coinName field -->
                <mat-form-field>
                    <input matInput formControlName="coinName" placeholder="Asset Definition Coin Name"
                        readonly="true" />
                </mat-form-field>

                <!-- description field -->
                <mat-form-field>
                    <textarea matInput style="height: 100px;" formControlName="description"
                        placeholder="Asset Definition Description" readonly="true"> </textarea>
                </mat-form-field>

                <!-- sortOrder field -->
                <mat-form-field>
                    <input matInput formControlName="sortOrder" placeholder="Asset Definition SortOrder"
                        readonly="true" />
                </mat-form-field>

                <!-- trading field -->
                <mat-checkbox formControlName="trading" labelPosition="before" style="margin-bottom: 10px;" disabled>
                    Trading</mat-checkbox>

                <!-- decimalPoints field -->
                <mat-form-field>
                    <input matInput formControlName="decimalPoints" placeholder="Asset Definition Decimal Points"
                        readonly="true" />
                </mat-form-field>

                <!-- taxonomy field -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="taxonomy != null">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Taxonomy
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                        <!-- Access field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyAccess" placeholder="Taxonomy Access"
                                readonly="true" />
                        </mat-form-field>
                        <!-- FCA field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyFCA" placeholder="Taxonomy FCA" readonly="true" />
                        </mat-form-field>
                        <!-- FINMA field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyFINMA" placeholder="Taxonomy FINMA"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Industry field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyIndustry" placeholder="Taxonomy Industry"
                                readonly="true" />
                        </mat-form-field>
                        <!-- collateralizedAsset field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyCollateralizedAsset"
                                placeholder="Taxonomy Collateralized Asset" readonly="true" />
                        </mat-form-field>
                        <!-- collateralizedAssetType field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyCollateralizedAssetType"
                                placeholder="Taxonomy Collateralized Asset Type" readonly="true" />
                        </mat-form-field>
                        <!-- collateralType field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyCollateralType"
                                placeholder="Taxonomy Collateral Type" readonly="true" />
                        </mat-form-field>
                        <!-- collateralInfo field -->
                        <mat-form-field>
                            <input matInput formControlName="taxonomyCollateralInfo"
                                placeholder="Taxonomy Collateral Info" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <!-- WeissRating field -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="weissRating != null">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Weiss Rating
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                        <!-- Category field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingCategory" placeholder="Category"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Rating.RatingSource field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingRatingSource"
                                placeholder="Rating: Rating Source" readonly="true" />
                        </mat-form-field>
                        <!-- Rating.RatingCode Code field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingRatingCode" placeholder="Rating: Rating Code"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Rating.RatingWeight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingRatingWeight"
                                placeholder="Rating: Rating Weight" readonly="true" />
                        </mat-form-field>
                        <!-- Rating.RatingChange field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingRatingChange"
                                placeholder="Rating: Rating Change" readonly="true" />
                        </mat-form-field>
                        <!-- Market Performance Source field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingMarketPerformanceSource"
                                placeholder="Market Performance: Source" readonly="true" />
                        </mat-form-field>
                        <!-- Market Performance Code field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingMarketPerformanceCode"
                                placeholder="Market Performance: Code" readonly="true" />
                        </mat-form-field>
                        <!-- Market Performance Weight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingMarketPerformanceWeight"
                                placeholder="Market Performance: Weight" readonly="true" />
                        </mat-form-field>
                        <!-- Tech Adoption Source field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingTechAdoptionSource"
                                placeholder="Tech Adoption: Source" readonly="true" />
                        </mat-form-field>
                        <!-- Tech Adoption Code field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingTechAdoptionCode"
                                placeholder="Tech Adoption: Code" readonly="true" />
                        </mat-form-field>
                        <!-- Tech Adoption Weight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingTechAdoptionWeight"
                                placeholder="Tech Adoption: Weight" readonly="true" />
                        </mat-form-field>
                        <!-- Momentum Source field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingMomentumSource" placeholder="Momentum: Source"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Momentum Code field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingMomentumCode" placeholder="Momentum: Code"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Momentum Weight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingMomentumWeight" placeholder="Momentum: Weight"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Risk Source field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingRiskSource" placeholder="Risk: Source"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Risk Code field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingRiskCode" placeholder="Risk: Code"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Risk Weight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingRiskWeight" placeholder="Risk: Weight"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Technology Source field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingTechnologySource"
                                placeholder="Technology: Source" readonly="true" />
                        </mat-form-field>
                        <!-- Technology Code Weight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingTechnologyCode" placeholder="Technology: Code"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Technology Weight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingTechnologyWeight"
                                placeholder="Technology: Weight" readonly="true" />
                        </mat-form-field>
                        <!-- Adoption Source field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingAdoptionSource" placeholder="Adoption: Source"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Adoption Code field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingAdoptionCode" placeholder="Adoption: Code"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Adoption Weight field -->
                        <mat-form-field>
                            <input matInput formControlName="weissRatingAdoptionWeight" placeholder="Adoption: Weight"
                                readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <!-- TechAttributes field -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="techAttributes != null">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Tech Attributes
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                        <!-- Built On field -->
                        <mat-form-field>
                            <input matInput formControlName="techAttributesBuiltOn" placeholder="Built On"
                                readonly="true" />
                        </mat-form-field>
                        <!-- Smart Contract Address field -->
                        <mat-form-field>
                            <input matInput formControlName="techAttributesSmartContractAddress"
                                placeholder="Smart Contract Address" readonly="true" />
                        </mat-form-field>
                        <!-- Algorithm field -->
                        <mat-form-field>
                            <input matInput formControlName="techAttributesAlgorithm" placeholder="Algorithm"
                                readonly="true" />
                        </mat-form-field>
                        <!-- techAttributesProofType field -->
                        <mat-form-field>
                            <input matInput formControlName="techAttributesProofType" placeholder="Proof Type"
                                readonly="true" />
                        </mat-form-field>
                        <!-- techAttributesPreMinedValue field -->
                        <mat-form-field>
                            <input matInput formControlName="techAttributesPreMinedValue" placeholder="Pre Mined Value"
                                readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <!-- tags field -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="tags.length !== 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Tags
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list role="list" *ngFor="let tag of tags" fxLayout="column">
                        <mat-list-item role="listitem">- {{tag}}</mat-list-item>
                    </mat-list>
                </mat-expansion-panel>

                <!-- Urls field -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="urls.length !== 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Urls
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list role="list" *ngFor="let url of urls" fxLayout="column">
                        <mat-list-item role="listitem"> - {{url.code}}: {{url.url}}</mat-list-item>
                    </mat-list>
                </mat-expansion-panel>

                <!-- socialAttributes field -->
                <mat-expansion-panel class="mat-elevation-z0" *ngIf="socialAttributes != null">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Social Attributes
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                        <mat-form-field>
                            <input matInput formControlName="socialAttributesSubreddit" placeholder="SubReddit"
                                readonly="true" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput formControlName="socialAttributesTwitterUsername"
                                placeholder="Twitter Username" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/assetDefinition')" color="primary">Back</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>