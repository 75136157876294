import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/services/disclaimer/translation.service';
import { TranslationDto } from 'src/app/shared/dto/disclaimer/TranslationDto.types';

@Component({
  selector: 'translations-list',
  templateUrl: './translations-list.component.html',
  styleUrls: ['./translations-list.component.scss'],
  providers: [
    TranslationService
  ]
})
export class TranslationsListComponent implements OnInit, OnDestroy {

  @Input() messageId: number;

  private _translationsSubject: ReplaySubject<TranslationDto[]> = new ReplaySubject<TranslationDto[]>(1);
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private translationService: TranslationService,
  ) {}

  get translations$(): Observable<TranslationDto[]> {
    return this._translationsSubject.asObservable();
  }

  get displayedColumns(): string[] {
    return ['id', 'title', 'language', 'text', 'detail'];
  }

  ngOnInit(): void {
    this.listTranslations();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private listTranslations(): void {
    this.translationService.findByMessageId(this.messageId).pipe(takeUntil(this._unsubscribeAll)).subscribe(translations => this._translationsSubject.next(translations));
  }

}
