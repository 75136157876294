<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">

    <h2>Data Integration Catalog</h2>
    <p>Imposta il filtro di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">

        <mat-form-field>
        <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
      </mat-form-field>

      <mat-form-field>
        <mat-select [(ngModel)]="parameters.dataintegrationStatus" placeholder="Data Integration Status" #statusSelect>
          <mat-option *ngIf="!statusSelect.empty && statusSelect.panelOpen"></mat-option>
          <mat-option *ngFor="let status of dataintegrationStatus" [value]="status">
            {{ status }}
          </mat-option>          
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select [(ngModel)]="parameters.dataIntegrationType" placeholder="Data Integration type" #typeSelect>
            <mat-option *ngIf="!typeSelect.empty && typeSelect.panelOpen"></mat-option>
            <mat-option *ngFor="let type of dataintegrationType" [value]="type">
                {{ type }}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-card-actions>
        <button mat-raised-button id="search-button" name="Search" color="primary" (click)="list()">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/dataintegration/detail/0']">New</button>
      </mat-card-actions>
      </div>
      <br />

      <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
        <ng-container *ngIf="parameters.dataSource.length; else noContent">
          
          <h2>Lista Data Integration</h2>
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
          matSortDisableClear [dataSource]="parameters.dataSource">
  
            <!-- id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
              <td mat-cell *matCellDef="let dataIntegration"> {{ dataIntegration.id }} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="dataIntegrationName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> NAME </th>
              <td mat-cell *matCellDef="let dataIntegration"> {{ dataIntegration.dataIntegrationName }} </td>
            </ng-container>
  
            <!-- Status Column -->
            <ng-container matColumnDef="dataIntegrationStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
              <td mat-cell *matCellDef="let dataIntegration"> {{ dataIntegration.dataIntegrationStatus }} </td>
            </ng-container>

            <!-- Beta Column -->
            <ng-container matColumnDef="dataIntegrationBeta">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> BETA </th>
              <td mat-cell *matCellDef="let dataIntegration"> 
                <mat-icon *ngIf="dataIntegration.dataIntegrationBeta">check</mat-icon>
                <mat-icon *ngIf="!dataIntegration.dataIntegrationBeta">close</mat-icon>
              </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="dataIntegrationType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TYPE </th>
              <td mat-cell *matCellDef="let dataIntegration"> 
                <mat-icon *ngIf="dataIntegration.dataIntegrationType === 'WALLET'">account_balance_wallet</mat-icon>
                <mat-icon *ngIf="dataIntegration.dataIntegrationType === 'EXCHANGE_ACCOUNT'">compare_arrows</mat-icon>
              </td>
            </ng-container>

            <!-- Detail Column -->
            <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef>DETAIL</th>
              <td mat-cell *matCellDef="let dataIntegration">
                <button mat-mini-fab color="primary" [routerLink]="['/dataintegration/detail/', dataIntegration.id]">
                  <mat-icon>search</mat-icon>
                </button>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>           
          </table>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
              (page)="setPaginator($event)"></mat-paginator>
          </div>
          
        </ng-container>
        <ng-template #noContent>
          <h2>Nessun Data Integration trovato</h2>
        </ng-template>
      </div>
    
  </mat-card-content>
</mat-card>