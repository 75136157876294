<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Cryptocompare Timeseries</h2>
    <p>Imposta il filtro di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">


      <mat-form-field>
        <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button"
        [routerLink]="['/managedSymbol/detail']">New</button>
      <button mat-raised-button (click)="back('/')" color="primary">Back</button>
    </mat-card-actions>
    <br />
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()" matSort
        (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Symbol </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.symbol}} </td>
        </ng-container>

        <!-- hourDownloadTimeframe Column -->
        <ng-container matColumnDef="hourDownloadTimeframe">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> HourDownloadTimeframe </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.hourDownloadTimeframe}} </td>
        </ng-container>

        <!-- lastTimeframedDownload Column -->
        <ng-container matColumnDef="lastTimeframedDownload">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> LastTimeframedDownload </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.lastTimeframedDownload}} </td>
        </ng-container>

        <!-- nextTimeframedDownload Column -->
        <ng-container matColumnDef="nextTimeframedDownload">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> NextTimeframedDownload </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.nextTimeframedDownload}} </td>
        </ng-container>

        <!-- fromSymbol Column -->
        <ng-container matColumnDef="fromSymbol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> FromSymbol </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.fromSymbol}} </td>
        </ng-container>

        <!-- toSymbol Column -->
        <ng-container matColumnDef="toSymbol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ToSymbol </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.toSymbol}} </td>
        </ng-container>

        <!-- exchange Column -->
        <ng-container matColumnDef="exchange">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Exchange </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.exchange}} </td>
        </ng-container>

        <!-- priviledged Column -->
        <ng-container matColumnDef="priviledged">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Priviledged </th>
          <td mat-cell *matCellDef="let timeseries">
            <mat-icon *ngIf="timeseries.priviledged" class="icon-font-size">done</mat-icon>
            <mat-icon *ngIf="!timeseries.priviledged" class="icon-font-size">close</mat-icon>
          </td>
        </ng-container>

        <!-- useCadli Column -->
        <ng-container matColumnDef="useCadli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> UseCadli </th>
          <td mat-cell *matCellDef="let timeseries">
            <mat-icon *ngIf="timeseries.useCadli" class="icon-font-size">done</mat-icon>
            <mat-icon *ngIf="!timeseries.useCadli" class="icon-font-size">close</mat-icon>
          </td>
        </ng-container>

        <!-- exchange Column -->
        <ng-container matColumnDef="configurationStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let timeseries"> {{timeseries.configurationStatus}} </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let asset">
            <button mat-mini-fab color="primary" [routerLink]="['/timeseriesDownloadConfiguration/detail/', asset.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
          (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
