<mat-tab-group mat-stretch-tabs [(selectedIndex)]="index" [id]="'provider-'+environmentName" #tab>
    <!-- TAB  COINMARKETCAP-->
    <mat-tab label="CoinMarketCap" name="CoinMarketCap">
        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
            <buttons-component [btnTxt]="'Asset Mapping'" [btnIcon]="'build_circle'" [btnLink]="['/assetMappingConfiguration']">
            </buttons-component>
            <buttons-component [btnTxt]="'Asset Cache'" [btnIcon]="'cached'" [btnLink]="['/coinmarketcapAssetCache']">
            </buttons-component>
        </div>
    </mat-tab>
    <!-- TAB  WEISSCRYPTO-->
    <mat-tab label="WeissRatings" name="WeissRatings">
        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
            <buttons-component [btnTxt]="'Asset Mapping'" [btnIcon]="'build_circle'" [btnLink]="['/weisscryptoAssetMappingConfiguration']">
            </buttons-component>
            <buttons-component [btnTxt]="'Asset Cache'" [btnIcon]="'cached'" [btnLink]="['/weisscryptoAssetCache']">
            </buttons-component>
        </div>
    </mat-tab>
    <!-- TAB  CRYPTOCOMPARE-->
    <mat-tab label="CryptoCompare" name="CryptoCompare">
        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
            <buttons-component [btnTxt]="'Timeseries'" [btnIcon]="'build_circle'" [btnLink]="['/timeseriesDownloadConfiguration']">
            </buttons-component>
            <buttons-component [btnTxt]="'Asset Cache'" [btnIcon]="'cached'" [btnLink]="['/cryptocompareAssetCache']">
            </buttons-component>
        </div>
    </mat-tab>
    <mat-tab label="AlternativeMe" name="AlternativeMe">
        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
            <buttons-component [btnTxt]="'Fear & Greed'" [btnIcon]="'timeline'" [btnLink]="['/alternativeMeFearAndGreed']">
            </buttons-component>
        </div>
    </mat-tab>
</mat-tab-group>