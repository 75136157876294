import { SubscriptionDTO } from '../../../shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoSubscriptionService extends GenericService<SubscriptionDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/subscription/crypto/admin';
    //this.url = "http://localhost:30300/product/admin/products"
  }

  public createSub(dto: SubscriptionDTO, username: string): Observable<SubscriptionDTO> {
    const params = {};
    if (username != null) {params['username'] = username;}
    return this.http.post<SubscriptionDTO>(`${this.url}`, dto, { params: params });
  }
}
