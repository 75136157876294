import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';

export class ChangeLogDto extends LogicalDeleteDTO{

	/**
	 * Change log id
	 */
    id: number;

	/**
	 * Change log's vertion (es: 0.7.0)
	 */
    version: string;

    /**
     * Date for the change log, which is when it was released
     */
    date: Date;

    constructor() {
        super();
        this.id = undefined;
        this.version = '';
        this.date = undefined;
    }
}
