import { SubscriptionDTO } from './../subscription/SubscriptionDTO';
import { UserSubscriptionsDTO } from '../user-subscriptions/UserSubscriptionsDTO';
import { TraceModificationDTO } from '../../basic/traceModificationDTO';

export type ReceiptType = 'PROMOTION' | 'CRYPTO' | 'CARD' | 'FREE' | 'TRIAL';

export class ReceiptDTO extends TraceModificationDTO {

    id: number;

    startDate: Date;

    endDate: Date;

    user: UserSubscriptionsDTO;

    subscription: SubscriptionDTO;

    idInfluencerAffiliation: number;

    idCodeAffiliation: number;

    ammountPaid: number;

    currency: string;

    receiptType: ReceiptType;

}
