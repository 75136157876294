import { ChangeLogDto } from './../../shared/dto/change-log/ChangeLog.types';
import { ChangeLogTranslationDto } from './../../shared/dto/change-log/ChangeLogTranslationDto.types';
import { ChangeLogService } from './../../services/change-log/change-log.service';
import { ChangeLogTranslationService } from './../../services/change-log/change-log-translation.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { languages } from 'src/app/shared/dto/languages.types';

@Component({
  selector: 'app-change-log-translation-detail',
  templateUrl: './change-log-translation-detail.component.html',
  styleUrls: ['./change-log-translation-detail.component.scss'],
  providers: [
    ChangeLogTranslationService,
    ChangeLogService
  ]
})
export class ChangeLogTranslationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly languages = languages;

  showPreview: boolean = false;

  private changeLog: ChangeLogDto;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private translationService: ChangeLogTranslationService,
    private changeLogService: ChangeLogService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    if(this.detailId()) {
      this.read(this.detailId());
    } else {
      this.readMessage();
    }

    this.changeLogService.read(this.detailChangeLogId())
      .subscribe(x => this.changeLog = x);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toTranslationDto());
      return;
    }
    this.create(this.toTranslationDto());
  }

  deleteTranslation(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare la traduzione?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }

  detailId(): number {
    return +this.activeRoute.snapshot.paramMap.get('id');
  }

  detailChangeLogId(): number {
    return +this.activeRoute.snapshot.paramMap.get('changeLogId');
  }

  private create(translation: ChangeLogTranslationDto): void {
    this.translationService.create(translation).subscribe((responseTranslation) => {
      this.router.navigateByUrl(this.router.url.replace('/' + this.detailId(), '/' + responseTranslation.id));
      this.form = this.loadForm({...new ChangeLogTranslationDto(), ...responseTranslation});
    });
  }

  private update(translation: ChangeLogTranslationDto): void {
    this.translationService.update(translation).subscribe(() => this.read(this.detailId()));
  }

  private read(id: number): void {
    this.translationService.read(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(translation => this.form = this.loadForm({...new ChangeLogTranslationDto(), ...translation}));
  }

  private delete(id: number): void {
    this.translationService.delete(id).subscribe(() => this.router.navigate(['/changeLogs/detail/' + this.detailChangeLogId()]));
  }

  private readMessage(): void {
    this.changeLogService
      .read(this.detailChangeLogId())
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(message => this.form = this.loadForm(new ChangeLogTranslationDto(message)));
  }

  private loadForm(translation: ChangeLogTranslationDto): FormGroup {
    return this._formBuilder.group({...translation, ...this.formValidators(translation)});
  }

  private toTranslationDto(): ChangeLogTranslationDto {
    const translation = this.form.getRawValue() as ChangeLogTranslationDto;
    translation.changeLog = this.changeLog;
    return translation;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(translation: ChangeLogTranslationDto) {
    return {
        title: [translation.title, [Validators.required, Validators.maxLength(255)]],
        text: [translation.text, [Validators.required]],
        language: [translation.language, Validators.required]
    };
  }
}
