import { TraceModificationDTO } from '../basic/traceModificationDTO';

export class WeisscryptoAssetMappingConfigurationDTO extends TraceModificationDTO {

    id: number;

    cryptoId: string;

    weissSymbol: string;

	weissName: string;

	arzakenaSymbol: string;

	action: string;

}
