import { NavigatorService } from './../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ComponentCacheService } from '../services/component-cache.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { GenericComponent } from './GenericComponent';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from './pipes/timestampFormatPipe';

@Injectable()
export abstract class GenericListComponent extends GenericComponent implements OnInit, OnDestroy {

    displayedColumns: string[];

    routerSnapshotUrlFrom: string;

    parameters: any;

    constructor(
        navigatorService: NavigatorService,
        private componentCacheService: ComponentCacheService,
        dateAdapter: DateAdapter<Date>,
        router: Router,
        snackBar: MatSnackBar,
        timestampFormatPipe: TimestampFormatPipe
    ) {
        super(
            navigatorService,
            router,
            dateAdapter,
            snackBar,
            timestampFormatPipe);
    }

    setSort(sort: Sort): void {
        this.parameters.sort = sort.active + ',' + sort.direction;
        this.list();
    }

    setPaginator(pagination: PageEvent): void {
        this.parameters.size = pagination.pageSize;
        this.parameters.page = pagination.pageIndex;
        this.list();
    }

    ngOnDestroy(): void {
        this.componentCacheService.set(this.routerSnapshotUrlFrom, this.parameters);
    }

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    ngOnInit(): void {
        this.routerSnapshotUrlFrom = this.calculateCacheName();
        if (this.componentCacheService.has(this.routerSnapshotUrlFrom)) {
            this.parameters = this.componentCacheService.get(this.routerSnapshotUrlFrom);
            if (this.parameters.showList) {
                this.list();
            }
        }
    }

    hiddenColumns(): number[] {
        const ret: number[] = [];
        this.displayedColumns.forEach((element, index) => {
            if (element === 'detail' || element === 'print') {
                ret.push(index);
            }
        });
        return ret;
    }

    calculateCacheName(): string {
        console.log('cache name : ' + this.router.routerState.snapshot.url);
        return this.router.routerState.snapshot.url;
    }

    abstract list();
}
