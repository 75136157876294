<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Genera Notification</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
                <!-- timestamp field -->
                <mat-form-field>
                    <input matInput [matDatepicker]="timestampPicker" formControlName="timestamp"
                        placeholder="Timestamp" (click)="timestampPicker.open()" readonly="true">
                    <mat-datepicker-toggle matSuffix [for]="timestampPicker"></mat-datepicker-toggle>
                    <mat-datepicker #timestampPicker></mat-datepicker>
                    <button mat-button *ngIf="form.get('timestamp').value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="form.get('timestamp').setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('timestamp').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- category field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="category" placeholder="Category">
                        <mat-option *ngFor="let category of categories" [value]="category.bundleKey">
                            {{category.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('category').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- channel field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="channel" placeholder="Channel" #channelSelect>
                        <mat-option *ngIf="!channelSelect.empty && channelSelect.panelOpen"></mat-option>
                        <mat-option *ngFor="let channel of channels" [value]="channel">
                            {{channel}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('channel').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- status field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="method" placeholder="Method">
                        <mat-option *ngFor="let method of methods" [value]="method">
                            {{method}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('method').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- logicalKey field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="logicalKey" placeholder="Logical Key">
                    <mat-error *ngIf="form.get('logicalKey').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('logicalKey').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- url field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="url" placeholder="Url">
                    <mat-error *ngIf="form.get('url').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- bundleKey field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="bundleKey" placeholder="Template">
                        <mat-option *ngFor="let template of templates" [value]="template">
                            {{template}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('bundleKey').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- userType field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="userType" placeholder="User Type">
                        <mat-option *ngFor="let userType of userTypes" [value]="userType">
                            {{userType}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('userType').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- single user fields -->
                <div *ngIf="form.get('userType').value==='SINGLE'">
                    <hr style="width:100%;text-align:left;margin-left:0">
                    <h4>Single User Fields</h4>
                    <!-- username field -->
                    <mat-form-field style="width:100%;">
                        <input matInput type="text" formControlName="username" placeholder="Username">
                    </mat-form-field>
                    <!-- email field -->
                    <mat-form-field style="width:100%;">
                        <input matInput type="text" formControlName="email" placeholder="Email">
                    </mat-form-field>
                </div>

                <!-- multiple user fields -->
                <div *ngIf="form.get('userType').value==='MULTIPLE'">
                    <hr style="width:100%;text-align:left;margin-left:0">
                    <h4>Multiple User Fields</h4>
                    <!-- usernames field -->
                    <div formArrayName="usernames" style="width:100%">
                        <div *ngFor="let username of usernamesArray.controls; let i = index" style="width:100%">
                            <div [formGroupName]="i" style="width:100%">
                                <mat-form-field [ngStyle]="{'padding-right': 2 + '%'}" style="width:80%">
                                    <input matInput placeholder="Username" formControlName="name">
                                    <mat-error *ngIf="username.get('name').errors?.required">
                                        Campo obbligatorio
                                    </mat-error>
                                </mat-form-field>
                                <button mat-mini-fab color="warn" (click)="removeUsername(i)">
                                    -
                                </button>
                            </div>
                        </div>
                    </div>
                    <button mat-raised-button color="primary" (click)="addUsername()" style="width:100%">Add username</button>
                </div>

                <!-- parameters field -->
                <hr style="width:100%;text-align:left;margin-left:0">
                <h4>Parameters</h4>
                <div formArrayName="parameters">
                    <div *ngFor="let parameter of parametersArray.controls; let i = index;">
                        <div [formGroupName]="i"
                            fxLayout="row" class="responsive-mat-form">
                            <!-- name field -->
                            <mat-form-field fxFlex="30" [ngStyle]="{'padding-right': 2 + '%'}">
                                <input matInput placeholder="Parameter name" formControlName="name">
                                <mat-error *ngIf="parameter.get('name').errors?.required">
                                    Campo obbligatorio
                                </mat-error>
                            </mat-form-field>
                            <!-- type field -->
                            <mat-form-field fxFlex="30" [ngStyle]="{'padding-right': 2 + '%'}">
                                <mat-select formControlName="type" placeholder="Parameter type">
                                    <mat-option *ngFor="let parameterType of parameterTypes" [value]="parameterType">
                                        {{parameterType}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="parameter.get('type').errors?.required">
                                    Campo obbligatorio
                                </mat-error>
                            </mat-form-field>
                            <!-- value fields -->
                            <div fxFlex="30" [ngStyle]="{'padding-right': 2 + '%'}">
                                <!-- string value -->
                                <mat-form-field style="width: 100%;" *ngIf="parameter.get('type').value==='STRING'">
                                    <input matInput placeholder="Parameter value" formControlName="value">
                                    <mat-error *ngIf="parameter.get('value').errors?.required">
                                        Campo obbligatorio
                                    </mat-error>
                                </mat-form-field>
                                <!-- numebr value -->
                                <mat-form-field style="width: 100%;" *ngIf="parameter.get('type').value==='NUMBER'">
                                    <input matInput type="number" placeholder="Parameter value" formControlName="value">
                                    <mat-error *ngIf="parameter.get('value').errors?.required">
                                        Campo obbligatorio
                                    </mat-error>
                                </mat-form-field>
                                <!-- date value -->
                                <mat-form-field style="width: 100%;" *ngIf="parameter.get('type').value==='DATE'">
                                    <input matInput [matDatepicker]="valuePicker" formControlName="value"
                                        placeholder="Parameter value" (click)="valuePicker.open()" readonly="true">
                                    <mat-datepicker-toggle matSuffix [for]="valuePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #valuePicker></mat-datepicker>
                                    <button mat-button *ngIf="parameter.get('value').value" matSuffix mat-icon-button
                                        aria-label="Clear" (click)="parameter.get('value').setValue(null)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error *ngIf="parameter.get('value').errors?.required">
                                        Campo obbligatorio
                                    </mat-error>
                                </mat-form-field>
                                <!-- boolean value -->
                                <mat-checkbox formControlName="value" labelPosition="before" 
                                    style="width: 100%;" *ngIf="parameter.get('type').value==='BOOLEAN'">
                                        Parameter value</mat-checkbox>
                                <!-- object value -->
                                <mat-form-field style="width: 100%;" *ngIf="parameter.get('type').value==='OBJECT'">
                                    <input matInput placeholder="Parameter value" formControlName="value">
                                    <mat-error *ngIf="parameter.get('value').errors?.required">
                                        Campo obbligatorio
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <button mat-mini-fab color="warn" (click)="removeParameter(i)">
                                -
                            </button>
                        </div>
                    </div>
                </div>
                <button mat-raised-button color="primary" (click)="addParameter()">Add parameter</button>

                <!-- attachments field -->
                <hr style="width:100%;text-align:left;margin-left:0">
                <h4>Attachments</h4>
                <div formArrayName="attachments">
                    <div *ngFor="let attachment of attachmentsArray.controls; let i = index">
                        <div [formGroupName]="i">
                            <mat-form-field [ngStyle]="{'padding-right': 2 + '%'}">
                                <input matInput placeholder="Attachment name" formControlName="name">
                                <mat-error *ngIf="attachment.get('name').errors?.required">
                                    Campo obbligatorio
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field [ngStyle]="{'padding-right': 2 + '%'}">
                                <input matInput placeholder="Attachment url" formControlName="url">
                                <mat-error *ngIf="attachment.get('url').errors?.required">
                                    Campo obbligatorio
                                </mat-error>
                            </mat-form-field>
                            <button mat-mini-fab color="warn" (click)="removeAttachment(i)">
                                -
                            </button>
                        </div>
                    </div>
                </div>
                <button mat-raised-button color="primary" (click)="addAttachment()">Add attachment</button>
                <hr style="width:100%;text-align:left;margin-left:0">
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('')" color="primary">Back</button>
            <button mat-raised-button (click)="notificationGenerate()" [disabled]="form.invalid || !form.dirty ">
                Generate
            </button>
            <button mat-raised-button (click)="formClear()">
                Clear
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>