import { ProductDTO } from '../product/ProductDTO';
import { TraceModificationDTO } from '../../basic/traceModificationDTO';

export type SubscriptionType = 'PROMOTION' | 'CRYPTO' | 'CARD';

export class SubscriptionDTO extends TraceModificationDTO {

    subscriptionType: SubscriptionType;

    id: number;

    startDate: Date;

    endDate: Date;

    upgrade: boolean;

    active: boolean;

    paid: boolean;

    pricePaid: number;

    ammountPaid: number;

    currency: string;

    product: ProductDTO;

    //CARD SUBSCRIPTION

    customerId: string;

    paymentId: string;

    subscriptionId: string;

    paymentReceipt: string;

    endDateDisplay: Date;

    //CRYPTO SUBSCRIPTION

    invoiceId: string;

    transactionId: string;

    cryptoAmount: number;

    transactionCurrency: string;

    //CARD AND CRYPTO

    email: string;

    country: string;

    //PROMOTION SUBSCRIPTION

    promotionCode: string;

    trial: boolean;

    free: boolean;

}
