
import { UserDTO } from './../../shared/dto/config/user';
import { Status } from './../../shared/dto/help-desk/ticket_status';
import { TicketDTO } from './../../shared/dto/help-desk/ticket';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageDTO } from '../../shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { Observable } from 'rxjs';
import { CorrectedUserDTO } from 'src/app/shared/dto/config/correctedUser';


@Injectable({
  providedIn: 'root'
})
export class TicketService extends GenericService<TicketDTO, number>{

  user: UserDTO;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-helpdesk/ticket';
  }

  public ticketList(page: number, size: number, sort: string,
    filter: string, idTicket: number, status: Status, dateFrom: Date, dateTo: Date, username: string, category: string): Observable<PageDTO<TicketDTO>> {
    const params = {};
    const from= new Date(dateFrom);
    const to= new Date(dateTo);

    if (page != null) {params['page'] = String(page);}
    if (size != null) {params['size'] = String(size);}

    if (sort != null) {
      params['sort'] = sort;
    }else{
      params['sort'] = 'id';
    }

    if (filter != null) {params['sortField'] = filter;}
    if (username != null) { params['username'] = username; }
    if (dateFrom != null) { params['dateFrom'] = from.toISOString(); }
    if (dateTo != null) { params['dateTo'] = to.toISOString(); }
    if (idTicket != null) { params['idTicket'] = idTicket; }
    if (status != null) { params['status'] = status; }
    if (category != null) { params['category'] = category; }


    return this.http.get<PageDTO<TicketDTO>>(`${this.url}/ticketList`, {
      params: params
    });
  }
  public readTicket(id: number, username: string): Observable<TicketDTO>{

    const params = {};
    if (id != null) { params['id'] = id; }

    return this.http.get<TicketDTO>(`${this.url}/` + id + '/' + username, {});
  }

  getUsernameById(id: string): Observable<CorrectedUserDTO>{
    const url = `${environment.urlPrefix}/arzakena-be-keycloak/keycloak/` + id;

    return this.http.get<CorrectedUserDTO>(url);
  }

  adminUpdateTicket(ticket: TicketDTO, username: string): Observable<void> {
    const url = `${this.url}/${username}`;
    return this.http.put<void>(url, ticket);
}

}
