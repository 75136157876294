import { AssetDefinitionDto } from './AssetDefinitionDto.types';
import { SmartContractAddressDto } from './SmartContractAddressDto.types';

export class SmartContractAddressMappingDto extends SmartContractAddressDto {

	id: number;

	assetDefinition: AssetDefinitionDto;

    alias: string;

    constructor() {
        super();
        this.id = undefined;
        this.assetDefinition = undefined;
        this.alias = '';
    }
}
