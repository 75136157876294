import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';
import { AssetDefinitionService } from 'src/app/services/core-catalog/asset-definition/asset-definition.service';
import { AssetDefinitionAliasWrapper } from '../smart-contract-address-mapping/smart-contract-address-mapping-detail/asset-definition-input/asset-definition-input.component';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipEvent, MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-asset-definition-alias-mapping',
  templateUrl: './asset-definition-alias-mapping.component.html',
  styleUrls: ['./asset-definition-alias-mapping.component.scss'],
})
export class AssetDefinitionAliasMappingComponent extends GenericDetailComponent {

  assetDefinition: AssetDefinitionDto;

  constructor(
    private asseetDefinitionService: AssetDefinitionService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  save(): void {
    this.form.markAsPristine();
    this.update(this.toAssetDefinitionDto());
  }

  assetDefinitionChanged($event: AssetDefinitionAliasWrapper): void {
    this.assetDefinition = undefined;
    this.form = this.newAssetDefinition($event.assetDefinition);
  }

  add(input: HTMLInputElement): void {
    if(input.value) {
      this.aliases.value.push(input.value);
      input.value = '';
      this.aliases.updateValueAndValidity();
      this.aliases.markAsDirty();
    }
  }

  remove(alias: string): void {
    // Remove element from control value array
    this.aliases.value.splice(this.aliases.value.indexOf(alias), 1);

    this.aliases.updateValueAndValidity();
    this.aliases.markAsDirty();
  }

  // use getter method to access courseIds control value easily
  get aliases(): AbstractControl {
    return this.form?.get('alias');
  }

  private update(assetDefinition: AssetDefinitionDto): void {
    this.asseetDefinitionService.update(assetDefinition).subscribe(() => this.router.navigate(['']));
  }

  private newAssetDefinition(assetDefinition: AssetDefinitionDto): FormGroup {
    if(assetDefinition?.symbol) {
      this.assetDefinition = assetDefinition;
      return this._formBuilder.group({alias: [this.assetDefinition.alias]});
    }
    return undefined;
  }

  private toAssetDefinitionDto(): AssetDefinitionDto {
    return {
      ...this.assetDefinition,
      ...{alias: this.aliases.value}
    };
  }
}
