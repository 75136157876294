<mat-card id="card" fxLayout="row" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="p-4" *ngIf="this.form">
      <div fxLayout="column" fxFlex="40" class="form">
        <h2>Advertisement Translation</h2>
        <div fxLayout="column">
          <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
            <!-- Id field -->
            <mat-form-field>
              <input matInput formControlName="id" placeholder="Id" readonly="true" />
            </mat-form-field>
  
            <mat-form-field>
              <input matInput formControlName="title" placeholder="Title" [readonly]="form.get('deleteDate').value"/>
            </mat-form-field>
            
            <mat-form-field>
              <mat-select formControlName="language" placeholder="Language" [disabled]="form.get('deleteDate').value">
                  <mat-option *ngFor="let language of languages" [value]="language">
                      {{language}}
                  </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="button" placeholder="Contenuto Bottone" [readonly]="form.get('deleteDate').value"/>
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="redirectUrl" placeholder="Url per reindirizzamento" [readonly]="form.get('deleteDate').value"/>
            </mat-form-field>

            <mat-form-field>
                <textarea matInput style="height: 100px;" formControlName="description"
                placeholder="Descrizione banner" [readonly]="form.get('deleteDate').value"> </textarea>
              </mat-form-field>
              
            
            <!-- Campi di informazione operazione -->
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Dettagli operazione
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row">
                <!-- InsertDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                </mat-form-field>
  
                <!-- InsertUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                </mat-form-field>
              </div>
  
              <div fxLayout="row">
                <!-- UpdateDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                </mat-form-field>
  
                <!-- UpdateUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                </mat-form-field>
              </div>
  
              <div fxLayout="row">
                <!-- DeleteDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="deleteDate" placeholder="Delete Date" readonly="true" />
                </mat-form-field>
  
                <!-- DeleteUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="deleteUser" placeholder="Delete User" readonly="true" />
                </mat-form-field>
              </div>
              
            </mat-expansion-panel>
          </form>
        </div>
  
        <mat-card-actions>
          <button mat-raised-button (click)="backConfirm('/campaigns/detail/' + detailAdvId())" color="primary">Back</button>
          <button mat-raised-button (click)="save()" [disabled]="!form.dirty || form.get('deleteDate').value">
            Save
          </button>
          <button mat-raised-button  (click)="deleteTranslation()" *ngIf="detailId()" [disabled]="form.get('deleteDate').value">
            Delete
          </button>
          <button mat-raised-button (click)="showPreview = !showPreview">
            Show Preview
          </button>
        </mat-card-actions>
      </div>
      <div fxFlex fxLayout="column" class="p-4" *ngIf="showPreview">
        <mat-card class="advertisement-example">
          <div class="card-content">
            <div class="text-content">
              <mat-card-title>{{form.get('title').value}}</mat-card-title>
              <mat-card-subtitle>{{form.get('description').value}}</mat-card-subtitle>
              <mat-card-actions>
                <button mat-raised-button color="primary" (click)="redirectToPage()">{{form.get('button').value}}</button>
              </mat-card-actions>
            </div>
            <div class="image-content">
              <img mat-card-image [src]="url">
            </div>
          </div>
        </mat-card>
      </div>
    </mat-card-content>
</mat-card>
