<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="row" class="p-4" *ngIf="this.form">
    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Dettagli Template</h2>
      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
          
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Id"
              readonly="true" />
          </mat-form-field>

          <!-- bundleKey field -->
          <mat-form-field fxFlex>
              <input matInput type="text" formControlName="bundleKey" placeholder="Bundle Key">
              <mat-error *ngIf="form.get('bundleKey').errors?.required">
                  Campo obbligatorio
              </mat-error>
              <mat-error *ngIf="form.get('bundleKey').errors?.maxlength">
                  Max length reached (256 max)
              </mat-error>
          </mat-form-field>

          <!-- templateHeader field -->
          <mat-form-field>
            <textarea matInput style="height: 100px;" formControlName="templateHeader"
            placeholder="Template Header"> </textarea>
            <mat-error *ngIf="form.get('templateHeader').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- templateBody field -->
          <mat-form-field>
            <textarea matInput style="height: 100px;" formControlName="templateBody"
            placeholder="Template Body"> </textarea>
            <mat-error *ngIf="form.get('templateBody').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- locale field -->
          <mat-form-field>
            <mat-select formControlName="locale" placeholder="Locale">
                <mat-option *ngFor="let language of languages" [value]="language">
                    {{language}}
                </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- channel field -->
          <mat-form-field>
            <mat-select formControlName="channel" placeholder="Channel">
                <mat-option *ngFor="let channel of channels" [value]="channel">
                    {{channel}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          
          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>

              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>

              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <!-- DeleteDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="deleteDate" placeholder="Delete Date" readonly="true" />
              </mat-form-field>

              <!-- DeleteUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="deleteUser" placeholder="Delete User" readonly="true" />
              </mat-form-field>
            </div>
            
          </mat-expansion-panel>
        </form>
      </div>

      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/notifications/template')" color="primary">Back</button>
        <button mat-raised-button (click)="templateSave()" [disabled]="form.invalid || !form.dirty ">
            Save
        </button>
        <button mat-raised-button (click)="templateDelete()" [disabled]="template?.id===null">
            Delete
        </button>
        <button mat-raised-button (click)="showPreview = 'templateHeader'">
          Show Header Preview
        </button>
        <button mat-raised-button (click)="showPreview = 'templateBody'">
          Show Body Preview
        </button>
        <button mat-raised-button (click)="showPreview = null">
          Remove Preview
        </button>
      </mat-card-actions>
    </div>
    <div class="previewBox" *ngIf="showPreview">
      <div class="disclaimerBody" [innerHTML]="doNotSanitizeHtml(form.get(showPreview).value)"></div>
    </div>
  </mat-card-content>

</mat-card>