import { Component, Input, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentCacheService } from '../../services/component-cache.service';
import { NavigatorService } from '../../services/navigator.service';
import { SocialDTO } from '../../shared/dto/influencer/SocialDTO';
import { GenericListComponent } from '../../shared/GenericListCoumponent';
import { TimestampFormatPipe } from '../../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent extends GenericListComponent implements OnInit {

  @Input() influencerId: number;
  @Input() socials: SocialDTO[];
  constructor(
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,

  ) {

    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['socialName', 'socialType', 'socialLink', 'favorite', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: true,
      filter: null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
    };
  }

  list(): void{
      if(this.socials != null){
        this.parameters.dataSource = this.socials;
        this.parameters.page = 1;
        this.parameters.length = 1;
        this.parameters.showList = true;
      }
  }

  ngOnInit(): void {
  }

}
