import { UserDTO } from './../../shared/dto/config/user';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { TicketService } from './../../services/help-desk/ticket.service';
import { TicketDTO } from './../../shared/dto/help-desk/ticket';
import { GenericDetailComponent } from './../../shared/GenericDetailComponent';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Status } from 'src/app/shared/dto/help-desk/ticket_status';

@Component({
  selector: 'app-ticket-management-detail',
  templateUrl: './ticket-management-detail.component.html',
  styleUrls: ['./ticket-management-detail.component.scss']
})

export class TicketManagementDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly statuses: Status[] = [Status.inAttesaDiRisposta, Status.inAttesaDiFeedback];

  displayedColumns: string[];
  parameters: any;
  idTicket: number;

  isUpdateInProgress: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _ticketSubject: ReplaySubject<TicketDTO> = new ReplaySubject<TicketDTO>(1);

  get ticket$(): Observable<TicketDTO> {
    return this._ticketSubject.asObservable();
  }

  constructor(
    public ticketService: TicketService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  back(): void {
    this.router.navigate(['/ticket-management']);
  }

  ngOnInit(): void {

    this.idTicket = Number(this.activeRoute.snapshot.paramMap.get('idTicket'));
    this.getTicket();
    this.ticketService.getUsernameById(this.activeRoute.snapshot.paramMap.get('username'))
      .pipe(
        takeUntil(this._unsubscribeAll),
        catchError((err) => {
          this.ticketService.user = new UserDTO();
          this.ticketService.user.username = this.activeRoute.snapshot.paramMap.get('username');
          return throwError(null);
          // in produzione e ci gli username arrivano già tutti sotto forma di uid...quindi non dovrebbe mai dare 404
        })
      ).subscribe(
        (res) => {
          this.ticketService.user = res;
        }
      );
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateTicketStatus($event: Status): void {
    this.ticket$.pipe(take(1)).subscribe((ticket) => {
      ticket.status = $event;
      this.isUpdateInProgress = true;
      this.ticketService.adminUpdateTicket(ticket, ticket.insertUser)
        .pipe(take(1),
          finalize(() => this.isUpdateInProgress = false)
        ).subscribe(() => this.getTicket());
    });
  }

  closeTicket(): void {
    this.updateTicketStatus(Status.chiusa);
  }

  reopenTicket(): void {
    this.updateTicketStatus(Status.inAttesaDiFeedback);
  }

  private getTicket(): void {
    this.ticketService.readTicket(Number(this.idTicket), this.activeRoute.snapshot.paramMap.get('username')).pipe(take(1)).subscribe(ticket => this._ticketSubject.next(ticket));
  }
}
