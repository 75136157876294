<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Category Configuration</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id"
                        readonly="true" />
                </mat-form-field>

                <!-- category field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="category" placeholder="Category">
                        <mat-option *ngFor="let category of categories" [value]="category.id">
                            {{category.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('category').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- channel field -->
                <mat-form-field fxFlex>
                    <mat-select formControlName="channel" placeholder="Channel">
                        <mat-option *ngFor="let channel of channels" [value]="channel">
                            {{channel}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('channel').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- defaultValue field -->
                <mat-checkbox formControlName="defaultValue" labelPosition="before" style="margin-bottom: 10px;">
                    Default Value</mat-checkbox>

                <!-- editable field -->
                <mat-checkbox formControlName="editable" labelPosition="before" style="margin-bottom: 10px;">
                    Editable</mat-checkbox>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/notifications/configuration')" color="primary">Back</button>
            <button mat-raised-button (click)="configurationSave()" [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <button mat-raised-button (click)="configurationDelete()" [disabled]="configuration?.id===null">
                Delete
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>