import { ChangeLogTranslationDto } from './../../shared/dto/change-log/ChangeLogTranslationDto.types';
import { ChangeLogTranslationService } from './../../services/change-log/change-log-translation.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'change-log-translations-list',
  templateUrl: './change-log-translations-list.component.html',
  styleUrls: ['./change-log-translations-list.component.scss'],
  providers: [
    ChangeLogTranslationService
  ]
})
export class ChangeLogTranslationsListComponent implements OnInit, OnDestroy {

  @Input() changeLogId: number;

  private _translationsSubject: ReplaySubject<ChangeLogTranslationDto[]> = new ReplaySubject<ChangeLogTranslationDto[]>(1);
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private translationService: ChangeLogTranslationService,
  ) {}

  get translations$(): Observable<ChangeLogTranslationDto[]> {
    return this._translationsSubject.asObservable();
  }

  get displayedColumns(): string[] {
    return ['id', 'title', 'language', 'text', 'detail'];
  }

  ngOnInit(): void {
    this.listTranslations();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private listTranslations(): void {
    this.translationService.findByChangeLogId(this.changeLogId).pipe(takeUntil(this._unsubscribeAll)).subscribe(translations => this._translationsSubject.next(translations));
  }

}
