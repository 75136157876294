import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeseriesDTO } from 'src/app/shared/dto/core-timeseries/timeseriesDto';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeseriesDataVisualizationService extends GenericService<TimeseriesDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-core-timeseries/timeseries/query';
  }

  public timeseriesDataForVisualization(symbol: string, timestampFrom: Date, timeseriesTo: Date, samplingNumber?: number, samplingUnit?: string): Observable<TimeseriesDTO> {
    const params = {};
    if (samplingNumber != null) {params['samplingNumber'] = samplingNumber;}
    if (samplingUnit != null) {params['samplingUnit'] = samplingUnit;}
    return this.http.get<TimeseriesDTO>(`${this.url}/`+symbol+'/'+timestampFrom.toISOString()+'/'+timeseriesTo.toISOString(), { params: params });
  }

}
