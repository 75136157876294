<h1 style="text-align:center;">Scheda Prodotto e Censimento SmartContract</h1>
<br>
<div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    <buttons-component [btnTxt]="'Asset Definition'" [btnIcon]="'description'" [btnLink]="['/assetDefinition']">
    </buttons-component>
    <buttons-component [btnTxt]="'Contract Mapping'" [btnIcon]="'swap_horiz'" [btnLink]="['/smartContractAddressMapping']">
    </buttons-component>
    <buttons-component [btnTxt]="'Symbol Mapping'" [btnIcon]="'add_circle'" [btnLink]="['/symbolMapping/detail']">
    </buttons-component>
    <buttons-component [btnTxt]="'Symbol Check'" [btnIcon]="'check_circle'" [btnLink]="['/checkForManagedSymbols/detail']">
    </buttons-component>
    <buttons-component [btnTxt]="'Data Integration'" [btnIcon]="'data_usage'" [btnLink]="['/dataintegration']">
    </buttons-component>
    <buttons-component [btnTxt]="'Alias Mapping'" [btnIcon]="'shuffle'" [btnLink]="['/assetDefinitionAliasMapping']">
    </buttons-component>
</div>