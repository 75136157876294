import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { NavigatorService } from './services/navigator.service';
import { TenantDTO } from './shared/dto/domain/tenant';
import { ResponseFail } from './shared/dto/responseFail';
import { CollaboratoreDTO } from './shared/dto/domain/collaboratore';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { UrlService } from './services/url.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  tenant: TenantDTO;

  protected previousUrl: string = null;
  protected currentUrl: string = null;
  protected collaboratore: CollaboratoreDTO;
  protected screenWidth: number;

  private readonly _unsubscribeAll: Subject<void> = new Subject<void>();

  get environmentName(): string {
    return environment.environmentName;
  }

  constructor(public mediaObserver: MediaObserver,
    public navigatorService: NavigatorService,
    public snackBar: MatSnackBar,
    private router: Router,
    private urlService: UrlService) {
    navigatorService.tenantInfo();
    navigatorService.tenant$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
      (res: TenantDTO) => {
        this.tenant = res;
      }
    );

    navigatorService.collaboratore$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
      }
    );

    navigatorService.httpError$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
      (err: HttpErrorResponse) => {
        let message: string;
        if (err.error != null && err.error.reasonBundleKey !== undefined) {
          const fail: ResponseFail = err.error;
          message = fail.reasonBundleKey + ' - ' + fail.reason;
        } else {
          if (err.status === 403) {
            message = 'L\'utente non ha i permessi necessari per svolgere questa operazione.';
          } else {
            message = 'Generic error contacting server! status : ' + err.status + ', message : ' + err.message;
          }
        }
        this.snackBar.open(message, 'Error', {
          duration: 5000
        });
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.screenWidth = screen.width < window.innerWidth ? screen.width : window.innerWidth;
    this.navigatorService.setScreenWidth(this.screenWidth);
  }

  logout(): void {
    this.navigatorService.logout();
  }

  isLoggedIn(): boolean {
    return this.navigatorService.isLoggedIn();
  }

  ngOnInit(): void {
    this.onResize(null);
    this.router.events.pipe(
      takeUntil(this._unsubscribeAll),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);

    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
