import { ChangeLogService } from './../../services/change-log/change-log.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { disclaimerTypes } from 'src/app/shared/dto/disclaimer/DisclaimerType.types';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss'],
  providers: [
    ChangeLogService
  ]
})
export class ChangeLogComponent extends GenericListComponent implements OnInit, OnDestroy {

  readonly disclaimerTypes = disclaimerTypes;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private changeLogService: ChangeLogService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'version', 'date', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: undefined,
      sort: undefined,
      page: 0,
      size: 10,
      disclaimerType: undefined,
      deleted: false,
      expired: false,
      length: 0
    };
  }

  list(): void {
    this.changeLogService.list()
    .subscribe((messages) => {
      this.parameters.dataSource = messages;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
