<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Categories</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">
    
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.name" placeholder="Name" />
            </mat-form-field>
    
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.bundleKey" placeholder="Bundle Key" />
            </mat-form-field>
    
        </div>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let category"> {{category.id}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let category"> {{category.name}} </td>
                </ng-container>

                <!-- bundleKey Column -->
                <ng-container matColumnDef="bundleKey">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Bundle Key </th>
                    <td mat-cell *matCellDef="let category"> {{category.bundleKey}} </td>
                </ng-container>

                <!-- durationCancellation Column -->
                <ng-container matColumnDef="durationCancellation">
                    <th mat-header-cell *matHeaderCellDef> Duration After Cancellation (days) </th>
                    <td mat-cell *matCellDef="let category"> {{category.durationCancellation}} </td>
                </ng-container>

                <!-- durationRead Column -->
                <ng-container matColumnDef="durationRead">
                    <th mat-header-cell *matHeaderCellDef> Duration After Read (days) </th>
                    <td mat-cell *matCellDef="let category"> {{category.durationRead}} </td>
                </ng-container>

                <!-- durationAbsolute Column -->
                <ng-container matColumnDef="durationAbsolute">
                    <th mat-header-cell *matHeaderCellDef> Duration Absolute (days) </th>
                    <td mat-cell *matCellDef="let category"> {{category.durationAbsolute}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let category">
                        <button mat-mini-fab color="primary" [routerLink]="['/notifications/category/detail/', category.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
