import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigatorService } from '../../services/navigator.service';
import { SocialService } from '../../services/social/social.service';
import { SocialDTO } from '../../shared/dto/influencer/SocialDTO';
import { GenericDetailComponent } from '../../shared/GenericDetailComponent';
import { TimestampFormatPipe } from '../../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-social-detail',
  templateUrl: './social-detail.component.html',
  styleUrls: ['./social-detail.component.scss'],
  providers: [
    SocialService
  ]
})
export class SocialDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  socialId: number;
  social: SocialDTO;
  socialTypeList: string[];
  favoriteChanged = false;
  influencerId: number = null;
  initialFavoriteValue: boolean;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private socialService: SocialService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      socialName: new FormControl('', [Validators.required, Validators.maxLength(64)]),
      socialType: new FormControl('', Validators.required),
      socialLink: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.socialTypeList = [
      'TELEGRAM',
      'INSTAGRAM',
      'DISCORD',
      'REDDIT',
      'LINKEDIN',
      'YOUTUBE',
      'TWITCH',
      'TWITTER',
      'FACEBOOK',
      'OTHER'
    ];
  }

  ngOnInit(): void {
    this.socialId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.influencerId = Number(this.activeRoute.snapshot.paramMap.get('influencerId'));

    if (this.socialId !== 0 && this.socialId != null) {
      this.socialRead();
    } else {
      this.social = new SocialDTO();
    }


  }

  socialSave(): void {
    if (this.socialId === 0) {
      this.socialCreate();
    } else {
      this.socialUpdate();
    }
  }

  //READ

  socialRead(): void {
    this.socialService.readById(this.socialId)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
        (res: SocialDTO) => {
          console.log('response : ' , res);
          this.social = res;
          this.socialId = res.id;
          this.dtoToForm();
          this.initialFavoriteValue = res.favorite;
        }
      );
    this.favoriteChanged = false;

  }

  //CREATE

  socialCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.socialService.createSocialByInfluencer(this.social, this.influencerId)
      .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: SocialDTO) => {
            console.log('response : ' , res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.social = res;
            console.log(res);
            this.socialId = this.social.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/social/detail/' + this.influencerId + '/' + res.id);
          }
        );
        this.favoriteChanged = false;
    }
  }

  // UPDATE

  socialUpdate(): void {
    if (this.form.valid && (this.form.dirty || this.favoriteChanged)) {
      this.confirm('Sei sicuro di voler sovrascrivere?')
      .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.socialService.update(this.social)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.socialRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  socialDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare il social?')
      .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.socialService.delete(this.social.id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
              (res) => {
                console.log('response : ' , res);
                this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
              }
            );
            console.log('entrato dopo viene il back');
            this.back('gestione-affiliation/detail/' + this.influencerId);
          }
        });
    }
  }


  modificaPreferito(): void {
    this.social.favorite = !this.social.favorite;
    if (this.social.favorite !== this.initialFavoriteValue) {
      this.favoriteChanged = true;
    } else {
      this.favoriteChanged = false;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {
    this.social.id = this.form.get('id').value;
    this.social.socialName = this.form.get('socialName').value.trim();
    this.social.socialType = this.form.get('socialType').value;
    this.social.socialLink = this.form.get('socialLink').value.trim();
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.social.id);
    this.form.get('socialName').setValue(this.social.socialName);
    this.form.get('socialType').setValue(this.social.socialType);
    this.form.get('socialLink').setValue(this.social.socialLink);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.social.insertDate));
    this.form.get('insertUser').setValue(this.social.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.social.updateDate));
    this.form.get('updateUser').setValue(this.social.updateUser);
    this.form.get('optLock').setValue(this.social.optLock);
    this.form.markAsPristine();
  }



}
