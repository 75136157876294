import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductService } from 'src/app/services/be-subscriptions/product/product.service';

import { PromotionService } from 'src/app/services/be-subscriptions/promotion/promotion.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { PromotionDTO } from 'src/app/shared/dto/be-subscriptions/promotion/PromotionDTO';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.scss']
})
export class PromotionDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  promotionId: number;
  promotion: PromotionDTO;
  check: boolean;
  products: ProductDTO[];

  productTranslations: Map<string, string> = ProductDTO.translations;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();


  constructor(
    private productService: ProductService,
    private promotionService: PromotionService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      code: new FormControl('', [Validators.required, Validators.maxLength(8)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      days: new FormControl('', [Validators.required, Validators.max(31), Validators.min(0)]),
      months: new FormControl('', [Validators.required, Validators.max(1024), Validators.min(0)]),
      product: new FormControl('', Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {

    this.promotionId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.promotionId !== 0 && this.promotionId != null) {
      this.promotionRead();
    } else {
      this.promotion = new PromotionDTO();
    }
    this.productService.readAll()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.products = res;
      });

  }

  promotionSave(): void {
    if (this.promotion.id == null) {
      this.promotionCreate();
    } else {
      this.promotionUpdate();
    }
  }

  //READ

  promotionRead(): void {
    this.promotionService.read(this.promotionId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: PromotionDTO) => {
          console.log('response : ' , res);
          this.check = res.active;
          this.promotion = res;
          this.promotionId = res.id;
          this.dtoToForm();
        }
      );
  }

  //CREATE

  promotionCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.promotionService.create(this.promotion)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: PromotionDTO) => {
            console.log('response : ' , res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.promotion = res;
            console.log(res);
            this.promotionId = this.promotion.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/promotion/detail/' + res.id);
          }
        );
    }
  }

  // UPDATE

  promotionUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere l\'asset mapping configuration?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.promotionService.update(this.promotion)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.promotionRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  promotionDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare l\'asset mapping configuration?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.promotionService.delete(this.promotion.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/promotion']);
                }
              );
          }
        });
    }
  }

  checkedControl(): boolean {
    if (this.form.get('active') == null) {
      return false;
    }
    return true;

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {
    this.promotion.code = this.form.get('code').value;
    this.promotion.description = this.form.get('description').value;
    this.promotion.idProduct = this.form.get('product').value;
    this.promotion.days = this.form.get('days').value;
    this.promotion.months = this.form.get('months').value;
    this.promotion.active = true;

  }
  private dtoToForm(): void {
    this.form.get('id').setValue(this.promotion.id);
    this.form.get('code').setValue(this.promotion.code);
    this.form.get('description').setValue(this.promotion.description);
    this.form.get('product').setValue(this.promotion.idProduct);
    this.form.get('days').setValue(this.promotion.days);
    this.form.get('months').setValue(this.promotion.months);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.promotion.insertDate));
    this.form.get('insertUser').setValue(this.promotion.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.promotion.updateDate));
    this.form.get('updateUser').setValue(this.promotion.updateUser);
    this.form.get('optLock').setValue(this.promotion.optLock);
    this.form.markAsPristine();
  }

}
