import { UserSubscriptionsService } from 'src/app/services/be-subscriptions/user-subscriptions/user-subscriptions.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { TicketService } from './../../services/help-desk/ticket.service';
import { TicketDTO } from './../../shared/dto/help-desk/ticket';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';

@Component({
  selector: 'app-ticket-management',
  templateUrl: './ticket-management.component.html',
  styleUrls: ['./ticket-management.component.scss']
})

export class TicketManagementComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratoriList: CollaboratoreDTO[];
  loggedUserName: string;
  displayedColumns: string[];
  form: FormGroup;
  parameters: any;
  ticket: TicketDTO;
  categories: string[] = ['SEGNALA_UN_ANOMALIA', 'RICHIESTA_INFORMAZIONI', 'PROBLEMI_SUI_PAGAMENTI', 'RICHIESTA_DI_UNA_NUOVA_FUNZIONALITÀ'];
  status: string[] = ['CREATA', 'IN_ATTESA_DI_FEEDBACK', 'IN_ATTESA_DI_RISPOSTA', 'CHIUSA'];

  usersArePaid: Map<string, boolean> = new Map();

  constructor(
    private ticketService: TicketService,
    componentCacheService: ComponentCacheService,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private userSubscriptionsService: UserSubscriptionsService) {
      super(navigatorService,
        componentCacheService,
        dateAdapter,
        router,
        snackBar,
        timestampFormatPipe);
        this.displayedColumns = ['detail','id', 'insertDate','updateDate','user', 'subject', 'status', 'category','paying','content'];
        this.parameters = {
          dataSourceIta: [],
          dataSourceEng: [],
          showList: false,
          engSelect: false,
          username: null,
          idTicket: null,
          status: null,
          dateFrom: null,
          dateTo: null,
          category:null,
          filter: null,
          sort: null,
          page: 0,
          size: 50,
          length: 0,
          filterEng: null,
          sortEng: null,
          pageEng: 0,
          sizeEng: 50,
          lengthEng: 0

        };
  }

  translateCategory(category): any{
    if(category === 'SEGNALA_UN_ANOMALIA'){ category = 'REPORT_AN_ANOMALY';}
    if(category === 'RICHIESTA_INFORMAZIONI'){ category = 'INFORMATION_REQUEST';}
    if(category === 'PROBLEMI_SUI_PAGAMENTI'){ category = 'PAYMENT_PROBLEMS';}
    if(category === 'RICHIESTA_DI_UNA_NUOVA_FUNZIONALITÀ'){ category = 'REQUEST_FOR_A_NEW_FEATURE';}

    return category;
  }

  list(): void{
    this.ticketService.ticketList(
      this.parameters.page,
      this.parameters.size,
      this.parameters.sort,
      this.parameters.filter,
      this.parameters.idTicket,
      this.parameters.status,
      this.parameters.dateFrom,
      this.parameters.dateTo,
      this.parameters.username,
      this.parameters.category).subscribe((res: PageDTO<TicketDTO>) => {

          this.parameters.dataSourceIta = res.content;
          this.parameters.pageNumber = res.page;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;

          const users = res.content
          .map(r => r.insertUser)
          .filter((elem, index, self) =>  index === self.indexOf(elem));
          this.userSubscriptionsService.usersArePaid(users)
          .subscribe(r => this.usersArePaid = r);

      },
      (err: HttpErrorResponse) =>{
        if(err.status === 401 || err.status === 403)
          {this.snackBar.open('Non sei autorizzato per fare questa operazione', null, { duration: 4000 });}
      });

      if(this.parameters.category != null){

        const category = this.translateCategory(this.parameters.category);

        this.ticketService.ticketList(
          this.parameters.pageEng,
          this.parameters.sizeEng,
          this.parameters.sort,
          this.parameters.filter,
          this.parameters.idTicket,
          this.parameters.status,
          this.parameters.dateFrom,
          this.parameters.dateTo,
          this.parameters.username,
          category).subscribe((res: PageDTO<TicketDTO>) => {

              this.parameters.dataSourceEng = res.content;
              this.parameters.pageNumberEng = res.page;
              this.parameters.lengthEng = res.totalElements;
              this.parameters.engSelect = true;

              const users = res.content
              .map(r => r.insertUser)
              .filter((elem, index, self) =>  index === self.indexOf(elem));
              this.userSubscriptionsService.usersArePaid(users)
              .subscribe(r => this.usersArePaid = r);

          });
      }
      else{
        this.parameters.engSelect = false;
      }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.navigatorService.collaboratore$.subscribe(
      (res: CollaboratoreDTO) => {
        this.loggedUserName = res.nome;
      }
    );
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  isTicketPaying(ticket: TicketDTO): boolean {
    return this.usersArePaid?.[ticket?.insertUser];
  }

}
