import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GenericService<DTO, ID> {

    protected url: string;

    constructor(protected http: HttpClient) { }

    public create(dto: DTO): Observable<DTO> {
        return this.http.post<DTO>(`${this.url}`, dto);
    }

    public read(id: ID): Observable<DTO> {
        return this.http.get<DTO>(`${this.url}/${id}`);
    }

    public update(dto: DTO): Observable<void> {
        return this.http.put<void>(`${this.url}`, dto);
    }

    public delete(id: ID): Observable<void> {
        return this.http.delete<void>(`${this.url}/${id}`);
    }
}
