import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentCacheService } from '../services/component-cache.service';
import { TimeseriesDownloadConfigurationService } from '../services/cryptocompare-timeseries/timeseries-download-configuration.service';
import { NavigatorService } from '../services/navigator.service';
import { TimeseriesDownloadConfigurationDTO } from '../shared/dto/criptocompare-timeseries/timeseriesDownloadConfiguretionDto';
import { PageDTO } from '../shared/dto/PageDTO';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { TimestampFormatPipe } from '../shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-di-cryptocompare-timeseries',
  templateUrl: './di-cryptocompare-timeseries.component.html',
  styleUrls: ['./di-cryptocompare-timeseries.component.scss']
})
export class CryptocompareTimeseriesComponent extends GenericListComponent  implements OnInit, OnDestroy  {

  constructor(
    private timeseriesDownloadConfigurationService: TimeseriesDownloadConfigurationService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
) {
  super(navigatorService,
    componentCacheService,
    dateAdapter,
    router,
    snackBar,
    timestampFormatPipe);
    this.displayedColumns = ['symbol', 'hourDownloadTimeframe', 'lastTimeframedDownload', 'nextTimeframedDownload',
    'fromSymbol', 'toSymbol', 'exchange', 'priviledged', 'useCadli', 'configurationStatus', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
};
}

list(): void{
  this.timeseriesDownloadConfigurationService.timeseriesDownloadConfiguration(
    this.parameters.page,
    this.parameters.size,
    this.parameters.sort,
    this.parameters.filter
  ).subscribe((response: PageDTO<TimeseriesDownloadConfigurationDTO>) => {
    console.log('response : ' , response);
    this.parameters.dataSource = response.content;
    this.parameters.page = response.page;
    this.parameters.length = response.totalElements;
    this.parameters.showList = true;
    }
  );
}

ngOnInit(): void {
  super.ngOnInit();
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}


}
