import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
import { PositionsInfoDTO } from 'src/app/shared/dto/positions-container/PositionsInfoDTO';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';



@Injectable()
export class PositionsContainerService extends GenericService <PositionsInfoDTO, number> {

    url: string;


  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-portfolio/positionsContainer';
  }

  public readInfos(containerCodeType: string, containerType: string, pageable: Pageable): Observable<PageDTO<PositionsInfoDTO>>{
    const params = {};
    if (containerCodeType != null) {
      params['containerCodeType'] = containerCodeType;
    }
    if (containerType != null) {
      params['containerType'] = containerType;
    }
    if (pageable.page != null) {
      params['page'] = pageable.page;
    }
    if (pageable.size != null) {
      params['size'] = pageable.size;
    }
    if (pageable.sort != null) {
      params['sort'] = pageable.sort;
    }
    return this.http.get<PageDTO<PositionsInfoDTO>>(`${this.url}/info`, {params : params});
  }

}
