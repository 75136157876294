import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { AdvertisementService } from '../services/advertisements/advertisement.service';
import { languages } from 'src/app/shared/dto/languages.types';

@Component({
  selector: 'app-campaigns',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss'],
  providers: [
    AdvertisementService
  ]
})
export class AdvertisementsComponent extends GenericListComponent implements OnInit, OnDestroy {
  today: Date = new Date();
  readonly languages = languages;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private advService: AdvertisementService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'campaigner', 'visualizationStart', 'visualizationEnd', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      advertiser: undefined,
      visualizationDate: undefined,
      sort: undefined,
      page: 0,
      size: 10,
      length: 0
    };
  }

  list(): void {
    this.advService.query({page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
         this.parameters.advertiser, this.parameters.visualizationDate).pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe((advs) => {
      this.parameters.dataSource = advs.content;
      this.parameters.page = advs.page;
      this.parameters.length = advs.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
