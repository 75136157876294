import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PositionsContainerDTO } from 'src/app/shared/dto/positions-container/PositionsContainerDTO';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExcelExporterService {
  protected url: string = environment.urlPrefix;

  constructor(protected http: HttpClient) { }

  downloadReport(container: PositionsContainerDTO, symbolTo: string, locale: string): Observable<Blob> {
    const route: string = '/arzakena-be-excel-exporter/excel/checkSquaring/positionsContainer/';
    return this.http.post<Blob>(`${this.url}${route}${symbolTo}/${locale}`, container, { responseType: 'blob' as 'json' });
  }
}
