import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from './tenant';

export class CollaboratoreDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    email: string;
 	userName: string;
    nome: string;
    cognome: string;
	telefono: string;
	sede: string;
	piano: string;
	ufficio: string;
	postazione: string;
	ruolo: string;
}
