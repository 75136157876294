import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AffiliationReportService } from '../services/affiliation-report/affiliation-report.service';
import { AffiliationReportDTO } from '../shared/dto/affiliation-report/AffiliationReportDTO';

@Component({
  selector: 'app-affiliation-report',
  templateUrl: './affiliation-report.component.html',
  styleUrls: ['./affiliation-report.component.scss'],
  providers: [
    AffiliationReportService
  ]
})
export class AffiliationReportComponent implements OnInit, OnDestroy, OnChanges {

  @Input() influencerId: number;
  @Input() codeId: number;
  //if true this will report by influencer else by code
  @Input() influencerOrCode: boolean;
  //Can be passed to select the date
  @Input() date: Date;
  @Input() applyMargin: boolean=false;

  affiliationReport: AffiliationReportDTO;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private affiliationReportService: AffiliationReportService
  ) {

  }

  ngOnInit(): void {
    this.loadReport();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.date = changes['date']?.currentValue;
    this.loadReport();
  }

  private loadReport(): void{
    if (this.influencerOrCode) {
      this.affiliationReportService.getTheReportByInfluencer(this.influencerId, this.date)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: AffiliationReportDTO) => {
          this.affiliationReport = res;
        });
    } else {
      this.affiliationReportService.getTheReportByCode(this.codeId, this.date)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: AffiliationReportDTO) => {
          this.affiliationReport = res;
        });
    }
  }
}

