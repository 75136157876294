import { NotificationChannel, NotificationMethod, NotificationUserType} from '../NotificationTypes';

export class TriggerNotificationDTO {

    triggerNotificationType: NotificationUserType;

    timestamp: Date;

    categoryBundleKey: string;

    channel: NotificationChannel;

    method: NotificationMethod;

    logicalKey: string;

    templateBundleKey: string;

    parameters: any;

    attachments: any;

    url: string;
}

export class TriggerNotificationSingleUserDto extends TriggerNotificationDTO {

    user: string;

    email: string;

}
export class TriggerNotificationUsersDto extends TriggerNotificationDTO {

    users: string[];

}
