import { TraceModificationDTO } from '../basic/traceModificationDTO';
import { TimeseriesDownloadConfigurationStatus } from './TimeseriesDownloadConfigurationStatus.types';

export class TimeseriesDownloadConfigurationDTO extends TraceModificationDTO{

    id: number;

    symbol: string;

    hourDownloadTimeframe: number;

	lastTimeframedDownload: Date;

	nextTimeframedDownload: Date;

    fromSymbol: string;

    toSymbol: string;

    exchange: string;

    priviledged: boolean;

    useCadli: boolean;

    configurationStatus: TimeseriesDownloadConfigurationStatus;

}
