<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione containers</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">

            <mat-form-field>
                <mat-label>Container Type</mat-label>
                <mat-select [(ngModel)]="positionsContainerType" (selectionChange)="updateMismatch()" name="p">
                    <mat-option *ngFor="let position of positionContainerTypes" [value]="position">
                        {{ position }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="positionsContainerType === 'EXCHANGE_ACCOUNT'">
                <mat-label>Exchange Type</mat-label>
                <mat-select [(ngModel)]="containerCodeType"  (selectionChange)="updateMismatch()" name="exchange">
                    <mat-option *ngFor="let exchange of exchangeTypes" [value]="exchange">
                        {{ exchange }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="positionsContainerType === 'WALLET'">
                <mat-label>Wallet Type</mat-label>
                <mat-select [(ngModel)]="containerCodeType" (selectionChange)="updateMismatch()"  name="blockchain">
                    <mat-option *ngFor="let blockchain of blockchainTypes" [value]="blockchain">
                        {{ blockchain }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="positionsContainerType">
                <mat-label>Code</mat-label>
                <input matInput [(ngModel)]="code" placeholder="symbol" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Fiat Currency Type</mat-label>
                <mat-select [(ngModel)]="selectedCurr" (selectionChange)="updateMismatch()"  name="blockchain">
                    <mat-option *ngFor="let currency of currencies" [value]="currency">
                        {{ currency }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Locale language</mat-label>
                <mat-select [(ngModel)]="selectedLocale" (selectionChange)="updateMismatch()"  name="blockchain">
                    <mat-option *ngFor="let locale of locales" [value]="locale">
                        {{ locale }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="downloadReport()" [disabled]="!isDownloadReady()" name="Search" color="primary">Download Report</button>
            <button mat-raised-button (click)="back('')" color="primary">Back</button>
        </mat-card-actions>
        <br />
    </mat-card-content>
</mat-card>