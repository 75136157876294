<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Promotions</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">
    
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.idFilter" placeholder="ID" />
            </mat-form-field>

            <mat-form-field>
                <input matInput [(ngModel)]="parameters.codeFilter" placeholder="Code" />
            </mat-form-field>

            <mat-form-field>
                <input matInput [(ngModel)]="parameters.descriptionFilter" placeholder="Description" />
            </mat-form-field>

            <mat-form-field>
              <mat-select multiple [(ngModel)]="this.parameters.productIds" placeholder="Product" #productSelect>
                <mat-option *ngFor="let product of products" [value]="product.id">
                    {{productTranslations.get(product.name)}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox matCheckbox [indeterminate]="parameters.active === undefined"
                [checked]="parameters.active !== undefined && parameters.active"
                (change)="changeBool(parameters.active, 'active')">Active?</mat-checkbox>
    
        </div>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
                <button mat-raised-button id="new-button" color="basic" [routerLink]="['/promotion/detail/0']">New
                    Promotion</button>

                <button mat-raised-button id="new-button" color="primary"
                    [routerLink]="['/promotion-generation']">Promotions
                    generator</button>

                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                    <td mat-cell *matCellDef="let promotion"> {{promotion.id}} </td>
                </ng-container>

                <!-- code Column -->
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> code </th>
                    <td mat-cell *matCellDef="let promotion"> {{promotion.code}} </td>
                </ng-container>

                <!-- description Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> description </th>
                    <td mat-cell *matCellDef="let promotion"> {{promotion.description}} </td>
                </ng-container>

                <!-- idProduct Column -->
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> product </th>
                    <td mat-cell *matCellDef="let promotion"> {{productsName.get(promotion.idProduct)}} </td>
                </ng-container>

                <!-- days Column -->
                <ng-container matColumnDef="days">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Days </th>
                    <td mat-cell *matCellDef="let promotion"> {{promotion.days}} </td>
                </ng-container>

                <!-- months Column -->
                <ng-container matColumnDef="months">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Months </th>
                    <td mat-cell *matCellDef="let promotion"> {{promotion.months}} </td>
                </ng-container>

                <!-- active Column -->
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> active </th>
                    <td mat-cell *matCellDef="let promotion">
                        <mat-icon *ngIf="promotion.active" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!promotion.active" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let asset">
                        <button mat-mini-fab color="primary" [routerLink]="['/promotion/detail/', asset.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>