<mat-card >

    <mat-card-content>
        <div fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisabled [dataSource]="users">

                <!-- socialName Column -->
                <ng-container matColumnDef="usersName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> UserUsername </th>
                    <td mat-cell *matCellDef="let user"> {{user}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user">
                        <button mat-mini-fab color="primary"  [routerLink]="['/users/detail/'+ influencerId + '/' + user]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
    </mat-card-content>
</mat-card>