import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GenericService } from 'src/app/shared/GenericService';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import {
  DataIntegrationStatus,
  DataIntegrationType
} from 'src/app/shared/dto/dataintegration-catalog/DataIntegrationStatus';
import { environment } from 'src/environments/environment';
import { DataIntegrationDto } from '../../shared/dto/dataintegration-catalog/DataIntegrationDto';

@Injectable({
  providedIn: 'root'
})
export class DataintegrationService extends GenericService<DataIntegrationDto, number> {
  constructor(protected http: HttpClient) {
    super(http);
    // this.url = 'http://localhost:31000/dataIntegrationCatalog';
    this.url = environment.urlPrefix + '/arzakena-be-dataintegration-catalog/dataIntegrationCatalog';
  }

  public dataIntegrationReadAllActive(pageable: Pageable, filter?: string): Observable<DataIntegrationDto[]> {
    const params = {};
    if (pageable?.page != null) {params['page'] = pageable.page;}
    if (pageable?.size != null) {params['size'] = pageable.size;}
    if (pageable?.sort != null) {params['sort'] = pageable.sort;}
    if (filter != null) {
      params['filter'] = filter;
      return this.http.get<DataIntegrationDto[]>(`${this.url}/${filter}`, { params: params });
    } else {
      return this.http.get<DataIntegrationDto[]>(`${this.url}/`, { params: params });
    }
  }


   query(pageable: Pageable, filter?: string, status?: DataIntegrationStatus, type?: DataIntegrationType): Observable<PageDTO<DataIntegrationDto>> {
     const params = {'filter': filter + '', 'status': status + '',
          'type': type+ '', 'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
     Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
     return this.http.get<PageDTO<DataIntegrationDto>>(`${this.url}/query`, {params: params});
   }

}
