<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Change logs</h2>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/changeLogs/detail/0']">New</button>
      <button mat-raised-button (click)="back('/')" color="primary">Back</button>
    </mat-card-actions>
    <br />

    <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
      <ng-container *ngIf="parameters.dataSource.length; else noContent">
        
        <h2>Lista Change Logs</h2>
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort (matSortChange)="setSort($event)"
          matSortDisableClear [dataSource]="parameters.dataSource">

          <!-- id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let changeLog"> {{changeLog.id}} </td>
          </ng-container>

          <!-- version Column -->
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
            <td mat-cell *matCellDef="let changeLog"> {{changeLog.version}} </td>
          </ng-container>

          <!-- date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let changeLog"> {{changeLog.date | date :'dd/MM/yyyy'}}</td>
          </ng-container>

          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let changeLog">
              <button mat-mini-fab color="primary" [routerLink]="['/changeLogs/detail/', changeLog.id]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </ng-container>
      <ng-template #noContent>
        <h2>Nessun change log trovato</h2>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>