<form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
  <mat-form-field>
    <input type="text" placeholder="Symbol" matInput maxlength="32" [value]="currentSymbol"
      (input)="symbolChanged()" formControlName="inputSymbol">
  </mat-form-field>
  <!-- Campi di informazione sull'asset -->
  <mat-expansion-panel class="mat-elevation-z0" *ngIf="form.controls['symbol'].value && assetDefinition">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Dettagli asset
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="row">
      <!-- Symbol field -->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
        [ngStyle.md]="{'padding-right.px': 0}">
      <input matInput formControlName="symbol" placeholder="Symbol" readonly="true" />
      </mat-form-field>

      <!-- AssetDefinitionType field -->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <input matInput formControlName="type" placeholder="Asset Type" readonly="true" />
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <!-- Fullname field -->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
        [ngStyle.md]="{'padding-right.px': 0}">
      <input matInput formControlName="fullName" placeholder="Full name" readonly="true" />
      </mat-form-field>

      <!-- Name field -->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
      <input matInput formControlName="coinName" placeholder="Coin name" readonly="true" />
      </mat-form-field>
    </div>
    <div fxLayout="row">
      <!-- Description field -->
      <mat-form-field fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
        [ngStyle.md]="{'padding-right.px': 0}">
      <textarea matInput formControlName="description" placeholder="Description" readonly="true"></textarea>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</form>