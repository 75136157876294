import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialDTO } from 'src/app/shared/dto/influencer/SocialDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocialService extends GenericService<SocialDTO, number>{



  constructor(http: HttpClient) {
    super(http);
    // this.url = "http://localhost:30900/social/admin";
    this.url = environment.urlPrefix + '/arzakena-be-affiliation/social/admin';
  }

  readById(id: number): Observable<SocialDTO>{
    return this.http.get<SocialDTO>(`${this.url}/readById/${id}`);
  }

  createSocialByInfluencer(social: SocialDTO, influencerId: number): Observable<SocialDTO> {
    return this.http.post<SocialDTO>(`${this.url}/${influencerId}`, social);
  }
}
