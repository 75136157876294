<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Asset Definition History</h2>
        <p>Imposta il filtro</p>
        <div fxLayout="column" class="responsive-mat-form">

            <mat-form-field>
                <input matInput [(ngModel)]="parameters.symbol" placeholder="Symbol" />
            </mat-form-field>

        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button" name="Search" (click)="list()" (click)="parameters.symbol != null && parameters.symbol!= '' ? list() : error() "  color="primary">Search</button>
            <button mat-raised-button (click)="back('/')" color="primary">Back</button>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- symbol Column -->
                <ng-container matColumnDef="symbol">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Symbol </th>
                    <td mat-cell *matCellDef="let asset"> {{asset.version.symbol}} </td>
                </ng-container>

                <!-- decimalPoints Column -->
                <ng-container matColumnDef="decimalPoints">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Decimal Points </th>
                    <td mat-cell *matCellDef="let asset"> {{asset.version.decimalPoints}} </td>
                </ng-container>

                <!-- From Column -->
                <ng-container matColumnDef="validFrom">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> From </th>
                    <td mat-cell *matCellDef="let asset"> {{asset.from}} </td>
                </ng-container>

                <!-- To Column -->
                <ng-container matColumnDef="validTo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> To </th>
                    <td mat-cell *matCellDef="let asset"> {{asset.to}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let asset">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/assetDefinitionHistory/detail/', asset.version.symbol]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>