<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Quadrature Stripe</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.username" placeholder="Username" />
            </mat-form-field>

            <div fxLayout="row" class="responsive-mat-form">
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.startDateTo"
                        [(ngModel)]="parameters.startDateFrom" [matDatepicker]="picker" placeholder="Start Date from">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="parameters.startDateFrom" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.startDateFrom=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.startDateFrom"
                        [(ngModel)]="parameters.startDateTo" [matDatepicker]="picker2" placeholder="Start Date to">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <button mat-button *ngIf="parameters.startDateTo" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.startDateTo=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="responsive-mat-form">
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker3.open()" [(max)]="parameters.endDateTo"
                        [(ngModel)]="parameters.endDateFrom" [matDatepicker]="picker3" placeholder="End Date from">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                    <button mat-button *ngIf="parameters.endDateFrom" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.endDateFrom=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker4.open()" [(min)]="parameters.endDateFrom"
                        [(ngModel)]="parameters.endDateTo" [matDatepicker]="picker4" placeholder="End Date to">
                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                    <button mat-button *ngIf="parameters.endDateTo" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.endDateTo=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
    
        </div>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList && parameters.dataSource && parameters.dataSource.length > 0" fxLayout="column">
            <h2>Lista Utenti con Card Subscription Attiva</h2>
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <ng-container matColumnDef="user.id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                    <td mat-cell *matCellDef="let user"> {{user.id}} </td>
                </ng-container>

                <ng-container matColumnDef="user.username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
                    <td mat-cell *matCellDef="let user"> {{user.username}} </td>
                </ng-container>

                <ng-container matColumnDef="user.renewable">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Rinnovabile? </th>
                    <td mat-cell *matCellDef="let user"> {{isRenewable(user.renewable)}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user">
                        <button mat-mini-fab color="primary" [routerLink]="['/stripe/detail/', user.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>