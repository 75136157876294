import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeseriesDTO } from 'src/app/shared/dto/core-timeseries/timeseriesDto';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeseriesService extends GenericService<TimeseriesDTO, number>{
 
  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-core-timeseries/timeseries';
  }

  public reset(symbol: string): Observable<void> {
    return this.http.put<void>(`${this.url}/reset/${symbol}`, {})
  }

}
