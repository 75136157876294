<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent"  *ngIf="this.form">
    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Managed Symbol</h2>
      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
          <!-- symbol form to make sure that the symbol exists in catalog -->
          <asset-definition-input [assetDefinition]="form.get('symbol')?.value" (assetDefinitionChangedEvent)="form.controls['symbol'].setValue($event?.assetDefinition?.symbol)"></asset-definition-input>

          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0" *ngIf="form.get('insertDate').value">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>

              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>

              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div> 
          </mat-expansion-panel>
        </form>
      </div>

      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/timeseriesDownloadConfiguration')" color="primary">Back</button>
        <button mat-raised-button (click)="save()" [disabled]="form.invalid">
          Save
        </button>
      </mat-card-actions>
    </div>
  </mat-card-content>
</mat-card>