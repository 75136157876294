import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { MatTab, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit{
  @ViewChild('tabs', { static: false }) tabs!: MatTabGroup;
  collaboratore: CollaboratoreDTO;

  today = new Date();
  selectedIndex = 0;
  untilNextWeek = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() +7,
    );

  parameters = {
    dataSource: []
  };

  constructor(private navigatorService: NavigatorService,
    private componentCacheService: ComponentCacheService) {
    }
  ngAfterViewInit(): void {
    let i = 0;
    if (this.tabs._tabs != null) {
      for (const x of this.tabs._tabs) {
        sessionStorage.setItem(x.ariaLabel, i.toString());
        i++;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedIndex = changes['selectedIndex'].currentValue;
    sessionStorage.setItem('selectedTab', this.selectedIndex.toString());
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('selectedTab')) {
      this.selectedIndex = Number(sessionStorage.getItem('selectedTab'));
      sessionStorage.removeItem('selectedTab');
    }
    this.navigatorService.collaboratore$.pipe(take(1)).subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
      }
    );
  }

  isSameDate(d1: Date, d2: Date): boolean {
    if(d1 == null || d2 == null) {
      return false;
    } else {
      return dayjs(d1).isSame(d2, 'day');
    }
  }

  isLoggedIn(): boolean {
    return this.navigatorService.isLoggedIn();
  }

  logout(): void {
    this.navigatorService.logout();
  }

  isActive(value: string): boolean {
    return this.navigatorService.isActive(value);
  }

  ngOnDestroy(): void {
    this.componentCacheService.set(HomeComponent.name, this.parameters);
    sessionStorage.setItem('selectedTab', this.selectedIndex.toString());
  }

  get environmentName(): string {
    return environment.environmentName;
  }
}
