
export class SymbolMappingDto {

    oldSymbol: string;

    newSymbol: string;

    constructor() {
        this.oldSymbol = '';
        this.newSymbol = '';
    }
}
