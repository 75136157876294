import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { NotificationMessageDTO } from 'src/app/shared/dto/be-notifications/notification/NotificationMessageDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationQueueService extends GenericService<NotificationMessageDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-notifications/queue';
    //this.url = 'http://localhost:31300/queue';
  }

  public stopNotification(): Observable<void> {
    return this.http.put<void>(`${this.url}/notification/stop`, {});
  }

  public startNotification(): Observable<void> {
    return this.http.put<void>(`${this.url}/notification/start`, {});
  }

  public loadNotificationActive(): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/notification`);
  }

  public stopMail(): Observable<void> {
    return this.http.put<void>(`${this.url}/mail/stop`, {});
  }

  public startMail(): Observable<void> {
    return this.http.put<void>(`${this.url}/mail/start`, {});
  }

  public loadMailActive(): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/mail`);
  }
}
