import { Pageable } from './../../shared/dto/Pageable.types';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { UserSubscriptionsService } from 'src/app/services/be-subscriptions/user-subscriptions/user-subscriptions.service';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserSubscriptionsDTO } from 'src/app/shared/dto/be-subscriptions/user-subscriptions/UserSubscriptionsDTO';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss']
})
export class UserSubscriptionsComponent extends GenericListComponent implements OnInit {

  formUser: FormGroup;

  invalidParameters: boolean = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private userSubscriptionsService: UserSubscriptionsService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'username', 'active', 'renewable', 'promotionTries', 'trialUsed', 'freeUsed', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      idFilter: undefined,
      username: undefined,
      active: undefined,
      renewable: undefined,
      trialUsed: undefined,
      freeUsed: undefined,
      sort: undefined,
      page: 0,
      size: 50,
      length: 0
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  list(): void {
    this.userSubscriptionsService.userList(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
      this.parameters.idFilter, this.parameters.username, this.parameters.active,
      this.parameters.renewable, this.parameters.trialUsed, this.parameters.freeUsed
    ).pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: PageDTO<UserSubscriptionsDTO>) => {
        this.parameters.dataSource = res.content;
        this.parameters.page = res.page;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      });
  }

  changeBool(value: boolean, field: string): void{
    if(value === undefined){
      this.parameters[field] = true;
    } else if (value) {
      this.parameters[field] = false;
    } else {
      this.parameters[field] = undefined;
    }
  }

}
