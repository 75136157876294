import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  public actualUrl: string = '';
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  constructor() { }

  setPreviousUrl(previousUrl: string): void{
    if(previousUrl !== this.actualUrl){
      this.previousUrl.next(previousUrl);
    }
  }

  setActualUrl(actualUrl: string): void{
    this.actualUrl = actualUrl;
  }
}
