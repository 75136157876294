import { TraceModificationDTO } from '../../basic/traceModificationDTO';

export class AsyncProcessErrorEventDTO extends TraceModificationDTO {

    id: number;

    source: string;

    timestamp: Date;

	failedClass: string;

	exceptionClass: string;

    message: string;

    stacktrace: string;

}
