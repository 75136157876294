import { Pageable } from './../../../shared/dto/Pageable.types';
import { SubscriptionType } from './../../../shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { PageDTO } from '../../../shared/dto/PageDTO';
import { SubscriptionDTO } from '../../../shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends GenericService<SubscriptionDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/subscription';
    //this.url = "http://localhost:30300/product/admin/products"
  }

  public readAll(pageable: Pageable, idFilter: number, startDateFrom: Date, startDateTo: Date,
    endDateFrom: Date, endDateTo: Date, active: boolean, paid: boolean,
    productId: number, type: SubscriptionType): Observable<PageDTO<SubscriptionDTO>> {
      const startDateFromString = (startDateFrom === undefined)? undefined : new Date(new Date(startDateFrom).getTime()).toISOString();
      const startDateToString = (startDateTo === undefined)? undefined : new Date(new Date(startDateTo).getTime()).toISOString();
      const endDateFromString = (endDateFrom === undefined)? undefined : new Date(new Date(endDateFrom).getTime()).toISOString();
      const endDateToString = (endDateTo === undefined)? undefined : new Date(new Date(endDateTo).getTime()).toISOString();
      const params = {'idSubscription': idFilter + '',
      'startDateFrom': startDateFromString + '',
      'startDateTo': startDateToString + '',
      'endDateFrom': endDateFromString + '',
      'endDateTo': endDateToString + '',
      'active': active + '',
      'paid': paid + '',
      'idProduct': productId + '',
      'type': type + '',
      'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
    return this.http.get<PageDTO<SubscriptionDTO>>(`${this.url}/admin/listAll`, { params: params });
  }

  public deactive(username: string): Observable<SubscriptionDTO>{
    if(username != null){
      return this.http.put<SubscriptionDTO>(`${this.url}/admin/deactivate/`+ username, {});
    }
  }

  public userSubscriptionsList(page: number, size: number, sort: string, username: string): Observable<PageDTO<SubscriptionDTO>>{
    const params = {};
    if(username != null){
      if (page != null) {params['page'] = page;}
      if (size != null) {params['size'] = size;}
      if (sort != null) {params['sort'] = sort;}
      return this.http.get<PageDTO<SubscriptionDTO>>(`${this.url}/admin/list/` + username + '/USD', { params: params });
    }
  }

  public getUsername(idSub: number): Observable<any> {
    return this.http.get<any>(`${this.url}/admin/getUser/${idSub}`, {responseType: 'text' as 'json'});
  }

}
