<h1 style="text-align:center;">Gestione degli abbonamenti e codici promozionali</h1>
<br>
<div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
  <buttons-component [btnTxt]="'Promotions'" [btnIcon]="'local_activity'" [btnLink]="['/promotion']">
  </buttons-component>
  <buttons-component [btnTxt]="'Products'" [btnIcon]="'inventory_2'" [btnLink]="['/product']">
  </buttons-component>
  <buttons-component [btnTxt]="'User Subscriptions'" [btnIcon]="'account_circle'" [btnLink]="['/user-subscriptions']">
  </buttons-component>
  <buttons-component [btnTxt]="'Price Exchanges'" [btnIcon]="'currency_exchange'" [btnLink]="['/price-exchange']">
  </buttons-component>
  <buttons-component [btnTxt]="'Subscriptions'" [btnIcon]="'subscriptions'" [btnLink]="['/subscription']">
  </buttons-component>
  <buttons-component [btnTxt]="'Receipts'" [btnIcon]="'receipt'" [btnLink]="['/receipt']">
  </buttons-component>
  <buttons-component [btnTxt]="'Extensions'" [btnIcon]="'expand'" [btnLink]="['/subscription-extension']">
  </buttons-component>
  <buttons-component [btnTxt]="'Quadrature Stripe'" [btnIcon]="'assignment_turned_in'" [btnLink]="['/stripe']">
  </buttons-component>
</div>