<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Notification Queue</h2>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [dataSource]="parameters.dataSource">
                <!-- id Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let queueType"> {{queueType}} </td>
                </ng-container>

                <!-- status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let queueType">
                        <ng-container *ngIf="{value: getStatus(queueType) | async} as context">
                            <ng-container *ngIf="context?.value || context?.value === false">
                                <span [ngStyle]="{'color': (context.value)? 'green' : 'red'}"><b>
                                    {{(context.value)? 'Active' : 'Inactive'}}
                                </b></span>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let queueType">
                        <button mat-raised-button color="primary" (click)="startQueue(queueType)">Start</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="stop">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let queueType">
                        <button mat-raised-button color="warn" (click)="stopQueue(queueType)" >Stop</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>
