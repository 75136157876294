import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TranslationDto } from './TranslationDto.types';

export class AdvertisementDto extends LogicalDeleteDTO{

	/**
	 * Message id
	 */
    id: number;

	/**
	 * Message's title (es: privacyV1)
	 */
    campaigner: string;

    /**
     * Url of the image
     */
    imageUrl: string;

    /**
     * Url to the site
     */
    redirectUrl: string;

    /**
     * Date of the beginning of the period of visualization
     */
    visualizationStart: Date;

    /**
     * Date of the ending of the period of visualization
     */
    visualizationEnd: Date;

    /**
     * List of the translations
     */
    translations: TranslationDto[];
    constructor() {
        super();
        this.id = undefined;
        this.campaigner = '';
        this.imageUrl = '';
        this.redirectUrl = '';
        this.visualizationStart = undefined;
        this.visualizationEnd = undefined;
        this.translations = [];
    }
}
