<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Receipts</h2>
        <p>Imposta il filtro di ricerca</p>
        
        <div fxLayout="column" class="responsive-mat-form">
    
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.idFilter" placeholder="ID" />
            </mat-form-field>
            
            <mat-form-field>
                <mat-select [(ngModel)]="parameters.receiptType" placeholder="Receipt Type" #typeSelect>
                    <mat-option *ngIf="!typeSelect.empty && typeSelect.panelOpen"></mat-option>
                    <mat-option *ngFor="let type of types" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div fxLayout="row" class="responsive-mat-form">
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.startDateTo"
                        [(ngModel)]="parameters.startDateFrom" [matDatepicker]="picker" placeholder="Start Date from">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="parameters.startDateFrom" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.startDateFrom=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.startDateFrom"
                        [(ngModel)]="parameters.startDateTo" [matDatepicker]="picker2" placeholder="Start Date to">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <button mat-button *ngIf="parameters.startDateTo" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.startDateTo=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="responsive-mat-form">
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker3.open()" [(max)]="parameters.endDateTo"
                        [(ngModel)]="parameters.endDateFrom" [matDatepicker]="picker3" placeholder="End Date from">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                    <button mat-button *ngIf="parameters.endDateFrom" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.endDateFrom=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker4.open()" [(min)]="parameters.endDateFrom"
                        [(ngModel)]="parameters.endDateTo" [matDatepicker]="picker4" placeholder="End Date to">
                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                    <button mat-button *ngIf="parameters.endDateTo" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.endDateTo=undefined">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
    
            <div fxLayout="row" class="responsive-mat-form">
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput [(ngModel)]="parameters.idSubscription" placeholder="ID Subscription" />
                </mat-form-field>
        
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput [(ngModel)]="parameters.idUser" placeholder="ID User" />
                </mat-form-field>
            </div>
    
            <div fxLayout="row" class="responsive-mat-form">
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput [(ngModel)]="parameters.idInfluencerAffiliation" placeholder="ID Affiliation influencer" />
                </mat-form-field>
        
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput [(ngModel)]="parameters.idCodeAffiliation" placeholder="ID Affiliation code" />
                </mat-form-field>
            </div>

        </div>

        <mat-card-actions>
            <div fxLayout="row">
                <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
                <button mat-raised-button (click)="back('/')" color="primary">Back</button>
            </div>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
            (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.id}} </td>
                </ng-container>

                <!-- receiptType Column -->
                <ng-container matColumnDef="receiptType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> receiptType </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.receiptType}} </td>
                </ng-container>

                <!-- startDate Column -->
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> startDate </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.startDate| date :'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <!-- endDate Column -->
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> endDate </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.endDate| date :'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <!-- idSub Column -->
                <ng-container matColumnDef="idSub">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> idSub </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.subscription.id}} </td>
                </ng-container>

                <!-- idUser Column -->
                <ng-container matColumnDef="idUser">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> idUser </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.user.id}} </td>
                </ng-container>

                <!-- idInfluencer Column -->
                <ng-container matColumnDef="idInfluencerAffiliation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> idInfluencerAffiliation </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.idInfluencerAffiliation}} </td>
                </ng-container>

                <!-- idCodeAffiliation Column -->
                <ng-container matColumnDef="idCodeAffiliation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> idCodeAffiliation </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.idCodeAffiliation}} </td>
                </ng-container>

                <!-- ammountPaid Column -->
                <ng-container matColumnDef="ammountPaid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ammountPaid </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.ammountPaid | number : '1.2-2'}} </td>
                </ng-container>

                <!-- currency Column -->
                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> currency </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.currency}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let receipt">
                        <button mat-mini-fab color="primary" [routerLink]="['/receipt/detail/', receipt.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>