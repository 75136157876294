import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReceiptService } from 'src/app/services/be-subscriptions/receipt/receipt.service';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { ReceiptDTO } from 'src/app/shared/dto/be-subscriptions/receipt/ReceiptDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'user-subscription-receipts',
  templateUrl: './user-subscription-receipts.component.html',
  styleUrls: ['./user-subscription-receipts.component.scss']
})
export class UserSubscriptionReceiptsComponent extends GenericListComponent implements OnInit {

  userSubscriptionsUsername: string;

  translations: Map<string, string> = ProductDTO.translations;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private receiptService: ReceiptService,
    private activeRoute: ActivatedRoute,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'startDate', 'endDate', 'subscription.active', 'subscription.paid', 'subscription.id', 'receiptType', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sort: undefined,
      page: 0,
      size: 50,
      length: 0
    };
   }

  ngOnInit(): void {
    this.userSubscriptionsUsername = (this.activeRoute.snapshot.paramMap.get('username'));
    super.ngOnInit();
    this.list();
  }

  list(): void {
    this.receiptService.userSubscriptionList(this.userSubscriptionsUsername,
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.parameters.dataSource = res.content;
        this.parameters.page = res.page;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      });
  }


}
