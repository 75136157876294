import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncProcessErrorEventDTO } from 'src/app/shared/dto/core-error-handling/errorDto/asyncProcessErrorEventDto';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorEventService extends GenericService<AsyncProcessErrorEventDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-core-errors-handling/errorEvent';
  }

  public errorEventList(page: number, size: number, sort: string, source?: string,
    dataDa?: Date, dataA?: Date, failedClass?: string ): Observable<PageDTO<AsyncProcessErrorEventDTO>> {
    const params = {};
    if (page != null) {params['page'] = page;}
    if (size != null) {params['size'] = size;}
    if (sort != null) {params['sort'] = sort;}
    if (source != null) {params['source'] = source;}
    if (dataDa != null) {params['dataDa'] = new Date(new Date(dataDa).getTime()).toISOString();}
    if (dataA != null) {params['dataA'] = new Date(new Date(dataA).getTime()).toISOString();}
    if (failedClass != null) {params['failedClass'] = failedClass;}
    return this.http.get<PageDTO<AsyncProcessErrorEventDTO>>(`${this.url}/errorEventsPage`, { params: params });
  }
}
