import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements OnInit {
  @Input() message: string = 'Sei sicuro di eseguire questa azione?'
  @Input() disabled: boolean;
  @Output() customClick = new EventEmitter<any>();
  constructor(protected dialog: MatDialog) { }

  ngOnInit(): void {
  }

  action():void{
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: this.message
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.customClick.emit(x);
      }
    });
  }
}

