import { Pageable } from './../../../shared/dto/Pageable.types';
import { PromotionDTO } from './../../../shared/dto/be-subscriptions/promotion/PromotionDTO';
import { List } from '@amcharts/amcharts4/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends GenericService<PromotionDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/promotion';
    //this.url = "http://localhost:30300/promotion"
  }

  public readAll(pageable: Pageable, idFilter: number, codeFilter: string, descriptionFilter: string, productId: number[], active: boolean): Observable<PageDTO<PromotionDTO>> {
    const params = {};
    if (pageable.page != null) {
      params['page'] = pageable.page;
    }
    if (pageable.size != null) {
      params['size'] = pageable.size;
    }
    if (pageable.sort != null) {
      params['sort'] = pageable.sort;
    }
    if (idFilter != null) {
      params['idPromotion'] = idFilter;
    }
    if (codeFilter != null) {
      params['code'] = codeFilter.toString();
    }
    if (descriptionFilter != null) {
      params['description'] = descriptionFilter.toString();
    }
    if (productId != null) {
      params['idProducts'] = productId;
    }
    if (active != null) {
      params['active'] = active.toString();
    }
    return this.http.get<PageDTO<PromotionDTO>>(`${this.url}/list`, { params: params });
  }

  public generatePromotions(promotion: PromotionDTO, totalNumber: number): Observable<List<PromotionDTO>> {
    if (totalNumber >= 0) {
      return this.http.post<List<PromotionDTO>>(`${this.url}/generate/${totalNumber}`, promotion);
    }
  }

}
