import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { AssetDefinitionHistoryService } from 'src/app/services/core-catalog/asset-definition-history/asset-definition-history.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { AssetDefinitionDto } from 'src/app/shared/dto/core-catalog/AssetDefinitionDto.types';
import { HistoryDTO } from 'src/app/shared/dto/core-catalog/HistoryDto.types';

@Component({
  selector: 'app-asset-definition-history',
  templateUrl: './asset-definition-history.component.html',
  styleUrls: ['./asset-definition-history.component.scss']
})
export class AssetDefinitionHistoryComponent extends GenericListComponent implements OnInit, OnDestroy {


  constructor(
    private assetDefinitinService: AssetDefinitionHistoryService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {

    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['symbol', 'decimalPoints', 'validFrom', 'validTo', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sort: null,
      symbol: null,
      page: 0,
      size: 50,
      length: 0
    };
  }

  list(): void {
    this.assetDefinitinService.assetDefinitionHistoryList(
      this.parameters.symbol,
      this.parameters.mini,
      this.parameters.taxonomy,
      this.parameters.weissRating,
      this.parameters.techAttributes,
      this.parameters.socialAttributes,
      this.parameters.page,
      this.parameters.size,
      this.parameters.sort
    ).subscribe((response: PageDTO<HistoryDTO<AssetDefinitionDto>>) => {
      console.log('response : ' , response);
      this.parameters.dataSource = response.content;
      this.parameters.page = response.page;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    });
  }

  error(): void{
    this.snackBar.open('Valorizzare il filtro prima di effettuare la ricerca!', null, { duration: 3000 });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


}
