import { TraceModificationDTO } from '../basic/traceModificationDTO';

export class SocialDTO extends TraceModificationDTO {

    id: number;

    socialName: string;

    socialLink: string;

	socialType: string;

    favorite: boolean;
}
