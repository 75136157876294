import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlockchainType } from 'src/app/shared/dto/commons/BlockchainType.types';
import { SmartContractAddressMappingDto } from 'src/app/shared/dto/core-catalog/SmartContractAssetMappingDto.types';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable()
export class SmartContractAddressMappingService extends GenericService<SmartContractAddressMappingDto, number>{
    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-core-catalog/smartContractAddressMapping';
    }

    query(pageable: Pageable, filter?: string, type?: BlockchainType): Observable<PageDTO<SmartContractAddressMappingDto>> {
        const params = {'filter': filter + '', 'builtOn': type + '', 'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.get<PageDTO<SmartContractAddressMappingDto>>(`${this.url}/query`, {params: params});
    }
}
