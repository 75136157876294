import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/services/be-notifications/category/category.service';
import { CategoryDTO } from 'src/app/shared/dto/be-notifications/category/CategoryDTO';

@Component({
  selector: 'app-notifications-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss'],
  providers: [
    CategoryService
  ]
})
export class CategoryDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  categoryId: number;
  category: CategoryDTO;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private categoryService: CategoryService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      name: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      bundleKey: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      durationCancellation: new FormControl('', [Validators.required, Validators.min(0)]),
      durationRead: new FormControl('', [Validators.required, Validators.min(0)]),
      durationAbsolute: new FormControl('', [Validators.required, Validators.min(0)]),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.categoryId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.categoryRead();
  }

  categorySave(): void {
    this.categoryUpdate();
  }

  //READ

  categoryRead(): void {
    this.categoryService.read(this.categoryId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: CategoryDTO) => {
          console.log('response : ' , res);
          this.category = res;
          this.categoryId = res.id;
          this.dtoToForm();
        }
      );
  }

  // UPDATE

  categoryUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere la category?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.categoryService.update(this.category)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.categoryRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  categoryDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare la category?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.categoryService.delete(this.category.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/notifications/category']);
                }
              );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {
    this.category.name = this.form.get('name').value;
    this.category.bundleKey = this.form.get('bundleKey').value;
    this.category.durationCancellation = this.form.get('durationCancellation').value;
    this.category.durationRead = this.form.get('durationRead').value;
    this.category.durationAbsolute = this.form.get('durationAbsolute').value;
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.category.id);
    this.form.get('name').setValue(this.category.name);
    this.form.get('bundleKey').setValue(this.category.bundleKey);
    this.form.get('durationCancellation').setValue(this.category.durationCancellation);
    this.form.get('durationRead').setValue(this.category.durationRead);
    this.form.get('durationAbsolute').setValue(this.category.durationAbsolute);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.category.insertDate));
    this.form.get('insertUser').setValue(this.category.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.category.updateDate));
    this.form.get('updateUser').setValue(this.category.updateUser);
    this.form.get('deleteDate').setValue(this.timestampFormatPipe.transform(this.category.deleteDate));
    this.form.get('deleteUser').setValue(this.category.deleteUser);
    this.form.get('optLock').setValue(this.category.optLock);
    this.form.markAsPristine();
  }

}
