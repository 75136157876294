/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { UserSubscriptionsService } from 'src/app/services/be-subscriptions/user-subscriptions/user-subscriptions.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserSubscriptionsDTO } from 'src/app/shared/dto/be-subscriptions/user-subscriptions/UserSubscriptionsDTO';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { CardSubscriptionService } from 'src/app/services/be-subscriptions/subscription-card/card-subscription.service';
import { SubscriptionDTO } from 'src/app/shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { StripeInvoice } from 'src/app/shared/dto/be-subscriptions/stripe/invoice.types';

@Component({
  selector: 'app-stripe-detail',
  templateUrl: './stripe-detail.component.html',
  styleUrls: ['./stripe-detail.component.scss']
})
export class StripeDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  userSubscriptions: UserSubscriptionsDTO;
  userSubscriptionsId: number;
  cardSubscription: SubscriptionDTO;
  subForm: FormGroup = null;
  invoiceForm: FormGroup = null;
  invoiceData: StripeInvoice = null;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private userSubscriptionsService: UserSubscriptionsService,
    private cardService: CardSubscriptionService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl({ value: '' }),
        username: new FormControl({ value: '' }),
      });
      this.subForm = new FormGroup({
        id: new FormControl({ value: '' }),
        subType: new FormControl({ value: '' }),
        amountPaid: new FormControl({ value: '' }),
        customerId: new FormControl({ value: '' }),
        customerEmail: new FormControl({ value: '' }),
        dateStart: new FormControl({ value: '' }),
        dateEnd: new FormControl({ value: '' })
      });
      this.invoiceForm = new FormGroup({
        id: new FormControl({ value: '' }),
        name: new FormControl({ value: ' '}),
        data: new FormControl({ value: '' }),
        customerId: new FormControl({ value: '' }),
        customerEmail: new FormControl({ value: '' }),
        periodStart: new FormControl({ value: '' }),
        periodEnd: new FormControl({ value: '' }),
        paidAt: new FormControl({ value: '' }),
        finalizedAt: new FormControl({ value: '' }),
        status: new FormControl({ value: '' }),
        amountDue: new FormControl({ value: '' }),
        amountPaid: new FormControl({ value: '' }),
        currency: new FormControl({ value: '' })
      });
   }

  ngOnInit(): void {
    this.userSubscriptionsId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.userSubscriptionsId !== 0 && this.userSubscriptionsId != null) {
      this.userRead();
    }
  }

  public userRead(): void {
    this.userSubscriptionsService.read(this.userSubscriptionsId)
      .pipe(takeUntil(this._unsubscribeAll),
      switchMap((userSubscription: UserSubscriptionsDTO) => {
        this.userSubscriptions = userSubscription;
        this.dtoToForm();
        if (this.userSubscriptions.username) {
          return this.cardService.readActiveSub(this.userSubscriptions.username);
        } else {
          return EMPTY;
        }
      }),
      switchMap((cardSubscriptionRes: SubscriptionDTO) => {
        this.cardSubscription = cardSubscriptionRes;
        this.dtoToFormSub();
        if (this.cardSubscription.id) {
          return this.cardService.getInvoiceData(this.cardSubscription.id);
        } else {
          return EMPTY;
        }
      })).subscribe((invoice: StripeInvoice) => {
        this.invoiceData = invoice;
        this.dtoToFormInvoice();
      });

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.userSubscriptions.id);
    this.form.get('username').setValue(this.userSubscriptions.username);
    this.form.markAsPristine();
  }

  private dtoToFormSub(): void {
    this.subForm.get('id').setValue(this.cardSubscription?.id);
    this.subForm.get('subType').setValue(this.cardSubscription?.subscriptionType.toString());
    this.subForm.get('amountPaid').setValue(this.cardSubscription?.ammountPaid);
    this.subForm.get('customerId').setValue(this.cardSubscription?.customerId);
    this.subForm.get('customerEmail').setValue(this.cardSubscription?.email);
    this.subForm.get('dateStart').setValue(this.timestampFormatPipe.transform(this.cardSubscription?.startDate));
    this.subForm.get('dateEnd').setValue(this.timestampFormatPipe.transform(this.cardSubscription?.endDate));
    this.subForm.markAsPristine();
  }
  private dtoToFormInvoice(): void {
    this.invoiceForm.get('id').setValue(this.invoiceData?.id);
    this.invoiceForm.get('name').setValue(this.invoiceData?.name);
    this.invoiceForm.get('data').setValue(this.invoiceData?.description);
    this.invoiceForm.get('customerId').setValue(this.invoiceData?.customerId);
    this.invoiceForm.get('customerEmail').setValue(this.invoiceData?.customerEmail);
    this.invoiceData?.paidAt ? this.invoiceForm.get('paidAt').setValue(this.timestampFormatPipe.transform(new Date(this.invoiceData?.paidAt * 1000))) :
    this.invoiceForm.get('paidAt').setValue('DA PAGARE');
    this.invoiceData?.finalizedAt ? this.invoiceForm.get('finalizedAt').setValue(this.timestampFormatPipe.transform(new Date(this.invoiceData?.finalizedAt * 1000))) :
    this.invoiceForm.get('finalizedAt').setValue('DA FINALIZZARE');
    this.invoiceForm.get('status').setValue(this.invoiceData?.status.toUpperCase());
    this.invoiceData?.periodStart ? this.invoiceForm.get('periodStart').setValue(this.timestampFormatPipe.transform(new Date(this.invoiceData?.periodStart * 1000))) :
    this.invoiceForm.get('periodStart').setValue('DATA NON DISPONIBILE');
    this.invoiceData?.periodEnd ? this.invoiceForm.get('periodEnd').setValue(this.timestampFormatPipe.transform(new Date(this.invoiceData?.periodEnd * 1000))) :
    this.invoiceForm.get('periodEnd').setValue('DATA NON DISPONIBILE');
    this.invoiceForm.get('amountDue').setValue(this.invoiceData?.amountDue / 100);
    this.invoiceForm.get('amountPaid').setValue(this.invoiceData?.amountPaid / 100);
    this.invoiceForm.get('currency').setValue(this.invoiceData?.currency.toUpperCase());
    this.invoiceForm.markAsPristine();
  }

}

