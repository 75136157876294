import { Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timestampFormat'
})
export class TimestampFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, format?: any): any {
    ///MMM/dd/yyyy
    return super.transform(value, format ? format : 'dd/MM/yyyy HH:mm:ss');
  }
}
