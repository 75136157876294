import { Pageable } from './../../../shared/dto/Pageable.types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSubscriptionsDTO } from 'src/app/shared/dto/be-subscriptions/user-subscriptions/UserSubscriptionsDTO';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionsService extends GenericService<UserSubscriptionsDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/user/admin';
  }

  public userList(pageable: Pageable, idFilter: number, username: string,
    active: boolean, renewable: boolean, trialUsed: boolean, freeUsed: boolean): Observable<PageDTO<UserSubscriptionsDTO>> {
    const params = {'idUser': idFilter + '',
      'username': username + '',
      'active': active + '',
      'renewable': renewable + '',
      'trialUsed': trialUsed + '',
      'freeUsed': freeUsed + '',
      'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
    return this.http.get<PageDTO<UserSubscriptionsDTO>>(`${this.url}/list`, { params: params });
  }

  public getListWithActiveCardSubscriptions(pageable: Pageable, startDateFrom: Date, startDateTo: Date,
    endDateFrom: Date, endDateTo: Date, username: string): Observable<PageDTO<UserSubscriptionsDTO>> {
    const startDateFromString = (startDateFrom === undefined)? undefined : new Date(new Date(startDateFrom).getTime()).toISOString();
    const startDateToString = (startDateTo === undefined)? undefined : new Date(new Date(startDateTo).getTime()).toISOString();
    const endDateFromString = (endDateFrom === undefined)? undefined : new Date(new Date(endDateFrom).getTime()).toISOString();
    const endDateToString = (endDateTo === undefined)? undefined : new Date(new Date(endDateTo).getTime()).toISOString();
    const params = {
      'username' : username + '',
      'startDateFrom': startDateFromString + '',
      'startDateTo': startDateToString + '',
      'endDateFrom': endDateFromString + '',
      'endDateTo': endDateToString + '',
      'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''
    };
    Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
    return this.http.get<PageDTO<UserSubscriptionsDTO>>(`${this.url}/listActiveCardSub`, { params: params });
  }

  public findByUser(username: string): Observable<UserSubscriptionsDTO>{
    const params = {};
    if (username != null) { params['username'] = username;}
    return this.http.get<UserSubscriptionsDTO>(`${this.url}/username/`+ username, {});
  }

  public usersArePaid(usernames: string[]): Observable<Map<string, boolean>>{
    const params = {};
    if (usernames != null) { params['usernames'] = usernames;}
    return this.http.get<Map<string, boolean>>(`${this.url}/usernames/paying/`+ usernames, {});
  }

}
