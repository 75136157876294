<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <div *ngIf="parameters.showList" fxLayout="column">
            <h2>Dettaglio delle ricevute</h2>
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" matSort
                (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.id}} </td>
                </ng-container>

                <!-- StartDate Column -->
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> start date </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.startDate| date :'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <!-- EndDate Column -->
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> end date </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.endDate| date :'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <!-- active Column -->
                <ng-container matColumnDef="subscription.active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> active </th>
                    <td mat-cell *matCellDef="let receipt">
                        <mat-icon *ngIf="receipt.active" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!receipt.active" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <!-- paid Column -->
                <ng-container matColumnDef="subscription.paid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> paid </th>
                    <td mat-cell *matCellDef="let receipt">
                        <mat-icon *ngIf="receipt.paid" class="icon-font-size">check</mat-icon>
                        <mat-icon *ngIf="!receipt.paid" class="icon-font-size">close</mat-icon>
                    </td>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="subscription.id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Subscription </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.subscription.id}} </td>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="receiptType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> type </th>
                    <td mat-cell *matCellDef="let receipt"> {{receipt.receiptType}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let receipt">
                        <button mat-mini-fab color="primary" [routerLink]="['/receipt/detail/', receipt.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-card-actions>
            <button mat-raised-button [routerLink]="['/user-subscriptions']" color="primary">Back</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>