<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Category</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id"
                        readonly="true" />
                </mat-form-field>

                <!-- name field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="name" placeholder="Name">
                    <mat-error *ngIf="form.get('name').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('name').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- bundleKey field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="bundleKey" placeholder="Bundle Key">
                    <mat-error *ngIf="form.get('bundleKey').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('bundleKey').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- durationCancellation field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="durationCancellation" placeholder="Duration After Cancellation" min="0">
                    <mat-error *ngIf="form.get('durationCancellation').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('durationCancellation').errors?.min">
                        Duration After Cancellation cannot be negative
                    </mat-error>
                </mat-form-field>

                <!-- durationRead field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="durationRead" placeholder="Duration After Read" min="0">
                    <mat-error *ngIf="form.get('durationRead').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('durationRead').errors?.min">
                        Duration After Read cannot be negative
                    </mat-error>
                </mat-form-field>

                <!-- durationAbsolute field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="durationAbsolute" placeholder="Duration Absolute" min="0">
                    <mat-error *ngIf="form.get('durationAbsolute').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('durationAbsolute').errors?.min">
                        Duration Absolute cannot be negative
                    </mat-error>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                      <!-- DeleteDate field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput formControlName="deleteDate" placeholder="Delete Date" readonly="true" />
                      </mat-form-field>
        
                      <!-- DeleteUser field -->
                      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput formControlName="deleteUser" placeholder="Delete User" readonly="true" />
                      </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/notifications/category')" color="primary">Back</button>
            <button mat-raised-button (click)="categorySave()" [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <button mat-raised-button (click)="categoryDelete()" [disabled]="category?.id===null">
                Delete
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>