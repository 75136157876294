import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericComponent } from 'src/app/shared/GenericComponent';

@Component({
  selector: 'app-subscription-extension',
  templateUrl: './subscription-extension.component.html',
  styleUrls: ['./subscription-extension.component.scss']
})
export class SubscriptionExtensionComponent extends GenericComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      router,
      dateAdapter,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
