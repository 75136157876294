<h1 style="text-align:center;">Gestione dell'invio delle notifiche</h1>
<br>
<div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
  <buttons-component [btnTxt]="'Category'" [btnIcon]="'list_alt'" [btnLink]="['/notifications/category']">
  </buttons-component>
  <buttons-component [btnTxt]="'Category Configuration'" [btnIcon]="'settings'" [btnLink]="['/notifications/configuration']">
  </buttons-component>
  <buttons-component [btnTxt]="'Template'" [btnIcon]="'description'" [btnLink]="['/notifications/template']">
  </buttons-component>
  <buttons-component [btnTxt]="'Notifications'" [btnIcon]="'notifications'" [btnLink]="['/notifications/notifications']">
  </buttons-component>
  <buttons-component [btnTxt]="'Generate Notifications'" [btnIcon]="'notifications_active'" [btnLink]="['/notifications/generate']">
  </buttons-component>
  <buttons-component [btnTxt]="'Notification Queue'" [btnIcon]="'queue'" [btnLink]="['/notifications/queue']">
  </buttons-component>
</div>