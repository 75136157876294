import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { WarningEventService } from 'src/app/services/core-errors-handling/warning-event.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { AsyncProcessWarningEventDTO } from 'src/app/shared/dto/core-error-handling/warningDto/asyncProcessWarningsEventDto';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';

@Component({
  selector: 'app-async-process-warning-event-detail',
  templateUrl: './async-process-warning-event-detail.component.html',
  styleUrls: ['./async-process-warning-event-detail.component.scss']
})
export class AsyncProcessWarningEventDetailComponent extends GenericDetailComponent implements OnInit{
  asyncProcessWarningEventId: number;
  asyncProcessWarningEvent: AsyncProcessWarningEventDTO;

  constructor(
    private warningEventService: WarningEventService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl({ value: '' }),
        source: new FormControl({ value:''}),
        timestamp: new FormControl({ value: ''}),
        warningClass: new FormControl({ value: ''}),
        warnings: new FormControl({ value: ''}),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
      });
  }


  ngOnInit(): void {
    this.asyncProcessWarningEventId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.asyncProcessWarningEventId !== 0 && this.asyncProcessWarningEventId != null) {
      this.assetMappingConfigurationRead();
    } else {
      this.asyncProcessWarningEvent = new AsyncProcessWarningEventDTO();
    }
  }

  //READ

  assetMappingConfigurationRead(): void {
    this.warningEventService.read(this.asyncProcessWarningEventId).subscribe(
      (res: AsyncProcessWarningEventDTO) => {
        console.log('response : ' , res);
        this.asyncProcessWarningEvent = res;
        this.asyncProcessWarningEventId = res.id;
        this.dtoToForm();
      }
    );
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.asyncProcessWarningEvent.id);
    this.form.get('source').setValue(this.asyncProcessWarningEvent.source);
    this.form.get('timestamp').setValue(this.asyncProcessWarningEvent.timestamp);
    this.form.get('warningClass').setValue(this.asyncProcessWarningEvent.warningClass);
    this.form.get('warnings').setValue(this.asyncProcessWarningEvent.warnings);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.asyncProcessWarningEvent.insertDate));
    this.form.get('insertUser').setValue(this.asyncProcessWarningEvent.insertUser);
    this.form.get('optLock').setValue(this.asyncProcessWarningEvent.optLock);
    this.form.markAsPristine();
  }


}
