<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Asset cache</h2>
        <p>Imposta il filtro</p>
        <div fxLayout="column" class="responsive-mat-form">

            <mat-form-field>
                <input matInput [(ngModel)]="parameters.symbol" placeholder="symbol" />
            </mat-form-field>

        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="parameters.symbol != null && parameters.symbol!= '' ? list() : error() "  name="Search" color="primary">Search</button>
            <button mat-raised-button (click)="back('/')" color="primary">Back</button>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex  [dataSource]="parameters.dataSource">

                <!-- symbol Column -->
                <ng-container matColumnDef="symbol">
                    <th mat-header-cell *matHeaderCellDef> Symbol </th>
                    <td mat-cell *matCellDef="let asset"> {{asset.symbol}} </td>
                </ng-container>

                <!-- fullName Column -->
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef> FullName </th>
                    <td mat-cell *matCellDef="let asset"> {{asset.fullName}} </td>
                </ng-container>

                <!-- decimalPoints Column -->
                <ng-container matColumnDef="decimalPoints">
                    <th mat-header-cell *matHeaderCellDef> DecimalPoints </th>
                    <td mat-cell *matCellDef="let asset"> {{asset.decimalPoints}} </td>
                </ng-container>

                <!-- Delete Column -->
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let asset">
                        <delete-button (customClick)="delete(asset.symbol)"
                                       message='Sei sicuro di voler cancellare questo asset?'
                        ></delete-button>
                    </td>
                </ng-container>          

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
            </div>
        </div>
    </mat-card-content>
</mat-card>