import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { Pageable } from './../../../shared/dto/Pageable.types';
import { ReceiptDTO, ReceiptType } from './../../../shared/dto/be-subscriptions/receipt/ReceiptDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService extends GenericService<ReceiptDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-subscriptions/receipt';
    //this.url = "http://localhost:30300/receipt"
  }

  public readAll(pageable: Pageable, idFilter: number, receiptType: ReceiptType, startDateFrom: Date, startDateTo: Date,
    endDateFrom: Date, endDateTo: Date, idSubscription: number, idUser: number,
    idInfluencerAffiliation: number, idCodeAffiliation: number): Observable<PageDTO<ReceiptDTO>> {
      const startDateFromString = (startDateFrom === undefined)? undefined : new Date(new Date(startDateFrom).getTime()).toISOString();
      const startDateToString = (startDateTo === undefined)? undefined : new Date(new Date(startDateTo).getTime()).toISOString();
      const endDateFromString = (endDateFrom === undefined)? undefined : new Date(new Date(endDateFrom).getTime()).toISOString();
      const endDateToString = (endDateTo === undefined)? undefined : new Date(new Date(endDateTo).getTime()).toISOString();
      const params = {'idReceipt': idFilter + '',
      'receiptType': receiptType + '',
      'startDateFrom': startDateFromString + '',
      'startDateTo': startDateToString + '',
      'endDateFrom': endDateFromString + '',
      'endDateTo': endDateToString + '',
      'idSubscription': idSubscription + '',
      'idUser': idUser + '',
      'idInfluencerAffiliation': idInfluencerAffiliation + '',
      'idCodeAffiliation': idCodeAffiliation + '',
      'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
    return this.http.get<PageDTO<ReceiptDTO>>(`${this.url}/receipts`, { params: params });
  }

  public userSubscriptionList(username: string, pageable: Pageable): Observable<PageDTO<ReceiptDTO>> {
    const params = {'user': username,
                    'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
                    Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
    return this.http.get<PageDTO<ReceiptDTO>>(`${this.url}/receipts/user`, { params: params });
  }

}
