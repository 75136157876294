<mat-form-field class="chip-list">
  <mat-label>Key List</mat-label>
  <mat-chip-list #chipList aria-label="Keys selection">
    <mat-chip *ngFor="let key of keyList" [selectable]="selectable" [removable]="removable" (removed)="remove(key)">
      {{key}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="New key..." #keyInput [formControl]="keyCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let key of filteredKeys | async" [value]="key">
      {{key}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>