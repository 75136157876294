import { FormGroup, Validators } from '@angular/forms';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { DisclaimerType } from './DisclaimerType.types';

export class MessageDto extends LogicalDeleteDTO{

	/**
	 * Message id
	 */
    id: number;

	/**
	 * Message's title (es: privacyV1)
	 */
    title: string;

    /**
     * If the message is required to accept
     */
    required: boolean;

    /**
     * Must be checked before continue (no accept required)
     */
    mustBeChecked: boolean;

    /**
     * Nullable expiration date for the message, after it expires it won't be shown to the used
     */
    expirationDate: Date;

    /**
     * Disclaimer Type for the current message
     */
    disclaimerType: DisclaimerType;

    constructor() {
        super();
        this.id = undefined;
        this.title = '';
        this.required = false;
        this.mustBeChecked = false;
        this.expirationDate = undefined;
        this.disclaimerType = undefined;
    }
}
