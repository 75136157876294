

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Check Symbols</h2>
      <div fxLayout="column">
        <mat-form-field class="example-chip-list responsive-mat-form">
          <mat-label>Insert Symbols</mat-label>
          <mat-chip-list #chipList aria-label="Symbol selection">
            <mat-chip *ngFor="let symbol of symbols" [selectable]="selectable"
                     [removable]="removable" (removed)="remove(symbol)">
              {{symbol.name}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="New symbol..."
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
        </mat-form-field>
      </div>

      <mat-card-actions>
        <button mat-raised-button (click)="back()" color="primary">Back</button>
        <button mat-raised-button (click)="save()" [disabled]="symbols.length === 0 || !checkInsertSimbols()">Save</button>
      </mat-card-actions>
    </div>

    <div fxLayout="column">
      <ng-container *ngFor="let symbol of symbols">
        <div fxLayout="row" class="container-symbol">
          <h3 class="align-items: center;">{{symbol.name}}</h3>
          <mat-icon *ngIf="symbol.color" style="color:green;" class="icon-font-size">check</mat-icon>
          <mat-icon *ngIf="!symbol.color" style="color:red;" class="icon-font-size">close</mat-icon>
        </div>
      </ng-container>        
    </div>
  </mat-card-content>

</mat-card>