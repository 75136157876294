<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent"  *ngIf="this.form">
    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Symbol mapping</h2>
      <h3> Prima di usare questa funzione, verifica che il simbolo sia stato correttamente mappato nel microservizio del Data Integration. In questo modo, tutti i movimenti successivi verranno salvati con il nuovo simbolo inserito durante il salvataggio.</h3>
      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
          <mat-form-field>
            <input matInput formControlName="oldSymbol" placeholder="Old symbol"/>
          </mat-form-field>

          <!-- symbol form to make sure that the symbol exists in catalog -->
          <asset-definition-input [assetDefinition]="form.get('newSymbol')?.value" (assetDefinitionChangedEvent)="form.controls['newSymbol'].setValue($event?.assetDefinition?.symbol)"></asset-definition-input>
        </form>
      </div>

      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/')" color="primary">Back</button>
        <button mat-raised-button (click)="save()" [disabled]="form.invalid">
          Save
        </button>
      </mat-card-actions>
    </div>
  </mat-card-content>
</mat-card>