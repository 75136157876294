<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Product</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id"
                        readonly="true" />
                </mat-form-field>

                <!-- name field -->
                <mat-form-field fxFlex>
                    <input matInput type="text" formControlName="name" placeholder="Name">
                    <mat-error *ngIf="form.get('name').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('name').errors?.maxlength">
                        Max length reached (256 max)
                    </mat-error>
                </mat-form-field>

                <!-- Cost field -->
                <mat-form-field>
                    <input matInput type="number" formControlName="cost" placeholder="Cost" min="0">
                    <mat-error *ngIf="form.get('cost').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('cost').errors?.min">
                        Cost cannot be negative
                    </mat-error>
                </mat-form-field>

                <!-- Yearly field -->
                <mat-checkbox formControlName="yearly" labelPosition="before" style="margin-bottom: 10px;">
                    Yearly</mat-checkbox>

                <!-- Free field -->
                <mat-checkbox formControlName="free" labelPosition="before" style="margin-bottom: 10px;">
                    Free</mat-checkbox>

                <!-- Trial field -->
                <mat-checkbox formControlName="trialProduct" labelPosition="before" style="margin-bottom: 10px;">
                    Trial</mat-checkbox>

                <!-- Affiliation field -->
                <mat-checkbox labelPosition="before" style="margin-bottom: 10px;"
                    [indeterminate]="form.get('affiliation').value === null"
                    [checked]="form.get('affiliation').value !== null && form.get('affiliation').value"
                    (change)="checkAffiliation(form.get('affiliation').value)">
                    Affiliation</mat-checkbox>

                <!-- description field -->
                <mat-form-field>
                    <textarea matInput type="text" class="resize-y" rows="6"
                     formControlName="description" placeholder="Description">
                    </textarea>
                    <mat-error *ngIf="form.get('description').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- metadata field -->
                <mat-form-field>
                    <input matInput type="text" formControlName="metadata" placeholder="Metadata">
                </mat-form-field>

                <button mat-raised-button (click)="openPriceExchange()" *ngIf="productId">Price exchange</button>


                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">

                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/product')" color="primary">Back</button>
            <button mat-raised-button (click)="productSave()" [disabled]="form.invalid || !form.dirty ">
                Save
            </button>
            <button mat-raised-button (click)="productDelete()" [disabled]="product?.id===null">
                Delete
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>