import { takeUntil } from 'rxjs/operators';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CategoryConfigurationService } from 'src/app/services/be-notifications/configuration/category-configuration.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { NotificationChannel } from 'src/app/shared/dto/be-notifications/NotificationTypes';

@Component({
  selector: 'app-notifications-category-configuration',
  templateUrl: './category-configuration.component.html',
  styleUrls: ['./category-configuration.component.scss'],
  providers: [
    CategoryConfigurationService
  ]
})
export class CategoryConfigurationComponent extends GenericListComponent implements OnInit, OnDestroy {

  readonly channels: NotificationChannel[] = ['MAIL', 'BELL', 'POPUP'];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private categoryConfigurationService: CategoryConfigurationService,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'category', 'channel', 'defaultValue', 'editable', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      idCategory: undefined,
      channel: undefined,
      page: 0,
      size: 10,
      length: 0
    };
  }

  list(): void{
    this.categoryConfigurationService.readAll(
      {page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable,
      this.parameters.idCategory, this.parameters.channel)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      this.parameters.dataSource = response.content;
      this.parameters.page = response.page;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
