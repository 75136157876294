<form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
    <mat-form-field>
      <input type="text" placeholder="User" matInput maxlength="255" [value]="userDefinition"
        (input)="userChanged()" formControlName="inputUsername">
    </mat-form-field>
    <!-- Campi di informazione sull'asset -->
    <mat-expansion-panel class="mat-elevation-z0" *ngIf="form.controls['inputUsername'].value && currentUser">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Dettagli user
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row">

        <!-- username field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
          [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
          [ngStyle.md]="{'padding-right.px': 0}">
        <input matInput formControlName="username" placeholder="Username" readonly="true" />
        </mat-form-field>
        <!-- email field -->
        <mat-form-field fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
          [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
          [ngStyle.md]="{'padding-right.px': 0}">
        <input matInput formControlName="email" placeholder="Email" readonly="true" />
        </mat-form-field>
      </div>
  
      <div fxLayout="row">
        <!-- familyName field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
          [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
          [ngStyle.md]="{'padding-right.px': 0}">
        <input matInput formControlName="familyName" placeholder="Family name" readonly="true" />
        </mat-form-field>
  
        <!-- firstName field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
          <input matInput formControlName="firstName" placeholder="First name" readonly="true" />
        </mat-form-field>
      </div>

