import { ChangeLogDto } from './../../shared/dto/change-log/ChangeLog.types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';

@Injectable()
export class ChangeLogService extends GenericService<ChangeLogDto, number> {

    noAdminurl: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-be-change-log/changeLog/admin';
        this.noAdminurl = environment.urlPrefix + '/arzakena-be-change-log/changeLog';
    }

    list(): Observable<ChangeLogDto[]> {
        return this.http.get<ChangeLogDto[]>(`${this.url}/list`);
    }
}
