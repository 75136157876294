import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { GenericService } from 'src/app/shared/GenericService';
import { AdvertisementDto } from 'src/app/shared/dto/advertisements/AdvertisementDto.types';

@Injectable()
export class AdvertisementService extends GenericService<AdvertisementDto, number> {

    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-be-advertisement/advertisement/admin';
    }

    adminRead(id: number): Observable<AdvertisementDto> {
        return this.http.get<AdvertisementDto>(`${this.url}/${id}`);
    }

    query(pageable: Pageable, advertiser?: string, visualizationDate?: Date): Observable<PageDTO<AdvertisementDto>> {
        const visualizationDateString = (visualizationDate === undefined)? undefined : new Date(new Date(visualizationDate).getTime()).toISOString();
        const params = {
            'campaigner': advertiser + '',
            'visualizationDate': visualizationDateString + '',
            'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
        Object.keys(params).forEach(key => params[key] === 'undefined' && delete params[key]);
        return this.http.get<PageDTO<AdvertisementDto>>(`${this.url}/getPage`, {params: params});
    }
}
