import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { TranslationService } from 'src/app/services/advertisements/translation.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TranslationDto } from 'src/app/shared/dto/advertisements/TranslationDto.types';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { languages } from 'src/app/shared/dto/languages.types';
import { AdvertisementService } from 'src/app/services/advertisements/advertisement.service';
import { AdvertisementDto } from 'src/app/shared/dto/advertisements/AdvertisementDto.types';

@Component({
  selector: 'app-campaigns-translation-detail',
  templateUrl: './translation-detail.component.html',
  styleUrls: ['./translation-detail.component.scss'],
  providers: [
    TranslationService,
    AdvertisementService
  ]
})
export class AdvertisementTranslationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly languages = languages;

  advertisement: AdvertisementDto;

  showPreview: boolean = false;

  url: string = '';

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private translationService: TranslationService,
    private advService: AdvertisementService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit(): void {
    if(this.detailId()!== 0) {
      this.read(this.detailId());
    } else {
      this.readAdvertisement();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toTranslationDto());
      return;
    }
    this.create(this.toTranslationDto());
  }

  deleteTranslation(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare la traduzione?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }

  detailId(): number {
    return +this.activeRoute.snapshot.paramMap.get('id');
  }

  detailAdvId(): number {
    return +this.activeRoute.snapshot.paramMap.get('advId');
  }

  redirectToPage(): void {
    if (this.form.get('language').value === 'en-US') {
      this.confirm('Are you sure do you want to go to the advertiser\'s site?')
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(choice => choice)
      ).subscribe(() => document.location.href = this.advertisement.redirectUrl);
    } else if (this.form.get('language').value === 'it-IT') {
      this.confirm('Sei sicuro di voler andare al sito dell\'advertiser?')
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(choice => choice)
      ).subscribe(() => document.location.href = this.advertisement.redirectUrl);
    }
  }

  private create(translation: TranslationDto): void {
    this.translationService.create(translation).subscribe((responseTranslation) => {
      this.router.navigateByUrl(this.router.url.replace('/' + this.detailId(), '/' + responseTranslation?.id));
      this.form = this.loadForm({...new TranslationDto(), ...responseTranslation});
    });
  }

  private update(translation: TranslationDto): void {
    this.translationService.update(translation).subscribe(() => this.read(this.detailId()));
  }

  private read(id: number): void {
    this.translationService.read(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((translation) => {
      this.form = this.loadForm({...new TranslationDto(), ...translation});
      this.readAdvertisement(translation.advertisementId);
    });
  }

  private delete(id: number): void {
    this.translationService.delete(id).subscribe(() => this.router.navigate(['/campaigns/detail/' + this.detailAdvId()]));
  }

  private readAdvertisement(advId?: number): void {
    this.advService.read(advId ?? this.detailAdvId())
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((adv) => {
      this.advertisement = adv;
      this.url = adv.imageUrl;
      this.form ??= this.loadForm(new TranslationDto(adv));
    });
  }

  private loadForm(translation: TranslationDto): FormGroup {
    return this._formBuilder.group({...translation, ...this.formValidators(translation)});
  }

  private toTranslationDto(): TranslationDto {
    return this.form.getRawValue() as TranslationDto;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private formValidators(translation: TranslationDto) {
    return {
        title: [translation.title, [Validators.required, Validators.maxLength(128)]],
        button: [translation.button, [Validators.required, Validators.maxLength(128)]],
      redirectUrl: [translation.redirectUrl, [Validators.required, Validators.maxLength(256)]],
        language: [translation.language, Validators.required],
        description: [translation.description, [Validators.required, Validators.maxLength(256)]],
        advertisementId: [translation.advertisementId, [Validators.required]]
    };
  }
}

