import { FormGroup } from '@angular/forms';
import { NavigatorService } from './../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { GenericComponent } from './GenericComponent';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TimestampFormatPipe } from './pipes/timestampFormatPipe';

export class GenericDetailComponent extends GenericComponent {

    form: FormGroup;

    constructor(
        navigatorService: NavigatorService,
        protected dialog: MatDialog,
        router: Router,
        dateAdapter: DateAdapter<Date>,
        protected activeRoute: ActivatedRoute,
        snackBar: MatSnackBar,
        timestampFormatPipe: TimestampFormatPipe
    ) {
        super(
            navigatorService,
            router,
            dateAdapter,
            snackBar,
            timestampFormatPipe);
    }

    confirm(message: string): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: message
        });
        return dialogRef.afterClosed();
    }

    backConfirm(url: string): void {
        const M: string = 'Proseguendo, tutte le modifiche andranno perse. Sei sicuro di voler tornare alla pagina precedente?';
        if (this.form.dirty) {
            this.confirm(M)
                .pipe(take(1))
                .subscribe((result) => {
                if (result) {
                    this.back(url);
                }
            });
        }
        else {
            this.back(url);
        }
    }
}
