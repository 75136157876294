import { HomeNotificationsComponent } from './home/notifications/home-notifications.component';
import { ReceiptDetailComponent } from './be-subscriptions/receipts/receipt-detail/receipt-detail.component';
import { ReceiptComponent } from './be-subscriptions/receipts/receipt.component';
import { ChangeLogTranslationsListComponent } from './change-logs/translations-list/change-log-translations-list.component';
import { ChangeLogTranslationDetailComponent } from './change-logs/translation-detail/change-log-translation-detail.component';
import { ChangeLogDetailComponent } from './change-logs/change-log-detail/change-log-detail.component';
import { ChangeLogComponent } from './change-logs/change-log/change-log.component';
import { AffiliationReportDetailComponent } from './gestione-affiliation/reportDetail/affiliation-report-detail.component';
import { FaqDetailComponent } from './faq-management/faq-detail.component';
import { FaqComponent } from './faq-management/faq.component';
import { ConversationComponent } from './ticket-management/conversation/conversation.component';
import { TicketManagementDetailComponent } from './ticket-management/ticket/ticket-management-detail.component';
import { TicketManagementComponent } from './ticket-management/ticket/ticket-management.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { L10nTranslationModule, L10nIntlModule, L10nValidationModule, L10nLoader } from 'angular-l10n';

import { AppMaterialModule } from './app.material.module';
import { AppRoutingModule } from './app.routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { NavigatorService } from './services/navigator.service';
import { ComponentCacheService } from './services/component-cache.service';
import { FakeAuthenticationHeaderInterceptor } from './commons/fakeAuthenticationHeaderInterceptor';
import { ServerErrorInterceptor } from './commons/serverErrorInterceptor';
import { DateFormatPipe } from './shared/pipes/dateFormatPipe';
import { AvatarModule } from 'ngx-avatar';

import { l10nConfig, initL10n, AppStorage, HttpTranslationLoader, LocaleValidation } from './l10n-config';

import { AssetMappingConfigurationComponent } from './di-coinmarketcap-catalog/di-coinmarketcap.component';
import { WeisscryptoCatalogComponent } from './di-weisscrypto-catalog/weisscrypto-catalog.component';
import { WeisscryptoCatalogDetailComponent } from './di-weisscrypto-catalog/weisscrypto-catalog-detail.component';
import { CoinmarketcapAssetMappingConfigurationService } from './services/coinmarketcap-catalog/asset-mapping-configuration.service';
import { CoinmarketcapAssetMappingConfigurationDetailComponent } from './di-coinmarketcap-catalog/di-coinmarketcap-detail.component';
import { CryptocompareTimeseriesComponent } from './di-cryptocompare-timeseries/di-cryptocompare-timeseries.component';
import { CryptocompareTimeseriesDetailComponent } from './di-cryptocompare-timeseries/di-cryptocompare-timeseries-detail.component';
import { AsyncProcessErrorEventComponent } from './core-errors-handling/async-process-error-event/async-process-error-event.component';
import { AsyncProcessWarningEventComponent } from './core-errors-handling/async-process-warning-event/async-process-warning-event.component';
import { AsyncProcessWarningEventDetailComponent } from './core-errors-handling/async-process-warning-event/async-process-warning-event-detail.component';
import { AsyncProcessErrorEventDetailComponent } from './core-errors-handling/async-process-error-event/async-process-error-event-detail.component';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { CoinmarketcapAssetCacheComponent } from './di-coinmarketcap-catalog/asset-cache/asset-cache.component';
import { CryptocompareAssetCacheComponent } from './di-cryprocompare-catalog/cryptocompareAssetCache.component';
import { WeisscryptoAssetCacheComponent } from './di-weisscrypto-catalog/asset-cache/weisscrypto-asset-cache/weisscrypto-asset-cache.component';
import { TimeseriesDataVisualizationComponent } from './timeseries-data-visualization/timeseries-data-visualization.component';
import { AssetDefinitionComponent } from './core-catalog/asset-definition/asset-definition.component';
import { AssetDefinitionHistoryComponent } from './core-catalog/asset-definition-history/asset-definition-history.component';
import { AssetDefinitionDetailComponent } from './core-catalog/asset-definition/asset-definition-detail.component';
import { AssetDefinitionHistoryDetailComponent } from './core-catalog/asset-definition-history/asset-definition-history-detail.component';
import { GestioneAffiliationComponent } from './gestione-affiliation/gestione-affiliation.component';
import { GestioneAffiliationDetailComponent } from './gestione-affiliation/gestione-affiliation-detail.component';
import { SocialComponent } from './gestione-affiliation/social/social.component';
import { SocialDetailComponent } from './gestione-affiliation/social/social-detail.component';
import { SocialFormListComponent } from './gestione-affiliation/social/social-form-list/social-form-list.component';
import { SocialFormComponent } from './gestione-affiliation/social/social-form/social-form.component';
import { UserComponent } from './user/user.component';
import { AffiliatedUsersComponent } from './gestione-affiliation/users/users.component';
import { CodeComponent } from './gestione-affiliation/code/code.component';
import { CodeDetailComponent } from './gestione-affiliation/code/code-detail.component';
import { AffiliationReportComponent } from './affiliation-report/affiliation-report.component';
import { PromotionComponent } from './be-subscriptions/promotion/promotion.component';
import { PromotionGenerationComponent } from './be-subscriptions/promotion/promotion-generation/promotion-generation.component';
import { PromotionDetailComponent } from './be-subscriptions/promotion/promotion-detail/promotion-detail.component';
import { ProductComponent } from './be-subscriptions/product/product.component';
import { ProductDetailComponent } from './be-subscriptions/product/product-detail/product-detail.component';
import { UserSubscriptionsComponent } from './be-subscriptions/user-subscriptions/user-subscriptions.component';
import { UserSubscriptionsDetailComponent } from './be-subscriptions/user-subscriptions/user-subscriptions-detail/user-subscriptions-detail.component';
import { PriceExchangeComponent } from './be-subscriptions/price-exchange/price-exchange.component';
import { PriceExchangeDetailComponent } from './be-subscriptions/price-exchange/price-exchange-detail/price-exchange-detail.component';
import { SubscriptionsComponent } from './be-subscriptions/subscriptions/subscriptions.component';
import { SubscriptionDetailComponent } from './be-subscriptions/subscriptions/subscription-detail/subscription-detail.component';
import { DisclaimerMessageComponent } from './disclaimers/message/disclaimer-message.component';
import { DisclaimerMessageDetailComponent } from './disclaimers/message-detail/disclaimer-message-detail.component';
import { TranslationsListComponent } from './disclaimers/translations-list/translations-list.component';
import { TranslationsAdvertisementsListComponent } from './advertisements/translation-list/translations-list.component';
import { TimestampFormatPipe } from './shared/pipes/timestampFormatPipe';
import { SubstringPipe } from './shared/pipes/substringPipe.pipe';
import { AdvertisementDetailComponent } from './advertisements/advertisement-detail/advertisement-detail.component';
import { DisclaimerMessageTranslationDetailComponent } from './disclaimers/translation-detail/disclaimer-message-translation-detail.component';
import { UserSubscriptionSubsComponent } from './be-subscriptions/user-subscriptions/user-subscription-subs/user-subscription-subs.component';
import { SmartContractAddressMappingComponent } from './core-catalog/smart-contract-address-mapping/smart-contract-address-mapping/smart-contract-address-mapping.component';
import { SmartContractAddressMappingDetailComponent } from './core-catalog/smart-contract-address-mapping/smart-contract-address-mapping-detail/smart-contract-address-mapping-detail.component';
import { AssetDefinitionInputComponent } from './core-catalog/smart-contract-address-mapping/smart-contract-address-mapping-detail/asset-definition-input/asset-definition-input.component';
import { ManagedSymbolDetailComponent } from './di-cryptocompare-timeseries/managed-symbol/managed-symbol-detail/managed-symbol-detail.component';
import { UserSubscriptionReceiptsComponent } from './be-subscriptions/user-subscriptions/user-subscription-receipts/user-subscription-receipts.component';
import { SymbolMappingDetailComponent } from './core-wallets/symbol-mapping/symbol-mapping-detail.component';
import { HomeComponent } from './home/home.component';
import { UsersDetailComponent } from './gestione-affiliation/users/users-detail.component';
import { UserDefinitionInputComponent } from './gestione-affiliation/users/user-definition-input/user-definition-input.component';
import { AboutComponent } from './about/about.component';
import { AdvertisementsComponent } from './advertisements/advertisement.component';
import { CryptoWidgetComponent } from './crypto-widget/crypto-widget.component';
import { GenericDataComponent } from './generic-data/generic-data.component';
import { ProviderComponent } from './home/provider/provider.component';
import { ErrorsComponent } from './home/errors/errors.component';
import { TimeseriesComponent } from './home/timeseries/timeseries.component';
import { CatalogComponent } from './home/catalog/catalog.component';
import { SegnalazioniComponent } from './home/segnalazioni/segnalazioni.component';
import { AffiliationComponent } from './home/affiliation/affiliation.component';
import { PositionsContainerComponent } from './home/positionsContainers/positionsContainer.component';
import { HomeSubscriptionsComponent } from './home/subscriptions/subscriptions.component';
import { AltroComponent } from './home/altro/altro.component';
import { ButtonsComponent } from './home/buttons/buttons.component';
import { SubscriptionExtensionComponent } from './be-subscriptions/extensions/subscription-extension.component';
import { SubscriptionExtensionDetailComponent } from './be-subscriptions/extensions/extension-detail/subscription-extension-detail.component';
import { SubscriptionExtensionTableComponent } from './be-subscriptions/extensions/extension-table/subscription-extension-table.component';
import { AssetDefinitionAliasMappingComponent } from './core-catalog/asset-definition-alias-mapping/asset-definition-alias-mapping.component';
import { PositionsComponent } from './positions-container/positions-container.component';
import { CheckForManagedSymbolsComponent } from './core-wallets/check-for-managed-symbols/check-for-managed-symbols.component';

import { DataintegrationComponent } from './dataintegration-catalog/dataintegration/dataintegration.component';
import { DataintegrationDetailComponent } from './dataintegration-catalog/dataintegration-detail/dataintegration-detail.component';
import { DataintegrationChipListComponent } from './dataintegration-catalog/dataintegration-detail/dataintegration-detail-chiplist.component';

import { StripeComponent } from './be-subscriptions/stripe/stripe.component';
import { StripeDetailComponent } from './be-subscriptions/stripe/stripeDetail/stripe-detail.component';
import { DIAlternativeMeFearAndGreedComponent } from './di-alternativeme-fearandgreed/di-alternativeme-fearandgreed.component';
import { AdvertisementTranslationDetailComponent } from './advertisements/translation-detail/translation-detail.component';
import { CategoryComponent } from './be-notifications/category/category.component';
import { CategoryDetailComponent } from './be-notifications/category/category-detail/category-detail.component';
import { TemplateComponent } from './be-notifications/template/template.component';
import { TemplateDetailComponent } from './be-notifications/template/template-detail/template-detail.component';
import { CategoryConfigurationComponent } from './be-notifications/category-configuration/category-configuration.component';
import { CategoryConfigurationDetailComponent } from './be-notifications/category-configuration/category-configuration-detail/category-configuration-detail.component';
import { NotificationComponent } from './be-notifications/notification/notification.component';
import { NotificationDetailComponent } from './be-notifications/notification/notification-detail/notification-detail.component';
import { NotificationGenerateComponent } from './be-notifications/notification-generate/notification-generate.component';
import { NotificationQueueComponent } from './be-notifications/notification-queue/notification-queue.component';
import { CheckSquaringComponent } from './check-squaring/check-squaring.component';
import {DeleteButtonComponent} from "./shared/delete-button/delete-button.component";
@NgModule({
    declarations: [
        AppComponent,
        DateFormatPipe,
        TimestampFormatPipe,
        SubstringPipe,
        PositionsComponent,
        AssetMappingConfigurationComponent,
        CoinmarketcapAssetMappingConfigurationDetailComponent,
        WeisscryptoCatalogComponent,
        WeisscryptoCatalogDetailComponent,
        CryptocompareTimeseriesComponent,
        CryptocompareTimeseriesDetailComponent,
        AsyncProcessErrorEventComponent,
        AsyncProcessWarningEventComponent,
        TranslationsAdvertisementsListComponent,
        AsyncProcessWarningEventDetailComponent,
        AsyncProcessErrorEventDetailComponent,
        AdvertisementsComponent,
        CoinmarketcapAssetCacheComponent,
        CryptocompareAssetCacheComponent,
        WeisscryptoAssetCacheComponent,
        TimeseriesDataVisualizationComponent,
        DIAlternativeMeFearAndGreedComponent,
        AssetDefinitionComponent,
        AssetDefinitionHistoryComponent,
        AssetDefinitionDetailComponent,
        AssetDefinitionHistoryDetailComponent,
        TicketManagementComponent,
        TicketManagementDetailComponent,
        ConversationComponent,
        FaqComponent,
        FaqDetailComponent,
        GestioneAffiliationComponent,
        GestioneAffiliationDetailComponent,
        AffiliationReportDetailComponent,
        AdvertisementTranslationDetailComponent,
        SocialComponent,
        AdvertisementDetailComponent,
        SocialDetailComponent,
        SocialFormListComponent,
        SocialFormComponent,
        UserComponent,
        CodeComponent,
        CodeDetailComponent,
        AffiliationReportComponent,
        PromotionComponent,
        PromotionDetailComponent,
        PromotionGenerationComponent,
        ProductComponent,
        ProductDetailComponent,
        UserSubscriptionsComponent,
        UserSubscriptionsDetailComponent,
        PriceExchangeComponent,
        PriceExchangeDetailComponent,
        SubscriptionsComponent,
        SubscriptionDetailComponent,
        StripeComponent,
        StripeDetailComponent,
        ReceiptComponent,
        ReceiptDetailComponent,
        SubscriptionExtensionComponent,
        SubscriptionExtensionDetailComponent,
        SubscriptionExtensionTableComponent,
        DisclaimerMessageComponent,
        DisclaimerMessageDetailComponent,
        TranslationsListComponent,
        DisclaimerMessageTranslationDetailComponent,
        UserSubscriptionSubsComponent,
        UserSubscriptionReceiptsComponent,
        ChangeLogComponent,
        ChangeLogDetailComponent,
        ChangeLogTranslationDetailComponent,
        ChangeLogTranslationsListComponent,
        CategoryComponent,
        CategoryDetailComponent,
        TemplateComponent,
        TemplateDetailComponent,
        CategoryConfigurationComponent,
        CategoryConfigurationDetailComponent,
        NotificationComponent,
        NotificationDetailComponent,
        NotificationGenerateComponent,
        NotificationQueueComponent,
        SmartContractAddressMappingComponent,
        SmartContractAddressMappingDetailComponent,
        AssetDefinitionInputComponent,
        ManagedSymbolDetailComponent,
        SymbolMappingDetailComponent,
        HomeComponent,
        AboutComponent,
        CryptoWidgetComponent,
        GenericDataComponent,
        ProviderComponent,
        ErrorsComponent,
        TimeseriesComponent,
        CatalogComponent,
        SegnalazioniComponent,
        AffiliationComponent,
        HomeSubscriptionsComponent,
        HomeNotificationsComponent,
        AltroComponent,
        ButtonsComponent,
        AffiliatedUsersComponent,
        UserDefinitionInputComponent,
        UsersDetailComponent,
        CheckForManagedSymbolsComponent,
        DataintegrationComponent,
        DataintegrationDetailComponent,
        DataintegrationChipListComponent,
        AssetDefinitionAliasMappingComponent,
        PositionsContainerComponent,
        CheckForManagedSymbolsComponent,
        CheckSquaringComponent,
        DeleteButtonComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    AppMaterialModule,
    AppRoutingModule,
    AvatarModule,
    ScrollingModule,
    MatAutocompleteModule,
    OAuthModule.forRoot( {
      resourceServer: {
          sendAccessToken: true
      }
    }),
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
          storage: AppStorage,
          translationLoader: HttpTranslationLoader
      }
    ),
    L10nIntlModule,
    L10nValidationModule.forRoot({ validation: LocaleValidation }),
  ],
  exports:[
    DateFormatPipe,
    TimestampFormatPipe
  ],
  providers: [
    NavigatorService,
    ComponentCacheService,
    DateFormatPipe,
    CoinmarketcapAssetMappingConfigurationService,
    TimestampFormatPipe,
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: FakeAuthenticationHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    }],
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
