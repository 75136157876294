import { ReceiptDTO } from './../../../shared/dto/be-subscriptions/receipt/ReceiptDTO';
import { ReceiptService } from './../../../services/be-subscriptions/receipt/receipt.service';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NavigatorService } from '../../../services/navigator.service';
import { Subject } from 'rxjs';
import { GenericDetailComponent } from '../../../shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-receipt-detail',
  templateUrl: './receipt-detail.component.html',
  styleUrls: ['./receipt-detail.component.scss']
})
export class ReceiptDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  receiptId: number;
  receipt: ReceiptDTO;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private receiptService: ReceiptService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      receiptType: new FormControl({ value: '', disabled: true}),
      startDate: new FormControl({ value: '', disabled: true}),
      endDate: new FormControl({ value: '', disabled: true }),
      ammountPaid: new FormControl({ value: '', disabled: true }),
      currency: new FormControl({ value: '', disabled: true }),
      userId: new FormControl({ value: '', disabled: true }),
      subscriptionId: new FormControl({ value: '', disabled: true }),
      idInfluencerAffiliation: new FormControl({ value: '', disabled: true }),
      idCodeAffiliation: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.receiptId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.receiptId !== 0 && this.receiptId != null) {
      this.priceExchangeRead();
    } else {
      this.receipt = new ReceiptDTO();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  //READ

  priceExchangeRead(): void {
    this.receiptService.read(this.receiptId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: ReceiptDTO) => {
          console.log('response : ', res);
          this.receipt = res;
          this.receiptId = res.id;
          this.dtoToForm();
        }
      );
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.receipt.id);
    this.form.get('receiptType').setValue(this.receipt.receiptType);
    this.form.get('startDate').setValue(this.receipt.startDate);
    this.form.get('endDate').setValue(this.receipt.endDate);
    this.form.get('ammountPaid').setValue(this.receipt.ammountPaid);
    this.form.get('currency').setValue(this.receipt.currency);
    this.form.get('userId').setValue(this.receipt.user?.id);
    this.form.get('subscriptionId').setValue(this.receipt.subscription?.id);
    this.form.get('idInfluencerAffiliation').setValue(this.receipt.idInfluencerAffiliation);
    this.form.get('idCodeAffiliation').setValue(this.receipt.idCodeAffiliation);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.receipt.insertDate));
    this.form.get('insertUser').setValue(this.receipt.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.receipt.updateDate));
    this.form.get('updateUser').setValue(this.receipt.updateUser);
    this.form.get('optLock').setValue(this.receipt.optLock);
    this.form.markAsPristine();
  }

}
