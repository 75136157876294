import { FormGroup, FormControl } from '@angular/forms';
import { FaqDTO } from '../shared/dto/help-desk/Faq';
import { FaqService } from '../services/help-desk/faq.service';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})

export class FaqComponent extends GenericListComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  displayedColumnsFaqs: string[];
  parameters: any;
  languages: string[] = ['it-IT', 'en-US'];
  form: FormGroup;

  constructor(
    private faqService: FaqService,
    componentCacheService: ComponentCacheService,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumnsFaqs = ['faq', 'detail'];
    this.parameters = {
      faqs: [],
      faqsPerCategories: [],
      categories: [],
      edit: [],
      category: null,
      question: null,
      userLocale: this.languages[0],
      showList: false,
      sortField: null,
      sort: null,
      page: 0,
      size: 50,
      length: 0
    };
    this.form = new FormGroup({
      category: new FormControl(''),
    });

  }

  searchFaqWithFilter(category, question): void {

    this.faqService.faqList(
      category,
      question,
      this.parameters.userLocale).subscribe(
        (res: FaqDTO) => {
          this.parameters.faqs[category] = res;
          this.parameters.showList = true;

          let i = 0;
          for (const f of this.parameters.faqs[category]) {
            i++;
          }
          this.parameters.faqsPerCategories[category] = i;
        }
      );
  }

  list(): void {
    this.faqService.categoryList(this.parameters.userLocale).subscribe(
      (res: string[]) => {
        this.parameters.categories = res;

        for (const category of this.parameters.categories) {
          this.parameters.edit[category] = false;
          this.searchFaqWithFilter(category, this.parameters.question);

        }
      }
    );


  }

  ngOnInit(): void {
    super.ngOnInit();

  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  clickEdit(category: string): void {
    for (const c of this.parameters.categories) {
      if (c !== category) {
        this.parameters.edit[c] = false;
      }
    }
    this.parameters.edit[category] = !this.parameters.edit[category];

  }

  updateCategory(category): void {
    if (this.checkChangeCateogory(this.form.get('category').value)) {
      for (let i = 0; i < this.parameters.faqs[category].length; i++) {
        this.parameters.faqs[category][i].category = this.form.get('category').value;
        this.faqService.update(this.parameters.faqs[category][i]).subscribe(
          (res) => {
            if (i === 0) {
              this.faqService.categoryList(this.parameters.userLocale).subscribe(
                (response: string[]) => {
                  this.parameters.categories = response;
                  this.searchFaqWithFilter(this.form.get('category').value, null);

                }
              );
            }
          }
        );
      }
    }
    else{
      this.snackBar.open('La categoria ' + this.form.get('category').value + ' esiste già o non è valida', null, { duration: 3000 });
    }

  }

  private checkChangeCateogory(category): boolean {
    for (const c of this.parameters.categories) {
      if (c === category) {
        return false;
      }
    }
    if(category == null || category === '' || category === ' ' ) {
      return false;
    }
    return true;
  }
}
