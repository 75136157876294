import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { ChangeLogDto } from './ChangeLog.types';

export class ChangeLogTranslationDto extends LogicalDeleteDTO {

	/**
	 * Translations id
	 */
	id: number;

	/**
	 * Body of the translations
	 */
	text: string;

	/**
	 * Title of the translations
	 */
	title: string;

	/**
	 * Language for the translations
	 */
	language: string;

	/**
	 * Referenced message from the translation
	 */
	changeLog: ChangeLogDto;

    constructor(message?: ChangeLogDto) {
        super();
        this.id = undefined;
		this.text = '';
        this.title = '';
		this.language = '';
		this.changeLog = message;
    }
}
