import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { PageDTO } from 'src/app/shared/dto/PageDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { NotificationChannel, NotificationStatus } from 'src/app/shared/dto/be-notifications/NotificationTypes';
import { NotificationMessageDTO } from 'src/app/shared/dto/be-notifications/notification/NotificationMessageDTO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends GenericService<NotificationMessageDTO, number> {

  noAdminurl: string;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/arzakena-be-notifications/notification/admin';
    this.noAdminurl = environment.urlPrefix + '/arzakena-be-notifications/notification';
    //this.url = 'http://localhost:31300/notification/admin';
    //this.noAdminurl = 'http://localhost:31300/notification';
  }

  public readAll(pageable: Pageable, categoryBundleKey: string, status: NotificationStatus, channel: NotificationChannel,
    logicalKey: string, username: string, email: string): Observable<PageDTO<NotificationMessageDTO>> {
    const params = {'categoryBundleKey': categoryBundleKey + '',
      'status': status + '',
      'channel': channel + '',
      'logicalKey': logicalKey + '',
      'username': username + '',
      'email': email + '',
      'page': pageable.page + '', 'size': pageable.size + '', 'sort': pageable.sort + ''};
    Object.keys(params).forEach(key => (params[key] === 'undefined' || params[key] === '') && delete params[key]);
    return this.http.get<PageDTO<NotificationMessageDTO>>(`${this.noAdminurl}/list`, { params: params });
  }
}
