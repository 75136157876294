import { ProductService } from '../../../services/be-subscriptions/product/product.service';
import { ProductDTO } from 'src/app/shared/dto/be-subscriptions/product/ProductDTO';
import { PromotionSubscriptionService } from './../../../services/be-subscriptions/subscription-promotion/promotion-subscription.service';
import { CryptoSubscriptionService } from './../../../services/be-subscriptions/subscription-crypto/crypto-subscription.service';
import { CardSubscriptionService } from './../../../services/be-subscriptions/subscription-card/card-subscription.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NavigatorService } from './../../../services/navigator.service';
import { SubscriptionService } from './../../../services/be-subscriptions/subscription/subscription.service';
import { GenericDetailComponent } from './../../../shared/GenericDetailComponent';
import { SubscriptionDTO, SubscriptionType } from './../../../shared/dto/be-subscriptions/subscription/SubscriptionDTO';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss']
})
export class SubscriptionDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  subscriptionType: SubscriptionType;
  subscriptionId: number;
  subscription: SubscriptionDTO;

  subscriptionTypes: string[] = ['CARD', 'CRYPTO', 'PROMOTION'];

  products: ProductDTO[];
  productTranslations: Map<string, string> = ProductDTO.translations;

  extensions: boolean = false;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private productService: ProductService,
    private subscritpionService: SubscriptionService,
    private cardSubscriptionService: CardSubscriptionService,
    private cryptoSubscriptionService: CryptoSubscriptionService,
    private promotionSubscriptionService: PromotionSubscriptionService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      subscriptionType: new FormControl({ value: '' }),
      username: new FormControl(''),

      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      upgrade: new FormControl(''),
      active: new FormControl(''),
      paid: new FormControl(''),
      pricePaid: new FormControl(''),
      ammountPaid: new FormControl(''),
      currency: new FormControl(''),
      product: new FormControl({value: ''}, [Validators.required]),

      customerId: new FormControl(''),
      paymentId: new FormControl(''),
      subscriptionId: new FormControl(''),
      paymentReceipt: new FormControl(''),
      endDateDisplay: new FormControl(''),

      invoiceId: new FormControl(''),
      transactionId: new FormControl(''),
      cryptoAmount: new FormControl(''),
      transactionCurrency: new FormControl(''),

      email: new FormControl(''),
      country: new FormControl(''),

      promotionCode: new FormControl(''),
      trial: new FormControl(''),
      free: new FormControl(''),

      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.subscriptionType = this.activeRoute.snapshot.paramMap.get('type') as SubscriptionType;
    this.subscriptionId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.subscriptionId !== 0 && this.subscriptionId != null) {
      this.form.get('subscriptionType').disable();
      this.form.get('product').disable();
      this.form.get('username').disable();
      this.subscriptionRead();
    } else {
      this.subscription = new SubscriptionDTO();
    }
    this.productService.readAll()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((res) => {
        this.products = res;
      }
    );
  }

  subscriptionSave(): void {
    if (this.subscription.id == null) {
      this.subscriptionCreate();
    } else {
      this.subscriptionUpdate();
    }
  }

  //READ

  subscriptionRead(): void {
    let sub$ = null;
    switch(this.subscriptionType){
      case 'CARD':
        sub$ = this.cardSubscriptionService.read(this.subscriptionId);
        break;
      case 'CRYPTO':
        sub$ = this.cryptoSubscriptionService.read(this.subscriptionId);
        break;
      case 'PROMOTION':
        sub$ = this.promotionSubscriptionService.read(this.subscriptionId);
        break;
    }
    sub$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: SubscriptionDTO) => {
          console.log('response : ' , res);
          this.subscription = res;
          this.subscriptionId = res.id;
          this.subscriptionType = res.subscriptionType;
          this.dtoToForm();
        }
      );
  }

  //CREATE

  subscriptionCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.productService.read(this.form.get('product').value).subscribe((product) => {
        let sub$ = null;
        this.subscription.product = product;
        switch(this.subscriptionType){
          case 'CARD':
            sub$ = this.cardSubscriptionService.createSub(this.subscription, this.form.get('username').value);
            break;
          case 'CRYPTO':
            sub$ = this.cryptoSubscriptionService.createSub(this.subscription, this.form.get('username').value);
            break;
          case 'PROMOTION':
            sub$ = this.promotionSubscriptionService.createSub(this.subscription, this.form.get('username').value);
            break;
        }
        sub$.pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: SubscriptionDTO) => {
            console.log('response : ' , res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.subscription = res;
            console.log(res);
            this.subscriptionId = this.subscription.id;
            this.subscriptionType = this.subscription.subscriptionType;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/subscription/detail/' + res.subscriptionType + '/' + res.id);
          }
        );
      });
    }
  }

  // UPDATE

  subscriptionUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere la subscription?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            let sub$ = null;
            switch(this.subscriptionType){
              case 'CARD':
                sub$ = this.cardSubscriptionService.update(this.subscription);
                break;
              case 'CRYPTO':
                sub$ = this.cryptoSubscriptionService.update(this.subscription);
                break;
              case 'PROMOTION':
                sub$ = this.promotionSubscriptionService.update(this.subscription);
                break;
            }
            sub$.pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.subscriptionRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  subscriptionDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare la subscription?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            let sub$ = null;
            switch(this.subscriptionType){
              case 'CARD':
                sub$ = this.cardSubscriptionService.delete(this.subscription.id);
                break;
              case 'CRYPTO':
                sub$ = this.cryptoSubscriptionService.delete(this.subscription.id);
                break;
              case 'PROMOTION':
                sub$ = this.promotionSubscriptionService.delete(this.subscription.id);
                break;
            }
            sub$.pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/subscription']);
                }
              );
          }
        });
    }
  }
  changeType(event: MatSelectChange): void{
    this.subscriptionType = event.value;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  showExtensions(): void {
    this.extensions = !this.extensions;
  }

  private formToDto(): void {
    this.subscription.subscriptionType = this.form.get('subscriptionType').value;

    this.subscription.startDate = this.form.get('startDate').value;
    this.subscription.endDate = this.form.get('endDate').value;
    this.subscription.upgrade = this.form.get('upgrade').value;
    this.subscription.active = this.form.get('active').value;
    this.subscription.paid = this.form.get('paid').value;
    this.subscription.pricePaid = this.form.get('pricePaid').value;
    this.subscription.ammountPaid = this.form.get('ammountPaid').value;
    this.subscription.currency = this.form.get('currency').value;

    this.subscription.customerId = this.form.get('customerId').value;
    this.subscription.paymentId = this.form.get('paymentId').value;
    this.subscription.subscriptionId = this.form.get('subscriptionId').value;
    this.subscription.paymentReceipt = this.form.get('paymentReceipt').value;
    this.subscription.endDateDisplay = this.form.get('endDateDisplay').value;

    this.subscription.invoiceId = this.form.get('invoiceId').value;
    this.subscription.transactionId = this.form.get('transactionId').value;
    this.subscription.cryptoAmount = this.form.get('cryptoAmount').value;
    this.subscription.transactionCurrency = this.form.get('transactionCurrency').value;

    this.subscription.email = this.form.get('email').value;
    this.subscription.country = this.form.get('country').value;

    this.subscription.promotionCode = this.form.get('promotionCode').value;
    this.subscription.trial = this.form.get('trial').value;
    this.subscription.free = this.form.get('free').value;
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.subscription.id);
    this.form.get('subscriptionType').setValue(this.subscription.subscriptionType);

    if(this.subscriptionId !== 0 && this.subscriptionId != null){
      this.subscritpionService.getUsername(this.subscriptionId).subscribe(
        (res) => {
          this.form.get('username').setValue(res);
        }
      );
    }

    this.form.get('startDate').setValue(this.subscription.startDate);
    this.form.get('endDate').setValue(this.subscription.endDate);
    this.form.get('upgrade').setValue(this.subscription.upgrade);
    this.form.get('active').setValue(this.subscription.active);
    this.form.get('paid').setValue(this.subscription.paid);
    this.form.get('pricePaid').setValue(this.subscription.pricePaid);
    this.form.get('ammountPaid').setValue(this.subscription.ammountPaid);
    this.form.get('currency').setValue(this.subscription.currency);
    this.form.get('product').setValue(this.subscription.product.id);

    this.form.get('customerId').setValue(this.subscription.customerId);
    this.form.get('paymentId').setValue(this.subscription.paymentId);
    this.form.get('subscriptionId').setValue(this.subscription.subscriptionId);
    this.form.get('paymentReceipt').setValue(this.subscription.paymentReceipt);
    this.form.get('endDateDisplay').setValue(this.subscription.endDateDisplay);

    this.form.get('invoiceId').setValue(this.subscription.invoiceId);
    this.form.get('transactionId').setValue(this.subscription.transactionId);
    this.form.get('cryptoAmount').setValue(this.subscription.cryptoAmount);
    this.form.get('transactionCurrency').setValue(this.subscription.transactionCurrency);

    this.form.get('email').setValue(this.subscription.email);
    this.form.get('country').setValue(this.subscription.country);

    this.form.get('promotionCode').setValue(this.subscription.promotionCode);
    this.form.get('trial').setValue(this.subscription.trial);
    this.form.get('free').setValue(this.subscription.free);

    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.subscription.insertDate));
    this.form.get('insertUser').setValue(this.subscription.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.subscription.updateDate));
    this.form.get('updateUser').setValue(this.subscription.updateUser);
    this.form.get('optLock').setValue(this.subscription.optLock);
    this.form.markAsPristine();
  }

}
