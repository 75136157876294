import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/services/be-notifications/category/category.service';
import { CategoryDTO } from 'src/app/shared/dto/be-notifications/category/CategoryDTO';
import { CategoryConfigurationService } from 'src/app/services/be-notifications/configuration/category-configuration.service';
import { CategoryConfigurationDTO } from 'src/app/shared/dto/be-notifications/configuration/CategoryConfigurationDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';

@Component({
  selector: 'app-notifications-category-configuration-detail',
  templateUrl: './category-configuration-detail.component.html',
  styleUrls: ['./category-configuration-detail.component.scss'],
  providers: [
    CategoryConfigurationService,
    CategoryService
  ]
})
export class CategoryConfigurationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  configurationId: number;
  configuration: CategoryConfigurationDTO;

  categories: CategoryDTO[];
  readonly channels = ['MAIL', 'BELL', 'POPUP'];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private categoryConfigurationService: CategoryConfigurationService,
    private categoryService: CategoryService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      category: new FormControl('', [Validators.required]),
      channel: new FormControl('', [Validators.required]),
      defaultValue: new FormControl('', [Validators.required]),
      editable: new FormControl('', [Validators.required]),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.configurationId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.configurationRead();

    this.categoryService.readAll({page: undefined, size: 1000, sort: undefined} as Pageable, undefined, undefined)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      this.categories = response.content;
    });
  }

  configurationSave(): void {
    this.configurationUpdate();
  }

  //READ

  configurationRead(): void {
    this.categoryConfigurationService.read(this.configurationId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: CategoryConfigurationDTO) => {
          console.log('response : ' , res);
          this.configuration = res;
          this.configurationId = res.id;
          this.dtoToForm();
        }
      );
  }

  // UPDATE

  configurationUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere la configuration?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.categoryConfigurationService.update(this.configuration)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.configurationRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  configurationDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare la configuration?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.categoryConfigurationService.delete(this.configuration.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/notifications/configuration']);
                }
              );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {

    this.configuration.category = this.categories.find((category) => category.id === this.form.get('category').value);
    this.configuration.channel = this.form.get('channel').value;
    this.configuration.defaultValue = this.form.get('defaultValue').value;
    this.configuration.editable = this.form.get('editable').value;

  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.configuration.id);
    this.form.get('category').setValue(this.configuration.category.id);
    this.form.get('channel').setValue(this.configuration.channel);
    this.form.get('defaultValue').setValue(this.configuration.defaultValue);
    this.form.get('editable').setValue(this.configuration.editable);
    this.form.get('optLock').setValue(this.configuration.optLock);
    this.form.markAsPristine();
  }

}
