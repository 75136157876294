<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Error Events</h2>
        <p>Imposta il filtro di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">
            
            <!-- filtri -->
            <!-- Source field -->
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.source" placeholder="Source" />
            </mat-form-field>

            <!-- Responsive -->
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                <!-- DataDa field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
                        [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataDa=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <!-- DataA field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
                        [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataA=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <!-- FailedClass field -->
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.failedClass" placeholder="FailedClass" />
            </mat-form-field>

        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button (click)="back('/')" color="primary">Back</button>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- source Column -->
                <ng-container matColumnDef="source">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
                    <td mat-cell *matCellDef="let event"> {{event.source}} </td>
                </ng-container>

                <!-- timestamp Column -->
                <ng-container matColumnDef="timestamp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </th>
                    <td mat-cell *matCellDef="let event"> {{event.timestamp}} </td>
                </ng-container>

                <!-- failedClass Column -->
                <ng-container matColumnDef="failedClass">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>FailedClass </th>
                    <td mat-cell *matCellDef="let event"> {{event.failedClass}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let event">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/asyncProcessErrorEvent/detail/' , event.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.size" [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                    (page)="setPaginator($event)"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>