import { TraceModificationDTO } from '../../basic/traceModificationDTO';

export class AsyncProcessWarningEventDTO extends TraceModificationDTO {

    id: number;

    source: string;

    timestamp: Date;

	warningClass: string;

	warnings: Set<string>;

}
