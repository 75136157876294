import { TraceModificationDTO } from '../basic/traceModificationDTO';
import { InfluencerDTO } from '../influencer/InfluencerDTO';


export class CodeDTO extends TraceModificationDTO {
    id: number;

    code: string;

    influencer: InfluencerDTO;

    expirationDate: Date;
}
