<div fxLayout="column" fxLayoutAlign="start none" class="body">
    <div fxLayout="row">

        <mat-card class="card">
            <mat-card-title>Utenti registrati</mat-card-title>
            <mat-card-content class="content" [ngClass]="'content-'+environmentName">
                {{ userCounted }}
            </mat-card-content>

        </mat-card>
        <mat-card class="card">
            <mat-card-title>Wallet registrati</mat-card-title>
            <mat-card-content class="content" [ngClass]="'content-'+environmentName">
                {{ walletCounted }}
            </mat-card-content>
        </mat-card>

        <mat-card class="card">
            <mat-card-title>Bitcoin gestiti</mat-card-title>
            <mat-card-content class="content" [ngClass]="'content-'+environmentName">
                {{ bitcoin | number:'1.0-4':'it-IT'}}
            </mat-card-content>
            <mat-card-footer class="card-footer">
                {{ (settingsService.btcValue*bitcoin) | number:'1.0-2':'it-IT' }}€
            </mat-card-footer>
        </mat-card>

    </div>


    <div fxLayout="row">
        <mat-card class="card">
            <mat-card-title>Utenti con almeno un wallet</mat-card-title>
            <mat-card-content class="content" [ngClass]="'content-'+environmentName">
                {{ oneWalletUsers }}
            </mat-card-content>
        </mat-card>

        <mat-card class="card">
            <mat-card-title>Transazioni totali gestite</mat-card-title>
            <mat-card-content class="content" [ngClass]="'content-'+environmentName">
                {{ totalTransactions }}
            </mat-card-content>
            
        </mat-card>


        <mat-card class="card">
            <mat-card-title>Ether gestiti</mat-card-title>
            <mat-card-content class="content" [ngClass]="'content-'+environmentName">
                {{ ether | number:'1.0-4':'it-IT'}}
            </mat-card-content>
            <mat-card-footer class="card-footer">
                {{ (settingsService.ethValue*ether) | number:'1.0-2':'it-IT' }}€
            </mat-card-footer>
        </mat-card>

    </div>


</div>