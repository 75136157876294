<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>FAQ management</h2>
    <p>Imposta i filtri di ricerca</p>


    <div fxLayout="column" class="responsive-mat-form">

      <!-- QUESTION FIELD -->
      <mat-form-field>
        <input matInput [(ngModel)]="parameters.question" placeholder="Domanda" />
      </mat-form-field>

      <!-- USERLOCALE FIELD -->
      <mat-form-field>
        <mat-select name="userLocale" [(ngModel)]="parameters.userLocale" placeholder="Language">
          <mat-option *ngFor="let language of languages" [value]="language">
            {{ language }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <mat-card-actions>
      <button (click)="list()" mat-raised-button id="search-button" name="Search" color="primary">Search</button>
      <button mat-raised-button id="search-button" name="Search" [routerLink]="['/faq/detail/0']">New</button>
      <button mat-raised-button (click)="back('/')" color="primary">Back</button>
    </mat-card-actions>

    <div *ngIf="parameters.showList" fxLayout="column">

      <div fxLayout="row" class="responsive-mat-form">
        <mat-card-actions fxFlex="25" fxFlex.xs="40">
          <button mat-button mat-raised-button (click)="accordion.closeAll()">Collapse All</button>
        </mat-card-actions>
        <mat-card-actions fxFlex="25">
          <button mat-button mat-raised-button (click)="accordion.openAll()">Open All</button>
        </mat-card-actions>
      </div>

      <mat-accordion multi>
        <div *ngFor="let category of parameters.categories">

          <!-- Category panels -->
          <mat-expansion-panel>
            <mat-expansion-panel-header #panelH>
              <mat-panel-title class="category">

                <div fxLayoutAlign="space-between center" class="responsive-mat-form">
                  <div fxFlex="90" fxLayout="column">
                    {{ category }} ({{ parameters.faqsPerCategories[category]}})
                  </div>

                  <div fxLayout="column" [ngStyle]="{'padding-right': 2 + '%'}">
                    <mat-icon class="unselectable" title="Rinomina categoria" (click)="panelH._toggle()" (click)="clickEdit(category)">edit
                    </mat-icon>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="parameters.edit[category]">

                  <mat-form-field [ngStyle]="{'padding-right': 4 + '%'}">
                    <form [formGroup]="form">
                      <input (keydown.Space)="$event.stopImmediatePropagation()" formControlName="category" [ngModel]="category" matInput (click)="panelH._toggle()" maxlength="255">
                    </form>
                  </mat-form-field>
                  <div fxLayout="column" [ngStyle]="{'padding-right': 2 + '%'}">
                    <mat-icon (click)="updateCategory(category)" (click)="panelH._toggle()">send</mat-icon>
                  </div>

                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
              [dataSource]="parameters.faqs[category]">
              <!-- Question field -->
              <ng-container matColumnDef="faq">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let faq">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>

                        <div class="div-title question" [innerHTML]="(faq.question.length>80)? (faq.question | slice:0:80) +'...':(faq.question)">
                          
                        </div>

                        <div [innerHTML]="(faq.answer.length>100)? (faq.answer | slice:0:100) +'...':(faq.answer)"
                          class="div-title answer">
                        </div>

                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="div-title">
                      <i>Riposta completa:</i><br><br>
                      <span [innerHTML]="faq.answer"></span>
                    </div>
                  </mat-expansion-panel>

                </td>
              </ng-container>


              <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let faq">

                  <button mat-mini-fab color="primary" [routerLink]="['/faq/detail/', faq.id]">
                    <mat-icon>search</mat-icon>
                  </button>

                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsFaqs"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsFaqs;"></tr>
            </table>
          </mat-expansion-panel>

        </div>
      </mat-accordion>
    </div>

    <br />
  </mat-card-content>
</mat-card>