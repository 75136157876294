import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/services/be-notifications/category/category.service';
import { CategoryDTO } from 'src/app/shared/dto/be-notifications/category/CategoryDTO';
import { CategoryConfigurationDTO } from 'src/app/shared/dto/be-notifications/configuration/CategoryConfigurationDTO';
import { NotificationService } from 'src/app/services/be-notifications/notification/notification.service';
import { NotificationMessageDTO } from 'src/app/shared/dto/be-notifications/notification/NotificationMessageDTO';
import { Pageable } from 'src/app/shared/dto/Pageable.types';

@Component({
  selector: 'app-notifications-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
  providers: [
    NotificationService,
    CategoryService
  ]
})
export class NotificationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  notificationId: number;
  notification: NotificationMessageDTO;

  categories: CategoryDTO[];
  readonly statuses = ['READ', 'UNREAD'];
  readonly channels = ['MAIL', 'BELL', 'POPUP'];

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(private notificationService: NotificationService,
    private categoryService: CategoryService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      category: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      channel: new FormControl('', [Validators.required]),
      logicalKey: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      header: new FormControl('', [Validators.required]),
      body: new FormControl('', [Validators.required]),
      attachments: new FormControl(''),
      readAt: new FormControl('', [Validators.required]),
      url: new FormControl(''),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true })
    });
  }

  ngOnInit(): void {
    this.notificationId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if (this.notificationId !== 0 && this.notificationId != null) {
      this.notificationRead();
    } else {
      this.notification = new NotificationMessageDTO();
    }
    this.categoryService.readAll({page: undefined, size: 1000, sort: undefined} as Pageable, undefined, undefined)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((response) => {
      this.categories = response.content;
    });
  }

  notificationSave(): void {
    if (this.notification.id == null) {
      this.notificationCreate();
    } else {
      this.notificationUpdate();
    }
  }

  //READ

  notificationRead(): void {
    this.notificationService.read(this.notificationId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: NotificationMessageDTO) => {
          console.log('response : ' , res);
          this.notification = res;
          this.notificationId = res.id;
          this.dtoToForm();
        }
      );
  }

  //CREATE

  notificationCreate(): void {
    if (this.form.valid) {
      this.formToDto();
      this.notificationService.create(this.notification)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res: NotificationMessageDTO) => {
            console.log('response : ' , res);
            this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
            this.notification = res;
            console.log(res);
            this.notificationId = this.notification.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.back('/notifications/notifications/detail/' + res.id);
          }
        );
    }
  }

  // UPDATE

  notificationUpdate(): void {
    if (this.form.valid && this.form.dirty) {
      this.confirm('Sei sicuro di voler sovrascrivere la notification?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.notificationService.update(this.notification)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Salvataggio avvenuto con successo!', null, { duration: 3000 });
                  this.notificationRead();
                  this.form.markAsPristine();
                }
              );
          }
        });
    }
  }

  //DELETE

  notificationDelete(): void {
    if (this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare la notification?')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result) {
            this.formToDto();
            this.notificationService.delete(this.notification.id)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (res) => {
                  console.log('response : ' , res);
                  this.snackBar.open('Cancellazione avvenuta con successo!', null, { duration: 3000 });
                  this.router.navigate(['/notifications/notifications']);
                }
              );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private formToDto(): void {
    this.notification.category = this.form.get('category').value;
    this.notification.status = this.form.get('status').value;
    this.notification.channel = this.form.get('channel').value;
    this.notification.logicalKey = this.form.get('logicalKey').value;
    this.notification.username = this.form.get('username').value;
    this.notification.email = this.form.get('email').value;
    this.notification.header = this.form.get('header').value;
    this.notification.body = this.form.get('body').value;
    this.notification.attachments = this.form.get('attachments').value;
    this.notification.readAt = this.form.get('readAt').value;
    this.notification.url = this.form.get('url').value;
  }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.notification.id);
    this.form.get('category').setValue(this.notification.category);
    this.form.get('status').setValue(this.notification.status);
    this.form.get('channel').setValue(this.notification.channel);
    this.form.get('logicalKey').setValue(this.notification.logicalKey);
    this.form.get('username').setValue(this.notification.username);
    this.form.get('email').setValue(this.notification.email);
    this.form.get('header').setValue(this.notification.header);
    this.form.get('body').setValue(this.notification.body);
    this.form.get('attachments').setValue(this.notification.attachments);
    this.form.get('readAt').setValue(this.notification.readAt);
    this.form.get('url').setValue(this.notification.url);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.notification.insertDate));
    this.form.get('insertUser').setValue(this.notification.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.notification.updateDate));
    this.form.get('updateUser').setValue(this.notification.updateUser);
    this.form.get('deleteDate').setValue(this.timestampFormatPipe.transform(this.notification.deleteDate));
    this.form.get('deleteUser').setValue(this.notification.deleteUser);
    this.form.get('optLock').setValue(this.notification.optLock);
    this.form.markAsPristine();
  }

}
