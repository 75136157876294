import { Component, Input } from '@angular/core';

@Component({
  selector: 'buttons-component',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent{
  @Input() btnTxt: string;
  @Input() btnIcon: string;
  @Input() btnLink: string;

	constructor() {}
}
