import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { TenantDTO } from '../shared/dto/domain/tenant';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { AuthConfig, OAuthService, JwksValidationHandler, OAuthEvent, OAuthInfoEvent, LoginOptions } from 'angular-oauth2-oidc';
import { filter, takeWhile } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppDTO } from '../shared/dto/config/app';
import { MediaObserver } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})

export class NavigatorService {

  loggedIn: boolean = false;

  accessToken: any;

  private readonly versionInfoURL = environment.sitePrefix + 'META-INF/maven/it.jenia.arzakena/arzakena-fe-backoffice/pom.properties';

  private readonly homeURL = environment.sitePrefix + '/';

  private appSource = new ReplaySubject<AppDTO>(1);

  private tenantSource = new ReplaySubject<TenantDTO>(1);

  private collaboratoreSource = new ReplaySubject<CollaboratoreDTO>(1);

  private screenWidthSource = new ReplaySubject<number>(1);

  private httpErrorSource = new ReplaySubject<HttpErrorResponse>(1);

  get app$(): Observable<AppDTO> {
     return this.appSource.asObservable();
  }

  get tenant$(): Observable<TenantDTO> {
    return this.tenantSource.asObservable();
  }

  get collaboratore$(): Observable<CollaboratoreDTO> {
    return this.collaboratoreSource.asObservable();
  }

  get screenWidth$(): Observable<number> {
    return this.screenWidthSource.asObservable();
  }

  get httpError$(): Observable<HttpErrorResponse> {
    return this.httpErrorSource.asObservable();
  }

  constructor(private oauthService: OAuthService, private http: HttpClient, public mediaObserver: MediaObserver) {
  }

  setScreenWidth(screenWidth: number): void {
    this.screenWidthSource.next(screenWidth);
  }

  public tenantInfo(): void {
    this.http.get(`${this.versionInfoURL}`, { responseType: 'text' }).subscribe(
      (data) => {
        const lines = data.split('\n');
        const properties: AppDTO = new AppDTO();
        for (const l of lines) {
          const line = l.trim();
          if (!line || line[0] === '#') {
            continue;
          }
          const keyValue = line.split('=');
          const key = keyValue[0].trim();
          const value = keyValue[1].trim();
          properties[key] = value;
        }
        this.appSource.next(properties);
      }, (error) => {}
    );
    /* this.http.get<ServerDTO>(environment.srvPrefix + 'actuator/info').subscribe(
      (res: ServerDTO) => {
        console.log("serverInfo response : " + res);
        this.serverSource.next(res);
      }
    ); */

    //set del tenant
    const tenantDto = new TenantDTO();
    tenantDto.id = 1;
    tenantDto.clientId = environment.clientId;
    tenantDto.issuer = environment.issuer;
    tenantDto.jwkProviderUrl = environment.jwkProviderUrl;
    this.tenantSource.subscribe(
      (res: TenantDTO) => {
        this.login(res);
      }
    );
    this.tenantSource.next(tenantDto);

  }

  public login(tenant: TenantDTO): void {
    if (environment.production) {
      const authConfig: AuthConfig = {
        issuer: tenant.issuer,
        redirectUri: window.location.href,
        clientId: tenant.clientId,
        scope: 'openid profile email',
        requireHttps: (tenant.issuer.startsWith('https') ? true : false),
        postLogoutRedirectUri: this.homeURL,
        strictDiscoveryDocumentValidation: false
      };
      this.oauthService.configure(authConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.events.pipe(
        takeWhile(() => !this.loggedIn),
        filter((e: any) => e.type === 'discovery_document_loaded'))
        .subscribe((event: OAuthEvent) => {
          const infoEvent: OAuthInfoEvent = event as OAuthInfoEvent;
          if (infoEvent.info != null && infoEvent.info.jwks != null) {
            const jwtHelper: JwtHelperService = new JwtHelperService();
            this.accessToken = jwtHelper.decodeToken(this.oauthService.getAccessToken());
            if (this.accessToken != null) {
              this.checkRole();
              this.postLogin();
            }
          }
        });
      this.oauthService.events.pipe(filter((e: any) => e.type === 'logout'))
        .subscribe((event: OAuthEvent) => {
          this.postLogout();
        });
      this.oauthService.loadDiscoveryDocumentAndLogin({
        onTokenReceived: (tokenParams) => {
          const jwtHelper: JwtHelperService = new JwtHelperService();
          this.accessToken = jwtHelper.decodeToken(tokenParams.accessToken);
          if (this.accessToken != null) {
            this.checkRole();
            this.postLogin();
          }
        }
      } as LoginOptions);
    } else {
      this.postLogin();
      this.accessToken = {
        // roles : environment.fakeRoles
        roles: ['ROLE_USER', 'ROLE_ANONYMOUS']
      };
    }
  }

  public postLogin(): void {
    const json = JSON.parse(JSON.stringify(this.accessToken));
    const collaboratoreDTO = new CollaboratoreDTO();
    collaboratoreDTO.id = 1;
    collaboratoreDTO.insertDate = new Date();
    collaboratoreDTO.insertUser = 'Pippo';
    collaboratoreDTO.nome = json.given_name;
    collaboratoreDTO.cognome = json.family_name;
    this.collaboratoreSource.subscribe(
      (res: CollaboratoreDTO) => {
        this.loggedIn = true;
      }
    );
    this.collaboratoreSource.next(collaboratoreDTO);

  }

  public logout(): void {
    if (environment.production) {
      this.oauthService.logOut();
    } else {
      this.postLogout();
    }
  }

  public postLogout(): void {
    this.collaboratoreSource.next(null);
    this.loggedIn = false;
    this.accessToken = null;
  }

  public checkRole(): void {
    if(!JSON.parse(JSON.stringify(this.accessToken)).realm_access.roles.includes('ROLE_ARZAKENA_ADMIN')){
      this.logout();
    }
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public setHttpError(httpError: HttpErrorResponse): void {
    this.httpErrorSource.next(httpError);
  }

  public isActive(value: string): boolean {
    return this.mediaObserver.isActive(value);
  }

}
